import React from "react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faGithub,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";

import { Formik } from "formik";
import * as Yup from "yup";

import { Routes } from "../../routes";
import BgImage from "../../assets/img/illustrations/signin.svg";

import { LOGIN_TYPE } from "../../contains/common";
import UserService from "../../services/user.services";

import { ERROR_MESSAGE } from "../../contains/errors";
import { showToast, readProfile } from "../../store/global";

const SignIn = () => {
  const userService = new UserService();

  const dispatch = useDispatch();

  const getProfile = async (body) => {
    try {
      if (body?.access_token) {
        await userService.getMe();
        dispatch(readProfile());
      }
    } catch (error) {
      throw error;
    }
  };

  const onValidate = Yup.object().shape({
    username: Yup.string()
      // TODO: Nghiệp vửa sửa đổi, chỉ cho phép login = email, phần này vẫn check email, phone.
      // Nếu thay đổi lại nghiệp vụ thì re-comment đoạn này
      // .matches(/(^[a-zA-z0-9.]+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$|^(0|84)\d{9}$)/, ERROR_MESSAGE.ACCOUNT_NOT_PASS)
      // .matches(/(^[a-zA-z0-9.]+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$)/, ERROR_MESSAGE.ACCOUNT_NOT_PASS)
      .required(ERROR_MESSAGE.USERNAME_REQUIRED),
    password: Yup.string().required(ERROR_MESSAGE.PASSWORD_REQUIRED),
  });

  const isEmail = (email) => {
    const re = /^[a-zA-z0-9.]+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
    return re.test(email);
  };

  const isPhone = (phone) => {
    const re = /^(0|84)\d{9}$/;
    return re.test(phone);
  };

  const onSubmit = async (values) => {
    try {
      // 1: login theo username
      // 2: login theo email hoặc số điện thoại.
      const type =
        isEmail(values.username) || isPhone(values.username)
          ? LOGIN_TYPE.EMAIL_OR_PHONE
          : LOGIN_TYPE.USERNAME;
      const body = await userService.login(
        values.username.trim(),
        values.password.trim(),
        type
      );
      await getProfile(body);
    } catch (error) {
      dispatch(
        showToast({
          message: error.message,
          type: "error",
        })
      );
    } finally {
    }
  };

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row
            className="justify-content-center form-bg-image"
            style={{ backgroundImage: `url(${BgImage})` }}
          >
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Sign in to Babiuni</h3>
                </div>

                <Formik
                  initialValues={{ username: "", password: "" }}
                  onSubmit={onSubmit}
                  validationSchema={onValidate}
                >
                  {(props) => (
                    <Form className="mt-4" onSubmit={props.handleSubmit}>
                      <Form.Group id="email" className="mb-4">
                        <Form.Label>Your Username</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faEnvelope} />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            isInvalid={
                              props.errors.username && props.touched.username
                            }
                            placeholder="Username"
                            name="username"
                            onChange={props.handleChange}
                            value={props.values.username}
                          />
                        </InputGroup>
                        {props.errors.username && props.touched.username && (
                          <div className="text-sm text-danger mt-2">
                            {props.errors.username}
                          </div>
                        )}
                      </Form.Group>
                      <Form.Group>
                        <Form.Group id="password" className="mb-4">
                          <Form.Label>Your Password</Form.Label>
                          <InputGroup>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faUnlockAlt} />
                            </InputGroup.Text>
                            <Form.Control
                              type="password"
                              isInvalid={
                                props.errors.password && props.touched.password
                              }
                              disabled={props.isSubmitting}
                              placeholder="Password"
                              name="password"
                              onChange={props.handleChange}
                              value={props.values.password}
                            />
                          </InputGroup>
                          {props.errors.password && props.touched.password && (
                            <div className="text-sm text-danger mt-2">
                              {props.errors.password}
                            </div>
                          )}
                        </Form.Group>
                        <div className="d-flex justify-content-between align-items-center mb-4">
                          <Form.Check type="checkbox">
                            <FormCheck.Input
                              id="defaultCheck5"
                              className="me-2"
                            />
                            <FormCheck.Label
                              htmlFor="defaultCheck5"
                              className="mb-0"
                            >
                              Remember me
                            </FormCheck.Label>
                          </Form.Check>
                          <Card.Link
                            as={Link}
                            to={Routes.ForgotPassword.path}
                            className="small text-end"
                          >
                            Lost password?
                          </Card.Link>
                        </div>
                      </Form.Group>
                      <Button variant="primary" type="submit" className="w-100">
                        Sign in
                      </Button>
                    </Form>
                  )}
                </Formik>

                <div className="mt-3 mb-4 text-center">
                  <span className="fw-normal">or login with</span>
                </div>
                <div className="d-flex justify-content-center my-4">
                  <Button
                    variant="outline-light"
                    className="btn-icon-only btn-pill text-facebook me-2"
                  >
                    <FontAwesomeIcon icon={faFacebookF} />
                  </Button>
                  <Button
                    variant="outline-light"
                    className="btn-icon-only btn-pill text-twitter me-2"
                  >
                    <FontAwesomeIcon icon={faTwitter} />
                  </Button>
                  <Button
                    variant="outline-light"
                    className="btn-icon-only btn-pil text-dark"
                  >
                    <FontAwesomeIcon icon={faGithub} />
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default SignIn;