import Profile1 from "../assets/img/team/profile-picture-1.jpg";
import Profile2 from "../assets/img/team/profile-picture-2.jpg";
import Profile3 from "../assets/img/team/profile-picture-3.jpg";
import Profile4 from "../assets/img/team/profile-picture-4.jpg";
import Profile5 from "../assets/img/team/profile-picture-5.jpg";

export default [
  {
    id: 1,
    read: false,
    image: Profile1,
    sender: "Jose Leos",
    time: "a few moments ago",
    link: "#",
    message: `Added you to an event "Project stand-up" tomorrow at 12:30 AM.`,
  },
  {
    id: 2,
    read: false,
    image: Profile2,
    sender: "Neil Sims",
    time: "2 hrs ago",
    link: "#",
    message: `You've been assigned a task for "Awesome new project".`,
  },
  {
    id: 3,
    read: false,
    image: Profile3,
    sender: "Roberta Casas",
    time: "5 hrs ago",
    link: "#",
    message: `Tagged you in a document called "First quarter financial plans".`,
  },
  {
    id: 4,
    read: true,
    image: Profile4,
    sender: "Joseph Garth",
    time: "1 day ago",
    link: "#",
    message: `New message: "Hey, what's up? All set for the presentation?"`,
  },
  {
    id: 5,
    read: true,
    image: Profile5,
    sender: "Jose Leos",
    time: "2 days ago",
    link: "#",
    message: `New message: "We need to improve the UI/UX for the landing page."`,
  },
];
