import React from "react";
import {
  Modal,
  Button,
  InputGroup,
  FormControl,
  Form,
} from "@themesberg/react-bootstrap";
import _ from "lodash";
import WeaningFoodsService from "../../services/weaningFoods.service";
import FileService from "../../services/file.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import Dropzone from "react-dropzone";
import mammoth from "mammoth";
import Select from "react-select";
import { toast } from "react-toastify";

export default class ModalFood extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bodyData: {},
    };

    this.fileService = new FileService();
  }

  componentDidMount() {
    this.getFoodGroups();
    this.getWeaningMethods();
    this.getWeaningStages();
    this.getWeaningProcessing();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(prevProps.dataDetail) !==
      JSON.stringify(this.props.dataDetail)
    ) {
      const { dataDetail = {} } = this.props;

      if (dataDetail?.id || dataDetail?.id === 0) {
        let newObj = {
          ...dataDetail,
          weaningFoodGroup: {
            value: dataDetail?.weaningFoodGroup?.code,
            label: dataDetail?.weaningFoodGroup?.name,
          },
          weaningMethods: dataDetail?.weaningMethod
            ? dataDetail?.weaningMethod.split(",").map((x) => x.trim())
            : [],
          weaningStages: dataDetail?.weaningStage
            ? dataDetail?.weaningStage.split(",").map((x) => x.trim())
            : [],
          weaningProcessions: dataDetail?.weaningProcessing
            ? dataDetail?.weaningProcessing.split(",").map((x) => x.trim())
            : [],
        };
        this.setState({
          bodyData: newObj,
          originalData: _.cloneDeep(newObj),
        });
      } else {
        this.setState({
          bodyData: {},
          originalData: {},
        });
      }
    }
  }

  onSubmit = async () => {
    const { bodyData = {}, originalData = {} } = this.state;
    const { getData } = this.props;

    if (!bodyData?.name) {
      toast.error("Vui lòng nhập tên thực phẩm");
      return;
    }

    if (!bodyData?.weaningFoodGroup) {
      toast.error("Vui lòng chọn nhóm thực phẩm");
      return;
    }

    // console.log('bodyData', bodyData)

    let body = {
      name: bodyData?.name.trim(),
      weaningFoodGroupCode: bodyData?.weaningFoodGroup?.value,
      image: bodyData?.image,
      weaningMethods: bodyData?.weaningMethods,
      weaningStages: bodyData?.weaningStages,
      weaningProcessions: bodyData?.weaningProcessions,
      weaningFoodDescription: bodyData?.weaningFoodDescription
        ? bodyData?.weaningFoodDescription.trim()
        : "",
    };

    try {
      if (bodyData?.id || bodyData?.id === 0) {
        if (body.name === originalData.name) {
          delete body.name;
        }
        await WeaningFoodsService.updateFood(bodyData?.id, body);
        toast.success("Sửa thành công");
      } else {
        await WeaningFoodsService.createFood(body);
        toast.success("Tạo thành công");
      }
      this.onToggle();
      getData();
    } catch (error) {
      toast.error(
        error?.message ||
          "Có lỗi xảy ra vui lòng liên hệ kỹ thuật để được hỗ trợ."
      );
    }
  };

  getFoodGroups = async () => {
    const resp = await WeaningFoodsService.getFoodGroups();
    this.setState({
      foodGroup: resp.data,
    });
  };

  getWeaningMethods = async () => {
    const resp = await WeaningFoodsService.getWeaningMethods();
    this.setState({
      weaningMethods: resp.data,
    });
  };

  getWeaningStages = async () => {
    const resp = await WeaningFoodsService.getWeaningStages();
    this.setState({
      weaningStages: resp.data,
    });
  };

  getWeaningProcessing = async () => {
    const resp = await WeaningFoodsService.getWeaningProcessing();
    this.setState({
      weaningProcessing: resp.data,
    });
  };

  handleFileUploadByPostsFormat = async (files) => {
    // console.log('vào handleFileUploadByPostsFormat')
    const file = files[0];
    let fileSize = file.size / 1024 / 1024; // in MiB
    if (fileSize > 500) {
      return alert("Dung lượng file lớn hơn 500 MiB, vui lòng chọn file khác.");
    }

    let { bodyData = {} } = this.state;

    let body = {
      objectType: "foods",
      displayType: 1,
    };
    // objectType: "posts"
    // objectId: ID bài viết
    // displayType: 1(thumbnail), 2( icon), 3(Cover), 4(video), 5(Audio), 6(Infographic), 7(avatar)

    try {
      const resp = await this.fileService.uploadImage(file, body);
      if (resp?.linkUrl?.length > 0) {
        bodyData.image = resp.linkUrl[0];
      }
    } catch (error) {
      console.log(error);
    }

    this.setState({ bodyData: bodyData });
  };

  handleImageUploadInDocx = async (dataUrl, fileName) => {
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    const file = new File([blob], fileName, { type: "image/png" });
    try {
      const resp = await this.fileService.uploadImage(file);
      return resp.linkUrl[0];
    } catch (error) {
      return dataUrl;
    }
  };

  handleFileUpload = async (files) => {
    const file = files[0];
    let fileSize = file.size / 1024 / 1024; // in MiB
    if (fileSize > 20) {
      return alert(
        "Dung lượng file lớn hơn 20 MiB, vui lòng xem lại nội dung bài viết"
      );
    }
    let { bodyData = {} } = this.state;

    try {
      let fDescription = "";
      let fName = "";
      let reader = new FileReader();
      const options = {
        convertImage: mammoth.images.imgElement((image) => {
          return image.read("base64").then(async (imageBuffer) => {
            const sourceImage =
              "data:" + image.contentType + ";base64," + imageBuffer;
            return {
              src: await this.handleImageUploadInDocx(sourceImage, Date.now()),
            };
          });
        }),
      };
      reader.onload = async function () {
        const doc = await mammoth.convertToHtml(
          { arrayBuffer: this.result },
          options
        );
        const html = doc?.value;

        if (html) {
          const div = document.createElement("div");
          div.innerHTML = html;
          fName = div.querySelector("h1").textContent;

          const h3 = div.getElementsByTagName("h3");
          if (h3[0]) {
            fDescription = h3[0].nextElementSibling.textContent;
          }
        }
      };
      reader.onloadend = async (e) => {
        await e.currentTarget.onload();
        this.setState({
          bodyData: {
            ...bodyData,
            name: fName,
            weaningFoodDescription: fDescription,
          },
        });
      };

      reader.readAsArrayBuffer(file);

      // console.log('fName', fName)
    } catch (error) {
      console.log(error);
    }
  };

  onItemChecked = (item, props) => {
    const { bodyData = {} } = this.state;
    let data = bodyData[props] || [];
    if (data.find((x) => x === item.code)) {
      data = data.filter((x) => x !== item.code);
    } else {
      data.push(item.code);
    }
    this.setState({ bodyData: { ...bodyData, [props]: data } });
  };

  onToggle = () => {
    const { toggle } = this.props;
    this.setState(
      {
        bodyData: {},
      },
      () => {
        toggle();
      }
    );
  };

  render() {
    const { modalStatus } = this.props;
    const {
      foodGroup = [],
      bodyData = {},
      weaningMethods = [],
      weaningStages = [],
      weaningProcessing = [],
    } = this.state;

    return (
      <Modal
        dialogClassName="modal-90w"
        as={Modal.Dialog}
        show={modalStatus}
        onHide={this.onToggle}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {bodyData?.foodId || bodyData?.foodId === 0
              ? "Chỉnh sửa thực phẩm"
              : "Thêm mới thực phẩm"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-row d-flex align-items-center">
            <div className="modal-label">Tên thực phẩm:</div>

            <FormControl
              value={bodyData.name}
              onChange={(e) =>
                this.setState({
                  bodyData: { ...bodyData, name: e.target.value },
                })
              }
              placeholder="Nhập tên thực phẩm"
            />
          </div>

          <div className="modal-row d-flex align-items-center">
            <div className="modal-label">Nhóm thực phẩm:</div>

            {foodGroup && (
              <Select
                className="custom-react-select"
                placeholder="Chọn nhóm thực phẩm"
                options={foodGroup.map((x) => {
                  return { value: x.code, label: x.name };
                })}
                value={bodyData.weaningFoodGroup}
                isMulti={false}
                onChange={(e) => {
                  this.setState({
                    bodyData: { ...bodyData, weaningFoodGroup: e },
                  });
                }}
                isClearable={true}
                backspaceRemovesValue={true}
              />
            )}
          </div>

          <div className="modal-row">
            <div className="d-flex align-items-center">
              <div className="modal-label">Tải lên ảnh thực phẩm:</div>
              <Dropzone
                accept={"image/*"}
                multiple={false}
                onDrop={(acceptedFiles) =>
                  this.handleFileUploadByPostsFormat(acceptedFiles)
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <section key={"dropzone"}>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <FontAwesomeIcon
                        icon={Icon.faCloudUploadAlt}
                        className="me-2"
                      />
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            <div className="d-flex align-items-center">
              <div className="modal-label" />
              {bodyData.image && (
                <img
                  src={bodyData.image}
                  style={{ height: 300, width: "auto" }}
                />
              )}
            </div>
          </div>

          <div className="modal-row d-flex align-items-center">
            <div className="modal-label">Tải lên file nội dung:</div>
            <Dropzone
              accept={".docx, .doc"}
              multiple={false}
              onDrop={(acceptedFiles) => this.handleFileUpload(acceptedFiles)}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <FontAwesomeIcon
                      icon={Icon.faCloudUploadAlt}
                      className="me-2"
                    />
                  </div>
                </section>
              )}
            </Dropzone>
          </div>

          <div className="modal-row d-flex align-items-center">
            <div className="modal-label">Phương thức ăn dặm:</div>
            <div className="d-flex align-items-center">
              {weaningMethods.map((item, index) => {
                return (
                  <Form.Check
                    key={index}
                    className="inbox-check pdr-30"
                    checked={
                      Array.isArray(bodyData?.weaningMethods) &&
                      bodyData?.weaningMethods.find((x) => x === item.code)
                    }
                    onChange={(e) => this.onItemChecked(item, "weaningMethods")}
                    label={item.name}
                  />
                );
              })}
            </div>
          </div>

          <div className="modal-row d-flex align-items-center">
            <div className="modal-label">Giai đoạn tuổi:</div>
            <div className="d-flex align-items-center">
              {weaningStages.map((item, index) => {
                return (
                  <Form.Check
                    key={index}
                    className="inbox-check pdr-30"
                    checked={
                      Array.isArray(bodyData?.weaningStages) &&
                      bodyData?.weaningStages.find((x) => x === item.code)
                    }
                    onChange={(e) => this.onItemChecked(item, "weaningStages")}
                    label={item.name}
                  />
                );
              })}
            </div>
          </div>

          {/*<div className="modal-row d-flex align-items-center">*/}
          {/*    <div className="modal-label">*/}
          {/*        Cách chế biến:*/}
          {/*    </div>*/}
          {/*    <div className="d-flex align-items-center">*/}
          {/*        {weaningProcessing.map((item,index) => {*/}
          {/*            return <Form.Check*/}
          {/*                key={index}*/}
          {/*                className="inbox-check pdr-30"*/}
          {/*                checked={Array.isArray(bodyData?.weaningProcessions) && bodyData?.weaningProcessions.find(x=>x === item.code)}*/}
          {/*                onChange={e => this.onItemChecked(item, 'weaningProcessions')}*/}
          {/*                label={item.name}*/}
          {/*            />*/}
          {/*        })}*/}
          {/*    </div>*/}
          {/*</div>*/}

          <div className="modal-row">
            <div className="d-flex align-items-center mgbt-5">
              <div className="modal-label">Mô tả:</div>
              <div />
            </div>
            <Form.Control
              value={bodyData?.weaningFoodDescription}
              onChange={(e) =>
                this.setState({
                  bodyData: {
                    ...bodyData,
                    weaningFoodDescription: e.target.value,
                  },
                })
              }
              as="textarea"
              placeholder="Nhập mô tả..."
              style={{ height: "100px" }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.onToggle}>
            Đóng
          </Button>
          <Button variant="primary" onClick={() => this.onSubmit()}>
            {bodyData?.id || bodyData?.id === 0 ? "Lưu" : "Tạo thực phẩm"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
