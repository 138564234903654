import { useWindowSize } from "src/hooks/customHooks";

export const GLOBAL_HEIGHT = 64 + 32 + 16 + 56 + 48;
export const DEFAULT_PAGE_SIZE = 20;
export const PAGE_SIZE_LIST = [10, 20, 50, 100];

export const NOTIFICATION_TYPES = [
  { id: 1, title: "Tin tức" },
  { id: 2, title: "Ưu đãi" },
  { id: 3, title: "Remind" },
  { id: 4, title: "Khác" },
];
