import React from "react";
import { Modal, Button, FormControl } from "@themesberg/react-bootstrap";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icon from "@fortawesome/free-solid-svg-icons";

import { toast } from "react-toastify";
import NurseryEducationsService from "../../services/nursery-educations.service";
import Select from "react-select";
import {
  NURSERY_EDUCATIONS_MONTHS,
  NURSERY_EDUCATIONS_TEACHING_AID_GROUP,
} from "../../contains/common";
import Dropzone from "react-dropzone";
import FileService from "../../services/file.service";

export default class ModalTeachingAids extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bodyData: {},
    };
    this.fileService = new FileService();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(prevProps.dataDetail) !==
      JSON.stringify(this.props.dataDetail)
    ) {
      const { dataDetail = {} } = this.props;

      let monthsArr = [];
      let monthObjArr = [];
      if (dataDetail?.months) {
        monthsArr = dataDetail?.months.split(",");
        monthsArr.map((item, index) => {
          let curMonth = NURSERY_EDUCATIONS_MONTHS.find((x) => x.code === item);
          if (curMonth) {
            monthObjArr.push({
              value: curMonth?.code,
              label: curMonth?.name,
            });
          }
        });
      }

      let todoArr = [];
      if (Array.isArray(dataDetail?.urls)) {
        dataDetail?.urls.map((item, index) => {
          todoArr.push({
            id: index + 1,
            url: item,
          });
        });
      }

      let newObj = {
        ...dataDetail,
        teachingGroup: dataDetail?.teachingGroup && {
          value: dataDetail?.teachingGroup,
          label: dataDetail?.teachingGroup,
        },
        months: monthObjArr,
      };

      this.setState({
        bodyData: newObj,
        originalData: _.cloneDeep(newObj),
        todoLists: todoArr,
      });
    }
  }

  handleFileUploadByPostsFormat = async (files) => {
    // console.log('vào handleFileUploadByPostsFormat')
    const file = files[0];
    let fileSize = file.size / 1024 / 1024; // in MiB
    if (fileSize > 500) {
      return alert("Dung lượng file lớn hơn 500 MiB, vui lòng chọn file khác.");
    }

    let { bodyData = {} } = this.state;

    let body = {
      objectType: "nurseryEducations",
      displayType: 1,
    };
    // objectType: "posts"
    // objectId: ID bài viết
    // displayType: 1(thumbnail), 2( icon), 3(Cover), 4(video), 5(Audio), 6(Infographic), 7(avatar)

    try {
      const resp = await this.fileService.uploadImage(file, body);
      if (resp?.linkUrl?.length > 0) {
        bodyData.image = resp.linkUrl[0];
      }
    } catch (error) {
      console.log(error);
    }

    this.setState({ bodyData: bodyData });
  };

  onSubmit = async () => {
    const { bodyData = {}, todoLists = [] } = this.state;
    const { getData } = this.props;

    if (!bodyData?.teachingName || !bodyData?.teachingName.trim()) {
      toast.error("Vui lòng nhập tên giáo cụ");
      return;
    }

    if (!bodyData?.description || !bodyData?.description.trim()) {
      toast.error("Vui lòng nhập mô tả");
      return;
    }

    if (!bodyData?.teachingGroup) {
      toast.error("Vui lòng chọn nhóm giáo cụ");
      return;
    }

    if (!Array.isArray(bodyData?.months) || bodyData?.months.length === 0) {
      toast.error("Vui lòng thêm tháng");
      return;
    }

    // console.log('bodyData', bodyData)
    let monthStr = "";
    if (Array.isArray(bodyData?.months)) {
      monthStr = ",";
      bodyData?.months.map((item, index) => {
        monthStr = monthStr + `${item?.value},`;
      });
    }
    let urls = [];
    todoLists.map((item, index) => {
      urls.push(item?.url);
    });

    let body = {
      teachingName: bodyData?.teachingName.trim(),
      teachingGroup: bodyData?.teachingGroup?.label,
      months: monthStr,
      urls: urls,
      description: bodyData?.description ? bodyData?.description.trim() : "",
      image: bodyData?.image,
    };

    try {
      if (bodyData?.id || bodyData?.id === 0) {
        await NurseryEducationsService.updateTeachingAid(bodyData?.id, body);
        toast.success("Sửa thành công");
      } else {
        await NurseryEducationsService.createTeachingAid(body);
        toast.success("Tạo thành công");
      }
      this.onToggle();
      getData();
    } catch (error) {
      let msg = "";
      switch (error?.message) {
        case "nursery_education_teaching_aid_duplicate":
          msg = "Giáo cụ đã tồn tại";
          break;
        case "nursery_education_teaching_aid_not_found":
          msg = "Giáo cụ không tồn tại";
          break;
        default:
          break;
      }
      toast.error(
        msg || "Có lỗi xảy ra vui lòng liên hệ kỹ thuật để được hỗ trợ."
      );
    }
  };

  onToggle = () => {
    const { toggle } = this.props;
    this.setState(
      {
        bodyData: {},
      },
      () => {
        toggle();
      }
    );
  };

  renderToDoList = () => {
    const { todoLists = [] } = this.state;

    return (
      <div>
        <div className="modal-row d-flex align-items-center">
          <div className="modal-label-small">Link URL:</div>

          <div
            className="cursor-pointer"
            onClick={() => {
              todoLists.push({
                id:
                  todoLists.length > 0
                    ? todoLists[todoLists.length - 1].id + 1
                    : 1,
                url: "",
              });
              this.setState({ todoLists });
            }}
          >
            <FontAwesomeIcon icon={Icon.faPlusCircle} className="me-2" />
          </div>
        </div>
        <div className="modal-row d-flex align-items-center">
          <div className="modal-label-small"></div>

          <div className="video-segments-container">
            {todoLists.map((item, index) => {
              return (
                <div
                  className="modal-row d-flex align-items-center"
                  key={index}
                >
                  <FormControl
                    value={item.url}
                    onChange={(e) => {
                      let curr = todoLists[index];
                      curr.url = e.target.value;
                      todoLists[index] = curr;
                      this.setState({ todoLists });
                    }}
                    placeholder="Nhập url"
                  />
                  <div
                    className="cursor-pointer pdl-10"
                    onClick={() => {
                      let arr = todoLists.filter((x) => x.id !== item.id);
                      this.setState({ todoLists: arr });
                    }}
                  >
                    <FontAwesomeIcon icon={Icon.faTrash} className="me-2" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { modalStatus } = this.props;
    const { bodyData = {} } = this.state;
    return (
      <Modal
        dialogClassName="modal-50w"
        as={Modal.Dialog}
        show={modalStatus}
        onHide={this.onToggle}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {bodyData?.id || bodyData?.id === 0
              ? "Chỉnh sửa giáo cụ"
              : "Thêm mới giáo cụ"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-row d-flex align-items-center">
            <div className="modal-label-small">Tên giáo cụ:</div>

            <FormControl
              value={bodyData.teachingName}
              onChange={(e) =>
                this.setState({
                  bodyData: { ...bodyData, teachingName: e.target.value },
                })
              }
              placeholder="Nhập tên giáo cụ"
            />
          </div>

          <div className="modal-row d-flex align-items-center">
            <div className="modal-label-small">Nhóm giáo cụ:</div>

            <Select
              className="custom-react-select"
              placeholder="Chọn nhóm giáo cụ"
              options={NURSERY_EDUCATIONS_TEACHING_AID_GROUP.map((x) => {
                return { value: x.name, label: x.name };
              })}
              value={bodyData?.teachingGroup}
              isMulti={false}
              onChange={(e) => {
                this.setState({ bodyData: { ...bodyData, teachingGroup: e } });
              }}
              isClearable={true}
              backspaceRemovesValue={true}
            />
          </div>

          <div className="modal-row d-flex align-items-center">
            <div className="modal-label-small">Tháng:</div>

            <Select
              className="custom-react-select"
              placeholder="Chọn tháng"
              options={NURSERY_EDUCATIONS_MONTHS.map((x) => {
                return { value: x.code, label: x.name };
              })}
              value={bodyData?.months}
              isMulti={true}
              onChange={(e) => {
                let arr = e;
                if (e.find((x) => x.value === "ALL")) {
                  arr = NURSERY_EDUCATIONS_MONTHS.filter(
                    (x) => x.code !== "ALL"
                  ).map((x) => {
                    return { value: x.code, label: x.name };
                  });
                }
                this.setState({ bodyData: { ...bodyData, months: arr } });
              }}
              isClearable={true}
              backspaceRemovesValue={true}
            />
          </div>

          <div className="modal-row d-flex align-items-start">
            <div className="modal-label-small">Mô tả:</div>

            <FormControl
              value={bodyData.description}
              onChange={(e) =>
                this.setState({
                  bodyData: { ...bodyData, description: e.target.value },
                })
              }
              as="textarea"
              rows="5"
              placeholder="Nhập mô tả"
            />
          </div>

          <div className="modal-row">
            <div className="d-flex align-items-center">
              <div className="modal-label-small">Ảnh bìa bài viết:</div>
              <Dropzone
                accept={"image/*"}
                multiple={false}
                onDrop={(acceptedFiles) =>
                  this.handleFileUploadByPostsFormat(acceptedFiles)
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <section key={"dropzone"}>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <FontAwesomeIcon
                        icon={Icon.faCloudUploadAlt}
                        className="me-2"
                      />
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            <div className="d-flex align-items-center">
              <div className="modal-label-small" />
              <div>
                {bodyData.image && (
                  <img
                    src={bodyData.image}
                    style={{ maxHeight: 300, maxWidth: "100%" }}
                  />
                )}
              </div>
            </div>
          </div>

          {this.renderToDoList()}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.onToggle}>
            Đóng
          </Button>
          <Button variant="primary" onClick={() => this.onSubmit()}>
            {bodyData?.id || bodyData?.id === 0 ? "Lưu" : "Tạo"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
