import React from "react";
import SurveysService from "../../services/surveys.service";
import moment from "moment";
import {
  Breadcrumb,
  Spinner,
  Table,
  Pagination,
  Badge,
} from "@themesberg/react-bootstrap";
import { Routes } from "../../routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import { Helper } from "../../common/helper";

import { toast } from "react-toastify";
import ModalForm from "./ModalForm";

const pageSize = 20;
export default class Foods extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      listChecked: [],
      page: 1,
      pageSize: pageSize,
      modalShow: false,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true }, () => {
      this.getData();
    });
  }

  getData = async () => {
    const { page, pageSize } = this.state;
    let queryString = this.buildQueryString();
    if (queryString) {
      queryString =
        queryString +
        `&page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdDate:desc`;
    } else {
      queryString = `?page=${
        page ? page - 1 : 0
      }&size=${pageSize}&sort=createdDate:desc`;
    }

    const resp = await SurveysService.listSurveys(queryString);
    const headers = resp.headers || {};
    this.setState({
      data: resp.data,
      metadata: {
        pageCount: parseFloat(headers["x-page-count"]),
        page: parseFloat(headers["x-page-number"]),
        size: parseFloat(headers["x-page-size"]),
        total: parseFloat(headers["x-total-count"]),
      },
      isLoading: false,
    });
  };

  getDetailData = async (id) => {
    const resp = await SurveysService.getDetail(id);
    this.setState({
      dataDetail: resp.data,
      isLoadingDetail: false,
      modalShow: true,
    });
  };

  changeStatusData = async (id, status) => {
    try {
      await SurveysService.changeActiveSurvey(id, status === 0 ? 1 : 0);
      this.getData();
      toast.success("Đổi trạng thái thành công");
    } catch (err) {
      toast.error(
        err?.message ||
          "Có lỗi xảy ra vui lòng liên hệ kỹ thuật để được hỗ trợ."
      );
    }
  };

  deleteSurvey = async (id) => {
    try {
      await SurveysService.deleteSurvey(id);
      this.getData();
      toast.success("Xóa bản ghi thành công");
    } catch (err) {
      // console.log('rẻe', err)
      toast.error(
        err?.message ||
          "Có lỗi xảy ra vui lòng liên hệ kỹ thuật để được hỗ trợ."
      );
    }
  };

  onPageChanged = (page) => {
    this.setState({ page: page }, () => {
      this.getData();
    });
  };

  /**
   * xử lý với dữ liệu search trong state
   */
  onSubmitSearch = () => {
    this.setState({ page: 1 }, () => {
      this.getData();
    });
  };

  /**
   * xây dựng query string theo các giá trị trong state
   * @returns {string}
   */
  buildQueryString = () => {
    let { listChecked, startValue, endValue, querySearch } = this.state;
    let queryString = "";
    for (let p in listChecked) {
      let value = "";
      if (Array.isArray(listChecked[p]) && listChecked[p].length > 0) {
        listChecked[p].map((x) => {
          value = value + x + ",";
        });

        value = value.slice(0, -1);
      }
      if (value)
        queryString = Helper.updateQueryStringParameter(queryString, p, value);
    }

    if (querySearch) {
      queryString = Helper.updateQueryStringParameter(
        queryString,
        "query",
        querySearch.trim()
      );
    }

    return queryString;
  };

  toggleModal = () => {
    if (this.state.modalShow) {
      this.setState({
        modalShow: false,
        dataDetail: {},
      });
    } else {
      this.setState({
        modalShow: true,
      });
    }
  };

  renderRow = (item, index) => {
    const { listItemChecked = [] } = this.state;
    return (
      <tr key={index}>
        <td>
          <span className="fw-normal">
            <b>{index + 1}</b>
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {item?.surveyReceiver?.name || "---"}
          </span>
        </td>
        <td>
          <span className={`fw-bold`}>{item?.title || "---"}</span>
        </td>
        <td>
          <span className={`fw-normal`}>
            {moment(item?.createdDate).format("DD/MM/YYYY")}
          </span>
        </td>
        <td>
          <span className={`fw-normal`}>{item?.questionNumber || 0}</span>
        </td>
        <td>
          {item?.active ? (
            <Badge className="custom-badge" bg="success">
              Hoạt động
            </Badge>
          ) : (
            <Badge className="custom-badge" bg="secondary">
              Ngừng hoạt động
            </Badge>
          )}
        </td>
        <td>
          <div ref={(ref) => (this.actionContainer = ref)} className="dp-flex">
            <div
              onClick={() => this.getDetailData(item.id)}
              className={"table-action-btn"}
            >
              <FontAwesomeIcon icon={Icon.faEye} className="me-2" />
            </div>
            <div
              onClick={() => this.changeStatusData(item.id, item.active)}
              className={"table-action-btn"}
            >
              <FontAwesomeIcon icon={Icon.faExchangeAlt} className="me-2" />
            </div>
            <div
              onClick={(e) => {
                if (window.confirm("Bạn có chắc muốn tiếp tục thao tác?")) {
                  this.deleteSurvey(item?.id);
                } else {
                  console.log("cancel called");
                }
              }}
              className={"table-action-btn"}
            >
              <FontAwesomeIcon icon={Icon.faTrashAlt} className="me-2" />
            </div>
          </div>
        </td>
      </tr>
    );
  };

  renderContentFood = () => {
    const { data = [], page, metadata = {} } = this.state;
    if (this.state.isLoading) {
      return (
        <div className="d-flex justify-content-center pt-8">
          <Spinner animation="border" />
        </div>
      );
    }

    return (
      <div>
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th key={2} className="border-bottom">
                STT
              </th>
              <th key={3} className="border-bottom">
                Đối tượng nhận
              </th>
              <th key={4} className="border-bottom">
                Tiêu đề
              </th>
              <th key={5} className="border-bottom">
                Ngày tạo
              </th>
              <th key={6} className="border-bottom">
                Số lượng câu hỏi
              </th>
              <th key={7} className="border-bottom">
                Trạng thái
              </th>
              <th key={8} className="border-bottom" />
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => this.renderRow(item, index))}
          </tbody>
        </Table>
        <Pagination className="mb-2 mb-lg-0">
          <Pagination.Prev
            disabled={metadata?.page === 0}
            onClick={(e) => this.onPageChanged(page - 1)}
          >
            Previous
          </Pagination.Prev>
          {[...Array(metadata?.pageCount).keys()].map((p) => (
            <Pagination.Item
              key={`page-${p}`}
              onClick={(e) => this.onPageChanged(p + 1)}
              active={metadata.page === p}
            >
              {" "}
              {p + 1}{" "}
            </Pagination.Item>
          ))}
          <Pagination.Next
            disabled={metadata?.page + 1 === metadata?.pageCount}
            onClick={(e) => this.onPageChanged(page + 1)}
          >
            Next
          </Pagination.Next>
        </Pagination>
      </div>
    );
  };

  render() {
    const { data, modalShow, dataDetail = {} } = this.state;
    if (!Array.isArray(data)) {
      return <div>Có lỗi xảy ra vui lòng liên kệ kỹ thuật</div>;
    }

    return (
      <div>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-md-0">
            <Breadcrumb
              className="d-none d-md-inline-block"
              listProps={{
                className: "breadcrumb-dark breadcrumb-transparent",
              }}
            >
              <Breadcrumb.Item to={Routes.DashboardOverview.path}>
                <FontAwesomeIcon icon={Icon.faHome} />
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Surveys</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>

        <div>
          <div className="dp-flex justify-content-space-between align-items-center">
            <div
              onClick={(e) => {
                this.setState({ dataDetail: {} }, () => {
                  this.toggleModal();
                });
              }}
              className="custom-btn"
            >
              <FontAwesomeIcon icon={Icon.faPlus} className="me-2" /> Thêm
              Surveys
            </div>
          </div>

          {this.renderContentFood()}
        </div>

        <ModalForm
          dataDetail={dataDetail}
          toggle={this.toggleModal}
          getData={this.getData}
          modalStatus={modalShow}
        />
      </div>
    );
  }
}
