import { useEffect, useState, useRef } from "react";
import {
  Button,
  Col,
  Input,
  Pagination,
  Row,
  Select,
  Space,
  Spin,
  Table,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useWindowSize } from "src/hooks/customHooks";
import { GLOBAL_HEIGHT } from "src/utils/constant";
import { HiddenButton } from "../../components/exsoft/HiddenButton";
import { ModalCreatePostBlog } from "./ModalCreatePostBlog";
import { TitleColumn } from "src/components/table/TitleColumn";
import websiteExsoftServices from "src/services/exsoft.services";

type State = {
  loading: boolean;
  showModal: boolean;
  dataModal: BlogItem | undefined;
  categories: CategoryBlogItem[];
  blogs: BlogItem[];
  total: number;
};

const WebsiteBlog = () => {
  const COLUMNS: ColumnsType<any> = [
    {
      title: <TitleColumn title={"STT"} />,
      dataIndex: "userId",
      key: "userId",
      width: "8%",
      render: (_, __, index: number) => index + 1,
    },
    {
      title: <TitleColumn title={"Tên bài viết"} />,
      dataIndex: "name",
      key: "name",
    },
    {
      title: <TitleColumn title={"Chuyên mục"} />,
      dataIndex: "categoryBlog",
      key: "categoryBlog",
      width: "12%",
      render: (_, { categoryBlog }) => (
        <p style={{ fontSize: 14 }}>{categoryBlog.name}</p>
      ),
    },
    {
      title: <TitleColumn title={"Người tạo"} />,
      dataIndex: "createdBy",
      key: "createdBy",
      width: "12%",
      render: (_, { createdBy }) => <p style={{ fontSize: 14 }}>{createdBy}</p>,
    },
    {
      title: <TitleColumn title={"Ngày tạo"} />,
      dataIndex: "addedTimestamp",
      key: "addedTimestamp",
      width: "12%",
      render: (_, { addedTimestamp }) => (
        <p style={{ fontSize: 14 }}>
          {moment(addedTimestamp).format("DD/MM/YYYY")}
        </p>
      ),
    },
    {
      title: <TitleColumn title={"Hidden"} />,
      dataIndex: "createdDate",
      key: "createdDate",
      width: "8%",
      render: (_, item: BlogItem) => (
        <HiddenButton item={item} onHidden={onHiddenBlog} />
      ),
    },
    {
      title: <TitleColumn title={"Thao tác"} />,
      dataIndex: "action",
      key: "action",
      width: "10%",
      render: (_, item: BlogItem, __) => (
        <p
          style={{ textDecoration: "none", color: "#277CBE", fontSize: 14 }}
          onClick={() => onShowEditModal(item)}
        >
          Chỉnh sửa
        </p>
      ),
    },
  ];
  const windowSize = useWindowSize();

  const searchParams = useRef<SearchBlogParams>({
    name: "",
    categoryBlogId: null,
    page: 0,
    size: 20,
  });

  const [state, setState] = useState<State>({
    loading: true,
    showModal: false,
    dataModal: undefined,
    categories: [],
    blogs: [],
    total: 0,
  });

  useEffect(() => {
    getCategoryBlog();
    getBlogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBlogs = async (params?: SearchBlogParams) => {
    const { data, headers } = await websiteExsoftServices.getBlogs(params);
    setState((cur) => ({
      ...cur,
      blogs: data ?? [],
      loading: false,
      total: parseFloat(headers["x-total-count"]),
    }));
  };

  const getCategoryBlog = async () => {
    const response = await websiteExsoftServices.categoryBlogs();
    setState((cur) => ({
      ...cur,
      categories: response.data ?? [],
    }));
  };

  const onSearch = () => {
    getBlogs(searchParams.current);
  };

  const onShowModal = () => {
    setState((cur: State) => ({
      ...cur,
      dataModal: undefined,
      showModal: true,
    }));
  };

  const onShowEditModal = (data: BlogItem) => {
    setState((cur: State) => ({
      ...cur,
      dataModal: data,
      showModal: true,
    }));
  };

  const onHideModal = () => {
    setState((cur) => ({
      ...cur,
      showModal: false,
    }));
  };

  const onHiddenBlog = async (id: number, hidden: boolean) => {
    return await websiteExsoftServices.hiddenBlog(id, hidden);
  };

  const onShowSizeChange = (page: number, size: number) => {
    searchParams.current.page = page - 1;
    searchParams.current.size = size;
    getBlogs(searchParams.current);
  };

  const onChangeText = (e: any) => {
    searchParams.current.name = e.target.value;
  };

  const onSelectCategory = (value: number) => {
    searchParams.current.categoryBlogId = value ?? null;
  };

  const { Option } = Select;

  let tableHeight = windowSize?.height - GLOBAL_HEIGHT - 52;

  return (
    <Spin spinning={state.loading} tip={"Loading..."}>
      <Row>
        <Col span={4}>
          <Button type={"primary"} onClick={onShowModal}>
            Thêm mới
          </Button>
        </Col>
        <Col
          span={20}
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Space>
            <Input
              placeholder="Nhập tên bài viết"
              onChange={onChangeText}
              style={{ width: 250 }}
            />
            <Select
              allowClear
              placeholder="Chọn chuyên mục"
              optionFilterProp="children"
              style={{ width: 200 }}
              onChange={onSelectCategory}
            >
              {state.categories.map((item: CategoryBlogItem) => (
                <Option value={item.id} key={"cate" + item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
            <Button type="primary" onClick={onSearch}>
              Tìm kiếm
            </Button>
          </Space>
        </Col>
      </Row>

      <Table
        style={styles.marginTop12}
        dataSource={state.blogs}
        rowKey={"id"}
        columns={COLUMNS}
        pagination={false}
        scroll={{ y: tableHeight }}
      />
      <Pagination
        style={styles.marginTop12}
        showSizeChanger
        onChange={onShowSizeChange}
        onShowSizeChange={onShowSizeChange}
        defaultCurrent={1}
        total={state.total}
        showTotal={(total) => `Tổng số ${total}`}
      />
      {state.showModal && (
        <ModalCreatePostBlog
          data={state.dataModal}
          categories={state.categories}
          onHide={onHideModal}
        />
      )}
    </Spin>
  );
};

export { WebsiteBlog };

const styles = {
  marginTop12: {
    marginTop: 12,
  },
};
