import {
  Input,
  Modal,
  Button,
  Select,
  Upload,
  Form,
  notification,
  Spin,
  FormInstance,
} from "antd";
import ImgCrop from "antd-img-crop";
import type { UploadFile, UploadProps } from "antd/es/upload/interface";
import Editor from "src/components/Editor";
import { useEffect, useState } from "react";
import "./index.scss";
import FileService from "src/services/file.service";
import { isNullOrEmpty } from "src/utils/Lang";
import { CloseCircleOutlined } from "@ant-design/icons";
import websiteExsoftServices from "src/services/exsoft.services";
import React from "react";

type Props = {
  data: BlogItem | undefined;
  onHide: () => void;
  categories: CategoryBlogItem[];
};

type UploadImageState = {
  link: string | null;
  isUpload: boolean;
};

const ModalCreatePostBlog = (props: Props) => {
  const { data, onHide, categories } = props;

  const fileService = new FileService();

  const [form] = Form.useForm();
  const formRef = React.useRef<FormInstance<CreateBlogParams> | null>(null);

  const isCreatePost = isNullOrEmpty(data?.id);

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const [imgUpload, setImgUpload] = useState<UploadImageState>({
    link: data?.image ?? null,
    isUpload: false,
  });

  useEffect(() => {
    formRef?.current?.setFieldsValue({
      name: data?.name,
      categoryBlogId: data?.categoryBlog?.id,
      content: data?.content ?? "",
      image: data?.image,
    });
  }, [data]);

  const uploadImage = async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;

    try {
      setImgUpload((cur) => ({ ...cur, isUpload: true }));
      const res = await fileService.uploadImage(file);
      if (res?.linkUrl?.[0]) {
        onSuccess("Ok");
      } else {
        notification.error({ message: "Uploading image failed" });
      }
      setImgUpload((cur) => ({
        ...cur,
        isUpload: false,
        link: res?.linkUrl?.[0] ?? null,
      }));
    } catch (err) {
      notification.error({ message: "Uploading image failed" });
      onError({ err });
    }
  };

  const onChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const handleSubmit = async (values: CreateBlogParams) => {
    let response;
    let message;
    if (isCreatePost) {
      response = await websiteExsoftServices.createBlog({
        ...values,
        image: imgUpload.link,
      });
      message = "Tạo";
    } else {
      response = await websiteExsoftServices.updateBlog(data?.id ?? 0, {
        ...values,
        image: imgUpload.link,
      });
      message = "Cập nhật";
    }

    if (response?.status === 200) {
      notification.success({
        message: `${message} bài viết thành công`,
      });
      onHide();
    } else {
      notification.error({
        message: `${message} bài viết không thành công`,
      });
    }
  };

  const { Option } = Select;

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 18 },
  };

  return (
    <Modal
      open={true}
      style={{ top: 20 }}
      title={data?.id ? "Cập nhật bài viết" : "Thêm mới bài viết"}
      onCancel={onHide}
      footer={[]}
      width={"75%"}
    >
      <Spin spinning={imgUpload.isUpload}>
        <Form
          form={form}
          name="control-hooks"
          onFinish={handleSubmit}
          ref={formRef}
          {...formItemLayout}
        >
          <Form.Item
            name="name"
            label="Tên bài viết"
            rules={[{ required: true }]}
          >
            <Input placeholder="Nhập tên bài viết" />
          </Form.Item>

          <Form.Item name="image" label="Ảnh:">
            <div style={{ width: 150 }}>
              <Upload
                fileList={fileList}
                onChange={onChange}
                accept="image/*"
                customRequest={uploadImage}
              >
                {fileList.length < 1 && "+ Upload"}
              </Upload>
            </div>

            {/* <div>
                <Image width={200} src={data?.image} />
                <CloseCircleOutlined
                  style={{
                    height: 40,
                    width: 40,
                    position: "absolute",
                    left: 190,
                    top: 10,
                  }}
                  onClick={() => {
                    setImgUpload((cur) => ({ ...cur, link: null }));
                  }}
                />
              </div> */}
          </Form.Item>

          <Form.Item
            name="categoryBlogId"
            label="Chuyên mục:"
            rules={[{ required: true }]}
          >
            <Select placeholder="Chọn chuyên mục" optionFilterProp="children">
              {categories.map((item: CategoryBlogItem) => (
                <Option value={item.id} key={"cate" + item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="content"
            label="Nội dung"
            initialValue=""
            rules={[{ required: true }]}
          >
            <Editor key={"editor1"} className={"ql-editor"} />
          </Form.Item>

          <Form.Item>
            <div className="form-group">
              <Button type="primary" htmlType="submit">
                {data?.id ? "Cập nhật" : "Thêm mới"}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export { ModalCreatePostBlog };
