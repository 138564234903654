import React from "react";
import { Modal, Button, FormControl } from "@themesberg/react-bootstrap";

import FileService from "../../services/file.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import Dropzone from "react-dropzone";

import { toast } from "react-toastify";
import BabyCaresService from "../../services/baby-cares.service";
import _ from "lodash";
import Select from "react-select";
import { BABY_CARES_PERIOD } from "../../contains/common";
import { Col } from "antd";

export default class ModalBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bodyData: {},
    };

    this.fileService = new FileService();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(prevProps.dataDetail) !==
      JSON.stringify(this.props.dataDetail)
    ) {
      const { dataDetail = {} } = this.props;
      let curPeriod = BABY_CARES_PERIOD.find(
        (x) => x.id === dataDetail?.periodDevelopment
      );
      let newObj = {
        ...dataDetail,
        name: dataDetail?.name,
        description: dataDetail?.description,
        thumbnail: dataDetail?.thumbnail,
        periodDevelopment: curPeriod
          ? { value: curPeriod?.id, label: curPeriod?.name }
          : "",
      };

      this.setState({
        bodyData: newObj,
        originalData: _.cloneDeep(newObj),
      });
    }
  }

  onSubmit = async () => {
    const { bodyData = {}, originalData = {} } = this.state;
    const { getData } = this.props;

    if (!bodyData?.name || !bodyData?.name.trim()) {
      toast.error("Vui lòng nhập tên chương trình");
      return;
    }
    // if (!bodyData?.description || !bodyData?.description.trim()) {
    //     toast.error("Vui lòng nhập mô tả")
    //     return
    // }
    if (!bodyData?.periodDevelopment) {
      toast.error("Vui lòng chọn giai đoạn");
      return;
    }
    if (!bodyData?.thumbnail) {
      toast.error("Vui lòng nhập ảnh");
      return;
    }

    // console.log('bodyData', bodyData)
    let body = {
      name: bodyData?.name.trim(),
      description: bodyData?.description ? bodyData?.description.trim() : "",
      thumbnail: bodyData?.thumbnail,
      periodDevelopment: bodyData?.periodDevelopment?.value,
    };

    try {
      if (bodyData?.id || bodyData?.id === 0) {
        await BabyCaresService.updateTopic(bodyData?.id, body);
        toast.success("Sửa thành công");
      } else {
        await BabyCaresService.createTopic(body);
        toast.success("Tạo thành công");
      }
      this.onToggle();
      getData();
    } catch (error) {
      let msg = "";
      switch (error?.message) {
        case "topic_duplicate":
          msg = "Chủ đề đã tồn tại";
          break;
        case "topic_not_found":
          msg = "Chủ đề không tồn tại";
          break;
        case "post_not_found":
          msg = "Bài viết không tồn tại";
          break;
        case "post_duplicate":
          msg = "Bài viết đã tồn tại";
          break;
        default:
          break;
      }
      toast.error(
        msg || "Có lỗi xảy ra vui lòng liên hệ kỹ thuật để được hỗ trợ."
      );
    }
  };

  handleFileUploadByPostsFormat = async (files) => {
    // console.log('vào handleFileUploadByPostsFormat')
    const file = files[0];
    let fileSize = file.size / 1024 / 1024; // in MiB
    if (fileSize > 500) {
      return alert("Dung lượng file lớn hơn 500 MiB, vui lòng chọn file khác.");
    }

    let { bodyData = {} } = this.state;

    let body = {
      objectType: "babyCaresTopic",
      displayType: 1,
    };
    // objectType: "posts"
    // objectId: ID bài viết
    // displayType: 1(thumbnail), 2( icon), 3(Cover), 4(video), 5(Audio), 6(Infographic), 7(avatar)

    try {
      const resp = await this.fileService.uploadImage(file, body);
      if (resp?.linkUrl?.length > 0) {
        bodyData.thumbnail = resp.linkUrl[0];
      }
    } catch (error) {
      console.log(error);
    }

    this.setState({ bodyData: bodyData });
  };

  onToggle = () => {
    const { toggle } = this.props;
    this.setState(
      {
        bodyData: {},
      },
      () => {
        toggle();
      }
    );
  };

  render() {
    const { modalStatus } = this.props;
    const { bodyData = {} } = this.state;
    return (
      <Modal
        dialogClassName="modal-50w"
        as={Modal.Dialog}
        show={modalStatus}
        onHide={this.onToggle}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {bodyData?.id || bodyData?.id === 0
              ? "Chỉnh sửa Topic"
              : "Thêm mới Topic"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-row d-flex align-items-center">
            <div className="modal-label-small">Tên chủ đề:</div>

            <FormControl
              value={bodyData.name}
              onChange={(e) =>
                this.setState({
                  bodyData: { ...bodyData, name: e.target.value },
                })
              }
              placeholder="Nhập tên chủ đề"
            />
          </div>
          <div className="modal-row d-flex align-items-center">
            <div className="modal-label-small">Mô tả:</div>

            <FormControl
              value={bodyData.description}
              onChange={(e) =>
                this.setState({
                  bodyData: { ...bodyData, description: e.target.value },
                })
              }
              placeholder="Nhập mô tả"
            />
          </div>
          <div className="modal-row d-flex align-items-center">
            <div className="modal-label-small">Giai đoạn:</div>

            <Select
              className="custom-react-select"
              placeholder="Chọn giai đoạn"
              options={BABY_CARES_PERIOD.map((x) => {
                return { value: x.id, label: x.name };
              })}
              value={bodyData?.periodDevelopment}
              isMulti={false}
              onChange={(e) => {
                this.setState({
                  bodyData: { ...bodyData, periodDevelopment: e },
                });
              }}
              isClearable={true}
              backspaceRemovesValue={true}
            />
          </div>

          <div className="modal-row">
            <div className="d-flex align-items-center">
              <div className="modal-label-small">Ảnh thumbnail:</div>
              <Dropzone
                accept={"image/*"}
                multiple={false}
                onDrop={(acceptedFiles) =>
                  this.handleFileUploadByPostsFormat(acceptedFiles)
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <section key={"dropzone"}>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <FontAwesomeIcon
                        icon={Icon.faCloudUploadAlt}
                        className="me-2"
                      />
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            <div className="d-flex align-items-center">
              <div className="modal-label-small" />
              {bodyData.thumbnail && (
                <img
                  src={bodyData.thumbnail}
                  style={{ height: 300, width: "auto" }}
                />
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.onToggle}>
            Đóng
          </Button>
          <Button variant="primary" onClick={() => this.onSubmit()}>
            {bodyData?.id || bodyData?.id === 0 ? "Lưu" : "Tạo"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
