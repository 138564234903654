import React, {useEffect, useRef, useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Col, FormControl, InputGroup, Row, Spinner} from '@themesberg/react-bootstrap';
import _ from 'lodash';
import ChatsService from "../../services/chats.service";
import CampaignService from "../../services/campaign.service";
import WebSocketAsPromised from 'websocket-as-promised';
import {faPaperPlane, faPlusCircle, faUserNinja} from "@fortawesome/free-solid-svg-icons";
import {useDropzone} from 'react-dropzone';
import InfiniteScroll from "react-infinite-scroller";
import {CHAT_IMG_URL, CHAT_SOCKET_URL} from "../../contains/common";

export default () => {

    const [loading, setLoading] = useState(true);
    const [chatLoading, setChatLoading] = useState(false);
    const [listRooms, setListRooms] = useState([]);
    const [listRoomsMetadata, setListRoomsMetadata] = useState({});
    const [listChats, setListChats] = useState([]);
    const [joinObj, setJoinObj] = useState({});
    const [value, setValue] = useState('');

    const scrollRef = useRef()

    useEffect(() => {
        getListRooms({
            pageNumber: 0,
            pageSize: 50
        })
    }, [])

    const chatsService = new ChatsService();
    const getListRooms = async (obj) => {
        try {
            const resp = await CampaignService.getListChat(obj);
            const headers = resp.headers || {};
            const metadata = {
                pageCount: parseFloat(headers['x-page-count']),
                page: parseFloat(headers['x-page-number']),
                size: parseFloat(headers['x-page-size']),
                total: parseFloat(headers['x-total-count']),
            }
            setLoading(false)
            if (metadata.page === 0) {
                setListRooms(resp?.data)
            } else {
                setListRooms([...listRooms, ...resp?.data])
            }
            setListRoomsMetadata(metadata)
        } catch (error) {
            console.log(error);
        }
    }

    const nextCommentPage = () => {
        if (listRoomsMetadata?.page+1 >= listRoomsMetadata?.pageCount) {
            return
        }
        getListRooms({
            pageNumber: listRoomsMetadata?.page + 1,
            pageSize: 50
        })
    }


    const joinChat = async (roomId) => {
        setChatLoading(true)
        try {
            const resp = await chatsService.join({roomId});
            setChatLoading(false)
            setJoinObj(resp)
            conn(resp)
            // console.log('joinChat', resp)
        } catch (error) {
            console.log(error);
        }
    }

    const sendMessage = async (body) => {
        try {
            const resp = await chatsService.sendMessage(body);
            // console.log('sendMessage')
        } catch (error) {
            console.log(error);
        }
    }

    const uploadFile = async (file) => {
        try {
            const resp = await chatsService.uploadFile({
                roomId: joinObj?.body?.roomId,
                headers: {
                    "X-Auth-Token": joinObj?.body?.rocketUser?.authToken,
                    "X-User-Id": joinObj?.body?.rocketUser?.userId
                },
                file,
                description: value || ''
            });
            setValue('')
        } catch (error) {
            console.log(error);
        }
    }

    const onDrop = acceptedFiles => {
        // Do something with the files
        // console.log('acceptedFiles', acceptedFiles)
        if (Array.isArray(acceptedFiles) && acceptedFiles.length > 0) {
            uploadFile(acceptedFiles[0])
        }
    }

    const {getRootProps, getInputProps, open, acceptedFiles} = useDropzone({
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
        accept: 'image/*',
        maxFiles: 1,
        onDrop
    });

    const conn = (res) => {
        console.log('conn start')
        const wsp = new WebSocketAsPromised(CHAT_SOCKET_URL);
        wsp.open().then(() =>
            wsp.send(
                JSON.stringify({
                    msg: 'connect',
                    version: '1',
                    support: ['1', 'pre2', 'pre1'],
                }),
            ),
        )
            .then(() =>
                wsp.send(
                    JSON.stringify({
                        id: res?.body?.roomId,
                        msg: 'method',
                        method: 'login',
                        params: [{resume: res?.body?.rocketUser?.authToken}],
                    }),
                ),
            )
            .then(() =>
                wsp.send(
                    JSON.stringify({
                        msg: 'sub',
                        id: res?.body?.roomId,
                        name: 'stream-room-messages',
                        params: [res?.body?.roomId, false],
                    }),
                ),
            )
            .catch(e => console.error(e));

        wsp.onMessage.addListener(data => {
            let dataObj = JSON.parse(data);
            console.log('dataObj', dataObj)
            if (dataObj?.msg === "changed" || dataObj?.msg === "ready") {
                wsp.send(JSON.stringify(
                    {
                        "msg": "method",
                        "method": "loadHistory",
                        "id": "42",
                        "params": [res?.body?.roomId, null, 9999, null ]
                    }
                ))
            } else if (dataObj?.msg === "result") {
                if (dataObj?.result && Array.isArray(dataObj?.result?.messages)) {
                    setListChats(_.reverse(dataObj?.result?.messages))
                    // console.log('wsp.result', _.reverse(dataObj?.result?.messages))
                    setTimeout(() => {
                        // refScrollView?.current?.scrollToEnd({ animated: true })
                        scrollToBottom()
                    }, 500)
                }
            } else if (dataObj?.msg === "ping") {
                // console.log('ping', dataObj?.msg)
                wsp.send(JSON.stringify({ msg: "pong" }))
            }
        });
    }

    const onSendMessage = () => {
        if (!value || !joinObj?.body?.rocketUser?.userId || !joinObj?.body?.rocketUser?.authToken || !joinObj?.body?.roomId) return;

        sendMessage({
            headers: {
                "X-Auth-Token": joinObj?.body?.rocketUser?.authToken,
                "X-User-Id": joinObj?.body?.rocketUser?.userId
            },
            body: {
                "message": JSON.stringify({
                    msg: 'method',
                    method: "sendMessage",
                    params: [
                        {
                            "rid": joinObj?.body?.roomId,
                            "msg": value
                        }
                    ]
                })
            }
        })
        setValue('')
    }

    const scrollToBottom = () => {
        const scrollHeight = scrollRef?.current?.scrollHeight;
        const height = scrollRef?.current?.clientHeight;
        const maxScrollTop = scrollHeight - height;
        if (scrollRef?.current) {
            scrollRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
        }
    }


    const renderListRooms = () => {

        return <div className="list-room-container">
            <h6>Danh sách phòng chat</h6>
            {
                loading ? <div className="d-flex justify-content-center pt-8">
                    <Spinner animation="border"/>
                </div>
                :

                    <div className="list-rooms">
                        <InfiniteScroll
                            initialLoad={false}
                            pageStart={0}
                            loadMore={nextCommentPage}
                            hasMore={listRoomsMetadata?.page + 1 < listRoomsMetadata?.pageCount}
                            useWindow={false}
                        >

                            {Array.isArray(listRooms) && listRooms.map((item,index) => {
                                return <div key={index} className="room-item" onClick={() => joinChat(item.roomId)}>
                                    {item.title}
                                </div>
                            })}
                        </InfiniteScroll>
                    </div>
            }
        </div>
    }



    const getValue = (value) => {
        let newValue = value
        if (value?.value) {
            newValue = getValue(newValue.value)
        }
        return newValue
    }

    const renderChatPartner = (item, index, notShowAvatar) => {
        return <div key={index} className="chat-partner-container">
            {
                !notShowAvatar ? <div
                        className="chat-partner-avatar has-avatar"
                    >
                        <FontAwesomeIcon icon={faUserNinja} />
                    </div>
                    :
                    <div className="chat-partner-avatar" />
            }

            {
                item.msg && <div className="chat-partner-content">
                    {item.msg}
                </div>
            }

            {/*{*/}
            {/*    Array.isArray(item.md) ?*/}
            {/*        item.md.map((x,y) => {*/}
            {/*            if (Array.isArray(x?.value)) {*/}
            {/*                return x?.value.map((a,b) => <div key={`${y}${b}`} className="chat-partner-content">*/}
            {/*                    {typeof getValue(a?.value) === 'string' ? getValue(a?.value) : ''}*/}
            {/*                </div>)*/}
            {/*            }*/}
            {/*            return <div key={`${y}`}/>*/}
            {/*        })*/}
            {/*        :*/}
            {/*        <div />*/}
            {/*}*/}

            {
                Array.isArray(item.attachments) ?
                    item.attachments.map((x,y) => {
                        return <div key={y} className="chat-partner-content">
                            <div className="chat-partner-text">{x?.description}</div>
                            {
                                item.file?.type.indexOf("image/") >= 0 && <div>
                                    <img
                                        className="content-image"
                                        src={`${CHAT_IMG_URL}${x?.image_url}`}
                                    />
                                </div>
                            }
                        </div>
                    })
                    :
                    <div />
            }
        </div>
    };

    const renderChatSelf = (item, index) => {
        return <div key={index} className="chat-self-container">
            {
                item.msg && <div className="chat-self-content">
                    {item.msg}
                </div>
            }
            {/*{*/}
            {/*    Array.isArray(item.md) ?*/}
            {/*        item.md.map((x, y) => {*/}
            {/*            if (Array.isArray(x?.value)) {*/}
            {/*                return x?.value.map((a,b) => <div key={`${y}${b}`} className="chat-self-content">*/}
            {/*                    {typeof getValue(a?.value) === 'string' ? getValue(a?.value) : ''}*/}
            {/*                </div>)*/}
            {/*            }*/}
            {/*            return <div key={`${y}`}/>*/}
            {/*        })*/}
            {/*        : <div/>*/}
            {/*}*/}

            {
                Array.isArray(item.attachments) ?
                    item.attachments.map((x,y) => {
                        return <div key={y} className="chat-self-content">
                            <div className="chat-self-text">{x?.description}</div>
                            {
                                item.file?.type.indexOf("image/") >= 0 && <div>
                                    <img
                                        className="content-image"
                                        src={`${CHAT_IMG_URL}${x?.image_url}`}
                                    />
                                </div>
                            }
                        </div>
                    })
                    :
                    <div/>
            }
        </div>
    };

    const renderBtnAttachment = () => {
        return <div {...getRootProps({className: 'dropzone'})}>
            <input {...getInputProps()} />
            <div onClick={open} className="chat-input-attachments">
                <FontAwesomeIcon icon={faPlusCircle} />
            </div>
        </div>
    }

    let notShowAvatar = false;

    // console.log('listChats', listChats)

    const renderChat = () => {
        const currentUserId = joinObj?.body?.rocketUser?.userId
        return <div className="list-room-container">
            <h6>Chat</h6>
            <div ref={scrollRef} className="list-chats">
                {
                    chatLoading ? <div className="d-flex justify-content-center pt-8">
                        <Spinner animation="border"/>
                    </div>
                    :
                    listChats.map((item,index) => {
                        notShowAvatar = false
                        if (item?.u && item?.u?._id !== currentUserId) {
                            if (index > 0 && listChats[index - 1]?.u?._id !== currentUserId) {
                                notShowAvatar = true
                            }
                            return renderChatPartner(item, index, notShowAvatar)
                        }
                        return renderChatSelf(item,index)
                    })
                }
            </div>
            <div className="chat-input-container mt-3">
                {renderBtnAttachment()}
                <InputGroup className="">
                    <FormControl
                        aria-label="Nhập nội dung"
                        value={value}
                        onChange={(e) => setValue(e?.target?.value)}
                        onKeyDown={(e) => {
                            if (e?.keyCode === 13) {
                                onSendMessage()
                            }
                        }}
                    />
                </InputGroup>

                <div
                    onClick={() => onSendMessage()}
                    className="chat-input-send"
                >
                    <FontAwesomeIcon icon={faPaperPlane} />
                </div>
            </div>
        </div>
    }

    return (
        <div className="full-page-container">
            <Row className="justify-content-between chat-container">
                <Col xs={3} md={3} xl={3} className="chat-col">
                    {renderListRooms()}
                </Col>
                <Col xs={9} md={9} xl={9} className="chat-col">
                    {joinObj?.body?.roomId ? renderChat() : ''}
                </Col>
            </Row>

        </div>
    );
};
