import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH,
  faEye,
  faUserShield,
  faUserTimes,
  faTimesCircle,
  faHandSparkles,
} from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  Button,
  Table,
  Dropdown,
  ButtonGroup,
  OverlayTrigger,
  Tooltip,
  Form,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";

import { Routes } from "../../routes";
import { Paging } from "../../components/Paging";

import {
  fetchAllUsers,
  checkAll,
  checkItem,
  onChangePage,
} from "../../store/users";
import { FORMAT_DATE_NORMAL } from "../../contains/common";
import dayjs from "dayjs";

const TableRow = (row) => {
  const dispatch = useDispatch();

  const {
    userId,
    username,
    email,
    phoneNumber,
    fullname,
    status,
    statusStr,
    modifiedBy,
    createdDate,
  } = row;
  const statusVariant =
    status === "Paid"
      ? "success"
      : status === "Due"
      ? "warning"
      : status === "Canceled"
      ? "danger"
      : "primary";

  return (
    <tr>
      <td>
        <Form.Check
          className="inbox-check me-2"
          checked={row.isChecked}
          onChange={(e) => dispatch(checkItem(row))}
        />
      </td>
      <td>
        <span className="fw-normal">{userId ?? "-"}</span>
      </td>
      <td>
        <Card.Link
          as={Link}
          to={Routes.UserForm.path.replace(/:userId/, userId)}
          className="d-flex align-items-center card-link"
        >
          {!row.avatar ? (
            <div className="user-avatar bg-tertiary me-3 text-uppercase">
              <span>{row.username.slice(0, 2)}</span>
            </div>
          ) : (
            <img
              src={row.avatar}
              className="user-avatar rounded-circle me-3"
              alt={row.username}
            />
          )}

          <div className="d-block">
            <span className="fw-bold">{fullname}</span>
            <div className="small text-gray">{username}</div>
            <div className="small text-gray">{email}</div>
          </div>
        </Card.Link>
      </td>
      <td>
        <span className="fw-normal">{phoneNumber ?? "-"}</span>
      </td>
      <td>
        <span className="fw-normal">
          {dayjs(createdDate).format(FORMAT_DATE_NORMAL) ?? "-"}
        </span>
      </td>
      <td>
        <span className="fw-normal">{modifiedBy}</span>
      </td>
      <td>
        <span className={`fw-normal text-${statusVariant}`}>{statusStr}</span>
      </td>
      <td>
        <Dropdown as={ButtonGroup}>
          <Dropdown.Toggle
            as={Button}
            split
            variant="link"
            className="text-dark m-0 p-0"
          >
            <span className="icon icon-sm">
              <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item>
              <FontAwesomeIcon icon={faUserShield} className="me-2" /> Cấp lại
              mật khẩu
            </Dropdown.Item>
            <Dropdown.Item>
              <FontAwesomeIcon icon={faEye} className="me-2" /> Chi tiết
            </Dropdown.Item>
            <Dropdown.Item className="text-danger">
              <FontAwesomeIcon icon={faUserTimes} className="me-2" /> Đình chỉ
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
    </tr>
  );
};

export const UsersTable = () => {
  const dispatch = useDispatch();
  const data = useSelector(({ users }) => users.table);
  const searchParams = useSelector(({ users }) => users.searchParams);

  useEffect(() => {
    dispatch(fetchAllUsers(searchParams));
  }, [dispatch, searchParams]);

  return (
    <React.Fragment>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body>
          <div className="d-flex mb-3">
            <div className="mb-0 me-3">
              <InputGroup>
                <Form.Select size="md">
                  <option value="">Chọn tác vụ</option>
                  <option value="">Gửi email</option>
                  <option value="">Thay đổi quyền</option>
                  <option value="">Xóa đã chọn</option>
                </Form.Select>
                <Button className="border-light" variant="white" size="md">
                  <FontAwesomeIcon icon={faHandSparkles} className="me-2" />
                </Button>
              </InputGroup>
            </div>
            <div></div>
          </div>
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>
                <th className="border-bottom">
                  <Form.Check
                    className="inbox-check me-2"
                    checked={data.isCheckedAll}
                    onChange={(e) => dispatch(checkAll(!data.isCheckedAll))}
                  />
                </th>
                <th className="border-bottom">#</th>
                <th className="border-bottom">Name</th>
                <th className="border-bottom">Phone Number</th>
                <th className="border-bottom">Date Created</th>
                <th className="border-bottom">Modifier</th>
                <th className="border-bottom">Status</th>
                <th className="border-bottom">Action</th>
              </tr>
            </thead>
            <tbody>
              {data.items.map((t) => (
                <TableRow key={`user-${t.userId}`} {...t} />
              ))}
            </tbody>
          </Table>
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
            <Paging {...{ searchParams, onChangePage, data }} />
          </Card.Footer>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};
