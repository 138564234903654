import React from "react";
import { Modal, Button, FormControl } from "@themesberg/react-bootstrap";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icon from "@fortawesome/free-solid-svg-icons";

import { toast } from "react-toastify";
import NurseryEducationsService from "../../services/nursery-educations.service";
import Select from "react-select";
import {
  NURSERY_EDUCATIONS_DEVELOPMENT_TYPE,
  NURSERY_EDUCATIONS_MONTHS,
  NURSERY_EDUCATIONS_TEACHING_AID_GROUP,
} from "../../contains/common";

export default class ModalMilestone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bodyData: {},
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(prevProps.dataDetail) !==
      JSON.stringify(this.props.dataDetail)
    ) {
      const { dataDetail = {} } = this.props;
      const month =
        NURSERY_EDUCATIONS_MONTHS.find((x) => x.id === dataDetail?.month) || {};
      let newObj = {
        ...dataDetail,
        developmentType: dataDetail?.developmentType && {
          value: dataDetail?.developmentType,
          label: dataDetail?.developmentType,
        },
        month: month?.name && { value: month?.id, label: month?.name },
      };

      this.setState({
        bodyData: newObj,
        originalData: _.cloneDeep(newObj),
      });
    }
  }

  onSubmit = async () => {
    const { bodyData = {} } = this.state;
    const { getData } = this.props;

    if (!bodyData?.checklistItem || !bodyData?.checklistItem.trim()) {
      toast.error("Vui lòng nhập checklist item");
      return;
    }

    if (!bodyData?.developmentType) {
      toast.error("Vui lòng chọn loại phát triển");
      return;
    }

    if (!bodyData?.month) {
      toast.error("Vui lòng chọn tháng");
      return;
    }

    // console.log('bodyData', bodyData)

    let body = {
      title: bodyData?.title ? bodyData?.title.trim() : "",
      checklistItem: bodyData?.checklistItem.trim(),
      developmentType: bodyData?.developmentType?.value,
      month: bodyData?.month?.value,
    };

    try {
      if (bodyData?.id || bodyData?.id === 0) {
        await NurseryEducationsService.updateMilestone(bodyData?.id, body);
        toast.success("Sửa thành công");
      } else {
        await NurseryEducationsService.createMilestone(body);
        toast.success("Tạo thành công");
      }
      this.onToggle();
      getData();
    } catch (error) {
      let msg = "";
      switch (error?.message) {
        case "nursery_education_teaching_aid_duplicate":
          msg = "Giáo cụ đã tồn tại";
          break;
        case "nursery_education_teaching_aid_not_found":
          msg = "Giáo cụ không tồn tại";
          break;
        default:
          break;
      }
      toast.error(
        msg || "Có lỗi xảy ra vui lòng liên hệ kỹ thuật để được hỗ trợ."
      );
    }
  };

  onToggle = () => {
    const { toggle } = this.props;
    this.setState(
      {
        bodyData: {},
      },
      () => {
        toggle();
      }
    );
  };

  render() {
    const { modalStatus } = this.props;
    const { bodyData = {} } = this.state;
    return (
      <Modal
        dialogClassName="modal-50w"
        as={Modal.Dialog}
        show={modalStatus}
        onHide={this.onToggle}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {bodyData?.id || bodyData?.id === 0
              ? "Chỉnh sửa milestone"
              : "Thêm mới milestone"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-row d-flex align-items-center">
            <div className="modal-label-small">Loại phát triển:</div>

            <Select
              className="custom-react-select"
              placeholder="Chọn loại phát triển"
              options={NURSERY_EDUCATIONS_DEVELOPMENT_TYPE.map((x) => {
                return { value: x.name, label: x.name };
              })}
              value={bodyData?.developmentType}
              isMulti={false}
              onChange={(e) => {
                this.setState({
                  bodyData: { ...bodyData, developmentType: e },
                });
              }}
              isClearable={true}
              backspaceRemovesValue={true}
            />
          </div>

          <div className="modal-row d-flex align-items-center">
            <div className="modal-label-small">Tháng:</div>

            <Select
              className="custom-react-select"
              placeholder="Chọn tháng"
              options={NURSERY_EDUCATIONS_MONTHS.filter(
                (x) => x.code !== "ALL"
              ).map((x) => {
                return { value: x.id, label: x.name };
              })}
              value={bodyData?.month}
              isMulti={false}
              onChange={(e) => {
                this.setState({ bodyData: { ...bodyData, month: e } });
              }}
              isClearable={true}
              backspaceRemovesValue={true}
            />
          </div>

          <div className="modal-row d-flex align-items-center">
            <div className="modal-label-small">Tiêu đề:</div>

            <FormControl
              value={bodyData.title}
              onChange={(e) =>
                this.setState({
                  bodyData: { ...bodyData, title: e.target.value },
                })
              }
              placeholder="Nhập tiêu đề"
            />
          </div>

          <div className="modal-row d-flex align-items-center">
            <div className="modal-label-small">Checklist Item:</div>

            <FormControl
              value={bodyData.checklistItem}
              onChange={(e) =>
                this.setState({
                  bodyData: { ...bodyData, checklistItem: e.target.value },
                })
              }
              placeholder="Nhập checklist item"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.onToggle}>
            Đóng
          </Button>
          <Button variant="primary" onClick={() => this.onSubmit()}>
            {bodyData?.id || bodyData?.id === 0 ? "Lưu" : "Tạo"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
