import React, { useEffect, useState } from "react";
import { Breadcrumb, Modal } from "@themesberg/react-bootstrap";
import moment from "moment";
import lodash from "lodash";
import { Routes } from "../../routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  TimePicker,
  notification,
} from "antd";
import { notificationObjectService } from "../../services/notification-object.service";
import {
  getAppUtilities,
  gotoItems,
  loopTypes,
} from "../../common/notification.constant";
import notificationService from "../../services/notification.service";
import { categoryService } from "../../services/category.service";
import { topicService } from "../../services/topic.service";
import { postService } from "../../services/post.service";
import { Link, useHistory } from "react-router-dom";
import { Helper } from "../../common/helper";

const repeatTypes = [
  {
    code: "daily",
    name: "Ngày",
  },
  {
    code: "weekly",
    name: "Tuần",
  },
  {
    code: "monthly",
    name: "Tháng",
  },
];

export const NotificationDetail = (props) => {
  const [notificationObjects, setNotificationObjects] = useState([]);
  const [subGoals, setSubGoals] = useState([]);
  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [isShowLoopConfig, setIsShowLoopConfig] = useState(false);
  const defaultForm = {
    displayType: [],
    openScreen: {},
  };
  const [form, setForm] = useState({ ...defaultForm });
  const history = useHistory();
  const id = props.match.params.id;

  const getNotificationDetail = () => {
    setLoading(true);
    notificationService
      .getDetail(id)
      .then((response) => {
        console.log(response);
        const time = moment(response.data.sendingTime);
        setForm({
          ...response.data,
          time: time,
          displayType: response.data.displayType.split(","),
          openScreen: lodash.get(response, "data.openScreen")
            ? JSON.parse(response.data.openScreen)
            : {},
          loopType: response.data.repetitionInterval,
          loopCount: response.data.paused,
        });

        if (response.data.interval) {
          setIsShowLoopConfig(true);
        }
      })
      .catch(() => {
        setForm({ ...defaultForm });
      });
  };

  useEffect(() => {
    getNotificationDetail();
    setLoading(true);

    notificationObjectService
      .filter({ size: 1000 })
      .then((response) => setNotificationObjects(response.data))
      .catch(() => setNotificationObjects([]))
      .finally(() => setLoading(false));

    categoryService
      .getSubGoal({ size: 1000 })
      .then((response) => setSubGoals(response.data))
      .catch(() => setSubGoals([]));

    topicService
      .search({ size: 1000 })
      .then((response) => setTopics(lodash.get(response, "body.content") || []))
      .catch(() => setTopics([]));
  }, []);

  const onUpdate = async () => {
    // TODO:

    const data = {
      customerGroupDefinitionId: form.customerGroupDefinitionId,
      displayType: form.displayType,
      sendingTime: form.sendingTime,
      repetitionInterval: form.repetitionInterval,
      interval: form.interval,
      repeatTimeBegin: form.repeatTimeBegin
        ? moment(form.repeatTimeBegin).format("x")
        : null,
      title: form.title,
      content: form.content,
      description: form.description,
      goTo: form.goTo,
      maximumRepeatTime: form.maximumRepeatTime || 0,
      openScreen: JSON.stringify(form.openScreen),
    };

    const response = await notificationService.update(id, data);
    if (response.status === 200) {
      notification.success({
        message: "Cập thông báo thành công",
      });
      history.push("/notifications");
    } else {
      notification.success({
        message: "Cập thông báo thất bại",
      });
    }
  };

  const handleChangeFieldForm = (field, value) => {
    if (field === "openScreen.topic" && value) {
      setPosts([]);
      setLoading(true);
      lodash.unset(form, "openScreen.post");
      setForm({ ...form });
      postService
        .search({
          topicIds: [value],
        })
        .then((response) => {
          setPosts(lodash.get(response, "body.content"));
        })
        .catch(() => setPosts([]))
        .finally(() => setLoading(false));
    }

    lodash.set(form, field, value);

    setForm({
      ...form,
    });
  };

  const handleSearchPost = (filter) => {
    setLoading(true);
    postService
      .search({ ...filter })
      .then((response) => {
        setPosts(lodash.get(response, "body.content"));
      })
      .catch(() => setPosts([]))
      .finally(() => setLoading(false));
  };

  const handleChangeDisplayType = (value) => {
    if (form.displayType.indexOf(value) < 0) {
      form.displayType.push(value);
    } else {
      form.displayType.splice(form.displayType.indexOf(value), 1);
    }

    setForm({
      ...form,
    });
  };

  console.log(lodash.get(form, "loopCount"));
  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item to={Routes.DashboardOverview.path}>
              <FontAwesomeIcon icon={Icon.faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item to={Routes.NotificationObject.path}>
              <FontAwesomeIcon icon={Icon.faBell} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Chi tiết thông báo</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Form labelCol={{ sm: 24, xl: 6 }} wrapperCol={{ sm: 24, xl: 12 }}>
        <div className={"white-box pd-20"}>
          <h5>Cấu hình thông báo</h5>
          {/*<Form.Item label={"Đối tượng nhận thông báo"} required={true}>*/}
          {/*    <Select*/}
          {/*        value={form.customerGroupDefinitionId}*/}
          {/*        onChange={value => handleChangeFieldForm('customerGroupDefinitionId', value)}*/}
          {/*    >*/}
          {/*        {notificationObjects.map(item => (*/}
          {/*            <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>*/}
          {/*        ))}*/}
          {/*    </Select>*/}
          {/*</Form.Item>*/}
          <Form.Item
            label={"Đối tượng nhận thông báo"}
            labelAlign={"left"}
            labelCol={{ span: 4, offset: 1 }}
          >
            <Select
              value={form.customerGroupDefinitionId}
              showSearch={true}
              filterOption={(value, option) => {
                return (
                  Helper.removeAscent(option.children)
                    .toLowerCase()
                    .indexOf(Helper.removeAscent(value).toLowerCase()) >= 0
                );
              }}
              onChange={(value) =>
                handleChangeFieldForm("customerGroupDefinitionId", value)
              }
            >
              {notificationObjects.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={"Hiển thị"}
            labelAlign={"left"}
            labelCol={{ span: 4, offset: 1 }}
          >
            <Row gutter={20}>
              <Col span={10}>
                <Form.Item noStyle>
                  <Checkbox
                    checked={form.displayType.indexOf("onscreen") >= 0}
                    value={"onscreen"}
                    onChange={(e) => handleChangeDisplayType(e.target.value)}
                  >
                    Ngoài màn hình
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item noStyle>
                  <Checkbox
                    checked={form.displayType.indexOf("inapp") >= 0}
                    value={"inapp"}
                    onChange={(e) => handleChangeDisplayType(e.target.value)}
                  >
                    In app(quả chuông)
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            label={"Thời gian gửi"}
            labelAlign={"left"}
            labelCol={{ span: 4, offset: 1 }}
          >
            <Row gutter={15}>
              <Col>
                <DatePicker
                  showTime
                  type="date"
                  disabledDate={(current) => {
                    return current && current < moment.now();
                  }}
                  placeholder={"Nhập ngày"}
                  suffixIcon={null}
                  format={"YYYY-MM-DD"}
                  value={form.sendingTime ? moment(form.sendingTime) : null}
                  onChange={(value) =>
                    handleChangeFieldForm(
                      "sendingTime",
                      value ? value.toISOString() : null
                    )
                  }
                />
              </Col>
              <Col>
                <TimePicker
                  showTime
                  suffixIcon={null}
                  format={"HH:mm"}
                  placeholder={"Nhập giờ"}
                  value={form.time ? moment(form.time) : null}
                  onChange={(value) =>
                    handleChangeFieldForm(
                      "time",
                      value ? value.toISOString() : null
                    )
                  }
                />
              </Col>
            </Row>
          </Form.Item>

          <Form.Item
            label={"Lặp lại"}
            labelAlign={"left"}
            labelCol={{ span: 4, offset: 1 }}
          >
            <Select
              allowClear={true}
              value={lodash.get(form, "loopType")}
              onChange={(value) => handleChangeFieldForm("loopType", value)}
              onSelect={(value) =>
                value === "custom" && setIsShowLoopConfig(true)
              }
            >
              {loopTypes.map((item) => (
                <Select.Option key={item.code} value={item.code}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={"Số lần gửi thông báo"}
            labelAlign={"left"}
            name={"loopCount"}
            labelCol={{ span: 4, offset: 1 }}
          >
            {/*<Radio.Group onChange={e => handleChangeFieldForm('loopCount', e.target.value)}*/}
            {/*             value={lodash.get(form, 'loopCount')} defaultValue={lodash.get(form, 'loopCount')}>*/}
            <Row>
              <Radio
                value={0}
                checked={lodash.get(form, "loopCount") === 0}
                onChange={() => {}}
              >
                Không giới hạn
              </Radio>
            </Row>
            <Row aria-label={"center"}>
              <Radio
                value={1}
                checked={lodash.get(form, "loopCount") === 1}
                onChange={() => {}}
              >
                Giới hạn số lần
              </Radio>
              <Form.Item noStyle>
                <InputNumber
                  disabled={lodash.get(form, "loopCount") !== 1}
                  onChange={(e) =>
                    handleChangeFieldForm("maximumRepeatTime", e)
                  }
                  value={
                    (lodash.get(form, "loopCount") === 1 &&
                      lodash.get(form, "maximumRepeatTime")) ||
                    undefined
                  }
                />
              </Form.Item>
            </Row>

            {/*</Radio.Group>*/}
          </Form.Item>
          <h5>Nội dung thông báo</h5>
          <Form.Item
            label={"Tiêu đề"}
            labelAlign={"left"}
            labelCol={{ span: 4, offset: 1 }}
          >
            <Input
              value={form.title}
              onChange={(e) => handleChangeFieldForm("title", e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label={"Mô tả"}
            labelAlign={"left"}
            labelCol={{ span: 4, offset: 1 }}
          >
            <Input.TextArea
              value={form.description}
              onChange={(e) =>
                handleChangeFieldForm("description", e.target.value)
              }
            />
          </Form.Item>
          {/* <Form.Item
            label={"Nội dung"}
            labelAlign={"left"}
            labelCol={{ span: 4, offset: 1 }}
          > */}
          {/*<Editor*/}
          {/*    editorState={EditorState.createWithContent(convertData(form.content))}*/}
          {/*    toolbarClassName="toolbarClassName"*/}
          {/*    wrapperClassName="wrapperClassName"*/}
          {/*    editorClassName="editorClassName"*/}
          {/*    toolbar={{*/}
          {/*        options: ['fontFamily', 'textAlign', 'inline', 'image', 'fontSize', 'blockType', 'emoji'],*/}
          {/*        inline: {inDropdown: false, options: ['bold', 'italic', 'underline']},*/}
          {/*        textAlign: {inDropdown: false},*/}
          {/*    }}*/}
          {/*    onEditorStateChange={(e) => handleChangeFieldForm('content', e)}*/}
          {/*/>*/}
          {/* <Input.TextArea
              value={form.content}
              onChange={(e) => handleChangeFieldForm("content", e.target.value)}
            />
          </Form.Item> */}
          <Form.Item
            label={"Goto"}
            labelAlign={"left"}
            labelCol={{ span: 4, offset: 1 }}
          >
            <Select
              value={form.goTo}
              onChange={(value) => handleChangeFieldForm("goTo", value)}
            >
              {gotoItems.map((item) => (
                <Select.Option key={item.code} value={item.code}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {form.goTo === "home" && (
            <Form.Item
              label={"Lộ trình"}
              labelAlign={"left"}
              labelCol={{ span: 4, offset: 1 }}
            >
              <Select
                allowClear={true}
                value={lodash.get(form, "openScreen.subGoal")}
                showSearch={true}
                filterOption={false}
                onChange={(value) =>
                  handleChangeFieldForm("openScreen.subGoal", value)
                }
              >
                {subGoals.map((item) => (
                  <Select.Option key={item.code} value={item.code}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {form.goTo === "insights" && (
            <>
              <Form.Item
                label={"Chủ đề"}
                labelAlign={"left"}
                labelCol={{ span: 4, offset: 1 }}
              >
                <Select
                  allowClear={true}
                  value={lodash.get(form, "openScreen.topic")}
                  showSearch={true}
                  onChange={(value) =>
                    handleChangeFieldForm("openScreen.topic", value)
                  }
                >
                  {topics.map((item) => (
                    <Select.Option key={item.topicId} value={item.topicId}>
                      {item.topicName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={"Bài viết"}
                labelAlign={"left"}
                labelCol={{ span: 4, offset: 1 }}
              >
                <Select
                  value={lodash.get(form, "openScreen.post")}
                  showSearch={true}
                  allowClear={true}
                  onSearch={(value) =>
                    handleSearchPost({
                      topicIds: lodash.get(form, "openScreen.topic"),
                      keyword: value,
                    })
                  }
                  onChange={(value) =>
                    handleChangeFieldForm("openScreen.post", value)
                  }
                >
                  {posts.map((item) => (
                    <Select.Option key={item.postsId} value={item.postsId}>
                      {item.postsName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}

          {form.goTo === "app" && (
            <Form.Item
              label={"Tiện ích"}
              labelAlign={"left"}
              labelCol={{ span: 4, offset: 1 }}
            >
              <Select
                allowClear={true}
                value={lodash.get(form, "openScreen.utility")}
                showSearch={true}
                onChange={(value) =>
                  handleChangeFieldForm("openScreen.utility", value)
                }
              >
                {getAppUtilities().map((item) => (
                  <Select.Option key={item.code} value={item.code}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item wrapperCol={{ offset: 6, xl: 24 }}>
            <Button loading={loading} disabled={loading} type={"primary"}>
              <Link to={"/notifications"} style={{ color: "#fff" }}>
                Quay lại trang chủ
              </Link>
            </Button>
            <Button
              type={"primary"}
              style={{ marginLeft: 20 }}
              onClick={onUpdate}
            >
              Cập nhật
            </Button>
          </Form.Item>
        </div>
        {isShowLoopConfig && (
          <Modal as={Modal.Dialog} centered show={isShowLoopConfig}>
            <Modal.Header>
              <Modal.Title className="h6">Tuỳ chỉnh lặp lại</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Item
                label={"Lặp lại sau mỗi"}
                labelAlign={"left"}
                labelCol={{ span: 6, offset: 1 }}
              >
                <Row gutter={4}>
                  <Col span={8}>
                    <Form.Item noStyle>
                      <InputNumber
                        value={lodash.get(form, "intervalDraft")}
                        onChange={(value) =>
                          handleChangeFieldForm("intervalDraft", value)
                        }
                        placeholder={"n"}
                        className={"w100p"}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item noStyle>
                      <Select
                        value={lodash.get(form, "repetitionIntervalDraft")}
                        onChange={(value) =>
                          handleChangeFieldForm(
                            "repetitionIntervalDraft",
                            value
                          )
                        }
                        placeholder={"ngày/tuần/tháng"}
                        allowClear={true}
                      >
                        {repeatTypes.map((item) => (
                          <Select.Option key={item.code}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item
                label={"Số lần tối đa"}
                labelAlign={"left"}
                labelCol={{ span: 6, offset: 1 }}
              >
                <Row gutter={4}>
                  <Col span={8}>
                    <Form.Item noStyle>
                      <InputNumber
                        value={lodash.get(form, "maxCount")}
                        onChange={(value) =>
                          handleChangeFieldForm("maxCount", value)
                        }
                        placeholder={"n"}
                        className={"w100p"}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <label className={"ant-form-text mt-1"}>Lần</label>
                  </Col>
                </Row>
              </Form.Item>
            </Modal.Body>
            <Modal.Footer>
              <Row gutter={6}>
                <Form.Item wrapperCol={{ offset: 6, xs: 12 }}>
                  <Button loading={loading} disabled={loading} type={"primary"}>
                    <Link to={"/notifications"} style={{ color: "#fff" }}>
                      Quay lại trang chủ
                    </Link>
                  </Button>
                </Form.Item>
              </Row>
            </Modal.Footer>
          </Modal>
        )}
      </Form>
    </div>
  );
};
