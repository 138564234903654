/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import moment from "moment";
import React, { useState } from "react";
import { CreateSchedule } from "./CreateSchedule";
import { UserSchedule } from "./UserSchedule";

type RowFieldInfoProps = {
  title: string;
  value?: string;
};

const RowFieldInfo = (props: RowFieldInfoProps) => {
  const { title, value } = props;
  return (
    <div className="row-field-info">
      <p className="title txt-small txt-bold">{title}</p>
      <p className="txt-small txt-bold">{value}</p>
    </div>
  );
};

type Props = {
  customer: Customer | null;
};

const UserInfo = (props: Props) => {
  const [content, setContent] = useState<string>("");
  const { customer } = props;
  const updateContent = (txt: string) => {
    setContent(txt);
  };

  const convertCodeFlow = (txt: string): string => {
    switch (txt) {
      case "sausinh":
        return "Sau sinh";
      case "mangthai":
        return "Mang thai";
      case "mongcon":
        return "Mong con";
      default:
        return "";
    }
  };

  return (
    <div className="chat-user-info">
      <RowFieldInfo
        title={"Tên"}
        value={customer?.fullName}
      />
      <RowFieldInfo title={"Số điện thoại"} value={customer?.phoneNumber} />
      <RowFieldInfo
        title={"Mục tiêu"}
        value={convertCodeFlow(customer?.codeFlow)}
      />
      {/* <RowFieldInfo title={"Ngày dự sinh"} value={"01/07/2022"} /> */}
      <RowFieldInfo
        title={"Ngày thực sinh"}
        value={moment(customer?.dateOfBirth).format("DD/MM/YYYY")}
      />

      {/* <CreateSchedule content={content} updateContent={updateContent} />
      <UserSchedule /> */}
    </div>
  );
};

export { UserInfo };
