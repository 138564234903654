import React from "react";
import {
  Modal,
  Button,
  InputGroup,
  FormControl,
  Form,
} from "@themesberg/react-bootstrap";
import _ from "lodash";
import FoodsService from "../../services/foods.service";
import FileService from "../../services/file.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import Dropzone from "react-dropzone";
import mammoth from "mammoth";
import Select from "react-select";
import { DatePicker } from "antd";

import { BANNER_DISPLAY } from "../../contains/common";
import { toast } from "react-toastify";
import BannersService from "../../services/banners.service";
import moment from "moment";
const { RangePicker } = DatePicker;

export default class ModalBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bodyData: {},
    };

    this.fileService = new FileService();
  }

  componentDidMount() {
    this.getCategories();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(prevProps.dataDetail) !==
      JSON.stringify(this.props.dataDetail)
    ) {
      const { dataDetail = {} } = this.props;

      if (dataDetail?.id || dataDetail?.id === 0) {
        const dplObj =
          BANNER_DISPLAY.find((x) => x.code === dataDetail?.display) || {};
        let newObj = {
          ...dataDetail,
          display: { value: dplObj.code, label: dplObj.name },
          categoryPageId: {
            value: dataDetail?.categoryPage.id,
            label: dataDetail?.categoryPage.name,
          },
        };
        this.setState({
          bodyData: newObj,
          originalData: _.cloneDeep(newObj),
        });
      } else {
        this.setState({
          bodyData: {},
          originalData: {},
        });
      }
    }
  }

  onSubmit = async () => {
    const { bodyData = {}, originalData = {} } = this.state;
    const { getData } = this.props;

    if (!bodyData?.name) {
      toast.error("Vui lòng nhập tên chương trình");
      return;
    }
    if (!bodyData?.display) {
      toast.error("Vui lòng chọn phạm vi hiển thị");
      return;
    }
    if (!bodyData?.categoryPageId) {
      toast.error("Vui lòng chọn vị trí hiển thị");
      return;
    }
    if (!bodyData?.timestampFrom) {
      toast.error("Vui lòng nhập ngày bắt đầu");
      return;
    }
    if (!bodyData?.timestampTo) {
      toast.error("Vui lòng nhập ngày kết thúc");
      return;
    }
    if (!bodyData?.image) {
      toast.error("Vui lòng nhập ảnh");
      return;
    }
    if (!bodyData?.url) {
      toast.error("Vui lòng nhập link");
      return;
    }

    // console.log('bodyData', bodyData)
    let body = {
      name: bodyData?.name.trim(),
      display: bodyData?.display?.value,
      categoryPageId: bodyData?.categoryPageId?.value,
      url: bodyData?.url.trim(),
      timestampFrom: bodyData?.timestampFrom,
      timestampTo: bodyData?.timestampTo,
      image: bodyData?.image,
    };

    try {
      if (bodyData?.id || bodyData?.id === 0) {
        await BannersService.updatePopup(bodyData?.id, body);
        toast.success("Sửa thành công");
      } else {
        await BannersService.createPopup(body);
        toast.success("Tạo thành công");
      }
      this.onToggle();
      getData();
    } catch (error) {
      let msg = "";
      switch (error?.message) {
        case "banner_duplicate":
          msg = "Banner đã tồn tại";
          break;
        case "invalid_timestamp":
          msg = "Thời gian thiết lập không hợp lệ";
          break;
        case "banner_not_found":
          msg = "Banner không tồn tại";
          break;
        case "popup_not_found":
          msg = "Popup không tồn tại";
          break;
        case "popup_duplicate":
          msg = "Popup đã tồn tại";
          break;
        default:
          break;
      }

      toast.error(
        msg || "Có lỗi xảy ra vui lòng liên hệ kỹ thuật để được hỗ trợ."
      );
    }
  };

  getCategories = async () => {
    const resp = await BannersService.categoryPage();
    this.setState({
      categoriesPage: resp.data,
    });
  };

  handleFileUploadByPostsFormat = async (files) => {
    // console.log('vào handleFileUploadByPostsFormat')
    const file = files[0];
    let fileSize = file.size / 1024 / 1024; // in MiB
    if (fileSize > 500) {
      return alert("Dung lượng file lớn hơn 500 MiB, vui lòng chọn file khác.");
    }

    let { bodyData = {} } = this.state;

    let body = {
      objectType: "banners",
      displayType: 1,
    };
    // objectType: "posts"
    // objectId: ID bài viết
    // displayType: 1(thumbnail), 2( icon), 3(Cover), 4(video), 5(Audio), 6(Infographic), 7(avatar)

    try {
      const resp = await this.fileService.uploadImage(file, body);
      if (resp?.linkUrl?.length > 0) {
        bodyData.image = resp.linkUrl[0];
      }
    } catch (error) {
      console.log(error);
    }

    this.setState({ bodyData: bodyData });
  };

  onToggle = () => {
    const { toggle } = this.props;
    this.setState(
      {
        bodyData: {},
      },
      () => {
        toggle();
      }
    );
  };

  render() {
    const { modalStatus } = this.props;
    const { categoriesPage = [], bodyData = {} } = this.state;
    return (
      <Modal
        dialogClassName="modal-90w"
        as={Modal.Dialog}
        show={modalStatus}
        onHide={this.onToggle}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {bodyData?.id || bodyData?.id === 0
              ? "Chỉnh sửa Popup"
              : "Thêm mới Popup"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-row d-flex align-items-center">
            <div className="modal-label">Tên chương trình:</div>

            <FormControl
              value={bodyData.name}
              onChange={(e) =>
                this.setState({
                  bodyData: { ...bodyData, name: e.target.value },
                })
              }
              placeholder="Nhập tên chương trình"
            />
          </div>

          <div className="modal-row d-flex align-items-center">
            <div className="modal-label">Phạm vi hiển thị:</div>

            <Select
              className="custom-react-select"
              placeholder="Chọn vị trí hiển thị"
              options={BANNER_DISPLAY.map((x) => {
                return { value: x.code, label: x.name };
              })}
              value={bodyData.display}
              isMulti={false}
              onChange={(e) => {
                this.setState({ bodyData: { ...bodyData, display: e } });
              }}
              isClearable={true}
              backspaceRemovesValue={true}
            />
          </div>

          <div className="modal-row d-flex align-items-center">
            <div className="modal-label">Vị trí hiển thị:</div>

            {categoriesPage && (
              <Select
                className="custom-react-select"
                placeholder="Chọn vị trí hiển thị"
                options={categoriesPage.map((x) => {
                  return { value: x.id, label: x.name };
                })}
                value={bodyData.categoryPageId}
                isMulti={false}
                onChange={(e) => {
                  this.setState({
                    bodyData: { ...bodyData, categoryPageId: e },
                  });
                }}
                isClearable={true}
                backspaceRemovesValue={true}
              />
            )}
          </div>
          <div className="modal-row d-flex align-items-center">
            <div className="modal-label">Thời gian:</div>

            <RangePicker
              separator={"-"}
              value={[
                bodyData?.timestampFrom
                  ? moment(bodyData?.timestampFrom)
                  : null,
                bodyData?.timestampTo ? moment(bodyData?.timestampTo) : null,
              ]}
              onChange={(values) => {
                this.setState({
                  bodyData: {
                    ...bodyData,
                    timestampFrom: values[0] ? moment(values[0]).valueOf() : "",
                    timestampTo: values[1] ? moment(values[1]).valueOf() : "",
                  },
                });
              }}
              format={"DD/MM/YYYY"}
            />
          </div>

          <div className="modal-row">
            <div className="d-flex align-items-center">
              <div className="modal-label">Banner hiển thị:</div>
              <Dropzone
                accept={"image/*"}
                multiple={false}
                onDrop={(acceptedFiles) =>
                  this.handleFileUploadByPostsFormat(acceptedFiles)
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <section key={"dropzone"}>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <FontAwesomeIcon
                        icon={Icon.faCloudUploadAlt}
                        className="me-2"
                      />
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            <div className="d-flex align-items-center">
              <div className="modal-label" />
              {bodyData.image && (
                <img
                  src={bodyData.image}
                  style={{ height: 300, width: "auto" }}
                />
              )}
            </div>
          </div>

          <div className="modal-row d-flex align-items-center">
            <div className="modal-label">Đường dẫn:</div>

            <FormControl
              value={bodyData.url}
              onChange={(e) =>
                this.setState({
                  bodyData: { ...bodyData, url: e.target.value },
                })
              }
              placeholder="Nhập đường dẫn"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.onToggle}>
            Đóng
          </Button>
          <Button variant="primary" onClick={() => this.onSubmit()}>
            {bodyData?.id || bodyData?.id === 0 ? "Lưu" : "Tạo"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
