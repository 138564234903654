import APIService from "./customAPI.services";

class BabyCaresService extends APIService {
  constructor() {
    super();
  }

  async listPodcast(query) {
    return await this.request("GET", `podcasts/search${query || ""}`, {});
  }

  async listTopics(query) {
    return await this.request("GET", `podcast-topics${query || ""}`, {});
  }

  async getPodcast(id) {
    return await this.request("GET", `podcasts/${id}`, {});
  }

  async getTopic(id) {
    return await this.request("GET", `podcast-topics/${id}`, {});
  }

  async createPodcast(body) {
    return await this.request("POST", `podcasts`, body);
  }

  async createTopic(body) {
    return await this.request("POST", `podcast-topics`, body);
  }

  async updatePodcast(id, body) {
    return await this.request("PUT", `podcasts/${id}`, body);
  }

  async updateTopic(id, body) {
    return await this.request("PUT", `podcast-topics/${id}`, body);
  }

  async getAuthor(body) {
    return await this.request("GET", `podcasts/authors`, body);
  }
}
const babyCaresService = new BabyCaresService();
export default babyCaresService;
