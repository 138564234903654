import { useEffect, useRef, useState } from "react";
import { Button, Input, Pagination, Select, Space, Spin, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import ModalCreateNote from "./ModalCreateNote";
import { GLOBAL_HEIGHT } from "src/utils/constant";
import { useWindowSize } from "src/hooks/customHooks";
import CustomerService, {
  SearchUserVipParams,
} from "src/services/customer.services";
import { TitleColumn } from "src/components/table/TitleColumn";

interface UserVipInfo {
  userId?: number;
  fullName?: string;
  phoneNumber?: string;
  targetId?: number;
  targetName?: string;
  activatedDate?: number;
  expiredDate?: number;
  paymentMethod?: string;
  appointmentDate?: number;
  careStatus?: string;
  note?: string;
  careStaff?: string;
  careStatusColor?: string;
  email?: string;
  subscriptionPackage?: string;
}

type State = {
  users: UserVipInfo[];
  loading: boolean;
  isShowModal: boolean;
  isRefresh: boolean;
  contentNote: string;
  total: number;
};

const TARGETS = [
  { id: 1, title: "Mong con" },
  { id: 2, title: "Mang thai" },
  { id: 3, title: "Sau sinh" },
];

const PAYMENT_METHODS = [
  { id: "Manual", title: "Manual" },
  { id: "IAP", title: "IAP" },
  { id: "COD", title: "COD" },
  { id: "Momo", title: "Momo" },
];

const PACKAGE = [
  { id: "monthly", title: "1 tháng" },
  { id: "6month", title: "6 tháng" },
  { id: "anually", title: "1 năm" },
  { id: "nonconsumable", title: "Trọn đời" },
];

const defaultParams = {
  query: null,
  targetId: null,
  subscriptionPackage: null,
  paymentMethod: null,
  page: 0,
  size: 20,
};

function UserVip() {
  const COLUMNS: ColumnsType<any> = [
    {
      title: <TitleColumn title={"User Id"} />,
      dataIndex: "userId",
      key: "userId",
      width: "6%",
    },
    {
      title: <TitleColumn title={"Tên User"} />,
      dataIndex: "fullName",
      key: "fullName",
      width: "9%",
    },
    {
      title: <TitleColumn title={"Số điện thoại"} />,
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: "11%",
    },
    {
      title: <TitleColumn title={"Email"} />,
      dataIndex: "email",
      key: "email",
      width: "10%",
    },
    {
      title: <TitleColumn title={"Mục tiêu"} />,
      dataIndex: "targetName",
      key: "targetName",
      width: "8%",
    },
    {
      title: <TitleColumn title={"Gói đăng ký"} />,
      dataIndex: "subscriptionPackage",
      key: "subscriptionPackage",
      width: "9%",
    },
    {
      title: <TitleColumn title={"Ngày kích hoạt"} />,
      dataIndex: "activatedDate",
      key: "activatedDate",
      width: "9%",
      render: (_, { activatedDate }) => (
        <p style={{ fontSize: 14 }}>
          {moment(activatedDate).format("DD/MM/YYYY")}
        </p>
      ),
    },
    {
      title: <TitleColumn title={"Ngày hết hạn"} />,
      dataIndex: "expiredDate",
      key: "expiredDate",
      width: "9%",
      render: (_, { expiredDate }) => (
        <p style={{ fontSize: 14 }}>
          {moment(expiredDate).format("DD/MM/YYYY")}
        </p>
      ),
    },
    {
      title: <TitleColumn title={"HT thanh toán"} />,
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      width: "10%",
    },
    {
      title: <TitleColumn title={"Ghi chú"} />,
      dataIndex: "note",
      key: "note",
      width: "11%",
    },
    {
      title: <TitleColumn title={"Thao tác"} />,
      dataIndex: "action",
      key: "action",
      width: "8%",
      render: (value: any, record: UserVipInfo, index: number) => (
        <p
          onClick={() => onClickNote(record)}
          style={{ color: "#3A85DC", fontSize: 14 }}
        >
          Chỉnh sửa
        </p>
      ),
    },
  ];
  const windowSize = useWindowSize();

  const [state, setState] = useState<State>({
    users: [],
    loading: true,
    isShowModal: false,
    isRefresh: false,
    contentNote: "",
    total: 0,
  });

  const currentNote = useRef<UserVipInfo | null>(null);

  const filters = useRef<SearchUserVipParams>(defaultParams);

  const userService = new CustomerService();

  const fetchUsers = async (params?: SearchUserVipParams) => {
    const { data, headers } = await userService.searchUserVip(params);
    setState((cur: State) => ({
      ...cur,
      users: data,
      loading: false,
      isRefresh: false,
      total: parseFloat(headers["x-total-count"]),
    }));
  };

  useEffect(() => {
    fetchUsers(defaultParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickNote = (item: UserVipInfo) => {
    currentNote.current = item;
    onShowModal(item?.note ?? "");
  };

  const onSearch = () => {
    setState((cur: State) => ({ ...cur, loading: true }));
    fetchUsers(filters.current);
  };

  const onShowSizeChange = (page: number, pageSize: number) => {
    setState((cur: State) => ({ ...cur, loading: true }));
    fetchUsers({ ...filters.current, page: page - 1, size: pageSize });
  };

  const onSelectTarget = (value: string) => {
    filters.current.targetId = value ?? null;
  };

  const onSelectPackage = (value: string) => {
    filters.current.subscriptionPackage = value ?? null;
  };

  const onSelectPaymentMethod = (value: string) => {
    filters.current.paymentMethod = value ?? null;
  };

  const onChangeText = (e: any) => {
    filters.current.query = e.target.value;
  };

  const onShowModal = (contentNote: string) => {
    setState((cur: State) => ({ ...cur, contentNote, isShowModal: true }));
  };

  const setContent = (contentNote: string) => {
    setState((cur: State) => ({ ...cur, contentNote }));
  };

  const onHideModal = () => {
    setState((cur: State) => ({ ...cur, isShowModal: false }));
  };

  const onSubmitNote = async (content: string) => {
    if (currentNote.current?.userId) {
      setState((cur: State) => ({ ...cur, loading: true }));
      await userService.saveNote(currentNote.current.userId, content);
      fetchUsers(defaultParams);
    }
  };

  const { Option } = Select;

  let tableHeight = windowSize?.height - GLOBAL_HEIGHT;

  return (
    <div className={"user-vip-container"}>
      <Spin spinning={state.loading || state.isRefresh} tip={"Loading..."}>
        <Space>
          <Input
            placeholder="Nhập User ID, SĐT, Email"
            style={{ width: 300 }}
            onChange={onChangeText}
          />

          <Select
            allowClear
            placeholder="Chọn mục tiêu"
            optionFilterProp="children"
            style={{ width: 150 }}
            onChange={onSelectTarget}
          >
            {TARGETS.map((item) => (
              <Option value={item.id} key={"target" + item.id}>
                {item.title}
              </Option>
            ))}
          </Select>

          <Select
            allowClear
            placeholder="Chọn gói đăng ký"
            optionFilterProp="children"
            style={{ width: 200 }}
            onChange={onSelectPackage}
          >
            {PACKAGE.map((item) => (
              <Option value={item.id} key={"package" + item.id}>
                {item.title}
              </Option>
            ))}
          </Select>

          <Select
            allowClear
            placeholder="Chọn HT thanh toán"
            optionFilterProp="children"
            style={{ width: 200 }}
            onChange={onSelectPaymentMethod}
          >
            {PAYMENT_METHODS.map((item) => (
              <Option value={item.id} key={"payment-method" + item.id}>
                {item.title}
              </Option>
            ))}
          </Select>

          <Button type="primary" onClick={onSearch}>
            Tìm kiếm
          </Button>
        </Space>

        <Table
          dataSource={state.users}
          columns={COLUMNS}
          pagination={false}
          rowKey={"userId"}
          scroll={{ y: tableHeight, x: windowSize?.width - 100 }}
        />
        <Pagination
          style={{ marginTop: 12 }}
          showSizeChanger
          onChange={onShowSizeChange}
          onShowSizeChange={onShowSizeChange}
          defaultCurrent={1}
          total={state.total}
          showTotal={(total) => `Tổng số ${total}`}
        />
      </Spin>

      <ModalCreateNote
        note={state.contentNote}
        isShowModal={state.isShowModal}
        onHide={onHideModal}
        onSubmit={onSubmitNote}
        setContent={setContent}
      />
    </div>
  );
}

export { UserVip };
