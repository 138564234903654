import React, { useEffect, useState } from "react";
import { Breadcrumb } from "@themesberg/react-bootstrap";
import { Routes } from "../../routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  notification,
  Radio,
  Row,
  Select,
} from "antd";
import lodash from "lodash";
import {
  getStagesByGoal,
  NOTIFICATION_OBJECT_ACCOUNT_TYPE,
  NOTIFICATION_OBJECT_TARGET_ARR,
  NOTIFICATION_OBJECT_TYPE,
  OBJECT_CONDITION,
  SYSTEM_CONDITION,
} from "../../common/notification.constant";
import CategoryService from "../../services/category.service";
import NotificationObjectService from "../../services/notification-object.service";
import { Link } from "react-router-dom";
import { useWatch } from "antd/es/form/Form";

const notificationObjectTypes = lodash.map(NOTIFICATION_OBJECT_TYPE, (v) => v);
const notificationObjectAccountTypes = lodash.map(
  NOTIFICATION_OBJECT_ACCOUNT_TYPE,
  (v) => v
);

const categoryService = new CategoryService();
const notificationObjectService = new NotificationObjectService();

export const NotificationObjectCreate = () => {
  // const [form, setForm] = useState({})

  const [useFormData] = Form.useForm();
  const customerClassification = useWatch(
    "customerClassification",
    useFormData
  );
  const customerType = useWatch("customerType", useFormData);
  const goal = useWatch("goal", useFormData);
  const subGoal = useWatch("subGoal", useFormData);
  const stage = useWatch("stage", useFormData);
  const customerStatus = useWatch("customerStatus", useFormData);

  const [conditionsByAccountType, setConditionsByAccountType] = useState([]);
  // const [isShowInputDay, setIsShowInputDay] = useState(false)
  const [isShowInputOfTypeObject, setIsShowInputOfTypeObject] = useState(false);
  const [conditions, setConditions] = useState([]);
  // const [subGoals, setSubGoals] = useState([])
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    categoryService.getCustomerGroupCondition().then((response) => {
      setConditions(response.data);
    });
  }, []);

  // useEffect(() => {
  //     categoryService.getSubGoal()
  //         .then(response => setSubGoals(response.data))
  // }, [])

  useEffect(() => {
    if (goal === "mongcon") {
      useFormData.resetFields([
        "trimester",
        "week",
        "month",
        "stage",
        "stageValue",
      ]);
    }
  }, [goal]);

  useEffect(() => {
    if (customerStatus === "inactive") {
      useFormData.resetFields(["dayOfExpiredTrial", "dayOfRemainingTrial"]);
      return;
    }

    if (customerStatus === "trial_expired") {
      useFormData.resetFields(["dayOfInactive", "dayOfRemainingTrial"]);
      return;
    }

    if (customerStatus === "trial_expire_soon") {
      useFormData.resetFields(["dayOfExpiredTrial", "dayOfInactive"]);
      return;
    }
    if (["new", "inactive"].indexOf(customerStatus) < 0) {
      useFormData.resetFields(["dayOfInactive"]);
    }
  }, [customerStatus]);

  const renderBody = () => {
    if (isShowInputOfTypeObject) {
      return (
        <>
          <Form.Item
            label={"Mục tiêu"}
            labelAlign={"left"}
            labelCol={{ span: 3, offset: 1 }}
          >
            <Col span={12}>
              <Form.Item noStyle name={"goal"}>
                <Select allowClear={true}>
                  {NOTIFICATION_OBJECT_TARGET_ARR.map((item) => (
                    <Select.Option key={item.code} value={item.code}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Form.Item>
          {(goal === "mangthai" || goal === "sausinh") && (
            <Form.Item
              label={"Giai đoạn"}
              labelAlign={"left"}
              labelCol={{ span: 3, offset: 1 }}
            >
              <Row gutter={2}>
                <Col span={12} className={"gutter-row"}>
                  <Form.Item name={"stage"} noStyle={true}>
                    <Select allowClear={true}>
                      {getStagesByGoal(goal).map((item) => (
                        <Select.Option key={item.code} value={item.code}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4} className={"gutter-row"}>
                  {["trimester", "week", "month"].indexOf(stage) >= 0 && (
                    <Form.Item name={"stageValue"} noStyle={true}>
                      <InputNumber />
                    </Form.Item>
                  )}
                </Col>
              </Row>
            </Form.Item>
          )}
          {goal === "mangthai" && (
            <Form.Item
              label={"Điều kiện"}
              name={"conditions"}
              labelAlign={"left"}
              labelCol={{ span: 3, offset: 1 }}
            >
              <Col span={12}>
                <Select allowClear={true}>
                  {conditions.map((item) => (
                    <Select.Option key={item.code} value={item.code}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Form.Item>
          )}
        </>
      );
    }
    return (
      <Form.Item
        label={"Điều kiện"}
        className={"row"}
        labelAlign={"left"}
        labelCol={{ span: 3, offset: 1 }}
      >
        <Row gutter={20}>
          <Col span={10}>
            <Form.Item noStyle name={"customerStatus"}>
              <Select allowClear={true}>
                {conditionsByAccountType.map((item) => (
                  <Select.Option key={item.code} value={item.code}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {customerStatus === "inactive" && (
            <>
              <label className={"ant-form-text mt-1"}>trong vòng</label>
              <Col span={6}>
                <Form.Item name={"dayOfInactive"}>
                  <InputNumber className={"w100p"} />
                </Form.Item>
              </Col>
              <label className={"ant-form-text mt-1"}>Ngày</label>
            </>
          )}
          {customerStatus === "trial_expired" && (
            <>
              <label className={"ant-form-text mt-1"}>trong vòng</label>
              <Col span={6}>
                <Form.Item name={"dayOfExpiredTrial"}>
                  <InputNumber className={"w100p"} />
                </Form.Item>
              </Col>
              <label className={"ant-form-text mt-1"}>Ngày</label>
            </>
          )}
          {customerStatus === "expired" && (
            <>
              <Col span={6}>
                <Form.Item name={"dayOfExpiredTrial"}>
                  <InputNumber className={"w100p"} />
                </Form.Item>
              </Col>
              <label className={"ant-form-text mt-1"}>Ngày</label>
            </>
          )}

          {customerStatus === "trial_expire_soon" && (
            <>
              <label className={"ant-form-text mt-1"}>trong vòng</label>
              <Col span={6}>
                <Form.Item name={"dayOfRemainingTrial"}>
                  <InputNumber className={"w100p"} />
                </Form.Item>
              </Col>
              <label className={"ant-form-text mt-1"}>Ngày</label>
            </>
          )}
          {customerStatus === "trial_vip_soon" && (
            <>
              <label className={"ant-form-text mt-1"}>trong vòng</label>
              <Col span={6}>
                <Form.Item name={"dayOfExpiredVip"}>
                  <InputNumber className={"w100p"} />
                </Form.Item>
              </Col>
              <label className={"ant-form-text mt-1"}>Ngày</label>
            </>
          )}
        </Row>
      </Form.Item>
    );
  };

  const onFinish = (values) => {
    let dataSubmit = JSON.parse(JSON.stringify(values));
    if (dataSubmit.customerStatus === "trial_vip_soon") {
      dataSubmit = {
        ...dataSubmit,
        customerStatus: "expired",
      };
    }

    notificationObjectService
      .create(dataSubmit)
      .then(() => {
        setLoading(false);
        notification.success({
          message: "Tạo thành công",
        });
        useFormData.resetFields();
        // setForm({})
      })
      .catch((error) => {
        setLoading(false);
        notification.error({
          message: error.message || "Lỗi",
        });
      });
  };

  useEffect(() => {
    useFormData.setFieldValue("customerStatus", undefined);
    setIsShowInputOfTypeObject(
      NOTIFICATION_OBJECT_TYPE.OBJECT.code === customerClassification
    );
    switch (customerType) {
      case NOTIFICATION_OBJECT_ACCOUNT_TYPE.ALL.code:
        if (customerClassification === NOTIFICATION_OBJECT_TYPE.SYSTEM.code) {
          setConditionsByAccountType(SYSTEM_CONDITION.all);
          return;
        }
        if (customerClassification === NOTIFICATION_OBJECT_TYPE.OBJECT.code) {
          setConditionsByAccountType(OBJECT_CONDITION.all);
          return;
        }
        break;
      case NOTIFICATION_OBJECT_ACCOUNT_TYPE.STANDARD.code:
        if (customerClassification === NOTIFICATION_OBJECT_TYPE.SYSTEM.code) {
          setConditionsByAccountType(OBJECT_CONDITION.standard);
          return;
        }
        if (customerClassification === NOTIFICATION_OBJECT_TYPE.OBJECT.code) {
          return;
        }
        break;
      case NOTIFICATION_OBJECT_ACCOUNT_TYPE.PREMIUM.code:
        if (customerClassification === NOTIFICATION_OBJECT_TYPE.SYSTEM.code) {
          setConditionsByAccountType(OBJECT_CONDITION.premium);
          return;
        }
        if (customerClassification === NOTIFICATION_OBJECT_TYPE.OBJECT.code) {
          return;
        }
        break;
      default:
        break;
    }
  }, [customerClassification, customerType, useFormData]);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item to={Routes.DashboardOverview.path}>
              <FontAwesomeIcon icon={Icon.faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item to={Routes.NotificationObject.path}>
              <FontAwesomeIcon icon={Icon.faBeer} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              Tạo đối tượng nhận thông báo
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <Card title={"Tạo đối tượng nhận thông báo"}>
        <Form
          labelCol={{ sm: 24, xl: 6 }}
          wrapperCol={{ sm: 24, xl: 12 }}
          className={"col"}
          onFinish={onFinish}
          form={useFormData}
          initialValues={{
            customerClassification: "system",
            customerType: "all",
            name: "",
            customerStatus: undefined,
            goal: undefined,
          }}
        >
          <Form.Item
            label={"Tên đối tượng"}
            className={"row"}
            labelAlign={"left"}
            labelCol={{ span: 3, offset: 1 }}
            name={"name"}
            required={true}
            rules={[
              { required: true, message: "Vui lòng nhập tên đối tượng!" },
            ]}
          >
            <Input placeholder={"Nhập tên đối tượng..."} />
          </Form.Item>
          <Form.Item
            label={"Phân loại"}
            name={"customerClassification"}
            className={"row"}
            labelAlign={"left"}
            labelCol={{ span: 3, offset: 1 }}
            required={true}
            rules={[
              { required: true, message: "Vui lòng nhập phân loại đối tượng!" },
            ]}
          >
            <Radio.Group>
              {notificationObjectTypes.map((item) => (
                <Radio key={item.code} value={item.code}>
                  {item.name}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label={"Loại tài khoản"}
            name={"customerType"}
            className={"row"}
            labelAlign={"left"}
            labelCol={{ span: 3, offset: 1 }}
            required={true}
            rules={[
              { required: true, message: "Vui lòng nhập loại tài khoản!" },
            ]}
          >
            <Radio.Group>
              {notificationObjectAccountTypes.map((item) => (
                <Radio key={item.code} value={item.code}>
                  {item.name}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          {renderBody()}
          <Form.Item wrapperCol={{ offset: 6, xl: 24 }}>
            <Button
              loading={loading}
              disabled={loading}
              className={"col-2 mx-2"}
            >
              <Link to={"/notifications/objects"}>Huỷ</Link>
            </Button>
            <Button
              loading={loading}
              disabled={loading}
              className={"col-2"}
              htmlType="submit"
              type={"primary"}
            >
              Tạo đối tượng
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};
