import React from "react";
import { Modal, Button, FormControl } from "@themesberg/react-bootstrap";

import FileService from "../../services/file.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import Dropzone from "react-dropzone";

import { toast } from "react-toastify";
import apiService from "../../services/podcast.service";
import _ from "lodash";
import Select from "react-select";
import { BABY_CARES_PERIOD, PODCAST_TYPE } from "../../contains/common";
import { Col } from "antd";

export default class ModalBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bodyData: {},
    };

    this.fileService = new FileService();
  }

  componentDidMount() {
    const { dataDetail = {} } = this.props;

    let newObj = {
      ...dataDetail,
    };

    this.setState({
      bodyData: newObj,
      originalData: _.cloneDeep(newObj),
    });
  }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //     if (JSON.stringify(prevProps.dataDetail) !== JSON.stringify(this.props.dataDetail)) {
  //         const {dataDetail = {}} = this.props;
  //         let curPeriod = BABY_CARES_PERIOD.find(x => x.id === dataDetail?.periodDevelopment)
  //         let newObj = {
  //             ...dataDetail,
  //             name: dataDetail?.name,
  //             description: dataDetail?.description,
  //             thumbnail: dataDetail?.thumbnail,
  //             periodDevelopment: curPeriod ? {value: curPeriod?.id, label: curPeriod?.name} : '',
  //         }
  //
  //         this.setState({
  //             bodyData: newObj,
  //             originalData: _.cloneDeep(newObj)
  //         })
  //     }
  // }

  onSubmit = async () => {
    const { bodyData = {}, originalData = {} } = this.state;
    const { getData } = this.props;

    if (!bodyData?.topicName || !bodyData?.topicName.trim()) {
      toast.error("Vui lòng nhập tên chủ đề");
      return;
    }

    // if (!bodyData?.description || !bodyData?.description.trim()) {
    //     toast.error("Vui lòng nhập mô tả")
    //     return
    // }

    if (!bodyData?.topicAvatar) {
      toast.error("Vui lòng nhập ảnh");
      return;
    }

    // console.log('bodyData', bodyData)
    let body = {
      topicName: bodyData?.topicName.trim(),
      description: bodyData?.description ? bodyData?.description.trim() : "",
      topicAvatar: bodyData?.topicAvatar,
    };

    try {
      if (bodyData?.id || bodyData?.id === 0) {
        await apiService.updateTopic(bodyData?.id, body);
        toast.success("Sửa thành công");
      } else {
        await apiService.createTopic(body);
        toast.success("Tạo thành công");
      }
      this.onToggle();
      getData();
    } catch (error) {
      let msg = "";
      switch (error?.message) {
        case "topic_duplicate":
          msg = "Chủ đề đã tồn tại";
          break;
        case "topic_not_found":
          msg = "Chủ đề không tồn tại";
          break;
        case "post_not_found":
          msg = "Bài viết không tồn tại";
          break;
        case "post_duplicate":
          msg = "Bài viết đã tồn tại";
          break;
        default:
          break;
      }
      toast.error(
        msg || "Có lỗi xảy ra vui lòng liên hệ kỹ thuật để được hỗ trợ."
      );
    }
  };

  handleFileUpload = async (files) => {
    const file = files[0];
    let fileSize = file.size / 1024 / 1024; // in MiB
    if (fileSize > 500) {
      return alert("Dung lượng file lớn hơn 500 MiB, vui lòng chọn file khác.");
    }

    let { bodyData = {} } = this.state;

    let body = {
      objectType: "babyCaresTopic",
      displayType: 1,
    };
    // objectType: "posts"
    // objectId: ID bài viết
    // displayType: 1(thumbnail), 2( icon), 3(Cover), 4(video), 5(Audio), 6(Infographic), 7(avatar)

    try {
      const resp = await this.fileService.uploadImage(file, body);
      // bodyData.imageObj = resp
      if (resp?.linkUrl?.length > 0) {
        bodyData.topicAvatar = resp.attachIdStr;
        bodyData.topicAvatarUrl = resp.linkUrl[0];
      }
    } catch (error) {
      console.log(error);
    }

    this.setState({ bodyData: bodyData });
  };

  onToggle = () => {
    const { toggle } = this.props;
    this.setState(
      {
        bodyData: {},
      },
      () => {
        toggle();
      }
    );
  };

  render() {
    const { modalStatus } = this.props;
    const { bodyData = {} } = this.state;
    return (
      <Modal
        dialogClassName="modal-50w"
        as={Modal.Dialog}
        show={modalStatus}
        onHide={this.onToggle}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {bodyData?.id || bodyData?.id === 0
              ? "Chỉnh sửa Topic"
              : "Thêm mới Topic"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-row d-flex align-items-center">
            <div className="modal-label-small">Tên chủ đề:</div>

            <FormControl
              value={bodyData.topicName}
              onChange={(e) =>
                this.setState({
                  bodyData: { ...bodyData, topicName: e.target.value },
                })
              }
              placeholder="Nhập tên chủ đề"
            />
          </div>
          <div className="modal-row d-flex align-items-center">
            <div className="modal-label-small">Mô tả:</div>

            <FormControl
              value={bodyData.description}
              onChange={(e) =>
                this.setState({
                  bodyData: { ...bodyData, description: e.target.value },
                })
              }
              placeholder="Nhập mô tả"
            />
          </div>

          <div className="modal-row">
            <div className="d-flex align-items-center">
              <div className="modal-label-small">Ảnh thumbnail:</div>
              <Dropzone
                accept={"image/*"}
                multiple={false}
                onDrop={(acceptedFiles) => this.handleFileUpload(acceptedFiles)}
              >
                {({ getRootProps, getInputProps }) => (
                  <section key={"dropzone"}>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <FontAwesomeIcon
                        icon={Icon.faCloudUploadAlt}
                        className="me-2"
                      />
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            <div className="d-flex align-items-center">
              <div className="modal-label-small" />
              {bodyData?.topicAvatarUrl && (
                <img
                  src={bodyData?.topicAvatarUrl}
                  style={{ maxHeight: 300, maxWidth: "100%" }}
                />
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.onToggle}>
            Đóng
          </Button>
          <Button variant="primary" onClick={() => this.onSubmit()}>
            {bodyData?.id || bodyData?.id === 0 ? "Lưu" : "Tạo"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
