import React from "react";
import NurseryEducationsService from "../../services/nursery-educations.service";
import {
  Breadcrumb,
  Button,
  InputGroup,
  FormControl,
  Spinner,
  Table,
  Tabs,
  Pagination,
} from "@themesberg/react-bootstrap";

import { Routes } from "../../routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import { Helper } from "../../common/helper";
import ModalPost from "./ModalPost";
import ModalTeachingAids from "./ModalTeachingAids";
import ModalMilestone from "./ModalMilestone";
import ModalDaily from "./ModalDaily";
import moment from "moment";
import {
  NURSERY_EDUCATIONS_DEVELOPMENT_TYPE,
  NURSERY_EDUCATIONS_MONTHS,
  NURSERY_EDUCATIONS_POST_FORMAT,
  NURSERY_EDUCATIONS_POST_TYPE,
  NURSERY_EDUCATIONS_TEACHING_AID_GROUP,
} from "../../contains/common";
import Select from "react-select";
import { toast } from "react-toastify";
import { Switch } from "antd";
import BabyCaresService from "../../services/baby-cares.service";

const { Tab } = Tabs;
const pageSize = 20;
export default class NurseryEducations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      categoriesPage: [],
      tabActive: "posts",
      page: 1,
      pageSize: pageSize,

      modalShow: false,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true }, () => {
      this.getData();
    });
  }

  getData = async () => {
    const { tabActive } = this.state;

    // if (tabActive === 'posts') {
    //     this.getListPosts()
    // } else if (tabActive === 'milestone') {
    //     this.getListMilestones()
    // } else if (tabActive === 'teaching_aids') {
    //     this.getListTeachingAids()
    // } else if (tabActive === 'daily') {
    //     this.getListDaily()
    // }
    switch (tabActive) {
      case "posts":
        this.getListPosts();
        break;
      case "milestone":
        this.getListMilestones();
        break;
      case "teaching_aids":
        this.getListTeachingAids();
        break;
      case "daily":
        this.getListDaily();
        break;
      default:
        this.getListPosts();
        break;
    }
  };

  getDetailData = async (id) => {
    const { tabActive } = this.state;
    this.setState({ isLoadingDetail: true }, () => {
      switch (tabActive) {
        case "posts":
          this.getPostDetail(id);
          break;
        case "milestone":
          this.getMilestoneDetail(id);
          break;
        case "teaching_aids":
          this.getTeachingAidDetail(id);
          break;
        case "daily":
          this.getDailyDetail(id);
          break;
        default:
          this.getPostDetail(id);
          break;
      }
    });
  };

  getListPosts = async () => {
    const { page, pageSize } = this.state;
    let queryString = this.buildQueryString();
    if (queryString) {
      queryString =
        queryString +
        `&page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdDate:desc`;
    } else {
      queryString = `?page=${
        page ? page - 1 : 0
      }&size=${pageSize}&sort=createdDate:desc`;
    }

    const resp = await NurseryEducationsService.listPosts(queryString);
    const headers = resp?.headers || {};
    this.setState({
      data: resp?.data,
      metadata: {
        pageCount: parseFloat(headers["x-page-count"]),
        page: parseFloat(headers["x-page-number"]),
        size: parseFloat(headers["x-page-size"]),
        total: parseFloat(headers["x-total-count"]),
      },
      isLoading: false,
    });
  };

  getListTeachingAids = async () => {
    const { page, pageSize } = this.state;
    let queryString = this.buildQueryString();
    if (queryString) {
      queryString =
        queryString +
        `&page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdDate:desc`;
    } else {
      queryString = `?page=${
        page ? page - 1 : 0
      }&size=${pageSize}&sort=createdDate:desc`;
    }

    const resp = await NurseryEducationsService.listTeachingAids(queryString);
    const headers = resp.headers || {};
    this.setState({
      data: resp.data,
      metadata: {
        pageCount: parseFloat(headers["x-page-count"]),
        page: parseFloat(headers["x-page-number"]),
        size: parseFloat(headers["x-page-size"]),
        total: parseFloat(headers["x-total-count"]),
      },
      isLoading: false,
    });
  };

  getListDaily = async () => {
    const { page, pageSize } = this.state;
    let queryString = this.buildQueryString();
    if (queryString) {
      queryString =
        queryString +
        `&page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdDate:desc`;
    } else {
      queryString = `?page=${
        page ? page - 1 : 0
      }&size=${pageSize}&sort=createdDate:desc`;
    }

    const resp = await NurseryEducationsService.listDaily(queryString);
    const headers = resp.headers || {};
    this.setState({
      data: resp.data,
      metadata: {
        pageCount: parseFloat(headers["x-page-count"]),
        page: parseFloat(headers["x-page-number"]),
        size: parseFloat(headers["x-page-size"]),
        total: parseFloat(headers["x-total-count"]),
      },
      isLoading: false,
    });
  };

  getListMilestones = async () => {
    const { page, pageSize } = this.state;
    let queryString = this.buildQueryString();
    if (queryString) {
      queryString =
        queryString +
        `&page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdDate:desc`;
    } else {
      queryString = `?page=${
        page ? page - 1 : 0
      }&size=${pageSize}&sort=createdDate:desc`;
    }
    const resp = await NurseryEducationsService.listMilestones(queryString);
    const headers = resp.headers || {};
    this.setState({
      data: resp.data,
      metadata: {
        pageCount: parseFloat(headers["x-page-count"]),
        page: parseFloat(headers["x-page-number"]),
        size: parseFloat(headers["x-page-size"]),
        total: parseFloat(headers["x-total-count"]),
      },
      isLoading: false,
    });
  };

  getPostDetail = async (id) => {
    const resp = await NurseryEducationsService.getPost(id);
    this.setState({
      dataDetail: resp.data,
      isLoadingDetail: false,
      modalShow: true,
    });
  };

  getMilestoneDetail = async (id) => {
    const resp = await NurseryEducationsService.getMilestone(id);
    this.setState({
      dataDetail: resp.data,
      isLoadingDetail: false,
      modalShow: true,
    });
  };

  getTeachingAidDetail = async (id) => {
    const resp = await NurseryEducationsService.getTeachingAid(id);
    this.setState({
      dataDetail: resp.data,
      isLoadingDetail: false,
      modalShow: true,
    });
  };

  getDailyDetail = async (id) => {
    const resp = await NurseryEducationsService.getDaily(id);
    this.setState({
      dataDetail: resp.data,
      isLoadingDetail: false,
      modalShow: true,
    });
  };

  onPageChanged = (page) => {
    this.setState({ page: page }, () => {
      this.getData();
    });
  };

  /**
   * xử lý với dữ liệu search trong state
   */
  onSubmitSearch = () => {
    this.setState({ page: 1 }, () => {
      this.getData();
    });
  };

  /**
   * xây dựng query string theo các giá trị trong state
   * @returns {string}
   */
  buildQueryString = () => {
    let {
      listChecked,
      periodDevelopment,
      postType,
      postFormat,
      teachingName,
      teachingGroup,
      month,
      developmentType,
      day,
      querySearch,
    } = this.state;
    let queryString = "";
    for (let p in listChecked) {
      let value = "";
      if (Array.isArray(listChecked[p]) && listChecked[p].length > 0) {
        listChecked[p].map((x) => {
          value = value + x + ",";
        });

        value = value.slice(0, -1);
      }
      if (value)
        queryString = Helper.updateQueryStringParameter(queryString, p, value);
    }

    if (querySearch) {
      queryString = Helper.updateQueryStringParameter(
        queryString,
        "query",
        querySearch.trim()
      );
    }

    if (postFormat) {
      queryString = Helper.updateQueryStringParameter(
        queryString,
        "postFormat",
        postFormat?.value
      );
    }

    if (periodDevelopment) {
      queryString = Helper.updateQueryStringParameter(
        queryString,
        "periodDevelopment",
        periodDevelopment?.value
      );
    }

    if (postType) {
      queryString = Helper.updateQueryStringParameter(
        queryString,
        "postType",
        postType?.value
      );
    }

    if (teachingName) {
      queryString = Helper.updateQueryStringParameter(
        queryString,
        "teachingName",
        teachingName.trim()
      );
    }

    if (teachingGroup) {
      queryString = Helper.updateQueryStringParameter(
        queryString,
        "teachingGroup",
        teachingGroup?.value
      );
    }

    if (month) {
      queryString = Helper.updateQueryStringParameter(
        queryString,
        "month",
        month?.value
      );
    }

    if (developmentType) {
      queryString = Helper.updateQueryStringParameter(
        queryString,
        "developmentType",
        developmentType?.value
      );
    }

    if (day) {
      queryString = Helper.updateQueryStringParameter(queryString, "day", day);
    }

    return queryString;
  };

  onItemChecked = (item) => {
    const { listItemChecked = [] } = this.state;
    let newList = listItemChecked;
    if (!!listItemChecked.find((x) => x.foodId === item.foodId)) {
      newList = listItemChecked.filter((x) => x.foodId !== item.foodId);
    } else {
      newList.push(item);
    }

    this.setState({ listItemChecked: newList });
  };

  onCheckedAll = () => {
    const { data = [], listItemChecked = [] } = this.state;
    if (listItemChecked.length === data.length) {
      this.setState({ listItemChecked: [] });
    } else {
      this.setState({ listItemChecked: data });
    }
  };

  toggleHidden = async (item) => {
    const { tabActive } = this.state;
    let body = {
      hidden: !item?.hidden,
    };

    try {
      if (tabActive === "posts") {
        let resp = await NurseryEducationsService.toggleHidden(item?.id);
      } else if (tabActive === "milestone") {
        let resp = await NurseryEducationsService.updateMilestone(
          item?.id,
          body
        );
      } else if (tabActive === "teaching_aids") {
        let resp = await NurseryEducationsService.updateTeachingAid(
          item?.id,
          body
        );
      }
      this.getData();
    } catch (error) {
      let msg = "";
      console.log("error", error);
      toast.error(
        msg || "Có lỗi xảy ra vui lòng liên hệ kỹ thuật để được hỗ trợ."
      );
    }
  };

  toggleModal = () => {
    // console.log('vào toggleModal')
    if (this.state.modalShow) {
      this.setState({
        modalShow: false,
        dataDetail: {},
      });
    } else {
      this.setState({
        modalShow: true,
      });
    }
  };

  renderRow = (item, index) => {
    // const format = NURSERY_EDUCATIONS_POST_FORMAT.find(x => x.id === item?.postFormat) || {}
    // const type = NURSERY_EDUCATIONS_POST_TYPE.find(x => x.code === item?.postType) || {}
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>
          <span className="fw-normal">{item?.postName || "---"}</span>
        </td>
        <td>
          <span className="fw-normal">{item?.topicName || "---"}</span>
        </td>
        <td>
          <span className="fw-normal">{item?.postFormat || "---"}</span>
        </td>
        <td>
          <span className="fw-normal">{item?.ordinalNumber}</span>
        </td>
        <td>
          <span className="fw-normal">{item?.postType || "---"}</span>
        </td>
        <td>
          <Switch
            onClick={() => this.toggleHidden(item)}
            size="small"
            checked={item?.hidden}
          />
        </td>
        <td>
          <div ref={(ref) => (this.actionContainer = ref)} className="dp-flex">
            <div
              onClick={() => this.getDetailData(item.id)}
              className={"table-action-btn"}
            >
              <FontAwesomeIcon icon={Icon.faEdit} className="me-2" />
            </div>
          </div>
        </td>
      </tr>
    );
  };

  renderRowTeachingAids = (item, index) => {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>
          <span className="fw-normal">{item?.teachingName || "---"}</span>
        </td>
        <td>
          <span className="fw-normal">{item?.teachingGroup || "---"}</span>
        </td>
        <td>
          <span className="fw-normal">{item?.months || "---"}</span>
        </td>
        <td>
          <Switch
            onClick={() => this.toggleHidden(item)}
            size="small"
            checked={item?.hidden}
          />
        </td>
        <td>
          <div ref={(ref) => (this.actionContainer = ref)} className="dp-flex">
            <div
              onClick={() => this.getDetailData(item.id)}
              className={"table-action-btn"}
            >
              <FontAwesomeIcon icon={Icon.faEdit} className="me-2" />
            </div>
          </div>
        </td>
      </tr>
    );
  };

  renderRowMilestone = (item, index) => {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>
          <span className="fw-normal">{item?.title || "---"}</span>
        </td>
        <td>
          <span className="fw-normal">{item?.checklistItem || "---"}</span>
        </td>
        <td>
          <span className="fw-normal">{item?.developmentType || "---"}</span>
        </td>
        <td>
          <span className="fw-normal">{item?.month || "---"}</span>
        </td>
        <td>
          <Switch
            onClick={() => this.toggleHidden(item)}
            size="small"
            checked={item?.hidden}
          />
        </td>
        <td>
          <div ref={(ref) => (this.actionContainer = ref)} className="dp-flex">
            <div
              onClick={() => this.getDetailData(item.id)}
              className={"table-action-btn"}
            >
              <FontAwesomeIcon icon={Icon.faEdit} className="me-2" />
            </div>
          </div>
        </td>
      </tr>
    );
  };

  renderRowDaily = (item, index) => {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>
          <span className="fw-normal">{item?.day || "---"}</span>
        </td>
        <td>
          <span className="fw-normal">{item?.activityCount}</span>
        </td>
        <td>
          <span className="fw-normal">
            {item?.addedTimestamp
              ? moment(item?.addedTimestamp).format("DD/MM/YYYY")
              : "---"}
          </span>
        </td>
        <td>
          <div ref={(ref) => (this.actionContainer = ref)} className="dp-flex">
            <div
              onClick={() => this.getDetailData(item.id)}
              className={"table-action-btn"}
            >
              <FontAwesomeIcon icon={Icon.faEdit} className="me-2" />
            </div>
          </div>
        </td>
      </tr>
    );
  };

  renderContentPost = () => {
    const { data = [] } = this.state;
    if (this.state.isLoading) {
      return (
        <div className="d-flex justify-content-center pt-8">
          <Spinner animation="border" />
        </div>
      );
    }

    return (
      <div>
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th key={1} className="border-bottom">
                STT
              </th>
              <th key={2} className="border-bottom">
                Bài viết
              </th>
              <th key={3} className="border-bottom">
                Chủ đề
              </th>
              <th key={5} className="border-bottom">
                Dạng bài viết
              </th>
              <th key={6} className="border-bottom">
                Thứ tự hiển thị
              </th>
              <th key={7} className="border-bottom">
                Loại bài viết
              </th>
              <th key={8} className="border-bottom">
                Hidden
              </th>
              <th key={11} className="border-bottom" />
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => this.renderRow(item, index))}
          </tbody>
        </Table>
        {this.renderPagination()}
      </div>
    );
  };

  renderContentTeachingAids = () => {
    const { data = [] } = this.state;
    if (this.state.isLoading) {
      return (
        <div className="d-flex justify-content-center pt-8">
          <Spinner animation="border" />
        </div>
      );
    }

    return (
      <div>
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th key={1} className="border-bottom">
                STT
              </th>
              <th key={3} className="border-bottom">
                Tên giáo cụ
              </th>
              <th key={4} className="border-bottom">
                Nhóm giáo cụ
              </th>
              <th key={6} className="border-bottom">
                Tháng
              </th>
              <th key={8} className="border-bottom">
                Hidden
              </th>
              <th key={7} className="border-bottom" />
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => this.renderRowTeachingAids(item, index))}
          </tbody>
        </Table>
        {this.renderPagination()}
      </div>
    );
  };

  renderContentMilestone = () => {
    const { data = [] } = this.state;
    if (this.state.isLoading) {
      return (
        <div className="d-flex justify-content-center pt-8">
          <Spinner animation="border" />
        </div>
      );
    }

    return (
      <div>
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th key={1} className="border-bottom">
                STT
              </th>
              <th key={2} className="border-bottom">
                Title
              </th>
              <th key={3} className="border-bottom">
                Checklist Item
              </th>
              <th key={4} className="border-bottom">
                Loại phát triển
              </th>
              <th key={6} className="border-bottom">
                Thuộc tháng
              </th>
              <th key={8} className="border-bottom">
                Hidden
              </th>
              <th key={7} className="border-bottom" />
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => this.renderRowMilestone(item, index))}
          </tbody>
        </Table>
        {this.renderPagination()}
      </div>
    );
  };

  renderContentDaily = () => {
    const { data = [] } = this.state;
    if (this.state.isLoading) {
      return (
        <div className="d-flex justify-content-center pt-8">
          <Spinner animation="border" />
        </div>
      );
    }

    return (
      <div>
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th key={1} className="border-bottom">
                STT
              </th>
              <th key={3} className="border-bottom">
                Ngày
              </th>
              <th key={4} className="border-bottom">
                Số lượng hoạt động
              </th>
              <th key={6} className="border-bottom">
                Ngày tạo
              </th>
              <th key={7} className="border-bottom" />
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => this.renderRowDaily(item, index))}
          </tbody>
        </Table>
        {this.renderPagination()}
      </div>
    );
  };

  renderPagination = () => {
    const { page, metadata = {} } = this.state;
    return (
      <Pagination className="mb-2 mb-lg-0">
        <Pagination.Prev
          disabled={metadata?.page === 0}
          onClick={(e) => this.onPageChanged(page - 1)}
        >
          Previous
        </Pagination.Prev>
        {[...Array(metadata?.pageCount).keys()].map((p) => (
          <Pagination.Item
            key={`page-${p}`}
            onClick={(e) => this.onPageChanged(p + 1)}
            active={metadata.page === p}
          >
            {" "}
            {p + 1}{" "}
          </Pagination.Item>
        ))}
        <Pagination.Next
          disabled={metadata?.page + 1 === metadata?.pageCount}
          onClick={(e) => this.onPageChanged(page + 1)}
        >
          Next
        </Pagination.Next>
      </Pagination>
    );
  };

  renderModalByTab = () => {
    const { tabActive, modalShow, dataDetail = {} } = this.state;
    switch (tabActive) {
      case "posts":
        return (
          <ModalPost
            dataDetail={dataDetail}
            toggle={this.toggleModal}
            getData={this.getData}
            modalStatus={modalShow}
          />
        );
      case "milestone":
        return (
          <ModalMilestone
            dataDetail={dataDetail}
            toggle={this.toggleModal}
            getData={this.getData}
            modalStatus={modalShow}
          />
        );
      case "teaching_aids":
        return (
          <ModalTeachingAids
            dataDetail={dataDetail}
            toggle={this.toggleModal}
            getData={this.getData}
            modalStatus={modalShow}
          />
        );
      case "daily":
        return (
          <ModalDaily
            dataDetail={dataDetail}
            toggle={this.toggleModal}
            getData={this.getData}
            modalStatus={modalShow}
          />
        );
      default:
        return (
          <ModalPost
            dataDetail={dataDetail}
            toggle={this.toggleModal}
            getData={this.getData}
            modalStatus={modalShow}
          />
        );
    }
  };

  render() {
    const {
      tabActive,
      data,
      querySearch,
      day,
      postFormat,
      developmentType,
      postType,
      teachingName,
      teachingGroup,
      month,
    } = this.state;
    if (!Array.isArray(data)) {
      return <div>Có lỗi xảy ra vui lòng liên kệ kỹ thuật</div>;
    }

    return (
      <div>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-md-0">
            <Breadcrumb
              className="d-none d-md-inline-block"
              listProps={{
                className: "breadcrumb-dark breadcrumb-transparent",
              }}
            >
              <Breadcrumb.Item to={Routes.DashboardOverview.path}>
                <FontAwesomeIcon icon={Icon.faHome} />
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Giáo dục sớm</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>

        <Tabs
          activeKey={tabActive}
          onSelect={(k) =>
            this.setState(
              {
                tabActive: k,
                listItemChecked: [],
                data: [],
                isLoading: true,
                querySearch: "",
                postType: "",
                postFormat: "",
                developmentType: "",
                teachingGroup: "",
                month: "",
                teachingName: "",
                day: "",
              },
              () => this.getData()
            )
          }
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="posts" title="Bài Posts">
            <div className="dp-flex justify-content-space-between align-items-center">
              <div
                onClick={(e) => {
                  this.setState({ dataDetail: {} }, () => {
                    this.toggleModal();
                  });
                }}
                className="custom-btn"
              >
                <FontAwesomeIcon icon={Icon.faPlus} className="me-2" /> Thêm
              </div>

              <div>
                <div className="mb-3 d-flex align-items-center">
                  <div className="mgr-10 filter-option-item">
                    <FormControl
                      value={querySearch}
                      onChange={(e) =>
                        this.setState({ querySearch: e.target.value })
                      }
                      onKeyDown={(e) => {
                        if (e?.keyCode === 13) {
                          this.onSubmitSearch();
                        }
                      }}
                      placeholder={"Nhập tên bài viết/topic"}
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                    />
                  </div>
                  <div className="mgr-10 filter-option-item">
                    <Select
                      className="custom-react-select list"
                      placeholder="Chọn định dạng bài viết"
                      options={NURSERY_EDUCATIONS_POST_FORMAT.map((x) => {
                        return { value: x.name, label: x.name };
                      })}
                      value={postFormat}
                      isMulti={false}
                      onChange={(e) => {
                        this.setState({ postFormat: e });
                      }}
                      isClearable={true}
                      backspaceRemovesValue={true}
                    />
                  </div>
                  <div className="mgr-10 filter-option-item">
                    <Select
                      className="custom-react-select list"
                      placeholder="Chọn loại bài viết"
                      options={NURSERY_EDUCATIONS_POST_TYPE.map((x) => {
                        return { value: x.code, label: x.name };
                      })}
                      value={postType}
                      isMulti={false}
                      onChange={(e) => {
                        this.setState({ postType: e });
                      }}
                      isClearable={true}
                      backspaceRemovesValue={true}
                    />
                  </div>
                  <Button
                    onClick={() => {
                      this.onSubmitSearch();
                    }}
                    variant="outline-secondary"
                    id="button-addon2"
                  >
                    <FontAwesomeIcon icon={Icon.faSearch} className="" />
                  </Button>
                </div>
              </div>
            </div>

            {this.renderContentPost()}
          </Tab>

          <Tab eventKey="milestone" title="Milestone">
            <div className="dp-flex justify-content-space-between align-items-center">
              <div
                onClick={(e) => {
                  this.setState({ dataDetail: {} }, () => {
                    this.toggleModal();
                  });
                }}
                className="custom-btn"
              >
                <FontAwesomeIcon icon={Icon.faPlus} className="me-2" /> Thêm
              </div>

              <div>
                <div className="mb-3 d-flex align-items-center">
                  <div className="mgr-10 filter-option-item">
                    <Select
                      className="custom-react-select list"
                      placeholder="Chọn loại phát triển"
                      options={NURSERY_EDUCATIONS_DEVELOPMENT_TYPE.filter(
                        (x) => x.code !== ""
                      ).map((x) => {
                        return { value: x.name, label: x.name };
                      })}
                      value={developmentType}
                      isMulti={false}
                      onChange={(e) => {
                        this.setState({ developmentType: e });
                      }}
                      isClearable={true}
                      backspaceRemovesValue={true}
                    />
                  </div>
                  <div className="mgr-10 filter-option-item">
                    <Select
                      className="custom-react-select list"
                      placeholder="Chọn tháng"
                      options={NURSERY_EDUCATIONS_MONTHS.filter(
                        (x) => x.code !== "ALL"
                      ).map((x) => {
                        return { value: x.id, label: x.name };
                      })}
                      value={month}
                      isMulti={false}
                      onChange={(e) => {
                        this.setState({ month: e });
                      }}
                      isClearable={true}
                      backspaceRemovesValue={true}
                    />
                  </div>

                  <Button
                    onClick={() => {
                      this.onSubmitSearch();
                    }}
                    variant="outline-secondary"
                    id="button-addon2"
                  >
                    <FontAwesomeIcon icon={Icon.faSearch} className="" />
                  </Button>
                </div>
              </div>
            </div>

            {this.renderContentMilestone()}
          </Tab>

          <Tab eventKey="teaching_aids" title="Giáo cụ">
            <div className="dp-flex justify-content-space-between align-items-center">
              <div
                onClick={(e) => {
                  this.setState({ dataDetail: {} }, () => {
                    this.toggleModal();
                  });
                }}
                className="custom-btn"
              >
                <FontAwesomeIcon icon={Icon.faPlus} className="me-2" /> Thêm
              </div>

              <div>
                <div className="mb-3 d-flex align-items-center">
                  <div className="mgr-10 filter-option-item">
                    <FormControl
                      value={teachingName}
                      onChange={(e) =>
                        this.setState({ teachingName: e.target.value })
                      }
                      onKeyDown={(e) => {
                        if (e?.keyCode === 13) {
                          this.onSubmitSearch();
                        }
                      }}
                      placeholder={"Nhập tên giáo cụ"}
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                    />
                  </div>
                  <div className="mgr-10 filter-option-item">
                    <Select
                      className="custom-react-select list"
                      placeholder="Chọn nhóm giáo cụ"
                      options={NURSERY_EDUCATIONS_TEACHING_AID_GROUP.map(
                        (x) => {
                          return { value: x.name, label: x.name };
                        }
                      )}
                      value={teachingGroup}
                      isMulti={false}
                      onChange={(e) => {
                        this.setState({ teachingGroup: e });
                      }}
                      isClearable={true}
                      backspaceRemovesValue={true}
                    />
                  </div>
                  <div className="mgr-10 filter-option-item">
                    <Select
                      className="custom-react-select list"
                      placeholder="Chọn tháng"
                      options={NURSERY_EDUCATIONS_MONTHS.filter(
                        (x) => x.code !== "ALL"
                      ).map((x) => {
                        return { value: x.id, label: x.name };
                      })}
                      value={month}
                      isMulti={false}
                      onChange={(e) => {
                        this.setState({ month: e });
                      }}
                      isClearable={true}
                      backspaceRemovesValue={true}
                    />
                  </div>

                  <Button
                    onClick={() => {
                      this.onSubmitSearch();
                    }}
                    variant="outline-secondary"
                    id="button-addon2"
                  >
                    <FontAwesomeIcon icon={Icon.faSearch} className="" />
                  </Button>
                </div>
              </div>
            </div>

            {this.renderContentTeachingAids()}
          </Tab>

          <Tab eventKey="daily" title="Lộ trình theo ngày">
            <div className="dp-flex justify-content-space-between align-items-center">
              <div
                onClick={(e) => {
                  this.setState({ dataDetail: {} }, () => {
                    this.toggleModal();
                  });
                }}
                className="custom-btn"
              >
                <FontAwesomeIcon icon={Icon.faPlus} className="me-2" /> Thêm
              </div>

              <div>
                <div className="mb-3 d-flex align-items-center">
                  <div className="mgr-10 filter-option-item">
                    <FormControl
                      value={day}
                      onChange={(e) => this.setState({ day: e.target.value })}
                      onKeyDown={(e) => {
                        if (e?.keyCode === 13) {
                          this.onSubmitSearch();
                        }
                      }}
                      placeholder={"Nhập số ngày"}
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                    />
                  </div>

                  <Button
                    onClick={() => {
                      this.onSubmitSearch();
                    }}
                    variant="outline-secondary"
                    id="button-addon2"
                  >
                    <FontAwesomeIcon icon={Icon.faSearch} className="" />
                  </Button>
                </div>
              </div>
            </div>

            {this.renderContentDaily()}
          </Tab>
        </Tabs>

        {this.renderModalByTab()}
      </div>
    );
  }
}
