import { useEffect, useRef, useState } from "react";
import { Button, Input, Pagination, Select, Space, Spin, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { GLOBAL_HEIGHT, NOTIFICATION_TYPES } from "src/utils/constant";
import { useWindowSize } from "src/hooks/customHooks";
import { TitleColumn } from "src/components/table/TitleColumn";
import ModalCreatePostNoti from "./ModalCreatePostNoti";
import notificationPostService from "src/services/notification-post";

type State = {
  posts: PostNotification[];
  loading: boolean;
  isShowModal: boolean;
  postSelected?: PostNotification;
  isRefresh: boolean;
  total: number;
};

const defaultParams = {
  postName: undefined,
  postType: undefined,
  timestampFrom: undefined,
  timestampTo: undefined,
  page: 0,
  size: 20,
};

function NotificationPosts() {
  const COLUMNS: ColumnsType<any> = [
    {
      title: <TitleColumn title={"STT"} />,
      width: "5%",
      render: (_, __, index: number) => index + 1,
    },
    {
      title: <TitleColumn title={"Tên bài viết"} />,
      dataIndex: "postName",
      key: "postName",
      width: "9%",
    },
    {
      title: <TitleColumn title={"Loại thông báo"} />,
      dataIndex: "postType",
      key: "postType",
      width: "11%",
      render: (_, { postType }) => (
        <p style={{ fontSize: 14 }}>
          {NOTIFICATION_TYPES.find((item) => item.id === postType)?.title ??
            "---"}
        </p>
      ),
    },
    {
      title: <TitleColumn title={"Người tạo"} />,
      dataIndex: "createdBy",
      key: "createdBy",
      width: "10%",
    },
    {
      title: <TitleColumn title={"Ngày tạo"} />,
      dataIndex: "addedTimestamp",
      key: "addedTimestamp",
      width: "8%",
      render: (_, { addedTimestamp }) => (
        <p style={{ fontSize: 14 }}>
          {moment(addedTimestamp).format("DD/MM/YYYY")}
        </p>
      ),
    },
    {
      title: <TitleColumn title={"Thao tác"} />,
      dataIndex: "action",
      key: "action",
      width: "8%",
      render: (value: any, record: PostNotification, index: number) => (
        <p
          onClick={() => onShowModal(record)}
          style={{ color: "#3A85DC", fontSize: 14 }}
        >
          Chỉnh sửa
        </p>
      ),
    },
  ];
  const windowSize = useWindowSize();

  const [state, setState] = useState<State>({
    posts: [],
    postSelected: undefined,
    loading: false,
    isShowModal: false,
    isRefresh: false,
    total: 0,
  });

  const filters = useRef<SearchPostNotificationParams>(defaultParams);

  const fetchPosts = async (params?: SearchPostNotificationParams) => {
    const { data = [], headers = [] } = await notificationPostService.getPosts(
      params
    );
    setState((cur: State) => ({
      ...cur,
      posts: data,
      loading: false,
      isRefresh: false,
      isShowModal: false,
      total: parseFloat(headers["x-total-count"]),
    }));
  };

  useEffect(() => {
    fetchPosts(filters.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = () => {
    setState((cur: State) => ({ ...cur, loading: true }));
    fetchPosts(filters.current);
  };

  const onShowSizeChange = (page: number, pageSize: number) => {
    setState((cur: State) => ({ ...cur, loading: true }));
    fetchPosts({ ...filters.current, page: page, size: pageSize });
  };

  const onSelectType = (value: number) => {
    filters.current.postType = value ?? null;
  };

  const onChangeText = (e: any) => {
    filters.current.postName = e.target.value;
  };

  const onShowModal = (post: PostNotification) => {
    setState((cur: State) => ({
      ...cur,
      postSelected: post,
      isShowModal: true,
    }));
  };

  const onCreateNoti = () => {
    setState((cur: State) => ({
      ...cur,
      postSelected: undefined,
      isCreateModal: true,
      isShowModal: true,
    }));
  };

  const onHideModal = () => {
    setState((cur: State) => ({ ...cur, isShowModal: false }));
  };

  const onSubmitSuccess = async () => {
    fetchPosts(defaultParams);
  };

  const { Option } = Select;

  let tableHeight = windowSize?.height - GLOBAL_HEIGHT;

  return (
    <div className={"user-vip-container"}>
      {state.isShowModal && (
        <ModalCreatePostNoti
          notificationInfo={state.postSelected}
          onHide={onHideModal}
          onSubmitSuccess={onSubmitSuccess}
        />
      )}
      <Spin spinning={state.loading || state.isRefresh} tip={"Loading..."}>
        <Space>
          <Button type="primary" onClick={onCreateNoti}>
            Thêm mới
          </Button>

          <Input
            placeholder="Nhập tên bài viết"
            style={{ width: 300 }}
            onChange={onChangeText}
          />

          <Select
            allowClear
            placeholder="Loại thông báo"
            optionFilterProp="children"
            style={{ width: 150 }}
            onChange={onSelectType}
          >
            {NOTIFICATION_TYPES.map((item) => (
              <Option value={item.id} key={"noti-type-search-" + item.id}>
                {item.title}
              </Option>
            ))}
          </Select>

          <Button type="primary" onClick={onSearch}>
            Tìm kiếm
          </Button>
        </Space>

        <Table
          dataSource={state.posts}
          columns={COLUMNS}
          pagination={false}
          rowKey={"id"}
          scroll={{ y: tableHeight }}
        />
        <Pagination
          style={{ marginTop: 12 }}
          showSizeChanger
          onChange={onShowSizeChange}
          onShowSizeChange={onShowSizeChange}
          defaultCurrent={1}
          total={state.total}
          showTotal={(total) => `Tổng số ${total}`}
        />
      </Spin>
    </div>
  );
}

export { NotificationPosts };
