import { useEffect, useRef, useState } from "react";
import {
  Button,
  DatePicker,
  Input,
  notification,
  Pagination,
  Select,
  Space,
  Spin,
  Table,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { Link } from "react-router-dom";
import { saveAs } from "file-saver";
import { useWindowSize } from "src/hooks/customHooks";
import { UploadOutlined } from "@ant-design/icons";
import { GLOBAL_HEIGHT } from "src/utils/constant";
import CustomerService, {
  SearchUserParams,
} from "src/services/customer.services";
import { TitleColumn } from "src/components/table/TitleColumn";

interface UserInfo {
  userId?: number;
  fullName?: string;
  phoneNumber?: string;
  customerType: string;
  target?: string;
  createdDate?: number;
  expiredDate?: number;
  appointmentDate?: number;
  subscriptionPackage?: string;
  statusForDisplay: string;
  status: number;
}

type State = {
  users: UserInfo[];
  loading: boolean;
  isRefresh: boolean;
  stage: any[];
  total: number;
};

const TARGETS = [
  { id: "mongcon", title: "Mong con" },
  { id: "mangthai", title: "Mang thai" },
  { id: "sausinh", title: "Sau sinh" },
];

const STATUS = [
  { id: 1, title: "Active" },
  { id: 0, title: "Inactive" },
];

const CUSTOMER_TYPE = [
  { id: "vip", title: "Vip" },
  { id: "free", title: "Free" },
  { id: "trial", title: "Trial" },
];

const STAGE_PREGNANCY = [
  { id: "tamcanguyet1", title: "Tam cá nguyệt 1" },
  { id: "tamcanguyet2", title: "Tam cá nguyệt 2" },
  { id: "tamcanguyet3", title: "Tam cá nguyệt 3" },
];

const STAGE_BABY = [
  { id: "0_3_month", title: "Từ 0 - 3 tháng" },
  { id: "4_6_month", title: "Từ 4 - 6 tháng" },
  { id: "7_9_month", title: "Từ 7 - 9 tháng" },
  { id: "10_12_month", title: "Từ 10 - 12 tháng" },
];

const defaultParams = {
  query: null,
  target: null,
  status: null,
  stage: null,
  customerType: null,
  createdDateFrom: null,
  createdDateTo: null,
  expiredDateFrom: null,
  expiredDateTo: null,
  page: 0,
  size: 20,
};

function Customer() {
  const COLUMNS: ColumnsType<any> = [
    {
      title: <TitleColumn title={"User Id"} />,
      dataIndex: "userId",
      key: "userId",
      width: "8%",
    },
    {
      title: <TitleColumn title={"Tên User"} />,
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: <TitleColumn title={"SĐT"} />,
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (_, { phoneNumber }) => (
        <p style={{ width: 125, fontSize: 14 }}>{phoneNumber}</p>
      ),
    },
    {
      title: <TitleColumn title={"Mục tiêu"} />,
      dataIndex: "target",
      key: "target",
      width: "9%",
    },
    {
      title: <TitleColumn title={"Trạng thái"} />,
      dataIndex: "statusForDisplay",
      key: "statusForDisplay",
      width: "9%",
    },
    {
      title: <TitleColumn title={"Loại KH"} />,
      dataIndex: "customerType",
      key: "customerType",
      width: "8%",
    },
    {
      title: <TitleColumn title={"Ngày tạo App"} />,
      dataIndex: "createdDate",
      key: "createdDate",
      render: (_, { createdDate }) => (
        <p style={{ fontSize: 14 }}>
          {moment(createdDate).format("DD/MM/YYYY")}
        </p>
      ),
    },
    {
      title: <TitleColumn title={"Ngày hết hạn Vip"} />,
      dataIndex: "expiredDate",
      key: "expiredDate",
      render: (_, { expiredDate }) => (
        <p style={{ fontSize: 14 }}>
          {moment(expiredDate).format("DD/MM/YYYY")}
        </p>
      ),
    },
    {
      title: <TitleColumn title={"Thao tác"} />,
      dataIndex: "action",
      key: "action",
      render: (_, record: UserInfo, __) => (
        <Link
          className={"pointer"}
          to={`/customers/${record.userId}`}
          style={{ textDecoration: "none", color: "#277CBE" }}
        >
          Xem chi tiết
        </Link>
      ),
    },
  ];

  const windowSize = useWindowSize();

  const [state, setState] = useState<State>({
    users: [],
    loading: true,
    isRefresh: false,
    stage: [],
    total: 0,
  });

  const filters = useRef<SearchUserParams>(defaultParams);

  const userService = new CustomerService();

  const fetchUsers = async (params?: SearchUserParams) => {
    const response = await userService.searchUser(params);
    if (response?.status === 200) {
      const { data, headers } = response;
      setState((cur: State) => ({
        ...cur,
        users: data,
        loading: false,
        isRefresh: false,
        total: parseFloat(headers["x-total-count"]),
      }));
    } else {
      notification.error({message: "Có lỗi xảy ra!!!"})
      setState({
        users: [],
        loading: false,
        isRefresh: false,
        stage: [],
        total: 0,
      });
    };
  };

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = () => {
    setState((cur: State) => ({ ...cur, loading: true }));
    fetchUsers(filters.current);
  };

  const onSelectTarget = (value: string) => {
    filters.current.target = value ?? null;
    let stageData: any[] = [];
    switch (value) {
      case "mangthai":
        stageData = STAGE_PREGNANCY;
        break;
      case "sausinh":
        stageData = STAGE_BABY;
        break;
      default:
        filters.current.stage = null;
        break;
    }
    setState((cur: State) => ({
      ...cur,
      stage: stageData,
    }));
  };

  const onSelectCustomerType = (value: string) => {
    filters.current.customerType = value ?? null;
  };

  const onSelectStage = (value: string) => {
    filters.current.stage = value ?? null;
  };

  const onSelectStatus = (value: number) => {
    filters.current.status = value ?? null;
  };

  const onChangeText = (e: any) => {
    filters.current.query = e.target.value;
  };

  const convertDateToTimeStamp = (date: any, isStartOfDay: boolean): number => {
    if (isStartOfDay) {
      return Number(moment(date).startOf("day").format("X")) * 1000;
    } else {
      return Number(moment(date).endOf("day").format("X")) * 1000;
    }
  };

  const onShowSizeChange = (page: number, pageSize: number) => {
    setState((cur: State) => ({ ...cur, loading: true }));
    fetchUsers({ ...filters.current, page: page - 1, size: pageSize });
  };

  const onChangeDateCreateApp = (
    values: any,
    formatString: [string, string]
  ) => {
    if (values) {
      filters.current.createdDateFrom = convertDateToTimeStamp(values[0], true);
      filters.current.createdDateTo = convertDateToTimeStamp(values[1], false);
    } else {
      filters.current.createdDateFrom = null;
      filters.current.createdDateTo = null;
    }
  };

  const onChangeExpireVip = (values: any, formatString: [string, string]) => {
    if (values) {
      filters.current.expiredDateFrom = convertDateToTimeStamp(values[0], true);
      filters.current.expiredDateTo = convertDateToTimeStamp(values[1], false);
    } else {
      filters.current.expiredDateFrom = null;
      filters.current.expiredDateTo = null;
    }
  };

  const exportFile = async () => {
    const resp = await userService.exportFileCustomers(filters.current);
    const fileName = `BBU_Data_Export_${moment().format(
      "YYYYMMDD"
    )}_${moment().unix()}.xlsx`;
    saveAs(resp, fileName);
  };

  const { Option } = Select;

  const { RangePicker } = DatePicker;

  let tableHeight = windowSize?.height - GLOBAL_HEIGHT - 52;

  return (
    <div className={"user-vip-container"}>
      <Spin spinning={state.loading || state.isRefresh} tip={"Loading..."}>
        {!state.isRefresh ? (
          <Space direction="vertical">
            <Space>
              <Input
                placeholder="Nhập User ID, SĐT"
                style={{ width: 200 }}
                onChange={onChangeText}
                onPressEnter={onSearch}
              />

              <Select
                allowClear
                placeholder="Chọn trạng thái"
                optionFilterProp="children"
                style={{ width: 150 }}
                onChange={onSelectStatus}
              >
                {STATUS.map((item) => (
                  <Option value={item.id} key={"target" + item.id}>
                    {item.title}
                  </Option>
                ))}
              </Select>

              <Select
                allowClear
                placeholder="Chọn mục tiêu"
                optionFilterProp="children"
                style={{ width: 150 }}
                onChange={onSelectTarget}
              >
                {TARGETS.map((item) => (
                  <Option value={item.id} key={"target" + item.id}>
                    {item.title}
                  </Option>
                ))}
              </Select>

              <Select
                allowClear
                placeholder="Chọn loại KH"
                optionFilterProp="children"
                style={{ width: 150 }}
                onChange={onSelectCustomerType}
              >
                {CUSTOMER_TYPE.map((item) => (
                  <Option value={item.id} key={"target" + item.id}>
                    {item.title}
                  </Option>
                ))}
              </Select>

              <Select
                allowClear
                placeholder="Chọn giai đoạn"
                optionFilterProp="children"
                style={{ width: 150 }}
                onChange={onSelectStage}
                disabled={state.stage.length === 0}
              >
                {state.stage.map((item) => (
                  <Option value={item.id} key={"target" + item.id}>
                    {item.title}
                  </Option>
                ))}
              </Select>
            </Space>
            <Space>
              <RangePicker
                allowClear
                onChange={onChangeDateCreateApp}
                placeholder={["Ngày tạo từ", "Ngày tạo đến"]}
                format={"DD/MM/YYYY"}
                style={{ width: 300 }}
              />
              <RangePicker
                allowClear
                onChange={onChangeExpireVip}
                placeholder={["Ngày hết hạn từ", "Ngày hết hạn đến"]}
                format={"DD/MM/YYYY"}
                style={{ width: 300 }}
              />
              <Button type="primary" onClick={onSearch}>
                Tìm kiếm
              </Button>

              <Button onClick={exportFile} icon={<UploadOutlined />}>
                Export
              </Button>
            </Space>
          </Space>
        ) : null}

        <Table
          style={styles.marginTop12}
          dataSource={state.users}
          rowKey={"userId"}
          columns={COLUMNS}
          pagination={false}
          scroll={{ y: tableHeight }}
        />
        <Pagination
          style={styles.marginTop12}
          showSizeChanger
          onChange={onShowSizeChange}
          onShowSizeChange={onShowSizeChange}
          defaultCurrent={1}
          total={state.total}
          showTotal={(total) => `Tổng số ${total}`}
        />
      </Spin>
    </div>
  );
}

export { Customer };

const styles = {
  marginTop12: { marginTop: 12 },
  title: {
    maxWidth: 150,
    flexWrap: "wrap",
    wordWrap: "break-word",
    fontWeight: "bold",
  },
};
