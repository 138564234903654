import React from "react";
import { Modal, Button, FormControl } from "@themesberg/react-bootstrap";
import { Row, Col } from "antd";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import Dropzone from "react-dropzone";
import Select from "react-select";

import { toast } from "react-toastify";
import NurseryEducations from "../../services/nursery-educations.service";
import ProfessionalsService from "../../services/professionals.service";
import { NURSERY_EDUCATIONS_DEVELOPMENT_TYPE } from "../../contains/common";

export default class ModalPost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bodyData: {},
      todoLists: [
        {
          id: 1,
          topic: "",
          videoId: "",
          posts: [],
        },
      ],
    };
    this.timeOut = "";
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(prevProps.dataDetail) !==
      JSON.stringify(this.props.dataDetail)
    ) {
      const { dataDetail = {} } = this.props;

      let newObj = {
        ...dataDetail,
      };

      let todoArr = [
        {
          id: 1,
          topic: "",
          videoId: "",
          posts: [],
        },
      ];
      if (
        Array.isArray(dataDetail?.nurseryEducationPosts) &&
        dataDetail?.nurseryEducationPosts.length > 0
      ) {
        todoArr = [];
        dataDetail?.nurseryEducationPosts.map((item, index) => {
          todoArr.push({
            ...item,
            topic: item.topicName && {
              value: item.topicName,
              label: item.topicName,
            },
            post: item.postName && { value: item.id, label: item.postName },
          });
          setTimeout(() => {
            this.getListVideos("", item.topicName, index);
          }, 500);
        });
      }

      this.setState({
        bodyData: newObj,
        todoLists: todoArr,
        originalData: _.cloneDeep(newObj),
      });
    }
  }

  onSubmit = async () => {
    const { bodyData = {}, originalData = {}, todoLists = [] } = this.state;
    const { getData } = this.props;

    if (!bodyData?.day || !bodyData?.day.toString().trim()) {
      toast.error("Vui lòng nhập số ngày");
      return;
    }

    let listVideos = todoLists.filter((x) => !!x?.post && !!x?.post?.value);

    if (listVideos.length === 0) {
      toast.error("Vui lòng chọn bài Posts (Video)");
      return;
    }

    const nurseryEducationPostIds = listVideos.map((x) => x?.post?.value);

    let body = {
      day: bodyData?.day.toString().trim(),
      nurseryEducationPostIds: nurseryEducationPostIds || [],
    };

    try {
      if (bodyData?.id || bodyData?.id === 0) {
        await NurseryEducations.updateDaily(bodyData?.id, body);
        toast.success("Sửa thành công");
      } else {
        await NurseryEducations.createDaily(body);
        toast.success("Tạo thành công");
      }
      this.onToggle();
      getData();
    } catch (error) {
      let msg = "";
      switch (error?.message) {
        case "nursery_education_daily_duplicate":
          msg = "Lộ trình đã tồn tại";
          break;
        case "nursery_education_daily_not_found":
          msg = "Lộ trình không tồn tại";
          break;
        default:
          break;
      }

      toast.error(
        msg || "Có lỗi xảy ra vui lòng liên hệ kỹ thuật để được hỗ trợ."
      );
    }
  };

  onToggle = () => {
    const { toggle } = this.props;
    this.setState(
      {
        bodyData: {},
        todoLists: [
          {
            id: 1,
            topic: "",
            videoId: "",
            posts: [],
          },
        ],
      },
      () => {
        toggle();
      }
    );
  };

  getListVideos = async (searchString, topicName, index) => {
    const { todoLists = [] } = this.state;
    let queryString = `?page=0&size=${20}&postFormat=Video&topicName=${topicName}&sort=createdDate:desc${
      searchString ? `&postName=${encodeURIComponent(searchString)}` : ""
    }`;

    const resp = await NurseryEducations.listPosts(queryString);
    let curr = todoLists[index];
    curr.ports = resp.data || [];
    todoLists[index] = curr;
    this.setState({ todoLists });
  };

  onInputSelectChange = (value, topicName, index) => {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
    }
    this.timeOut = setTimeout(() => {
      this.getListVideos(value, topicName, index);
    }, 500);
  };

  renderToDoList = () => {
    const { todoLists = [] } = this.state;
    console.log("todoLists", todoLists);
    return (
      <div>
        <div className="modal-row d-flex align-items-center">
          <div className="modal-label-small">Hoạt động</div>

          <div
            className="cursor-pointer"
            onClick={() => {
              todoLists.push({
                id:
                  todoLists.length > 0
                    ? todoLists[todoLists.length - 1].id + 1
                    : 1,
                topic: "",
                videoId: "",
                posts: [],
              });
              this.setState({ todoLists });
            }}
          >
            <FontAwesomeIcon icon={Icon.faPlusCircle} className="me-2" />
          </div>
        </div>
        <div className="modal-row d-flex align-items-center">
          <div className="modal-label-small"></div>
          <div className="video-segments-container">
            {todoLists.map((item, index) => {
              return (
                <div
                  className="video-segments-item d-flex align-items-start"
                  key={index}
                >
                  <div className="width-100-percent">
                    <div className="modal-row d-flex align-items-center">
                      <div className="modal-label-small">
                        {index + 1}. Loại hoạt động:
                      </div>

                      <Select
                        className="custom-react-select"
                        placeholder="Chọn loại hoạt động"
                        options={NURSERY_EDUCATIONS_DEVELOPMENT_TYPE.map(
                          (x) => {
                            return { value: x.name, label: x.name };
                          }
                        )}
                        value={item?.topic}
                        isMulti={false}
                        onChange={(e) => {
                          let curr = todoLists[index];
                          curr.topic = e;
                          curr.post = "";
                          todoLists[index] = curr;
                          this.setState({ todoLists }, () => {
                            this.getListVideos("", e?.value, index);
                          });
                        }}
                        isClearable={true}
                        backspaceRemovesValue={true}
                      />
                    </div>

                    <div className="modal-row d-flex align-items-center">
                      <div className="modal-label-small">Post (Video):</div>
                      <Select
                        isDisabled={!item?.topic}
                        className="custom-react-select"
                        placeholder="Chọn Post (Video)"
                        onInputChange={(e) =>
                          this.onInputSelectChange(e, item?.topic?.value, index)
                        }
                        options={
                          Array.isArray(item.ports)
                            ? item.ports.map((x) => {
                                return { value: x.id, label: x.postName };
                              })
                            : []
                        }
                        value={item?.post}
                        isMulti={false}
                        onChange={(e) => {
                          let curr = todoLists[index];
                          curr.post = e;
                          todoLists[index] = curr;
                        }}
                        isClearable={true}
                        backspaceRemovesValue={true}
                      />
                    </div>
                  </div>

                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      let arr = todoLists.filter((x) => x.id !== item.id);
                      this.setState({ todoLists: arr });
                    }}
                  >
                    <FontAwesomeIcon icon={Icon.faTrash} className="pdl-20" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { modalStatus } = this.props;
    const { professionals = [], bodyData = {} } = this.state;

    return (
      <Modal
        dialogClassName="modal-90w"
        as={Modal.Dialog}
        show={modalStatus}
        onHide={this.onToggle}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {bodyData?.id || bodyData?.id === 0
              ? "Chỉnh sửa lộ trình"
              : "Thêm mới lộ trình"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row gutter={20}>
            <Col span={24}>
              <div className="modal-row d-flex align-items-center">
                <div className="modal-label-small">Ngày:</div>

                <FormControl
                  value={bodyData.day}
                  onChange={(e) =>
                    this.setState({
                      bodyData: { ...bodyData, day: e.target.value },
                    })
                  }
                  placeholder="Nhập số ngày"
                />
              </div>
            </Col>
          </Row>

          {this.renderToDoList()}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.onToggle}>
            Đóng
          </Button>
          <Button variant="primary" onClick={() => this.onSubmit()}>
            {bodyData?.id || bodyData?.id === 0 ? "Lưu" : "Tạo"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
