import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Modal } from "@themesberg/react-bootstrap";

import { Field, Formik } from "formik";
import * as Yup from "yup";

import { ERROR_MESSAGE } from "../../contains/errors";
import { onCreate, onUpdate, hideModal } from "../../store/news";

import Editor from "../../components/Editor";

const NewsForm = () => {
  const dispatch = useDispatch();
  const isOpenModal = useSelector(({ news }) => news.isOpenModal);
  const activeItem = useSelector(({ news }) => news.item);

  const closeModal = () => {
    dispatch(hideModal());
  };

  const removeFields = (item, fields) => {
    for (let field of fields) {
      if (item[field]) {
        delete item[field];
      }
    }
    return item;
  };

  const onValidate = Yup.object().shape({
    newsName: Yup.string().required(ERROR_MESSAGE.NEWS_NAME_REQUIRED),
    newsContent: Yup.string().required(ERROR_MESSAGE.NEWS_CONTENT_REQUIRED),
  });

  const onSubmit = async (values) => {
    let dataPost = {
      newsId: values.newsId,
      newsName: values.newsName,
      newsContent: values.newsContent,
      startDate: values.startDate || "",
      endDate: values.endDate || "",
      attachIds: values.attachIds || [],
    };

    if (activeItem.newsId > 0) {
      await dispatch(onUpdate(dataPost));
    } else {
      await dispatch(onCreate(dataPost));
    }
  };

  return (
    <Modal
      size="xl"
      as={Modal.Dialog}
      centered
      show={isOpenModal}
      onHide={closeModal}
    >
      <Formik
        initialValues={activeItem}
        onSubmit={onSubmit}
        validationSchema={onValidate}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <Modal.Header>
              <Modal.Title className="h6">Tin tức</Modal.Title>
              <Button variant="close" aria-label="Close" onClick={closeModal} />
            </Modal.Header>
            <Modal.Body>
              <Form.Group className="mb-3">
                <Form.Label>Tên tin tức</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nhập tên tin tức"
                  name="newsName"
                  isInvalid={props.errors.newsName && props.touched.newsName}
                  onChange={props.handleChange}
                  value={props.values.newsName}
                />
                <Form.Control.Feedback type="invalid">
                  {props.errors.newsName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Nội dung</Form.Label>
                <Field name="newsContent">
                  {({ field }) => (
                    <Editor
                      className={
                        props.errors.newsContent &&
                        props.touched.newsContent &&
                        `editor-errors`
                      }
                      value={field.value}
                      onChange={field.onChange(field.name)}
                    />
                  )}
                </Field>
                {props.errors.newsContent && props.touched.newsContent && (
                  <div className="input-error">{props.errors.newsContent}</div>
                )}
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                className="text-gray ms-auto"
                type="submit"
              >
                {activeItem.newsId > 0 ? `Cập nhật` : `Tạo tin tức`}
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default NewsForm;
