import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Modal } from "@themesberg/react-bootstrap";

import { Field, Formik } from "formik";
import * as Yup from "yup";

import { ERROR_MESSAGE } from "../../contains/errors";
import { fetchAll } from "../../store/target";
import { onCreate, onUpdate, hideModal } from "../../store/category";
import { SelectField } from "../../components/SelectField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import FileService from "../../services/file.service";
import { categoryService } from "../../services/category.service";

const CategoryForm = () => {
  const targetMap = [
    {
      id: 1,
      code: "mongcon",
    },
    {
      id: 2,
      code: "mangthai",
    },
    {
      id: 3,
      code: "sausinh",
    },
  ];
  const dispatch = useDispatch();
  const isOpenModal = useSelector(({ category }) => category.isOpenModal);
  const activeItem = useSelector(({ category }) => category.item);
  const newActiveItem = {
    ...activeItem,
    relatedSubGoals: Array.isArray(activeItem.relatedSubGoals)
      ? activeItem.relatedSubGoals.map((x) => ({
          value: x.code,
          label: x.name,
        }))
      : null,
  };

  const listTargets = useSelector(({ targets }) => targets.table.items);
  const fileService = new FileService();

  const [subGoals, setSubGoals] = useState([]);
  const [curTarget, setCurTarget] = useState({});

  useEffect(() => {
    (async () => {
      await dispatch(fetchAll());
    })();
  }, []);

  useEffect(() => {
    const curTarget = targetMap.find((x) => x.id === activeItem?.targetId);
    setCurTarget(curTarget);
  }, [activeItem]);

  useEffect(() => {
    categoryService
      .getSubGoal({ size: 1000 })
      .then((response) => setSubGoals(response.data))
      .catch(() => setSubGoals([]));
  }, []);

  const closeModal = () => {
    dispatch(hideModal());
  };

  const onValidate = Yup.object().shape({
    categoryName: Yup.string().required(ERROR_MESSAGE.CATEGORY_NAME_REQUIRED),
    preFix: Yup.string().required(ERROR_MESSAGE.PREFIX_REQUIRED),
    targetId: Yup.string().required(ERROR_MESSAGE.TARGET_OPTION_REQUIRED),
  });

  const onSubmit = async (values) => {
    let postData = {
      categoryId: values.categoryId,
      preFix: values.preFix,
      categoryName: values.categoryName,
      categoryContent: values.categoryContent,
      order: values.order,
      targetId: values.targetId,
      image: values.image,
      relatedSubGoals: Array.isArray(values.relatedSubGoals)
        ? values.relatedSubGoals.map((x) => x.value)
        : [],
    };

    if (newActiveItem.categoryId > 0) {
      await dispatch(onUpdate(postData));
    } else {
      await dispatch(onCreate(postData));
    }
  };

  const handleImageUpload = async (e, setFieldValue, prop) => {
    const file = e.target.files[0];
    var fileSize = file.size / 1024 / 1024; // in MiB
    if (fileSize > 5) {
      return alert("Dung lượng ảnh lớn hơn 5 MiB, vui lòng chọn ảnh khác.");
    }

    let body = {
      objectType: "category",
      displayType: 2,
    };
    // objectType: "posts"
    // objectId: ID bài viết
    // displayType: 1(thumbnail), 2( icon), 3(Cover), 4(video), 5(Audio), 6(Infographic), 7(avatar)
    try {
      const resp = await fileService.uploadImage(file, body);
      if (Array.isArray(resp.linkUrl)) {
        if (prop === "image") {
          setFieldValue("image", resp.attachIdStr);
          setFieldValue("imageUrl", resp.linkUrl[0]);
        } else {
          setFieldValue(prop, resp.linkUrl[0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal as={Modal.Dialog} centered show={isOpenModal} onHide={closeModal}>
      <Formik
        initialValues={newActiveItem}
        onSubmit={onSubmit}
        validationSchema={onValidate}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <Modal.Header>
              <Modal.Title className="h6">Chuyên mục</Modal.Title>
              <Button variant="close" aria-label="Close" onClick={closeModal} />
            </Modal.Header>
            <Modal.Body>
              <Form.Group className="mb-3">
                <Form.Label>Tên chuyên mục</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nhập tên chuyên mục"
                  name="categoryName"
                  isInvalid={
                    props.errors.categoryName && props.touched.categoryName
                  }
                  onChange={props.handleChange}
                  value={props.values.categoryName}
                />
                <Form.Control.Feedback type="invalid">
                  {props.errors.categoryName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Prefix chuyên mục</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nhập prefix chuyên mục"
                  name="preFix"
                  isInvalid={props.errors.preFix && props.touched.preFix}
                  onChange={props.handleChange}
                  value={props.values.preFix}
                />
                <Form.Control.Feedback type="invalid">
                  {props.errors.preFix}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Mục tiêu</Form.Label>
                <Form.Select
                  name="targetId"
                  isInvalid={props.errors.targetId && props.touched.targetId}
                  onChange={(e) => {
                    props.handleChange(e);
                    const curTarget = targetMap.find(
                      (x) => x.id == e.target.value
                    );
                    props.setFieldValue("relatedSubGoals", null);
                    setCurTarget(curTarget);
                  }}
                  value={props?.values?.targetId}
                >
                  <option value="">-- Chọn mục tiêu --</option>
                  {listTargets.map((target) => (
                    <option
                      key={`target-${target.targetId}`}
                      value={target.targetId}
                      label={target.targetName}
                    />
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {props.errors.targetId}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Lộ trình liên quan</Form.Label>
                <SelectField
                  name="relatedSubGoals"
                  placeholder="Thêm lộ trình bài viết"
                  options={subGoals
                    .filter((x) => x.goal === curTarget?.code)
                    .map((x) => ({
                      value: x.code,
                      label: x.name,
                    }))}
                  value={props.values.relatedSubGoals}
                  isMulti={true}
                  onChange={props.setFieldValue}
                  onBlur={props.setFieldTouched}
                  touched={props.touched.relatedSubGoals}
                  error={props.errors.relatedSubGoals}
                  isClearable={true}
                  backspaceRemovesValue={true}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Ảnh bìa Icon</Form.Label>
                <Form.Control
                  type="hidden"
                  name="image"
                  isInvalid={props.errors.image && props.touched.image}
                  onChange={props.handleChange}
                  value={props.values.image}
                />
                <Field
                  name="file"
                  render={({ field }) => (
                    <label className="mx-3" style={{ cursor: "pointer" }}>
                      <FontAwesomeIcon
                        icon={faCloudUploadAlt}
                        className="me-2"
                      />
                      <input
                        type="file"
                        accept="image/*"
                        {...field}
                        onChange={(e) =>
                          handleImageUpload(e, props.setFieldValue, "image")
                        }
                        className="d-none"
                      />
                    </label>
                  )}
                />
                <div>
                  {props.values.imageUrl && (
                    <img
                      src={props.values.imageUrl}
                      style={{ height: 300, width: "auto" }}
                    />
                  )}
                </div>
                <Form.Control.Feedback type="invalid">
                  {props.errors.postsAvatar}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Nội dung</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  name="categoryContent"
                  isInvalid={
                    props.errors.categoryContent &&
                    props.touched.categoryContent
                  }
                  onChange={props.handleChange}
                  value={props.values.categoryContent}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                className="text-gray ms-auto"
                type="submit"
              >
                {newActiveItem.categoryId > 0 ? `Cập nhật` : `Tạo chuyên mục`}
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default CategoryForm;
