import APIService from "./customAPI.services";

class FoodsService extends APIService {
  constructor() {
    super();
  }

  async listFoods(query) {
    return await this.request("GET", `foods${query || ""}`, {});
  }

  async listDishes(query) {
    return await this.request("GET", `dishes${query || ""}`, {});
  }

  async getFood(id) {
    return await this.request("GET", `foods/${id}`, {});
  }

  async getDishes(id) {
    return await this.request("GET", `dishes/${id}`, {});
  }

  async deleteFoods(ids) {
    return await this.request("DELETE", `foods?foodIds=${ids}`, {});
  }

  async deleteDishes(ids) {
    return await this.request("DELETE", `dishes?dishIds=${ids}`, {});
  }

  async createFood(body) {
    return await this.request("POST", `foods`, body);
  }

  async createDishes(body) {
    return await this.request("POST", `dishes`, body);
  }

  async updateFood(id, body) {
    return await this.request("PUT", `foods/${id}`, body);
  }

  async updateDishes(id, body) {
    return await this.request("PUT", `dishes/${id}`, body);
  }

  async getFoodGroups() {
    return await this.request("GET", `food-groups`, {});
  }

  async getNutritious() {
    return await this.request("GET", `categories/nutritious`, {});
  }

  async getMicronutrients() {
    return await this.request("GET", `categories/micronutrients`, {});
  }
}
const foodsService = new FoodsService();
export default foodsService;
