import React, { useState } from "react";
import { Button, Col, Form, Input, Row, Select } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import {
  displayTypes,
  gotoItems,
  repetitionIntervalItems,
} from "../../common/notification.constant";

export const Filter = (props) => {
  const [form, setForm] = useState({});

  const handleChangeFieldForm = (key, value) => {
    setForm({
      ...form,
      [key]: value,
    });
  };

  const handleSearch = () => {
    props.onSearch({ ...form });
  };

  const handleClearSearch = () => {
    setForm({});
    props.onSearch({});
  };

  return (
    <div className={"white-box white-box--filter"}>
      <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item label={"Hiển thị"}>
              <Select
                placeholder={"Hiển thị"}
                value={form.displayType}
                onChange={(value) =>
                  handleChangeFieldForm("displayType", value)
                }
              >
                {displayTypes.map((item) => (
                  <Select.Option key={item.code} value={item.code}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={"Lặp lại"}>
              <Select
                value={form.repetitionInterval}
                onChange={(value) =>
                  handleChangeFieldForm("repetitionInterval", value)
                }
                placeholder={"Lặp lại"}
              >
                {repetitionIntervalItems.map((item) => (
                  <Select.Option key={item.code} value={item.code}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={"Goto"}>
              <Select
                value={form.goTo}
                onChange={(value) => handleChangeFieldForm("goTo", value)}
                placeholder={"Goto"}
              >
                {gotoItems.map((item) => (
                  <Select.Option key={item.code} value={item.code}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={"Từ khoá"}>
              <Input
                value={form.query}
                onChange={(e) => handleChangeFieldForm("query", e.target.value)}
                placeholder={"Nhập từ khoá"}
                onPressEnter={handleSearch}
              />
            </Form.Item>
          </Col>
          <Col span={24} className={"text-right"}>
            <Button
              onClick={handleSearch}
              icon={<FontAwesomeIcon icon={Icon.faSearch} />}
            >
              &nbsp;Tìm kiếm
            </Button>
            <Button onClick={handleClearSearch} type={"link"}>
              Xoá lọc
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
