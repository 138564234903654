export const ERROR_MESSAGE = {
  NOT_UPDATE: "Chưa cập nhật",

  FIELD_REQUIRED: "Xin lỗi, không để trống trường này",
  PASSWORD_REQUIRED: "Vui lòng nhập thông tin mật khẩu.",
  PASSWORD_OLD_REQUIRED: "Vui lòng nhập thông tin mật khẩu cũ!",
  PASSWORD_NEW_REQUIRED: "Vui lòng nhập thông tin mật khẩu mới!",
  PASSWORD_CONFIRM_REQUIRED: "Vui lòng nhập lại mật khẩu.",
  FULLNAME_REQUIRED: "Vui lòng nhập thông tin họ và tên!",
  EMAIL_REQUIRED: "Vui lòng nhập thông tin địa chỉ hòm thư!",
  PHONE_REQUIRED: "Vui lòng nhập thông tin số điện thoại!",
  USERNAME_REQUIRED: "Vui lòng nhập thông tin tên đăng nhập!",
  TARGET_NAME_REQUIRED: "Vui lòng nhập tên mục tiêu",
  TARGET_OPTION_REQUIRED: "Vui lòng chọn mục tiêu",
  TOPIC_NAME_REQUIRED: "Vui lòng nhập chủ đề",
  TOPIC_OPTION_REQUIRED: "Vui lòng nhập chủ đề",
  CATEGORY_NAME_REQUIRED: "Vui lòng nhập tên chuyên mục",
  CATEGORY_OPTION_REQUIRED: "Vui lòng chọn chuyên mục",
  POST_NAME_REQUIRED: "Vui lòng nhập tên bài viết",
  POST_CONTENT_REQUIRED: "Vui lòng nhập nội dung bài viết",
  POST_TYPE_REQUIRED: "Vui lòng nhập loại bài viết",
  NEWS_NAME_REQUIRED: "Vui lòng nhập tiêu đề tin tức",
  NEWS_CONTENT_REQUIRED: "Vui lòng nhập nội dung tin tức",

  PREFIX_REQUIRED: "Vui lòng nhập prefix",
  COVER_IMAGE_REQUIRED: "Vui lòng chọn ảnh bìa",

  PASSWORD_TOO_SHORT: "Xin lỗi, mật khẩu nên nhiều hơn 6 ký tự",
  PASSWORD_MUST_BE_HAVE_NUMBER: "Xin lỗi, mật khẩu nên có ít nhất 1 ký tự số",
  PASSWORD_NOT_MATCH: "Xin lỗi, mật khẩu không giống nhau",

  PHONE_NOT_PASS:
    "Xin lỗi, số điện thoại phải có định dạng sau (0xxx..., 84xxx...)",
  EMAIL_NOT_PASS:
    "Xin lỗi, địa chỉ hòm thử chưa hợp lệ, chỉ được phép sử dụng các chữ cái (a-z), số (0-9), và dấu chấm (.).",
  ACCOUNT_NOT_PASS: "Xin lỗi, tài khoản không hợp lệ, chỉ chấp nhận email",
  FULLNAME_NOT_PASS: "Xin lỗi, tên người dùng không hợp lệ",
  FULLNAME_TOO_LONG: "Xin lỗi, tên người dùng quá dài, chỉ cho phép 30 ký tự",
};
