import APIService from "./customAPI.services";

class BabyCaresService extends APIService {
  constructor() {
    super();
  }

  async listPosts(query) {
    return await this.request(
      "GET",
      `roadmap/baby-cares/posts${query || ""}`,
      {}
    );
  }

  async listTopics(query) {
    return await this.request(
      "GET",
      `roadmap/baby-cares/topics${query || ""}`,
      {}
    );
  }

  async getPost(id) {
    return await this.request("GET", `roadmap/baby-cares/posts/${id}`, {});
  }

  async getTopic(id) {
    return await this.request("GET", `roadmap/baby-cares/topics/${id}`, {});
  }

  async createPost(body) {
    return await this.request("POST", `roadmap/baby-cares/posts`, body);
  }

  async createTopic(body) {
    return await this.request("POST", `roadmap/baby-cares/topics`, body);
  }

  async updatePost(id, body) {
    return await this.request("PATCH", `roadmap/baby-cares/posts/${id}`, body);
  }

  async updateTopic(id, body) {
    return await this.request("PATCH", `roadmap/baby-cares/topics/${id}`, body);
  }

  async getProfessionals() {
    return await this.request("GET", `professionals`, {});
  }
}
const babyCaresService = new BabyCaresService();
export default babyCaresService;
