import { useEffect, useState, useRef } from "react";
import {
  Button,
  Col,
  Input,
  Pagination,
  Row,
  Select,
  Space,
  Spin,
  Table,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useWindowSize } from "src/hooks/customHooks";
import { GLOBAL_HEIGHT } from "src/utils/constant";
import { TitleColumn } from "src/components/table/TitleColumn";
import { HiddenButton } from "src/components/exsoft/HiddenButton";
import websiteExsoftServices from "src/services/exsoft.services";
import ModalCreateJob from "./ModalCreateJob";

export const TEAM = [
  { id: 1, name: "Team Product" },
  { id: 2, name: "Team Marketing" },
  { id: 3, name: "Team Sale" },
  { id: 4, name: "Team Back Office" },
];

export const WORK_SCHEDULE_TYPE = [
  { id: 1, name: "Fulltime" },
  { id: 2, name: "Part-time" },
  { id: 3, name: "Intern" },
];

type State = {
  loading: boolean;
  showModal: boolean;
  dataModal: JobItem | undefined;
  categories: CategoryBlogItem[];
  jobs: JobItem[];
  total: number;
};

const WebsiteRecruit = () => {
  const COLUMNS: ColumnsType<any> = [
    {
      title: <TitleColumn title={"STT"} />,
      dataIndex: "userId",
      key: "userId",
      width: "8%",
      render: (_, __, index: number) => index + 1,
    },
    {
      title: <TitleColumn title={"Tên công việc"} />,
      dataIndex: "name",
      key: "name",
      width: "15%",
    },
    {
      title: <TitleColumn title={"Team"} />,
      dataIndex: "teamId",
      key: "teamId",
      width: "12%",
      render: (_, { teamId }) => (
        <p style={{ width: 125, fontSize: 14 }}>
          {TEAM.find((item) => item.id === teamId)?.name}
        </p>
      ),
    },
    {
      title: <TitleColumn title={"Hình thức làm việc"} />,
      dataIndex: "workScheduleType",
      key: "workScheduleType",
      width: "12%",
      render: (_, { workScheduleType }) => (
        <p style={{ width: 125, fontSize: 14 }}>
          {
            WORK_SCHEDULE_TYPE.find((item) => item.id === workScheduleType)
              ?.name
          }
        </p>
      ),
    },
    {
      title: <TitleColumn title={"Hạn tuyển dụng"} />,
      dataIndex: "deadline",
      key: "deadline",
      width: "12%",
      render: (_, { deadline }) => (
        <p style={{ fontSize: 14 }}>{moment(deadline).format("DD/MM/YYYY")}</p>
      ),
    },
    {
      title: <TitleColumn title={"Người tạo"} />,
      dataIndex: "createdBy",
      key: "createdBy",
      width: "8%",
    },
    {
      title: <TitleColumn title={"Hidden"} />,
      dataIndex: "hidden",
      key: "hidden",
      width: "8%",
      render: (_, item: BlogItem) => (
        <HiddenButton item={item} onHidden={onHiddenJob} />
      ),
    },
    {
      title: <TitleColumn title={"Thao tác"} />,
      dataIndex: "action",
      key: "action",
      width: "10%",
      render: (_, item: BlogItem, __) => (
        <p
          style={{ textDecoration: "none", color: "#277CBE", fontSize: 14 }}
          onClick={() => onShowEditModal(item)}
        >
          Chỉnh sửa
        </p>
      ),
    },
  ];
  const windowSize = useWindowSize();

  const searchParams = useRef<SearchJobParams>({
    name: "",
    teamId: null,
    workScheduleType: null,
    page: 0,
    size: 20,
  });

  const [state, setState] = useState<State>({
    loading: true,
    showModal: false,
    dataModal: undefined,
    categories: [],
    jobs: [],
    total: 0,
  });

  useEffect(() => {
    getJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getJobs = async (params?: SearchJobParams) => {
    const { data, headers } = await websiteExsoftServices.getJobs(params);
    setState((cur) => ({
      ...cur,
      jobs: data ?? [],
      loading: false,
      total: parseFloat(headers["x-total-count"]),
    }));
  };

  const onSearch = () => {
    getJobs(searchParams.current);
  };

  const onShowModal = () => {
    setState((cur: State) => ({
      ...cur,
      dataModal: undefined,
      showModal: true,
    }));
  };

  const onShowEditModal = (data: JobItem) => {
    setState((cur: State) => ({
      ...cur,
      dataModal: data,
      showModal: true,
    }));
  };

  const onHideModal = () => {
    setState((cur) => ({
      ...cur,
      showModal: false,
    }));
  };

  const onHiddenJob = async (id: number, hidden: boolean) => {
    return await websiteExsoftServices.hiddenJob(id, hidden);
  };

  const onShowSizeChange = (page: number, size: number) => {
    searchParams.current.page = page - 1;
    searchParams.current.size = size;
    getJobs(searchParams.current);
  };

  const onChangeText = (e: any) => {
    searchParams.current.name = e.target.value;
  };

  const onSelectTeam = (value: number) => {
    searchParams.current.teamId = value ?? null;
  };

  const onSelectWorkScheduleType = (value: number) => {
    searchParams.current.workScheduleType = value ?? null;
  };

  const { Option } = Select;

  let tableHeight = windowSize?.height - GLOBAL_HEIGHT - 52;

  return (
    <Spin spinning={state.loading} tip={"Loading..."}>
      <Row>
        <Col span={4}>
          <Button type={"primary"} onClick={onShowModal}>
            Thêm mới
          </Button>
        </Col>
        <Col
          span={20}
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Space>
            <Input
              placeholder="Nhập tên công việc"
              onChange={onChangeText}
              style={{ width: 250 }}
            />
            <Select
              allowClear
              placeholder="Chọn Team"
              optionFilterProp="children"
              style={{ width: 200 }}
              onChange={onSelectTeam}
            >
              {TEAM.map((item: any) => (
                <Option value={item.id} key={"cate" + item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
            <Select
              allowClear
              placeholder="Chọn hình thức làm việc"
              optionFilterProp="children"
              style={{ width: 200 }}
              onChange={onSelectWorkScheduleType}
            >
              {WORK_SCHEDULE_TYPE.map((item: any) => (
                <Option value={item.id} key={"cate" + item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
            <Button type="primary" onClick={onSearch}>
              Tìm kiếm
            </Button>
          </Space>
        </Col>
      </Row>

      <Table
        style={styles.marginTop12}
        dataSource={state.jobs}
        rowKey={"id"}
        columns={COLUMNS}
        pagination={false}
        scroll={{ y: tableHeight }}
      />
      <Pagination
        style={styles.marginTop12}
        showSizeChanger
        onChange={onShowSizeChange}
        onShowSizeChange={onShowSizeChange}
        defaultCurrent={1}
        total={state.total}
        showTotal={(total) => `Tổng số ${total}`}
      />

      {state.showModal && (
        <ModalCreateJob
          data={state.dataModal}
          onHide={onHideModal}
          callback={getJobs}
        />
      )}
    </Spin>
  );
};

export { WebsiteRecruit };

const styles = {
  marginTop12: {
    marginTop: 12,
  },
};
