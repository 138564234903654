import React, { useEffect, useState } from "react";
import { Breadcrumb } from "@themesberg/react-bootstrap";
import { Routes } from "../../routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import { Button, Card, Modal, notification, Table, Pagination } from "antd";
import { Link } from "react-router-dom";
import { Filter } from "./Filter";
import NotificationObjectService from "../../services/notification-object.service";
import {
  getNotificationObjectAccountType,
  getNotificationObjectClassification,
} from "../../common/notification.constant";
import lodash from "lodash";
import { GLOBAL_HEIGHT } from "src/utils/constant";
import { useWindowSize } from "src/hooks/customHooks";

const noObjService = new NotificationObjectService();

export const NotificationObject = () => {
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [objects, setObjects] = useState([]);
  const [filter, setFilter] = useState({});

  const windowSize = useWindowSize();

  useEffect(() => {
    setLoading(true);
    noObjService
      .filter({ ...filter, sort: "id:desc" })
      .then((response) => {
        setObjects(response.data);
        setTotal(lodash.get(response.headers, "x-total-count"));
      })
      .catch(() => setObjects([]))
      .finally(() => setLoading(false));
  }, [filter]);

  const columns = [
    {
      title: "Tên đối tượng",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Phân loại",
      key: "type",
      render: (row: any) => {
        return (
          getNotificationObjectClassification(row.customerClassification) ||
          "--"
        );
      },
    },
    {
      title: "Loại tài khoản",
      key: "accountType",
      render: (row: any) => {
        return getNotificationObjectAccountType(row.customerType) || "--";
      },
    },
    {
      title: "Thao tác",
      key: "actions",
      render: (row: any) => {
        return (
          <div>
            <Link
              className={"mgr-15"}
              to={Routes.NotificationObjectDetail.path.replace(":id", row.id)}
            >
              <FontAwesomeIcon
                className={"pointer text-info"}
                icon={Icon.faPencilAlt as any}
              />
            </Link>
            <FontAwesomeIcon
              onClick={() => handleRemoveNotificationObject(row)}
              className={"pointer text-danger"}
              icon={Icon.faTrash as any}
            />
          </div>
        );
      },
    },
  ];

  const handleFilter = (filter) => {
    setFilter(filter);
  };

  const handleRemoveNotificationObject = (object) => {
    Modal.confirm({
      title: "Bạn có chắc chắn muốn xoá đối tượng này?",
      okButtonProps: {
        loading: loading,
        disabled: loading,
      },
      cancelButtonProps: {
        loading: loading,
        disabled: loading,
      },
      onOk: () => {
        setLoading(true);
        noObjService
          .remove(object.id)
          .then(() => {
            notification.success({
              message: "Xoá thành công",
            });
            setFilter({
              ...filter,
              t: new Date().getMilliseconds(),
            });
          })
          .catch(() => {
            notification.error({
              message: "Xoá không thành công",
            });
          })
          .finally(() => setLoading(false));
      },
    });
  };

  const onShowSizeChange = (page: number, pageSize: number) => {
    setFilter({
      ...filter,
      page: page - 1,
      size: pageSize,
    });
  };

  let tableHeight = windowSize?.height - GLOBAL_HEIGHT;

  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item to={Routes.DashboardOverview.path}>
              <FontAwesomeIcon icon={Icon.faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item to={Routes.NotificationObject.path}>
              <FontAwesomeIcon icon={Icon.faBeer} /> Danh sách đối tượng nhận
              thông báo
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <Filter onFilter={handleFilter} />

      <Card
        title={"Danh sách đối tượng nhận thông báo"}
        extra={
          <Button loading={loading} type={"primary"}>
            <Link to={Routes.NotificationObjectCreate.path}>
              Thêm đối tượng
            </Link>
          </Button>
        }
      >
        <Table
          dataSource={objects}
          loading={loading}
          columns={columns}
          pagination={false}
          rowKey={"id"}
          scroll={{ y: tableHeight, x: windowSize?.width - 100 }}
        />
        <Pagination
          style={{ marginTop: 12 }}
          showSizeChanger
          onChange={onShowSizeChange}
          onShowSizeChange={onShowSizeChange}
          defaultCurrent={1}
          total={total}
          showTotal={(tt) => `Tổng số ${tt}`}
        />
      </Card>
    </div>
  );
};
