import APIService from "./api.services";

class PromotionService extends APIService {
  constructor() {
    super();
    this.modulePath = "promotion";
  }
}

export default PromotionService;
