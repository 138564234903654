import { Switch } from "antd";
import { AxiosResponse } from "axios";
import { useState } from "react";

type Props = {
  item: BlogItem;
  onHidden: (id: number, hidden: boolean) => Promise<AxiosResponse<any>>;
};

const HiddenButton = (props: Props) => {
  const { item, onHidden } = props;
  const [hidden, setHidden] = useState<boolean>(item.hidden);

  const onChange = async (checked: boolean) => {
    const response = await onHidden(item.id, checked);
    if (response.status === 200) {
      setHidden(!hidden);
    }
  };

  return <Switch checked={hidden} onChange={onChange} />;
};

export { HiddenButton };
