
export const Routes = {
    // pages
    Signin: { path: "/" },
    DashboardOverview: { path: "/" },

    // users
    Users: { path: "/users" },
    UserNew: { path: "/user/new" },
    UserForm: { path: "/user/:userId", init: "/user" },
    UserPermissions: { path: "/user/permissions"},
    UserRoles: { path: "/user/roles"},

    Targets: { path: "/targets" },
    Topics: { path: "/topics" },
    Posts: { path: "/posts" },
    Categories: { path: "/categories" },
    Packages: { path: "/packages" },
    Hashtags: { path: "/hashtag" },
    Promotions: { path: "/promotions" },
    News: { path: "/news" },
    Chats: { path: "/chats" },
    UserVip: {path: "/user-vip" },
    Foods: { path: "/foods" },
    Banners: { path: "/banners" },
    BabyCares: { path: "/baby-cares" },
    NurseryEducations: { path: "/nursery-educations" },
    WeaningFoods: { path: "/weaning-foods" },
    Surveys: { path: "/surveys" },
    Podcast: { path: "/podcast" },

    ActivationCodeBatch: { path: "/activation-code-batch" },
    ActivationCodeBatchCreate: { path: "/activation-code-batch/create" },
    ActivationCodeBatchDetail: { path: "/activation-code-batch/:id" },

    // website exsoft
    WebsiteBlog: {path: '/website-blog'},
    WebsiteRecruit: {path: '/website-recruit'},

    // notification
    Notification: { path: '/notifications'},
    NotificationPosts: {path: '/notifications/posts'},
    NotificationCreate: { path: '/notifications/create'},
    NotificationDetail: { path: '/notifications/:id'},

    NotificationObject: {path: '/notifications/objects'},
    NotificationObjectCreate: {path: '/notifications/objects/create'},
    NotificationObjectDetail: {path: '/notifications/objects/:id'},

    Transactions: { path: "/transactions" },
    Settings: { path: "/settings" },
    Upgrade: { path: "/upgrade" },
    BootstrapTables: { path: "/tables/bootstrap-tables" },
    Billing: { path: "/billing" },
    Invoice: { path: "/invoice" },
    Signup: { path: "/sign-up" },
    ForgotPassword: { path: "/forgot-password" },
    ResetPassword: { path: "/reset-password" },
    Lock: { path: "/lock" },
    NotFound: { path: "/404" },
    ServerError: { path: "/500" },

    Customer: { path: "/customers"},
    CustomerDetail: {path: "/customers/:id"},

    // docs
    DocsOverview: { path: "/documentation/overview" },
    DocsDownload: { path: "/documentation/download" },
    DocsQuickStart: { path: "/documentation/quick-start" },
    DocsLicense: { path: "/documentation/license" },
    DocsFolderStructure: { path: "/documentation/folder-structure" },
    DocsBuild: { path: "/documentation/build-tools" },
    DocsChangelog: { path: "/documentation/changelog" },

    // components
    Accordions: { path: "/components/accordions" },
    Alerts: { path: "/components/alerts" },
    Badges: { path: "/components/badges" },
    Widgets: { path: "/widgets" },
    Breadcrumbs: { path: "/components/breadcrumbs" },
    Buttons: { path: "/components/buttons" },
    Forms: { path: "/components/forms" },
    Modals: { path: "/components/modals" },
    Navs: { path: "/components/navs" },
    Navbars: { path: "/components/navbars" },
    Pagination: { path: "/components/pagination" },
    Popovers: { path: "/components/popovers" },
    Progress: { path: "/components/progress" },
    Tables: { path: "/components/tables" },
    Tabs: { path: "/components/tabs" },
    Tooltips: { path: "/components/tooltips" },
    Toasts: { path: "/components/toasts" },
    WidgetsComponent: { path: "/components/widgets" }
};
