import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import UserService from "../../services/user.services";

import { SEARCH_PARAMS } from "../../contains/common";

const userService = new UserService();
const namespace = "users";

const STATE = {
  loading: false,
  item: {
    userId: "",
    username: "",
    phoneNumber: "",
    email: "",
    fullname: "",
    password: "",
  },
  searchParams: { ...SEARCH_PARAMS, properties: "userId" },
  table: {
    items: [],
    isCheckedAll: false,
    numberOfElements: 0,
    totalElements: 0,
    totalPages: 0,
    number: 0,
  },
  error: {},
};

// actions
export const search = createAsyncThunk(
  `${namespace}/search`,
  async (searchParams = {}) => {
    const { body } = await userService.search({
      ...STATE.searchParams,
      ...searchParams,
    });
    return body;
  }
);

export const fetchAllUsers = createAsyncThunk(
  `${namespace}/fetchAllUsers`,
  async (searchParams) => {
    return await userService.getListUsers(searchParams);
  }
);

export const fetchUserById = createAsyncThunk(
  `${namespace}/fetchUserById`,
  async (userId) => {
    return await userService.getUserById(userId);
  }
);

const userSlice = createSlice({
  name: namespace,
  initialState: STATE,
  reducers: {
    setKeyword(state, { payload }) {
      state.searchParams = { ...state.searchParams, keyword: payload };
    },
    checkAll(state, { payload }) {
      state.table.isCheckedAll = payload;
      state.table.items.map((x) => (x.isChecked = payload));
    },
    checkItem(state, { payload }) {
      state.table.items = state.table.items.map((x) => {
        if (x.userId === payload.userId) {
          x.isChecked = !x.isChecked;
        }
        return x;
      });
      let haveItemFalse = state.table.items.find((x) => x.isChecked === false);
      if (haveItemFalse) {
        state.table.isCheckedAll = false;
      } else {
        state.table.isCheckedAll = true;
      }
    },
    onChoicePageSize(state, { payload }) {
      state.searchParams.size = payload;
      state.searchParams.page = 0;
      state.table.isCheckedAll = false;
    },
    onChangePage(state, { payload }) {
      if (payload < 0 || payload >= state.table.totalPages) {
        return;
      }
      state.searchParams.page = payload;
      state.table.isCheckedAll = false;
    },
  },
  extraReducers: {
    [search.pending](state) {
      state.loading = true;
    },
    [search.fulfilled](state, { payload }) {
      state.loading = false;
      state.table.items = payload.content.map((x) => {
        x.isChecked = false;
        return x;
      });
      state.table.numberOfElements = payload.numberOfElements;
      state.table.totalElements = payload.totalElements;
      state.table.totalPages = payload.totalPages;
      state.table.number = payload.number;
    },
    [search.rejected](state, { error }) {
      state.loading = false;
      state.error = error;
    },

    [fetchAllUsers.pending](state) {
      state.loading = true;
    },
    [fetchAllUsers.fulfilled](state, { payload }) {
      state.loading = false;
      state.table.items = payload.content.map((x) => {
        x.isChecked = false;
        return x;
      });
      state.table.numberOfElements = payload.numberOfElements;
      state.table.totalElements = payload.totalElements;
      state.table.totalPages = payload.totalPages;
      state.table.number = payload.number;
    },
    [fetchAllUsers.rejected](state, { error }) {
      state.loading = false;
      state.error = error;
    },

    [fetchUserById.pending](state) {
      state.loading = true;
    },
    [fetchUserById.fulfilled](state, { payload }) {
      state.loading = false;
      state.item = payload.body;
    },
    [fetchUserById.rejected](state, { error }) {
      state.loading = false;
      state.error = error;
    },
  },
});

export const {
  checkAll,
  checkItem,
  onChoicePageSize,
  onChangePage,
  setKeyword,
} = userSlice.actions;
export default userSlice.reducer;
