import {
  Button,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Modal,
  notification,
  Select,
} from "antd";
import moment, { Moment } from "moment";
import React, { useEffect } from "react";
import Editor from "src/components/Editor";
import websiteExsoftServices from "src/services/exsoft.services";
import { isNullOrEmpty } from "src/utils/Lang";
import { TEAM, WORK_SCHEDULE_TYPE } from ".";
import "../website-blog/index.scss";

type Props = {
  data: JobItem | undefined;
  onHide: () => void;
  callback: () => void;
};

const ModalCreateJob = (props: Props) => {
  const { data, onHide, callback } = props;
  const formRef = React.useRef<FormInstance<CreateJobParams> | null>(null);

  const [form] = Form.useForm();

  const isCreatePost = isNullOrEmpty(data?.id);

  useEffect(() => {
    formRef?.current?.setFieldsValue({
      name: data?.name,
      teamId: data?.teamId,
      workScheduleType: data?.workScheduleType,
      description: data?.description ?? "",
      deadline: data?.deadline ? moment(data?.deadline) : "",
      salary: data?.salary,
    });
  }, [data]);

  const handleSubmit = async (values: CreateJobParams) => {
    let response;
    let message;
    if (isCreatePost) {
      response = await websiteExsoftServices.createJob(values);
      message = "Tạo";
    } else {
      response = await websiteExsoftServices.updateJob(data?.id ?? 0, values);
      message = "Cập nhật";
    }

    if (response.status === 200) {
      notification.success({
        message: `${message} Job thành công`,
      });
      onHide();
      callback();
    } else {
      notification.error({
        message: `${message} Job thất bại`,
      });
    }
  };

  const { Option } = Select;

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 18 },
  };

  return (
    <Modal
      open={true}
      title={data?.id ? "Cập nhật" : "Thêm mới"}
      width={"75%"}
      style={{ top: 20 }}
      onCancel={onHide}
      footer={[]}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        ref={formRef}
        autoComplete="off"
        {...formItemLayout}
      >
        <Form.Item
          name="name"
          label="Tên công việc"
          rules={[{ required: true }]}
        >
          <Input placeholder="Nhập tên công việc" />
        </Form.Item>

        <Form.Item name="teamId" label="Tên Team" rules={[{ required: true }]}>
          <Select placeholder="Chọn Team" optionFilterProp="children">
            {TEAM.map((item: any) => (
              <Option value={item.id} key={"team" + item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="workScheduleType"
          label="Hình thức làm việc"
          rules={[{ required: true }]}
        >
          <Select
            placeholder="Chọn hình thức làm việc"
            optionFilterProp="children"
          >
            {WORK_SCHEDULE_TYPE.map((item: any) => (
              <Option value={item.id} key={"work" + item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="deadline"
          label="Hạn tuyển dụng"
          rules={[{ required: true }]}
        >
          <DatePicker
            picker="date"
            style={{ width: 200 }}
            placeholder={"Chọn ngày"}
            format={"DD/MM/YYYY"}
          />
        </Form.Item>

        <Form.Item
          name="salary"
          label="Mức lương ứng tuyển"
          initialValue={data?.name ?? ""}
          rules={[{ required: true }]}
        >
          <Input placeholder="Nhập mức lương" />
        </Form.Item>

        <Form.Item
          name="description"
          label="Mô tả"
          initialValue=""
          rules={[{ required: true }]}
        >
          <Editor key={"editor1"} className={"ql-editor"} />
        </Form.Item>

        <Form.Item>
          <div className="form-group">
            <Button type="primary" htmlType="submit">
              {data?.id ? "Cập nhật" : "Thêm mới"}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalCreateJob;
