export const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;

export const API_URL = process.env.REACT_APP_API_URL;
export const CHAT_API_URL = process.env.REACT_APP_CHAT_API_URL;
export const CHAT_IMG_URL = process.env.REACT_APP_CHAT_IMG_URL;
export const CHAT_SOCKET_URL = process.env.REACT_APP_CHAT_SOCKET_URL;
export const ROOM_SOCKET_URL = process.env.REACT_APP_ROOM_SOCKET_URL;

export const APP_SOCIAL = {
  FACEBOOK: "291501109066329",
  GOOGLE:
    "358685833274-en0pu0iltpl7ubuheoa6o5etvttig0a5.apps.googleusercontent.com",
};

export const BASIC_TOKEN = `QmFiaXVuaTpCYWJpdW5p`;

export const LIST_API_NOT_REQUIRE_AUTH = ["account-managers/login"];

export const FORMAT_DATE_NORMAL = "DD/MM/YYYY";

export const PAGE_SIZE_LIST = [10, 20, 30];

export const LOGIN_TYPE = {
  USERNAME: 1,
  EMAIL_OR_PHONE: 2,
};

export const SEARCH_PARAMS = {
  keyword: "",
  page: 0,
  size: 10,
  properties: "userId",
  sortBy: "asc",
};

export const POST_TYPE = [`FREE`, `VIP`];
export const POST_FORMAT = [
  {
    id: 1,
    name: "Bài Viết",
  },
  {
    id: 2,
    name: "Video/Audio",
  },
  {
    id: 3,
    name: "Info Graphic",
  },
];

export const DEVICE_FORMAT = [
  {
    id: 0,
    name: "App",
  },
  {
    id: 1,
    name: "Web Babiuni",
  },
  {
    id: 2,
    name: "All",
  },
];

export const FOOD_ENUM = [
  {
    id: "recommend",
    name: "Nên ăn",
  },
  {
    id: "warning",
    name: "Chú ý",
  },
  {
    id: "disadvise",
    name: "Không nên",
  },
];

export const SURVEY_DISPLAY_TYPE = [
  {
    id: "popup",
    name: "Show popup/Chat",
  },
  {
    id: "insight",
    name: "Hiển thị ngay trên Insights/Home",
  },
];

export const SURVEY_QUESTION_TYPE = [
  {
    id: "choice",
    name: "Chọn",
  },
  {
    id: "date",
    name: "Ngày tháng",
  },
  {
    id: "text",
    name: "Trả lời",
  },
  {
    id: "confirm",
    name: "Xác nhận",
  },
];

export const CUSTOMER_CODE_FLOW = [
  {
    code: "mongcon",
    name: "Mong con",
  },
  {
    code: "mangthai",
    name: "Mang thai",
  },
  {
    code: "sausinh",
    name: "Sau sinh",
  },
];

export const getCustomerCodeFlow = (code) => {
  return CUSTOMER_CODE_FLOW.find((item) => item.code === code);
};

export const PORTRAIT_ORIENTATION_LANDSCAPE = "landscape";
export const PORTRAIT_ORIENTATION_PORTRAIT = "portrait";
export const PORTRAIT_ORIENTATION = [
  {
    code: PORTRAIT_ORIENTATION_LANDSCAPE,
    name: "Chiều ngang",
  },
  {
    code: PORTRAIT_ORIENTATION_PORTRAIT,
    name: "Chiều dọc",
  },
];

export const BANNER_DISPLAY = [
  {
    id: 1,
    code: "ALL",
    name: "ALL",
  },
  {
    id: 2,
    code: "APP",
    name: "APP",
  },
  {
    id: 3,
    code: "WEBSITE",
    name: "WEBSITE",
  },
];

export const BABY_CARES_PERIOD = [
  {
    id: 1,
    code: "1",
    name: "6 tuần",
  },
  {
    id: 2,
    code: "2",
    name: "1.5 - 3 tháng",
  },
  {
    id: 3,
    code: "3",
    name: "3 - 6 tháng",
  },
  {
    id: 4,
    code: "4",
    name: "6 - 12 tháng",
  },
];
export const BABY_CARES_POST_FORMAT = [
  {
    id: 1,
    code: "1",
    name: "Bài viết",
  },
  {
    id: 2,
    code: "2",
    name: "Video",
  },
  {
    id: 3,
    code: "3",
    name: "Playlist",
  },
];

export const BABY_CARES_POST_TYPE = [
  {
    id: 1,
    code: "VIP",
    name: "VIP",
  },
  {
    id: 2,
    code: "FREE",
    name: "FREE",
  },
];

export const NURSERY_EDUCATIONS_POST_FORMAT = [
  {
    id: 1,
    code: "1",
    name: "Bài viết",
  },
  {
    id: 2,
    code: "2",
    name: "Video",
  },
  // {
  //     id: 3,
  //     code: '3',
  //     name: 'Tất cả định dạng'
  // },
];

export const NURSERY_EDUCATIONS_POST_TYPE = [
  // {
  //     id: 1,
  //     code: 'Tất cả',
  //     name: 'Tất cả'
  // },
  {
    id: 2,
    code: "VIP",
    name: "VIP",
  },
  {
    id: 3,
    code: "FREE",
    name: "FREE",
  },
];

export const NURSERY_EDUCATIONS_TEACHING_AID_GROUP = [
  {
    id: 1,
    code: "1",
    name: "Đồ chơi",
  },
  {
    id: 2,
    code: "2",
    name: "Giáo cụ khác",
  },
];

export const NURSERY_EDUCATIONS_MONTHS = [
  {
    id: -1,
    code: "ALL",
    name: "Tất cả",
  },
  {
    id: 1,
    code: "1",
    name: "Tháng 1",
  },
  {
    id: 2,
    code: "2",
    name: "Tháng 2",
  },
  {
    id: 3,
    code: "3",
    name: "Tháng 3",
  },
  {
    id: 4,
    code: "4",
    name: "Tháng 4",
  },
  {
    id: 5,
    code: "5",
    name: "Tháng 5",
  },
  {
    id: 6,
    code: "6",
    name: "Tháng 6",
  },
  {
    id: 7,
    code: "7",
    name: "Tháng 7",
  },
  {
    id: 8,
    code: "8",
    name: "Tháng 8",
  },
  {
    id: 9,
    code: "9",
    name: "Tháng 9",
  },
  {
    id: 10,
    code: "10",
    name: "Tháng 10",
  },
  {
    id: 11,
    code: "11",
    name: "Tháng 11",
  },
  {
    id: 12,
    code: "12",
    name: "Tháng 12",
  },
];

export const NURSERY_EDUCATIONS_DEVELOPMENT_TYPE = [
  // {
  //     id: 1,
  //     code: 'ALL',
  //     name: 'Tất cả'
  // },
  {
    id: 2,
    code: "2",
    name: "Vận động thô",
  },
  {
    id: 3,
    code: "3",
    name: "Vận động tinh",
  },
  {
    id: 4,
    code: "4",
    name: "Nhận thức",
  },
  {
    id: 5,
    code: "5",
    name: "Giác quan",
  },
  {
    id: 6,
    code: "6",
    name: "Cảm xúc",
  },
  {
    id: 7,
    code: "7",
    name: "Ngôn ngữ",
  },
  {
    id: 8,
    code: "8",
    name: "Các phát triển khác",
  },
];

export const PODCAST_TYPE = [
  {
    id: 1,
    code: "VIP",
    name: "VIP",
  },
  {
    id: 2,
    code: "FREE",
    name: "FREE",
  },
];

export const defaultCustomerAvatar = require("../assets/img/default-avatar.webp");
