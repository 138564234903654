import React from "react";
import apiServices from "../../services/podcast.service";
import professionalServices from "../../services/professionals.service";
import {
  Breadcrumb,
  Button,
  InputGroup,
  FormControl,
  Spinner,
  Table,
  Tabs,
  Pagination,
} from "@themesberg/react-bootstrap";
import { Switch, Avatar } from "antd";

import { Routes } from "../../routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import { Helper } from "../../common/helper";
import ModalPost from "./ModalPost";
import ModalTopic from "./ModalTopic";
import moment from "moment";
import { BABY_CARES_PERIOD, PODCAST_TYPE } from "../../contains/common";
import Select from "react-select";
import { toast } from "react-toastify";

const { Tab } = Tabs;
const pageSize = 20;
export default class Podcast extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      categoriesPage: [],
      tabActive: "posts",
      page: 1,
      pageSize: pageSize,

      modalShow: false,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true }, () => {
      this.getData();
    });
  }

  getData = async () => {
    const { tabActive } = this.state;
    if (tabActive === "posts") {
      this.getAllTopics();
      this.getAuthors();
      this.getListPodcast();
    } else {
      this.getListTopics();
    }
  };

  getDetailData = async (id) => {
    const { tabActive } = this.state;
    this.setState({ isLoadingDetail: true }, () => {
      if (tabActive === "posts") {
        this.getDetailPodcast(id);
      } else {
        this.getDetailTopic(id);
      }
    });
  };

  getListPodcast = async () => {
    const { page, pageSize } = this.state;
    let queryString = this.buildQueryString();
    if (queryString) {
      queryString =
        queryString +
        `&page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdDate:desc`;
    } else {
      queryString = `?page=${
        page ? page - 1 : 0
      }&size=${pageSize}&sort=createdDate:desc`;
    }

    const resp = await apiServices.listPodcast(queryString);
    const headers = resp.headers || {};
    this.setState({
      data: resp.data,
      metadata: {
        pageCount: parseFloat(headers["x-page-count"]),
        page: parseFloat(headers["x-page-number"]),
        size: parseFloat(headers["x-page-size"]),
        total: parseFloat(headers["x-total-count"]),
      },
      isLoading: false,
    });
  };

  getListTopics = async () => {
    const { page, pageSize } = this.state;
    let queryString = this.buildQueryString();
    if (queryString) {
      queryString =
        queryString +
        `&page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdDate:desc`;
    } else {
      queryString = `?page=${
        page ? page - 1 : 0
      }&size=${pageSize}&sort=createdDate:desc`;
    }
    const resp = await apiServices.listTopics(queryString);
    const headers = resp.headers || {};
    this.setState({
      data: resp.data,
      metadata: {
        pageCount: parseFloat(headers["x-page-count"]),
        page: parseFloat(headers["x-page-number"]),
        size: parseFloat(headers["x-page-size"]),
        total: parseFloat(headers["x-total-count"]),
      },
      isLoading: false,
    });
  };

  getAllTopics = async () => {
    let queryString = `?page=${0}&size=${100}&sort=createdDate:desc`;
    const resp = await apiServices.listTopics(queryString);
    this.setState({
      listTopics: resp.data,
      isLoading: false,
    });
  };

  getAuthors = async () => {
    let queryString = `?page=${0}&size=${100}&sort=createdDate:desc`;
    const resp = await apiServices.getAuthor(queryString);
    this.setState({
      listAuthors: resp.data?.body,
      isLoading: false,
    });
  };

  toggleHidden = async (item) => {
    const { tabActive } = this.state;

    try {
      if (tabActive === "posts") {
        const body = {
          active: item?.active === 1 ? 0 : 1,
          name: item?.name,
          description: item.description,
          duration: item.duration,
          podcastType: item.podcastType,
          image: item?.image,
          topicId: item?.topic.id,
          authorId: item?.author.id,
          audioId: item?.audioId,
        };
        await apiServices.updatePodcast(item?.id, body);
      } else {
        const body = {
          active: item?.active === 1 ? 0 : 1,
          topicName: item?.topicName,
          description: item?.description,
          topicAvatar: item?.topicAvatar,
        };
        await apiServices.updateTopic(item?.id, body);
      }

      this.getData();
    } catch (error) {
      let msg = "";
      switch (error?.message) {
        case "baby_care_post_duplicate":
          msg = "Bài viết đã tồn tại";
          break;
        case "ordinal_number_duplicate":
          msg = "Số thứ tự đã tồn tại";
          break;
        case "baby_care_post_not_found":
          msg = "Bài viết không tồn tại";
          break;
        case "popup_not_found":
          msg = "Popup không tồn tại";
          break;
        case "popup_duplicate":
          msg = "Popup đã tồn tại";
          break;
        default:
          break;
      }

      toast.error(
        msg || "Có lỗi xảy ra vui lòng liên hệ kỹ thuật để được hỗ trợ."
      );
    }
  };

  getDetailPodcast = async (id) => {
    const resp = await apiServices.getPodcast(id);
    this.setState({
      dataDetail: resp.data?.body,
      isLoadingDetail: false,
      modalShow: true,
    });
  };

  getDetailTopic = async (id) => {
    const resp = await apiServices.getTopic(id);
    this.setState({
      dataDetail: resp.data?.body,
      isLoadingDetail: false,
      modalShow: true,
    });
  };

  onPageChanged = (page) => {
    this.setState({ page: page }, () => {
      this.getData();
    });
  };

  /**
   * xử lý với dữ liệu search trong state
   */
  onSubmitSearch = () => {
    this.setState({ page: 1 }, () => {
      this.getData();
    });
  };

  /**
   * xây dựng query string theo các giá trị trong state
   * @returns {string}
   */
  buildQueryString = () => {
    let { listChecked, author, podcastType, topic, querySearch } = this.state;
    let queryString = "";
    for (let p in listChecked) {
      let value = "";
      if (Array.isArray(listChecked[p]) && listChecked[p].length > 0) {
        listChecked[p].map((x) => {
          value = value + x + ",";
        });

        value = value.slice(0, -1);
      }
      if (value)
        queryString = Helper.updateQueryStringParameter(queryString, p, value);
    }

    if (querySearch) {
      queryString = Helper.updateQueryStringParameter(
        queryString,
        "name",
        querySearch.trim()
      );
    }

    if (topic) {
      queryString = Helper.updateQueryStringParameter(
        queryString,
        "topicId",
        topic?.value
      );
    }

    if (author) {
      queryString = Helper.updateQueryStringParameter(
        queryString,
        "authorId",
        author?.value
      );
    }

    if (podcastType) {
      queryString = Helper.updateQueryStringParameter(
        queryString,
        "podcastType",
        podcastType?.value
      );
    }

    return queryString;
  };

  toggleModal = () => {
    // console.log('vào toggleModal')
    if (this.state.modalShow) {
      this.setState({
        modalShow: false,
        dataDetail: {},
      });
    } else {
      this.setState({
        modalShow: true,
      });
    }
  };

  renderRow = (item, index) => {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>
          <span className="fw-normal">{item?.name || "---"}</span>
        </td>
        <td>
          <span className="fw-normal">{item?.topic?.topicName || "---"}</span>
        </td>
        <td>
          <span className="fw-normal">
            {item?.author
              ? `${item?.author?.fullName} (${item?.author?.nickName})`
              : "---"}
          </span>
        </td>
        <td>
          <span className="fw-normal">{item?.podcastType || "---"}</span>
        </td>
        <td>
          <span className="fw-normal">{item?.duration}</span>
        </td>
        <td>
          <Switch
            onClick={() => this.toggleHidden(item)}
            size="small"
            checked={item?.active !== 1}
          />
        </td>
        <td>
          <div ref={(ref) => (this.actionContainer = ref)} className="dp-flex">
            <div
              onClick={() => this.getDetailData(item.id)}
              className={"table-action-btn"}
            >
              <FontAwesomeIcon icon={Icon.faEdit} className="me-2" />
            </div>
          </div>
        </td>
      </tr>
    );
  };

  renderRowTopic = (item, index) => {
    const period =
      BABY_CARES_PERIOD.find((x) => x.id === item?.periodDevelopment) || {};
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>
          <span className="fw-normal">{item?.topicName || "---"}</span>
        </td>
        <td>
          <span className="fw-normal">{item?.description || "---"}</span>
        </td>
        <td>
          <span className="fw-normal">
            {moment(item?.addedTimestamp).format("DD/MM/YYYY") || "---"}
          </span>
        </td>
        <td>
          <span className="fw-normal">{item?.createdBy || "---"}</span>
        </td>
        <td>
          <Switch
            onClick={() => this.toggleHidden(item)}
            size="small"
            checked={item?.active !== 1}
          />
        </td>
        <td>
          <div ref={(ref) => (this.actionContainer = ref)} className="dp-flex">
            <div
              onClick={() => this.getDetailData(item.id)}
              className={"table-action-btn"}
            >
              <FontAwesomeIcon icon={Icon.faEdit} className="me-2" />
            </div>
          </div>
        </td>
      </tr>
    );
  };

  renderContentPost = () => {
    const { data = [], page, metadata = {} } = this.state;
    if (this.state.isLoading) {
      return (
        <div className="d-flex justify-content-center pt-8">
          <Spinner animation="border" />
        </div>
      );
    }

    return (
      <div>
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th key={1} className="border-bottom">
                STT
              </th>
              <th key={2} className="border-bottom">
                Tên Podcast
              </th>
              <th key={3} className="border-bottom">
                Chủ đề
              </th>
              <th key={4} className="border-bottom">
                Tác giả
              </th>
              <th key={5} className="border-bottom">
                Loại Podcast
              </th>
              <th key={6} className="border-bottom">
                Thời lượng
              </th>
              <th key={8} className="border-bottom">
                Hidden
              </th>
              <th key={11} className="border-bottom" />
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => this.renderRow(item, index))}
          </tbody>
        </Table>
        <Pagination className="mb-2 mb-lg-0">
          <Pagination.Prev
            disabled={metadata?.page === 0}
            onClick={(e) => this.onPageChanged(page - 1)}
          >
            Previous
          </Pagination.Prev>
          {[...Array(metadata?.pageCount).keys()].map((p) => (
            <Pagination.Item
              key={`page-${p}`}
              onClick={(e) => this.onPageChanged(p + 1)}
              active={metadata.page === p}
            >
              {" "}
              {p + 1}{" "}
            </Pagination.Item>
          ))}
          <Pagination.Next
            disabled={metadata?.page + 1 === metadata?.pageCount}
            onClick={(e) => this.onPageChanged(page + 1)}
          >
            Next
          </Pagination.Next>
        </Pagination>
      </div>
    );
  };

  renderContentTopic = () => {
    const { data = [], page, metadata = {} } = this.state;
    if (this.state.isLoading) {
      return (
        <div className="d-flex justify-content-center pt-8">
          <Spinner animation="border" />
        </div>
      );
    }

    return (
      <div>
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th key={1} className="border-bottom">
                STT
              </th>
              <th key={3} className="border-bottom">
                Tên chủ đề
              </th>
              <th key={4} className="border-bottom">
                Mô tả
              </th>
              <th key={6} className="border-bottom">
                Ngày tạo
              </th>
              <th key={7} className="border-bottom">
                Người tạo
              </th>
              <th key={8} className="border-bottom">
                Hidden
              </th>
              <th key={9} className="border-bottom" />
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => this.renderRowTopic(item, index))}
          </tbody>
        </Table>
        <Pagination className="mb-2 mb-lg-0">
          <Pagination.Prev
            disabled={metadata?.page === 0}
            onClick={(e) => this.onPageChanged(page - 1)}
          >
            Previous
          </Pagination.Prev>
          {[...Array(metadata?.pageCount).keys()].map((p) => (
            <Pagination.Item
              key={`page-${p}`}
              onClick={(e) => this.onPageChanged(p + 1)}
              active={metadata.page === p}
            >
              {" "}
              {p + 1}{" "}
            </Pagination.Item>
          ))}
          <Pagination.Next
            disabled={metadata?.page + 1 === metadata?.pageCount}
            onClick={(e) => this.onPageChanged(page + 1)}
          >
            Next
          </Pagination.Next>
        </Pagination>
      </div>
    );
  };

  render() {
    const {
      tabActive,
      data,
      querySearch,
      listTopics = [],
      topic,
      listAuthors = [],
      author,
      podcastType,
      modalShow,
      dataDetail = {},
    } = this.state;
    if (!Array.isArray(data)) {
      return <div>Có lỗi xảy ra vui lòng liên kệ kỹ thuật</div>;
    }

    return (
      <div>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-md-0">
            <Breadcrumb
              className="d-none d-md-inline-block"
              listProps={{
                className: "breadcrumb-dark breadcrumb-transparent",
              }}
            >
              <Breadcrumb.Item to={Routes.DashboardOverview.path}>
                <FontAwesomeIcon icon={Icon.faHome} />
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                {tabActive === "posts" ? "Podcast" : "Chủ đề"}
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>

        <Tabs
          activeKey={tabActive}
          onSelect={(k) =>
            this.setState(
              {
                tabActive: k,
                listItemChecked: [],
                data: [],
                isLoading: true,
                querySearch: "",
                periodDevelopment: "",
                podcastType: "",
                postFormat: "",
              },
              () => this.getData()
            )
          }
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="posts" title="Podcast">
            <div className="dp-flex justify-content-space-between align-items-center">
              <div
                onClick={(e) => {
                  this.setState({ dataDetail: {} }, () => {
                    this.toggleModal();
                  });
                }}
                className="custom-btn"
              >
                <FontAwesomeIcon icon={Icon.faPlus} className="me-2" /> Thêm
              </div>

              <div>
                <div className="mb-3 d-flex align-items-center">
                  <div className="mgr-10 filter-option-item">
                    <FormControl
                      value={querySearch}
                      onChange={(e) =>
                        this.setState({ querySearch: e.target.value })
                      }
                      onKeyDown={(e) => {
                        if (e?.keyCode === 13) {
                          this.onSubmitSearch();
                        }
                      }}
                      placeholder={"Nhập tên Podcast"}
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                    />
                  </div>
                  <div className="mgr-10 filter-option-item">
                    <Select
                      className="custom-react-select list"
                      placeholder="Chọn topic"
                      options={listTopics.map((x) => {
                        return { value: x.id, label: x.topicName };
                      })}
                      value={topic}
                      isMulti={false}
                      onChange={(e) => {
                        this.setState({ topic: e });
                      }}
                      isClearable={true}
                      backspaceRemovesValue={true}
                    />
                  </div>
                  <div className="mgr-10 filter-option-item">
                    <Select
                      className="custom-react-select list"
                      placeholder="Chọn tác giả"
                      options={listAuthors.map((x) => {
                        return {
                          value: x.id,
                          label: `${x.fullName} (${x.nickName})`,
                        };
                      })}
                      value={author}
                      isMulti={false}
                      onChange={(e) => {
                        this.setState({ author: e });
                      }}
                      isClearable={true}
                      backspaceRemovesValue={true}
                    />
                  </div>
                  <div className="mgr-10 filter-option-item">
                    <Select
                      className="custom-react-select list"
                      placeholder="Chọn loại podcast"
                      options={PODCAST_TYPE.map((x) => {
                        return { value: x.code, label: x.name };
                      })}
                      value={podcastType}
                      isMulti={false}
                      onChange={(e) => {
                        this.setState({ podcastType: e });
                      }}
                      isClearable={true}
                      backspaceRemovesValue={true}
                    />
                  </div>
                  <Button
                    onClick={() => {
                      this.onSubmitSearch();
                    }}
                    variant="outline-secondary"
                    id="button-addon2"
                  >
                    <FontAwesomeIcon icon={Icon.faSearch} className="" />
                  </Button>
                </div>
              </div>
            </div>

            {this.renderContentPost()}
          </Tab>

          <Tab eventKey="topics" title="Chủ đề">
            <div className="dp-flex justify-content-space-between align-items-center">
              <div
                onClick={(e) => {
                  this.setState({ dataDetail: {} }, () => {
                    this.toggleModal();
                  });
                }}
                className="custom-btn"
              >
                <FontAwesomeIcon icon={Icon.faPlus} className="me-2" /> Thêm
              </div>
            </div>

            {this.renderContentTopic()}
          </Tab>
        </Tabs>

        {modalShow ? (
          tabActive === "posts" ? (
            <ModalPost
              dataDetail={dataDetail}
              toggle={this.toggleModal}
              getData={this.getData}
              modalStatus={modalShow}
            />
          ) : (
            <ModalTopic
              dataDetail={dataDetail}
              toggle={this.toggleModal}
              getData={this.getData}
              modalStatus={modalShow}
            />
          )
        ) : null}
      </div>
    );
  }
}
