import React from "react";
import {
  Modal,
  Button,
  InputGroup,
  FormControl,
  Form,
  Row,
  Col,
} from "@themesberg/react-bootstrap";
import _ from "lodash";
import WeaningFoodsService from "../../services/weaningFoods.service";
import FileService from "../../services/file.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import Dropzone from "react-dropzone";
import mammoth from "mammoth";
import Select from "react-select";

import { toast } from "react-toastify";
import Editor from "../../components/Editor";
import ReactHlsPlayer from "react-hls-player";
import HashTagService from "../../services/hashtag.service";
import { PORTRAIT_ORIENTATION_LANDSCAPE } from "../../contains/common";
const pageSize = 20;
export default class ModalDishes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bodyData: {},
      page: 1,
      pageSize: pageSize,
    };

    this.timeOut = null;
    this.fileService = new FileService();
    this.hashTagService = new HashTagService();
  }

  componentDidMount() {
    this.getListFoods();
    this.getWeaningMethods();
    this.getWeaningStages();
    this.getWeaningProcessing();
    this.getHashTags();
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(prevProps.dataDetail) !==
      JSON.stringify(this.props.dataDetail)
    ) {
      const { dataDetail = {} } = this.props;

      if (dataDetail?.id || dataDetail?.id === 0) {
        let arrRes = [];
        if (dataDetail?.hashtag) {
          try {
            const resp = await this.hashTagService.convertHashTag(
              dataDetail?.hashtag.replace(/\,/g, "")
            );
            arrRes = resp?.body.map((x) => ({
              value: x.hashtagId,
              label: x.hashtagName,
            }));
          } catch (error) {
            console.log(error);
          }
        }

        let newObj = {
          ...dataDetail,
          food: dataDetail.foods.map((item) => {
            return { value: item.id, label: item.name };
          }),
          weaningMethods: dataDetail?.weaningMethod
            ? dataDetail?.weaningMethod.split(",").map((x) => x.trim())
            : [],
          weaningStages: dataDetail?.weaningStage
            ? dataDetail?.weaningStage.split(",").map((x) => x.trim())
            : [],
          weaningProcessions: dataDetail?.weaningProcessing
            ? dataDetail?.weaningProcessing.split(",").map((x) => x.trim())
            : [],
          hashtags: arrRes,
        };

        if (
          Array.isArray(dataDetail?.attachments) &&
          dataDetail?.attachments.length > 0
        ) {
          newObj.attachFile = dataDetail?.attachments[0];
        }

        this.setState({
          bodyData: newObj,
          ingredients: dataDetail?.ingredients,
          instructions: dataDetail?.instructions,
          originalData: _.cloneDeep(newObj),
        });
      } else {
        this.setState({
          bodyData: {},
          originalData: {},
        });
      }
    }
  }

  getListFoods = async (searchString) => {
    const { page, pageSize } = this.state;
    let queryString = `?page=${
      page ? page - 1 : 0
    }&size=${pageSize}&sort=createdDate:desc${
      searchString ? `&query=${encodeURIComponent(searchString)}` : ""
    }`;

    const resp = await WeaningFoodsService.listFoods(queryString);
    const headers = resp.headers || {};
    this.setState({
      foods: resp.data,
      metadata: {
        pageCount: parseFloat(headers["x-page-count"]),
        page: parseFloat(headers["x-page-number"]),
        size: parseFloat(headers["x-page-size"]),
        total: parseFloat(headers["x-total-count"]),
      },
      isLoading: false,
    });
  };

  onSubmit = async () => {
    const {
      bodyData = {},
      originalData = {},
      ingredients = "",
      instructions = "",
    } = this.state;
    const { getData } = this.props;

    if (!bodyData?.name) {
      toast.error("Vui lòng nhập tên món ăn");
      return;
    }
    console.log("bodyData", bodyData);

    if (!bodyData?.food?.length) {
      toast.error("Vui lòng chọn thực phẩm chính");
      return;
    }

    if (bodyData?.cookingTime) {
      if (parseFloat(bodyData?.cookingTime) == NaN) {
        toast.error("Vui lòng nhập thời gian nấu bằng số");
        return;
      }
    }

    let body = {
      name: bodyData?.name.trim(),
      weaningDishDescription: bodyData?.weaningDishDescription
        ? bodyData?.weaningDishDescription.trim()
        : "",
      image: bodyData?.image,
      weaningFoodIds:
        Array.isArray(bodyData?.food) && bodyData?.food?.length > 0
          ? bodyData?.food?.map((x) => x.value)
          : [],
      weaningMethod: bodyData?.weaningMethods || [],
      weaningStage: bodyData?.weaningStages || [],
      weaningProcessing: bodyData?.weaningProcessions || [],
      ingredients: ingredients,
      instructions: instructions,
      hashtag:
        Array.isArray(bodyData?.hashtags) && bodyData?.hashtags?.length > 0
          ? bodyData?.hashtags?.map((x) => x.label).toString()
          : "",
      cookingTime: parseFloat(bodyData?.cookingTime), // Long
      intendedFor: bodyData?.intendedFor,
      attachId: bodyData?.attachId,
    };

    if (!bodyData?.attachId && bodyData?.attachId !== 0) {
      delete body.attachId;
    }

    try {
      if (bodyData?.id || bodyData?.id === 0) {
        if (body.dishName === originalData.dishName) {
          delete body.dishName;
        }
        await WeaningFoodsService.updateDishes(bodyData?.id, body);
        toast.success("Sửa thành công");
      } else {
        await WeaningFoodsService.createDishes(body);
        toast.success("Tạo thành công");
      }
      this.onToggle();
      getData();
    } catch (error) {
      toast.error(
        error?.message ||
          "Có lỗi xảy ra vui lòng liên hệ kỹ thuật để được hỗ trợ."
      );
    }
  };

  getFoodGroups = async () => {
    const resp = await WeaningFoodsService.getFoodGroups();
    this.setState({
      foodGroup: resp.data,
    });
  };

  getWeaningMethods = async () => {
    const resp = await WeaningFoodsService.getWeaningMethods();
    this.setState({
      weaningMethods: resp.data,
    });
  };

  getWeaningStages = async () => {
    const resp = await WeaningFoodsService.getWeaningStages();
    this.setState({
      weaningStages: resp.data,
    });
  };

  getWeaningProcessing = async () => {
    const resp = await WeaningFoodsService.getWeaningProcessing();
    this.setState({
      weaningProcessing: resp.data,
    });
  };

  getHashTags = async () => {
    const resp = await this.hashTagService.getList("");
    this.setState({
      hashtags: resp?.body?.content || [],
    });
  };

  onInputSelectChange = (value) => {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
    }

    this.timeOut = setTimeout(() => {
      this.getListFoods(value);
    }, 500);
  };

  handleVideoUpload = async (files) => {
    const file = files[0];
    let fileSize = file.size / 1024 / 1024; // in MiB
    if (fileSize > 500) {
      return alert("Dung lượng file lớn hơn 500 MiB, vui lòng chọn file khác.");
    }
    let { bodyData = {} } = this.state;
    let body = {
      objectType: "dishes",
      displayType: 4,
      orientation: PORTRAIT_ORIENTATION_LANDSCAPE,
    };
    // objectType: "posts"
    // objectId: ID bài viết
    // displayType: 1(thumbnail), 2( icon), 3(Cover), 4(video), 5(Audio), 6(Infographic), 7(avatar)

    try {
      const resp = await this.fileService.uploadImage(file, body);
      if (resp) {
        setTimeout(() => {
          bodyData.attachId = resp?.attachId;
          bodyData.attachFile = resp;
          this.setState({ bodyData: bodyData });
        }, 10000);
      }
    } catch (error) {
      console.log(error);
    }

    this.setState({ bodyData: bodyData });
  };

  handleImageUpload = async (files) => {
    const file = files[0];
    let fileSize = file.size / 1024 / 1024; // in MiB
    if (fileSize > 500) {
      return alert("Dung lượng file lớn hơn 500 MiB, vui lòng chọn file khác.");
    }
    let { bodyData = {} } = this.state;
    let body = {
      objectType: "dishes",
      displayType: 1,
    };
    // objectType: "posts"
    // objectId: ID bài viết
    // displayType: 1(thumbnail), 2( icon), 3(Cover), 4(video), 5(Audio), 6(Infographic), 7(avatar)

    try {
      const resp = await this.fileService.uploadImage(file, body);
      if (resp?.linkUrl?.length > 0) {
        bodyData.image = resp.linkUrl[0];
      }
    } catch (error) {
      console.log(error);
    }

    this.setState({ bodyData: bodyData });
  };

  handleImageUploadInDocx = async (dataUrl, fileName) => {
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    const file = new File([blob], fileName, { type: "image/png" });
    try {
      const resp = await this.fileService.uploadImage(file);
      return resp.linkUrl[0];
    } catch (error) {
      return dataUrl;
    }
  };

  handleFileUpload = async (files) => {
    const file = files[0];
    let fileSize = file.size / 1024 / 1024; // in MiB
    if (fileSize > 20) {
      return alert(
        "Dung lượng file lớn hơn 20 MiB, vui lòng xem lại nội dung bài viết"
      );
    }
    let { bodyData = {} } = this.state;

    try {
      let fInstructions = "";
      let fIngredients = "";
      let fTime = "";
      let fNum = "";
      let fName = "";
      let reader = new FileReader();
      const options = {
        convertImage: mammoth.images.imgElement((image) => {
          return image.read("base64").then(async (imageBuffer) => {
            const sourceImage =
              "data:" + image.contentType + ";base64," + imageBuffer;
            return {
              src: await this.handleImageUploadInDocx(sourceImage, Date.now()),
            };
          });
        }),
      };
      reader.onload = async function () {
        const doc = await mammoth.convertToHtml(
          { arrayBuffer: this.result },
          options
        );
        const html = doc?.value;

        //console.log('html', html);

        if (html) {
          const div = document.createElement("div");
          div.innerHTML = html;

          fName = div.firstChild.textContent;

          const h1 = div.getElementsByTagName("h1");
          if (h1[0]) {
            fIngredients = h1[0].nextElementSibling.outerHTML;
          }

          const h2 = div.getElementsByTagName("h2");
          if (h2[0]) {
            fInstructions = h2[0].nextElementSibling.outerHTML;
          }

          const h4 = div.getElementsByTagName("h4");
          if (h4[0]) {
            fTime = h4[0].nextElementSibling.textContent;
          }

          const h5 = div.getElementsByTagName("h5");
          if (h5[0]) {
            fNum = h5[0].nextElementSibling.textContent;
          }
        }
      };
      reader.onloadend = async (e) => {
        await e.currentTarget.onload();
        this.setState({
          bodyData: {
            ...bodyData,
            name: fName,
            cookingTime: fTime,
            intendedFor: fNum,
          },
          ingredients: fIngredients,
          instructions: fInstructions,
        });
      };

      reader.readAsArrayBuffer(file);

      // console.log('fName', fName)
    } catch (error) {
      console.log(error);
    }
  };

  onPasteHashtag = async (e) => {
    // console.log('onPasteHashtag', e, e?.keyCode)
    let { bodyData = {} } = this.state;
    if (e?.keyCode === 13) {
      if (e?.target?.value) {
        try {
          const resp = await this.hashTagService.convertHashTag(e.target.value);
          let arrRes = resp?.body.map((x) => ({
            value: x.hashtagId,
            label: x.hashtagName,
          }));
          bodyData.hashtags = _.unionBy(
            arrRes,
            bodyData.hashtags || [],
            "value"
          );
          this.setState({ bodyData: bodyData });
          this.selectRef.blur();
          this.selectRef.focus();
        } catch (error) {
          console.log(error);
        }
      }
    } else if ((e?.metaKey || e?.ctrlKey) && e?.keyCode === 86) {
      if (navigator?.clipboard?.readText) {
        let text = await navigator?.clipboard?.readText();
        if (text) {
          try {
            const resp = await this.hashTagService.convertHashTag(text);
            let arrRes = resp?.body.map((x) => ({
              value: x.hashtagId,
              label: x.hashtagName,
            }));
            bodyData.hashtags = _.unionBy(
              arrRes,
              bodyData.hashtags || [],
              "value"
            );
            this.setState({ bodyData: bodyData });
            this.selectRef.blur();
            this.selectRef.focus();
          } catch (error) {
            console.log(error);
          }
        }
      }
    }
  };

  onItemChecked = (item, props) => {
    const { bodyData = {} } = this.state;
    let data = bodyData[props] || [];
    if (data.find((x) => x === item.code)) {
      data = data.filter((x) => x !== item.code);
    } else {
      data.push(item.code);
    }
    this.setState({ bodyData: { ...bodyData, [props]: data } });
  };

  onToggle = () => {
    const { toggle } = this.props;
    this.setState(
      {
        bodyData: {},
        ingredients: "",
        instructions: "",
      },
      () => {
        toggle();
      }
    );
  };

  render() {
    const { modalStatus } = this.props;
    const {
      foods = [],
      bodyData = {},
      hashtags = [],
      weaningMethods = [],
      weaningStages = [],
      weaningProcessing = [],
      ingredients = "",
      instructions = "",
    } = this.state;

    let attachLink = bodyData?.attachFile
      ? bodyData?.attachFile?.linkUrl[0]
      : "";

    // console.log('bodyData', bodyData)

    return (
      <Modal
        dialogClassName="modal-90w"
        as={Modal.Dialog}
        show={modalStatus}
        onHide={this.onToggle}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {bodyData?.dishId || bodyData?.dishId === 0
              ? "Chỉnh sửa món ăn"
              : "Thêm mới món ăn"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={8}>
              <div className="modal-row d-flex align-items-center">
                <div className="modal-label">Tên món ăn:</div>

                <FormControl
                  value={bodyData.name}
                  onChange={(e) =>
                    this.setState({
                      bodyData: { ...bodyData, name: e.target.value },
                    })
                  }
                  placeholder="Nhập tên món ăn"
                />
              </div>

              <div className="modal-row d-flex align-items-center">
                <div className="modal-label">Thực phẩm chính:</div>

                {foods && (
                  <Select
                    className="custom-react-select"
                    placeholder="Chọn thực phẩm chính"
                    onInputChange={this.onInputSelectChange}
                    options={foods.map((x) => {
                      return { value: x.id, label: x.name };
                    })}
                    value={bodyData.food}
                    isMulti={true}
                    onChange={(e) => {
                      this.setState({ bodyData: { ...bodyData, food: e } });
                    }}
                    isClearable={true}
                    backspaceRemovesValue={true}
                  />
                )}
              </div>
            </Col>

            <Col md={4}>
              <div className="modal-row d-flex align-items-center">
                <div className="modal-label-small">Dành cho:</div>
                <InputGroup>
                  <FormControl
                    value={bodyData.intendedFor}
                    onChange={(e) =>
                      this.setState({
                        bodyData: { ...bodyData, intendedFor: e.target.value },
                      })
                    }
                  />
                  <InputGroup.Text>người</InputGroup.Text>
                </InputGroup>
              </div>
              <div className="modal-row d-flex align-items-center">
                <div className="modal-label-small">Thời gian nấu:</div>
                <InputGroup>
                  <FormControl
                    value={bodyData.cookingTime}
                    onChange={(e) =>
                      this.setState({
                        bodyData: { ...bodyData, cookingTime: e.target.value },
                      })
                    }
                  />
                  <InputGroup.Text>phút</InputGroup.Text>
                </InputGroup>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <div className="modal-row">
                <div className="d-flex align-items-center">
                  <div className="modal-label">Tải lên ảnh thực phẩm:</div>
                  <Dropzone
                    accept={"image/*"}
                    multiple={false}
                    onDrop={(acceptedFiles) =>
                      this.handleImageUpload(acceptedFiles)
                    }
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section key={"dropzone"}>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <FontAwesomeIcon
                            icon={Icon.faCloudUploadAlt}
                            className="me-2"
                          />
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
                <div className="d-flex align-items-center">
                  <div className="modal-label" />
                  {bodyData.image && (
                    <img
                      src={bodyData.image}
                      style={{ height: 300, width: "auto" }}
                    />
                  )}
                </div>
              </div>

              <div className="modal-row d-flex align-items-center">
                <div className="modal-label">Tải lên file nội dung:</div>
                <Dropzone
                  accept={".docx, .doc"}
                  multiple={false}
                  onDrop={(acceptedFiles) =>
                    this.handleFileUpload(acceptedFiles)
                  }
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <FontAwesomeIcon
                          icon={Icon.faCloudUploadAlt}
                          className="me-2"
                        />
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            </Col>
            <Col md={6}>
              <div className="modal-row">
                <div className="d-flex align-items-center">
                  <div className="modal-label">Tải lên video hướng dẫn:</div>
                  <Dropzone
                    accept={"video/*"}
                    multiple={false}
                    onDrop={(acceptedFiles) =>
                      this.handleVideoUpload(acceptedFiles)
                    }
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section key={"dropzone"}>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <FontAwesomeIcon
                            icon={Icon.faCloudUploadAlt}
                            className="me-2"
                          />
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
                <div className="d-flex align-items-center">
                  <div className="modal-label" />
                  {attachLink && (
                    <ReactHlsPlayer
                      src={attachLink}
                      autoPlay={false}
                      controls={true}
                      width="500"
                      height="auto"
                    />
                  )}
                </div>
              </div>

              <div className="modal-row">
                <div className="d-flex align-items-center">
                  <div className="modal-label">Tải lên video hướng dẫn:</div>

                  {hashtags && (
                    <Select
                      ref={(ref) => (this.selectRef = ref)}
                      className="custom-react-select"
                      placeholder="Thêm hashtag cho bài viết"
                      options={hashtags.map((x) => {
                        return { value: x.hashtagId, label: x.hashtagName };
                      })}
                      value={bodyData?.hashtags}
                      isMulti={true}
                      onChange={(e) => {
                        this.setState({
                          bodyData: { ...bodyData, hashtags: e },
                        });
                      }}
                      onKeyDown={(e) => this.onPasteHashtag(e)}
                      isClearable={true}
                      backspaceRemovesValue={true}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>

          <div className="modal-row d-flex align-items-center">
            <div className="modal-label">Phương thức ăn dặm:</div>
            <div className="d-flex align-items-center">
              {weaningMethods.map((item, index) => {
                return (
                  <Form.Check
                    key={index}
                    className="inbox-check pdr-30"
                    checked={
                      Array.isArray(bodyData?.weaningMethods) &&
                      bodyData?.weaningMethods.find((x) => x === item.code)
                    }
                    onChange={(e) => this.onItemChecked(item, "weaningMethods")}
                    label={item.name}
                  />
                );
              })}
            </div>
          </div>

          <div className="modal-row d-flex align-items-center">
            <div className="modal-label">Giai đoạn tuổi:</div>
            <div className="d-flex align-items-center">
              {weaningStages.map((item, index) => {
                return (
                  <Form.Check
                    key={index}
                    className="inbox-check pdr-30"
                    checked={
                      Array.isArray(bodyData?.weaningStages) &&
                      bodyData?.weaningStages.find((x) => x === item.code)
                    }
                    onChange={(e) => this.onItemChecked(item, "weaningStages")}
                    label={item.name}
                  />
                );
              })}
            </div>
          </div>

          <div className="modal-row d-flex align-items-center">
            <div className="modal-label">Cách chế biến:</div>
            <div className="d-flex align-items-center">
              {weaningProcessing.map((item, index) => {
                return (
                  <Form.Check
                    key={index}
                    className="inbox-check pdr-30"
                    checked={
                      Array.isArray(bodyData?.weaningProcessions) &&
                      bodyData?.weaningProcessions.find((x) => x === item.code)
                    }
                    onChange={(e) =>
                      this.onItemChecked(item, "weaningProcessions")
                    }
                    label={item.name}
                  />
                );
              })}
            </div>
          </div>

          <div className="modal-row">
            <div className="d-flex align-items-center mgbt-5">
              <div className="modal-label">Mô tả:</div>
              <div />
            </div>
            <Form.Control
              value={bodyData?.weaningDishDescription}
              onChange={(e) =>
                this.setState({
                  bodyData: {
                    ...bodyData,
                    weaningDishDescription: e.target.value,
                  },
                })
              }
              as="textarea"
              placeholder="Nhập mô tả..."
              style={{ height: "100px" }}
            />
          </div>

          <div className="modal-row">
            <div className="d-flex align-items-center mgbt-5">
              <div className="modal-label">Nguyên liệu:</div>
            </div>
            <div className={"editor-container"}>
              <Editor
                key={"editor1"}
                ref={(ref) => (this.editor1 = ref)}
                style={{ height: 250 }}
                value={ingredients || ""}
                onChange={(e) => {
                  this.setState({ ingredients: e });
                }}
              />
            </div>
          </div>

          <div className="modal-row">
            <div className="d-flex align-items-center mgbt-5">
              <div className="modal-label">Cách làm:</div>
            </div>
            <div className={"editor-container"}>
              <Editor
                key={"editor2"}
                ref={(ref) => (this.editor2 = ref)}
                style={{ height: 250 }}
                value={instructions || ""}
                onChange={(e) => {
                  this.setState({ instructions: e });
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.onToggle}>
            Đóng
          </Button>
          <Button variant="primary" onClick={() => this.onSubmit()}>
            {bodyData?.id || bodyData?.id === 0 ? "Lưu" : "Tạo thực phẩm"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
