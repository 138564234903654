import APIService from "./customAPI.services";
import { deleteParamsNotUsing, getQueryString } from "../utils";

export type SearchUserVipParams = {
  query: string | null;
  targetId: string | null;
  subscriptionPackage: string | null;
  paymentMethod: string | null;
  page: number | null;
  size: number | null;
};

export type SearchUserParams = {
  query: string | null;
  target: string | null; // mongcon, mangthai, sausinh
  status: number | null; // 1 | 0;
  stage: string | null;
  customerType: string | null; // vip | trial | free
  createdDateFrom: number | null;
  createdDateTo: number | null;
  expiredDateFrom: number | null;
  expiredDateTo: number | null;
  page: number | null;
  size: number | null;
};

class CustomerService extends APIService {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }

  async exportFileCustomers(params?: SearchUserParams) {
    deleteParamsNotUsing(params);
    const url = getQueryString("user-customers/export", params);
    const response = await this.request("GET", url, {}, false, {
      responseType: "blob",
    });
    return response?.data;
  }

  async searchUser(params?: SearchUserParams) {
    deleteParamsNotUsing(params);
    const url = getQueryString("user-customers/search", params);
    return await this.request("GET", url);
  }

  async searchUserVip(params?: SearchUserVipParams) {
    deleteParamsNotUsing(params);
    const url = getQueryString("user-customer-vip/search", params);
    return this.request("GET", url);
  }

  async saveNote(userId: number, content: string) {
    return await this.request("POST", `user-customer-vip/${userId}/care-note`, {
      note: content,
    });
  }

  async getById(id: number) {
    return this.request("GET", `user-customers/${id}`);
  }

  async upVip(data: any) {
    return this.request("POST", "user-customer-vip", data);
  }
}

export default CustomerService;
