import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import lodash from "lodash";
import CustomerService from "../../services/customer.services";

const namespace = "customer";
const apiService = new CustomerService();

const STATE = {
  loading: false,
  item: {
    userId: "",
    username: "",
    phoneNumber: "",
    email: "",
    fullname: "",
    expiredDate: "",
  },
  searchParams: {
    page: 0,
    size: 10,
    properties: "userId",
    sort: "userId:desc",
    query: "",
  },
  table: {
    items: [],
    numberOfElements: 0,
    totalElements: 0,
    totalPages: 0,
    number: 0,
  },
  error: {},
};

export const search = createAsyncThunk(
  `${namespace}/search`,
  async (searchParams = {}) => {
    return await apiService.search({ ...STATE.searchParams, ...searchParams });
  }
);

const customerSlice = createSlice({
  name: namespace,
  initialState: STATE,
  reducers: {
    onChoicePageSize(state, { payload }) {
      state.searchParams.size = payload;
      state.searchParams.page = 0;
    },

    onChangePage(state, { payload }) {
      if (payload < 0 || payload >= state.table.totalPages) {
        return;
      }
      state.searchParams.page = payload;
    },

    submitSearch(state, { payload }) {
      state.searchParams = {
        page: 0,
        size: state.searchParams.size,
        sort: state.searchParams.sort,
        query: payload,
      };
    },
  },
  extraReducers: {
    [search.pending](state) {
      state.loading = true;
    },

    [search.fulfilled](state, { payload }) {
      state.loading = false;
      state.table.items = [...payload.data];
      state.table.numberOfElements = Number(
        lodash.get(payload, "headers.x-page-size")
      );
      state.table.totalElements = Number(
        lodash.get(payload, "headers.x-total-count")
      );
      state.table.totalPages = Number(
        lodash.get(payload, "headers.x-page-count")
      );
      state.table.number = Number(
        lodash.get(payload, "headers.x-page-number", 0)
      );
    },
    [search.rejected](state, { error }) {
      state.loading = false;
      state.error = error;
    },
  },
});

export const { onChangePage, submitSearch } = customerSlice.actions;
export default customerSlice.reducer;
