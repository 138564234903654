import React, { useEffect, useRef } from "react";
import { RoomItem } from "./RoomItem";

type ChatRoomsProps = {
  rooms: RoomInfo[];
  onSelectRoom: (room: RoomInfo) => void;
  onReceiveContact: (room: RoomInfo) => void;
  onLoadMore: () => void;
  currentRoom: RoomInfo | null;
  showBtnReceive: boolean;
  isLoadMore: boolean;
};

const ChatRooms = (props: ChatRoomsProps) => {
  const chatRoomRefScroll = useRef<HTMLDivElement | null>(null);
  const {
    rooms = [],
    isLoadMore,
    onSelectRoom,
    onReceiveContact,
    onLoadMore,
    currentRoom,
    showBtnReceive,
  } = props;

  // useEffect(() => {
  //   chatRoomRefScroll.current?.addEventListener("scroll", () => {
  //     if (
  //       (chatRoomRefScroll.current?.scrollTop ?? 0) +
  //         (chatRoomRefScroll.current?.clientHeight ?? 0) >=
  //       (chatRoomRefScroll.current?.scrollHeight ?? 1)
  //     ) {
  //       onLoadMore();
  //       console.log("onLoadMore");
  //     }
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const handleScroll = (e: any) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop < (e.target.clientHeight + 10);
    if (bottom) {
      onLoadMore();
    }
  };

  if (rooms.length === 0) {
    return null;
  }
  return (
    <div
      className="chat-list-room"
      // ref={chatRoomRefScroll}
      onScroll={handleScroll}
    >
      {rooms.map((item: RoomInfo) => (
        <RoomItem
          key={item.id + "room"}
          item={item}
          isActive={currentRoom?.id === item.id}
          showBtn={showBtnReceive}
          onSelectRoom={() => onSelectRoom(item)}
          onReceiveContact={() => onReceiveContact(item)}
        />
      ))}
      {/* {isLoadMore && <p className="loading"> loading More Items..</p>} */}
    </div>
  );
};

export default ChatRooms;
