import { Input, Modal, Button } from "antd";

type Props = {
  note?: string;
  isShowModal: boolean;
  onHide: () => void;
  onSubmit: (content: string) => void;
  setContent: (content: string) => void;
};

const ModalCreateNote = (props: Props) => {
  const { isShowModal, onHide, onSubmit, setContent, note } = props;

  const onCancel = () => {
    setContent("");
    onHide();
  };

  const onSave = () => {
    setContent("");
    onSubmit(note ?? "");
    onHide();
  };

  return (
    <Modal
      open={isShowModal}
      title="Ghi chú"
      onCancel={onCancel}
      footer={[
        <Button type="primary" onClick={onSave}>
          Lưu
        </Button>,
      ]}
    >
      <div>
        <Input.TextArea
          value={note}
          autoFocus
          onChange={(e) => setContent(e.target.value)}
          placeholder="Nhập ghi chú"
          rows={3}
        />
      </div>
    </Modal>
  );
};

export default ModalCreateNote;
