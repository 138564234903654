import { combineReducers, configureStore } from "@reduxjs/toolkit";

// sub-reducer
import globalReducer from "./global";
import usersReducer from "./users";
import targetsReducer from "./target";
import categoryReducer from "./category";
import topicsReducer from "./topic";
import postReducer from "./post";
import hashtagReducer from "./hashtag";
import newsReducer from "./news";
import packageReducer from "./package";
import promotionReducer from "./promotion";
import customerReducer from "./customer";

export default configureStore({
  reducer: combineReducers({
    global: globalReducer,
    users: usersReducer,
    targets: targetsReducer,
    category: categoryReducer,
    topic: topicsReducer,
    post: postReducer,
    hashtag: hashtagReducer,
    news: newsReducer,
    packages: packageReducer,
    promotions: promotionReducer,
    customer: customerReducer,
  }),

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
