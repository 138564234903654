import React from "react";
import moment from "moment";
import { defaultCustomerAvatar } from "../../contains/common";
import { Image } from "antd";
import { isNullOrEmpty } from "src/utils/Lang";
import ReactPlayer from "react-player";

type Props = {
  key: string;
  item: MessageItemType;
  itMe: boolean;
  currentCustomer: Customer | null;
};

export const MessageItem = (props: Props) => {
  const { item, itMe, currentCustomer } = props;
  return (
    <div
      className={`chat-message-item ${
        itMe ? "chat-message-item--it-me" : "chat-message-item--not-me"
      }`}
    >
      <div
        className={`image-content ${
          itMe ? "image-content__it-me" : "chat-message-item__not-me"
        }`}
      >
        {item?.attachments?.map((item: any, index: number) => (
          <MetaData item={item} key={"meta-data" + index + item?.attachId} />
        ))}
      </div>

      <div className={"chat-message-content"}>
        <div className="chat-message-item__message">
          {!itMe && (
            // eslint-disable-next-line jsx-a11y/alt-text
            <img
              src={currentCustomer?.avatar || defaultCustomerAvatar}
              className={"chat-message-item__avatar"}
            />
          )}
          <div>
            {!isNullOrEmpty(item.content) && (
              <div className="box-text text">{item.content}</div>
            )}
            <span className={"time"}>
              {moment(item.addedTimestamp).format("DD/MM/YYYY HH:mm")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

type MetaDataProps = {
  item: any;
};

const MetaData = (props: MetaDataProps) => {
  const { item } = props;
  if (["video/mp4", "application/octet-stream"].includes(item?.contentType)) {
    return (
      <div className="player-wrapper">
        <ReactPlayer
          url={item.linkUrl?.[0]}
          controls={true}
          className="react-player"
          playing={false}
          width="100%"
          height="100%"
        />
      </div>
    );
  } else {
    return <Image height={160} src={item.linkUrl?.[0]} />;
  }
};
