import React, { useEffect, useState } from "react";
import SimpleBar from "simplebar-react";
import { Link, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBeer,
  faBell,
  faChartPie,
  faCode,
  faComments,
  faCubes,
  faIdCard,
  faSignOutAlt,
  faTable,
  faTimes,
  faUsers,
  faImages,
  faUsersCog,
  faUserShield,
  faUserTie,
  faStickyNote,
  faBookOpen,
  faWalking,
  faLeaf,
  faPager,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import {
  Accordion,
  Badge,
  Button,
  Dropdown,
  Image,
  Nav,
  Navbar,
} from "@themesberg/react-bootstrap";

import { Routes } from "../routes";
import ThemesbergLogo from "../assets/img/themesberg.svg";
import ReactHero from "../assets/img/technologies/react-hero-logo.svg";
import ProfilePicture from "../assets/img/team/profile-picture-3.jpg";

const Sidebar = (props = {}) => {
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";

  const onCollapse = () => setShow(!show);

  useEffect(() => {
    setTimeout(() => {
      const activeItem = window.document.querySelector(
        ".sidebar-inner .active.nav-item"
      );

      if (activeItem) {
        const parent = activeItem.parentElement.parentElement.parentElement;
        parent.classList.add("show");
        const parentOfParent =
          parent?.parentElement?.parentElement?.parentElement?.parentElement
            ?.parentElement;
        if (parentOfParent) {
          parentOfParent.classList.add("show");
        }
      }
    }, [500]);
  }, [pathname]);

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon, children = null } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";
    // console.log('pathname', pathname)
    return (
      <Accordion key={eventKey} as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button
            as={Nav.Link}
            className="d-flex justify-content-between align-items-center"
          >
            <span>
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon} />{" "}
              </span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">{children}</Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = (props) => {
    const {
      title,
      link,
      external,
      target,
      icon,
      image,
      badgeText,
      badgeBg = "secondary",
      badgeColor = "primary",
    } = props;
    const classNames = badgeText
      ? "d-flex justify-content-start align-items-center justify-content-between"
      : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? (
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon} />{" "}
              </span>
            ) : null}
            {image ? (
              <Image
                src={image}
                width={20}
                height={20}
                className="sidebar-icon svg-icon"
              />
            ) : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge
              pill
              bg={badgeBg}
              text={badgeColor}
              className="badge-md notification-count ms-2"
            >
              {badgeText}
            </Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        variant="dark"
        className="navbar-theme-primary px-4 d-md-none"
      >
        <Navbar.Brand
          className="me-lg-5"
          as={Link}
          to={Routes.DashboardOverview.path}
        >
          <Image src={ReactHero} className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle
          as={Button}
          aria-controls="main-navbar"
          onClick={onCollapse}
        >
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}
        >
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image
                    src={ProfilePicture}
                    className="card-img-top rounded-circle border-white"
                  />
                </div>
                <div className="d-block">
                  <h6>Hi, Jane</h6>
                  <Button
                    as={Link}
                    variant="secondary"
                    size="xs"
                    to={Routes.Signin.path}
                    className="text-dark"
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />{" "}
                    Sign Out
                  </Button>
                </div>
              </div>
              <Nav.Link
                className="collapse-close d-md-none"
                onClick={onCollapse}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              <NavItem
                title="Tổng quan"
                link={Routes.DashboardOverview.path}
                icon={faChartPie}
              />

              <CollapsableNavItem
                eventKey="userManagement"
                title="Quản lý nhân viên"
                icon={faUsersCog}
              >
                <NavItem
                  title="DS chức vụ"
                  icon={faUserTie}
                  link={Routes.UserRoles.path}
                />
                <NavItem
                  title="DS nhóm quyền"
                  icon={faUserShield}
                  link={Routes.UserPermissions.path}
                />
                <NavItem
                  title="DS nhân viên"
                  icon={faUsers}
                  link={Routes.Users.path}
                />
              </CollapsableNavItem>

              <Dropdown.Divider className="border-indigo" />

              <CollapsableNavItem
                eventKey="cms"
                title="CMS"
                icon={faStickyNote}
              >
                <CollapsableNavItem
                  eventKey="camnang"
                  title="Cẩm nang"
                  icon={faBookOpen}
                >
                  <NavItem
                    title="Mục tiêu"
                    icon={faMinus}
                    link={Routes.Targets.path}
                  />
                  <NavItem
                    title="Chuyên mục"
                    icon={faMinus}
                    link={Routes.Categories.path}
                  />
                  <NavItem
                    title="Chủ đề"
                    icon={faMinus}
                    link={Routes.Topics.path}
                  />
                  <NavItem
                    title="Bài viết"
                    icon={faMinus}
                    link={Routes.Posts.path}
                  />
                  <NavItem
                    title="Hashtag"
                    icon={faMinus}
                    link={Routes.Hashtags.path}
                  />
                  {/*<NavItem title="Tin tức" icon={faNewspaper} link={Routes.News.path} />*/}
                  {/*<NavItem title="Khuyến mại" icon={faPercent} link={Routes.Promotions.path} />*/}
                </CollapsableNavItem>
                <CollapsableNavItem
                  eventKey="lotrinh"
                  title="Lộ trình"
                  icon={faWalking}
                >
                  <NavItem
                    title="CS trẻ sơ sinh"
                    icon={faMinus}
                    link={Routes.BabyCares.path}
                  />
                  <NavItem
                    title="Giáo dục sớm"
                    icon={faMinus}
                    link={Routes.NurseryEducations.path}
                  />
                </CollapsableNavItem>
                <CollapsableNavItem
                  eventKey="tienich"
                  title="Tiện ích"
                  icon={faLeaf}
                >
                  <NavItem
                    title="Sổ tay dinh dưỡng"
                    icon={faMinus}
                    link={Routes.Foods.path}
                  />
                  <NavItem
                    title="Sổ tay ăn dặm"
                    icon={faMinus}
                    link={Routes.WeaningFoods.path}
                  />
                  <NavItem
                    title="Surveys"
                    icon={faMinus}
                    link={Routes.Surveys.path}
                  />
                  <NavItem
                    title="Podcast"
                    icon={faMinus}
                    link={Routes.Podcast.path}
                  />
                </CollapsableNavItem>
                <NavItem
                  title="Banners"
                  icon={faImages}
                  link={Routes.Banners.path}
                />
              </CollapsableNavItem>
              
              <Dropdown.Divider className="border-indigo" />

              <CollapsableNavItem
                eventKey="webExsoft"
                title="Website Exsoft"
                icon={faTable}
              >
                <NavItem
                  title="Blog"
                  link={Routes.WebsiteBlog.path}
                />
                <NavItem
                  title="Tuyển dụng"
                  link={Routes.WebsiteRecruit.path}
                />
                </CollapsableNavItem>

              <CollapsableNavItem eventKey="crm" title="CRM" icon={faUsers}>
                <NavItem
                  title="Khách hàng"
                  icon={faIdCard}
                  link={Routes.Customer.path}
                />
                <NavItem
                  title="Gói cước"
                  icon={faCubes}
                  link={Routes.Packages.path}
                />
                <NavItem
                  title="User Vip"
                  icon={faComments}
                  link={Routes.UserVip.path}
                />
                <NavItem
                  title="Chats"
                  icon={faComments}
                  link={Routes.Chats.path}
                />
                <NavItem
                  title="Mã kích hoạt"
                  icon={faCode}
                  link={Routes.ActivationCodeBatch.path}
                />
              </CollapsableNavItem>
              <CollapsableNavItem
                eventKey="notifications"
                title="Thông báo"
                icon={faBell}
              >
                <NavItem
                  title="Đối tượng"
                  icon={faBeer}
                  link={Routes.NotificationObject.path}
                />
                <NavItem
                  title="Thông báo"
                  icon={faBell}
                  link={Routes.Notification.path}
                />
                <NavItem
                  title="Bài viết thông báo"
                  icon={faBell}
                  link={Routes.NotificationPosts.path}
                />
              </CollapsableNavItem>
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};

export default Sidebar;
