import APIService from "./api.services";

class HashTagService extends APIService {
  constructor() {
    super();
    this.modulePath = "hashtag";
  }

  async convertHashTag(textHashtag) {
    return await this.request(
      "GET",
      `hashtag/convert-text-list?textHashtag=${encodeURIComponent(textHashtag)}`
    );
  }

  async getList(query) {
    return await this.request(
      "GET",
      `hashtag/search?keyword=${encodeURIComponent(query)}&size=20`
    );
  }
}

export default HashTagService;
