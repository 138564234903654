import React from "react";
import {
  Modal,
  Button,
  InputGroup,
  FormControl,
  Form,
} from "@themesberg/react-bootstrap";
import _ from "lodash";
import FoodsService from "../../services/foods.service";
import FileService from "../../services/file.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import Dropzone from "react-dropzone";
import mammoth from "mammoth";
import Select from "react-select";

import { FOOD_ENUM } from "../../contains/common";
import { toast } from "react-toastify";

export default class ModalFood extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bodyData: {},
    };

    this.fileService = new FileService();
  }

  componentDidMount() {
    this.getFoodGroups();
    this.getNutritious();
    this.getMicronutrients();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(prevProps.dataDetail) !==
      JSON.stringify(this.props.dataDetail)
    ) {
      const { dataDetail = {} } = this.props;
      const { micronutrients = [] } = this.state;

      if (dataDetail?.foodId || dataDetail?.foodId === 0) {
        const micronutrient = dataDetail?.micronutrient
          ? dataDetail?.micronutrient.split(",").map((x) => x.trim())
          : [];
        let newObj = {
          ...dataDetail,
          foodGroup: {
            value: dataDetail?.foodGroup?.code,
            label: dataDetail?.foodGroup?.foodGroupName,
          },
          nutritious: dataDetail?.nutrition
            ? dataDetail?.nutrition.split(",").map((x) => x.trim())
            : [],
          micronutrients: micronutrients
            .filter((x) => !!micronutrient.find((y) => y === x.code))
            .map((x) => {
              return {
                value: x?.code,
                label: x?.name,
              };
            }),
        };
        this.setState({
          bodyData: newObj,
          originalData: _.cloneDeep(newObj),
        });
      } else {
        this.setState({
          bodyData: {},
          originalData: {},
        });
      }
    }
  }

  onSubmit = async () => {
    const { bodyData = {}, originalData = {} } = this.state;
    const { getData } = this.props;

    if (!bodyData?.foodName) {
      toast.error("Vui lòng nhập tên thực phẩm");
      return;
    }

    if (!bodyData?.foodGroup) {
      toast.error("Vui lòng chọn nhóm thực phẩm");
      return;
    }

    // console.log('bodyData', bodyData)

    let body = {
      foodName: bodyData?.foodName.trim(),
      foodGroupCode: bodyData?.foodGroup?.value,
      image: bodyData?.image,
      nutritious: bodyData?.nutritious,
      micronutrients: Array.isArray(bodyData?.micronutrients)
        ? bodyData?.micronutrients.map((x) => x.value)
        : [],
      pregnant: bodyData?.pregnant,
      postnatal: bodyData?.postnatal,
      feeding: bodyData?.feeding,
      pregnantDescription: bodyData?.pregnantDescription.trim(),
      postnatalDescription: bodyData?.postnatalDescription.trim(),
      feedingDescription: bodyData?.feedingDescription.trim(),
    };

    try {
      if (bodyData?.foodId || bodyData?.foodId === 0) {
        if (body.foodName === originalData.foodName) {
          delete body.foodName;
        }
        await FoodsService.updateFood(bodyData?.foodId, body);
        toast.success("Sửa thành công");
      } else {
        await FoodsService.createFood(body);
        toast.success("Tạo thành công");
      }
      this.onToggle();
      getData();
    } catch (error) {
      toast.error(
        error?.message ||
          "Có lỗi xảy ra vui lòng liên hệ kỹ thuật để được hỗ trợ."
      );
    }
  };

  getFoodGroups = async () => {
    const resp = await FoodsService.getFoodGroups();
    this.setState({
      foodGroup: resp.data,
    });
  };

  getNutritious = async () => {
    const resp = await FoodsService.getNutritious();
    this.setState({
      nutritious: resp.data,
    });
  };

  getMicronutrients = async () => {
    const resp = await FoodsService.getMicronutrients();
    this.setState({
      micronutrients: resp.data,
    });
  };

  handleFileUploadByPostsFormat = async (files) => {
    // console.log('vào handleFileUploadByPostsFormat')
    const file = files[0];
    let fileSize = file.size / 1024 / 1024; // in MiB
    if (fileSize > 500) {
      return alert("Dung lượng file lớn hơn 500 MiB, vui lòng chọn file khác.");
    }

    let { bodyData = {} } = this.state;

    let body = {
      objectType: "foods",
      displayType: 1,
    };
    // objectType: "posts"
    // objectId: ID bài viết
    // displayType: 1(thumbnail), 2( icon), 3(Cover), 4(video), 5(Audio), 6(Infographic), 7(avatar)

    try {
      const resp = await this.fileService.uploadImage(file, body);
      if (resp?.linkUrl?.length > 0) {
        bodyData.image = resp.linkUrl[0];
      }
    } catch (error) {
      console.log(error);
    }

    this.setState({ bodyData: bodyData });
  };

  handleImageUploadInDocx = async (dataUrl, fileName) => {
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    const file = new File([blob], fileName, { type: "image/png" });
    try {
      const resp = await this.fileService.uploadImage(file);
      return resp.linkUrl[0];
    } catch (error) {
      return dataUrl;
    }
  };

  handleFileUpload = async (files) => {
    const file = files[0];
    let fileSize = file.size / 1024 / 1024; // in MiB
    if (fileSize > 20) {
      return alert(
        "Dung lượng file lớn hơn 20 MiB, vui lòng xem lại nội dung bài viết"
      );
    }
    let { bodyData = {} } = this.state;

    try {
      let fPregnantDescription = "";
      let fPostnatalDescription = "";
      let fFeedingDescription = "";
      let fName = "";
      let reader = new FileReader();
      const options = {
        convertImage: mammoth.images.imgElement((image) => {
          return image.read("base64").then(async (imageBuffer) => {
            const sourceImage =
              "data:" + image.contentType + ";base64," + imageBuffer;
            return {
              src: await this.handleImageUploadInDocx(sourceImage, Date.now()),
            };
          });
        }),
      };
      reader.onload = async function () {
        const doc = await mammoth.convertToHtml(
          { arrayBuffer: this.result },
          options
        );
        const html = doc?.value;

        if (html) {
          const div = document.createElement("div");
          div.innerHTML = html;
          fName = div.querySelector("h1").textContent;
          const nutritiousString =
            div.querySelector("h2").nextElementSibling.textContent;
          if (nutritiousString) {
            const nutritiousStringSplit = nutritiousString.split(",");
          }

          const h3 = div.getElementsByTagName("h3");
          if (h3[0]) {
            fPregnantDescription = h3[0].nextElementSibling.textContent;
          }
          if (h3[1]) {
            fPostnatalDescription = h3[1].nextElementSibling.textContent;
          }
          if (h3[2]) {
            fFeedingDescription = h3[2].nextElementSibling.textContent;
          }
        }
      };
      reader.onloadend = async (e) => {
        await e.currentTarget.onload();
        this.setState({
          bodyData: {
            ...bodyData,
            foodName: fName,
            pregnantDescription: fPregnantDescription,
            postnatalDescription: fPostnatalDescription,
            feedingDescription: fFeedingDescription,
          },
        });
      };

      reader.readAsArrayBuffer(file);

      // console.log('fName', fName)
    } catch (error) {
      console.log(error);
    }
  };

  onNutritiousItemChecked = (item) => {
    const { bodyData = {} } = this.state;
    let nutritious = bodyData?.nutritious || [];
    if (nutritious.find((x) => x === item.code)) {
      nutritious = nutritious.filter((x) => x !== item.code);
    } else {
      nutritious.push(item.code);
    }
    this.setState({ bodyData: { ...bodyData, nutritious } });
  };

  onToggle = () => {
    const { toggle } = this.props;
    this.setState(
      {
        bodyData: {},
      },
      () => {
        toggle();
      }
    );
  };

  render() {
    const { modalStatus } = this.props;
    const {
      foodGroup = [],
      bodyData = {},
      nutritious = [],
      micronutrients = [],
    } = this.state;

    return (
      <Modal
        dialogClassName="modal-90w"
        as={Modal.Dialog}
        show={modalStatus}
        onHide={this.onToggle}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {bodyData?.foodId || bodyData?.foodId === 0
              ? "Chỉnh sửa thực phẩm"
              : "Thêm mới thực phẩm"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-row d-flex align-items-center">
            <div className="modal-label">Tên thực phẩm:</div>

            <FormControl
              value={bodyData.foodName}
              onChange={(e) =>
                this.setState({
                  bodyData: { ...bodyData, foodName: e.target.value },
                })
              }
              placeholder="Nhập tên thực phẩm"
            />
          </div>

          <div className="modal-row d-flex align-items-center">
            <div className="modal-label">Nhóm thực phẩm:</div>

            {foodGroup && (
              <Select
                className="custom-react-select"
                placeholder="Chọn nhóm thực phẩm"
                options={foodGroup.map((x) => {
                  return { value: x.code, label: x.foodGroupName };
                })}
                value={bodyData.foodGroup}
                isMulti={false}
                onChange={(e) => {
                  this.setState({ bodyData: { ...bodyData, foodGroup: e } });
                }}
                isClearable={true}
                backspaceRemovesValue={true}
              />
            )}
          </div>

          <div className="modal-row">
            <div className="d-flex align-items-center">
              <div className="modal-label">Tải lên ảnh thực phẩm:</div>
              <Dropzone
                accept={"image/*"}
                multiple={false}
                onDrop={(acceptedFiles) =>
                  this.handleFileUploadByPostsFormat(acceptedFiles)
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <section key={"dropzone"}>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <FontAwesomeIcon
                        icon={Icon.faCloudUploadAlt}
                        className="me-2"
                      />
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            <div className="d-flex align-items-center">
              <div className="modal-label" />
              {bodyData.image && (
                <img
                  src={bodyData.image}
                  style={{ height: 300, width: "auto" }}
                />
              )}
            </div>
          </div>

          <div className="modal-row d-flex align-items-center">
            <div className="modal-label">Tải lên file nội dung:</div>
            <Dropzone
              accept={".docx, .doc"}
              multiple={false}
              onDrop={(acceptedFiles) => this.handleFileUpload(acceptedFiles)}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <FontAwesomeIcon
                      icon={Icon.faCloudUploadAlt}
                      className="me-2"
                    />
                  </div>
                </section>
              )}
            </Dropzone>
          </div>

          <div className="modal-row d-flex align-items-center">
            <div className="modal-label">Nhóm chất:</div>
            <div className="d-flex align-items-center">
              {nutritious.map((item, index) => {
                return (
                  <Form.Check
                    key={index}
                    className="inbox-check pdr-30"
                    checked={
                      Array.isArray(bodyData?.nutritious) &&
                      bodyData?.nutritious.find((x) => x === item.code)
                    }
                    onChange={(e) => this.onNutritiousItemChecked(item)}
                    label={item.name}
                  />
                );
              })}
            </div>
          </div>

          <div className="modal-row d-flex align-items-center">
            <div className="modal-label">Vi chất:</div>
            {micronutrients && (
              <Select
                className="custom-react-select"
                placeholder="Thêm vi chất cho thực phẩm"
                options={micronutrients.map((x) => {
                  return { value: x.code, label: x.name };
                })}
                value={bodyData.micronutrients}
                isMulti={true}
                onChange={(e) => {
                  this.setState({
                    bodyData: { ...bodyData, micronutrients: e },
                  });
                }}
                isClearable={true}
                backspaceRemovesValue={true}
              />
            )}
          </div>

          <div className="modal-row">
            <div className="d-flex align-items-center mgbt-5">
              <div className="modal-label">Mang thai:</div>
              <div className="d-flex align-items-center">
                {FOOD_ENUM.map((item, index) => {
                  return (
                    <Form.Check
                      key={index}
                      type={"radio"}
                      className="inbox-check pdr-30"
                      checked={bodyData?.pregnant === item.id}
                      onChange={(e) =>
                        this.setState({
                          bodyData: { ...bodyData, pregnant: item.id },
                        })
                      }
                      label={item.name}
                    />
                  );
                })}
              </div>
            </div>
            <Form.Control
              value={bodyData?.pregnantDescription}
              onChange={(e) =>
                this.setState({
                  bodyData: {
                    ...bodyData,
                    pregnantDescription: e.target.value,
                  },
                })
              }
              as="textarea"
              placeholder="Nhập mô tả..."
              style={{ height: "100px" }}
            />
          </div>

          <div className="modal-row">
            <div className="d-flex align-items-center mgbt-5">
              <div className="modal-label">Mới sinh:</div>
              <div className="d-flex align-items-center">
                {FOOD_ENUM.map((item, index) => {
                  return (
                    <Form.Check
                      key={index}
                      type={"radio"}
                      className="inbox-check pdr-30"
                      checked={bodyData?.postnatal === item.id}
                      onChange={(e) =>
                        this.setState({
                          bodyData: { ...bodyData, postnatal: item.id },
                        })
                      }
                      label={item.name}
                    />
                  );
                })}
              </div>
            </div>
            <Form.Control
              value={bodyData?.postnatalDescription}
              onChange={(e) =>
                this.setState({
                  bodyData: {
                    ...bodyData,
                    postnatalDescription: e.target.value,
                  },
                })
              }
              as="textarea"
              placeholder="Nhập mô tả..."
              style={{ height: "100px" }}
            />
          </div>

          <div className="modal-row">
            <div className="d-flex align-items-center mgbt-5">
              <div className="modal-label">Cho con bú:</div>
              <div className="d-flex align-items-center">
                {FOOD_ENUM.map((item, index) => {
                  return (
                    <Form.Check
                      key={index}
                      type={"radio"}
                      className="inbox-check pdr-30"
                      checked={bodyData?.feeding === item.id}
                      onChange={(e) =>
                        this.setState({
                          bodyData: { ...bodyData, feeding: item.id },
                        })
                      }
                      label={item.name}
                    />
                  );
                })}
              </div>
            </div>
            <Form.Control
              value={bodyData?.feedingDescription}
              onChange={(e) =>
                this.setState({
                  bodyData: { ...bodyData, feedingDescription: e.target.value },
                })
              }
              as="textarea"
              placeholder="Nhập mô tả..."
              style={{ height: "100px" }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.onToggle}>
            Đóng
          </Button>
          <Button variant="primary" onClick={() => this.onSubmit()}>
            {bodyData?.foodId || bodyData?.foodId === 0
              ? "Lưu"
              : "Tạo thực phẩm"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
