import APIService from "./api.services";
import axios from "axios";
import { CHAT_API_URL, PUBLIC_URL } from "../contains/common";

class ChatsService extends APIService {
  constructor() {
    super();
  }

  async getList(obj) {
    return await this.request(
      "GET",
      `chat/list?page=${obj.pageNumber}&size=${obj.pageSize}`,
      {}
    );
  }

  async join(obj) {
    return await this.request("POST", `chat/join`, obj);
  }

  async receiveRoom(data) {
    return await this.request("POST", `chat/receive-room`, data);
  }

  async sendMessage(data) {
    return await axios.post(
      `${CHAT_API_URL}/method.call/sendMessage`,
      data?.body,
      {
        headers: data.headers,
      }
    );
    // return await this.request('POST', `http://chat.dev.babiuni.com/api/v1/method.call/sendMessage`, body?.body, false, {
    //     notAuthorization: true,
    //     headers: body.headers,
    // });
  }

  async uploadFile(file) {
    const body = new FormData();
    body.append("description", file.name);
    body.append("file", file);
    console.log('body append', body);
    return await this.request("POST", 'chat/messages/upload',
      body
    );
  }

  async getMyChatList(obj) {
    return await this.makeRequest(
      "GET",
      `chat/current-user?page=${obj.pageNumber}&size=${obj.pageSize}`,
      {}
    );
  }

  async getPendingRooms(size) {
    return await this.request("GET", `chat/pending-rooms?size=${size}`);
  }

  async getProcessedRooms(size) {
    return await this.request("GET", `chat/processed-rooms?size=${size}`);
  }

  async getMessagesInRoom(roomId, size) {
    return await this.request("GET", `chat/rooms/${roomId}/messages?size=${size}`);
  }

  async searchRoomFromUserId(userId) {
    return await this.request("GET", `chat/rooms?customer=${userId}`);
  }

  async searchRoomFromContent(content) {
    return await this.request("GET", `chat/rooms/search?query=${content}`);
  }

  async receiveContact(roomId) {
    return await this.request("POST", `chat/rooms/${roomId}/process`);
  }
}

export default ChatsService;
