import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Routes } from "src/routes";
import {
  faPauseCircle,
  faPen,
  faPlayCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import notificationService from "src/services/notification.service";

type Props = {
  item: any;
  handleRemoveNotification: (item: any) => void;
};

const ActionsBox = (props: Props) => {
  const { item, handleRemoveNotification } = props;
  const [isPause, setIsPause] = useState<number>(item.paused);

  const handlePendingPauseNotification = async (item: any, pause: number) => {
    if (pause === 1) {
      await notificationService.unPause(item.id);
      setIsPause(0);
    } else {
      await notificationService.pause(item.id);
      setIsPause(1);
    }
  };

  return (
    <div>
      <FontAwesomeIcon
        onClick={() => handlePendingPauseNotification(item, isPause)}
        className={"mgr-15 pointer text-info"}
        icon={(isPause === 0 ? faPauseCircle : faPlayCircle) as IconProp}
      />
      <Link
        className={"mgr-15"}
        to={Routes.NotificationDetail.path.replace(":id", item.id)}
      >
        <FontAwesomeIcon
          className={"pointer text-info"}
          icon={faPen as IconProp}
        />
      </Link>
      <FontAwesomeIcon
        onClick={() => handleRemoveNotification(item)}
        className={"pointer text-danger"}
        icon={faTrash as IconProp}
      />
    </div>
  );
};

export default ActionsBox;
