import APIService from "./customAPI.services";

class NurseryEducationsService extends APIService {
  constructor() {
    super();
  }

  // post
  async listPosts(query) {
    return await this.request(
      "GET",
      `roadmap/nursery-educations/posts${query || ""}`,
      {}
    );
  }

  async getPost(id) {
    return await this.request(
      "GET",
      `roadmap/nursery-educations/posts/${id}`,
      {}
    );
  }

  async createPost(body) {
    return await this.request("POST", `roadmap/nursery-educations/posts`, body);
  }

  async updatePost(id, body) {
    return await this.request(
      "PATCH",
      `roadmap/nursery-educations/posts/${id}`,
      body
    );
  }

  async toggleHidden(id) {
    return await this.request(
      "POST",
      `roadmap/nursery-educations/posts/${id}/hidden`,
      {}
    );
  }

  //milestones
  async listMilestones(query) {
    return await this.request(
      "GET",
      `roadmap/nursery-educations/milestones${query || ""}`,
      {}
    );
  }

  async getMilestone(id) {
    return await this.request(
      "GET",
      `roadmap/nursery-educations/milestones/${id}`,
      {}
    );
  }

  async createMilestone(body) {
    return await this.request(
      "POST",
      `roadmap/nursery-educations/milestones`,
      body
    );
  }

  async updateMilestone(id, body) {
    return await this.request(
      "PATCH",
      `roadmap/nursery-educations/milestones/${id}`,
      body
    );
  }

  //teaching_aids
  async listTeachingAids(query) {
    return await this.request(
      "GET",
      `roadmap/nursery-educations/teaching-aids${query || ""}`,
      {}
    );
  }

  async getTeachingAid(id) {
    return await this.request(
      "GET",
      `roadmap/nursery-educations/teaching-aids/${id}`,
      {}
    );
  }

  async createTeachingAid(body) {
    return await this.request(
      "POST",
      `roadmap/nursery-educations/teaching-aids`,
      body
    );
  }

  async updateTeachingAid(id, body) {
    return await this.request(
      "PATCH",
      `roadmap/nursery-educations/teaching-aids/${id}`,
      body
    );
  }

  //daily
  async listDaily(query) {
    return await this.request(
      "GET",
      `roadmap/nursery-educations/daily${query || ""}`,
      {}
    );
  }

  async getDaily(id) {
    return await this.request(
      "GET",
      `roadmap/nursery-educations/daily/${id}`,
      {}
    );
  }

  async createDaily(body) {
    return await this.request("POST", `roadmap/nursery-educations/daily`, body);
  }

  async updateDaily(id, body) {
    return await this.request(
      "PATCH",
      `roadmap/nursery-educations/daily/${id}`,
      body
    );
  }
}
const nurseryEducationsService = new NurseryEducationsService();
export default nurseryEducationsService;
