import { deleteParamsNotUsing, getQueryString } from "src/utils";
import APIService from "./customAPI.services";

class NotificationPostService extends APIService {
  async getPosts(params?: SearchPostNotificationParams) {
    deleteParamsNotUsing(params);
    const url = getQueryString("customer-notification-posts", { ...params, sort: "addedTimestamp:desc" });
    return await this.request("GET", url);
  }

  create(params: CreatePostNotificationParams) {
    return this.request("POST", "customer-notification-posts", params);
  }

  edit(id: number, params: CreatePostNotificationParams) {
    return this.request("PATCH", `customer-notification-posts/${id}`, params);
  }

  detail(id: number) {
    return this.request("GET", `customer-notification-posts/${id}`);
  }
}
const notificationPostService = new NotificationPostService();

export default notificationPostService;
