import { deleteParamsNotUsing, getQueryString } from "src/utils";
import APIService from "./customAPI.services";

class WebsiteExsoftServices extends APIService {
  async createBlog(body: CreateBlogParams) {
    return await this.request("POST", "web-blogs", body);
  }

  async getBlogs(params?: SearchBlogParams) {
    deleteParamsNotUsing(params);
    const url = getQueryString("web-blogs", {
      ...params,
      sort: "addedTimestamp:desc",
    });
    return await this.request("GET", url);
  }

  async hiddenBlog(id: number, hidden: boolean) {
    return await this.request("PATCH", `web-blogs/${id}`, { hidden });
  }

  async detailBlog(id: number) {
    return await this.request("GET", `web-blogs/${id}`);
  }

  async updateBlog(id: number, body: CreateBlogParams) {
    return await this.request("PATCH", `web-blogs/${id}`, body);
  }

  async categoryBlogs() {
    return await this.request("GET", "web-category-blogs");
  }

  async getJobs(params?: SearchJobParams) {
    deleteParamsNotUsing(params);
    const url = getQueryString("jobs", { ...params, sort: "createdDate:desc" });
    return await this.request("GET", url);
  }

  async createJob(body: CreateJobParams) {
    return await this.request("POST", "jobs", body);
  }

  async updateJob(id: number, body: CreateJobParams) {
    return await this.request("PATCH", `jobs/${id}`, body);
  }

  async hiddenJob(id: number, hidden: boolean) {
    return await this.request("PATCH", `jobs/${id}`, { hidden });
  }
}

const websiteExsoftServices = new WebsiteExsoftServices();
export default websiteExsoftServices;
