import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Modal } from "@themesberg/react-bootstrap";

import { Field, Formik } from "formik";
import * as Yup from "yup";

import { ERROR_MESSAGE } from "../../contains/errors";
import { fetchAll as targetFetchAll } from "../../store/target";
import { fetchAll as catFetchAll } from "../../store/category";
import { onCreate, onUpdate, hideModal } from "../../store/topic";
import { showToast } from "../../store/global";
import FileService from "../../services/file.service";

import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Mục tiêu ~> Chuyên mục ~> Chủ đề

const TopicForm = () => {
  const fileService = new FileService();
  const dispatch = useDispatch();

  let isOpenModal = useSelector(({ topic }) => topic.isOpenModal);
  let activeItem = useSelector(({ topic }) => topic.item);

  let error = useSelector(({ topic }) => topic.error);

  let listTargets = useSelector(({ targets }) => targets.table.items);
  let listCategories = useSelector(({ category }) => category.table.items);

  useEffect(() => {
    (async () => {
      await dispatch(targetFetchAll());
    })();
  }, []);

  useEffect(() => {
    // fetch target when targetId change
    (async () => {
      if (activeItem.targetId) {
        await dispatch(targetFetchAll());
        await dispatch(
          catFetchAll({
            targetId: activeItem.targetId,
          })
        );
      }
    })();
    // check errors
    if (error && error.message) {
      dispatch(
        showToast({
          message: error.message,
          type: "error",
          deplay: 3000,
        })
      );
    }
  }, [activeItem, error]);

  const closeModal = () => {
    dispatch(hideModal());
  };

  const handleFileUpload = async (e, setFieldValue, prop) => {
    const file = e.target.files[0];
    var fileSize = file.size / 1024 / 1024; // in MiB
    if (fileSize > 5) {
      return alert("Dung lượng ảnh lớn hơn 5 MiB, vui lòng chọn ảnh khác.");
    }

    // objectType: ""topic""
    // objectId: ID bài viết
    // displayType: 1(thumbnail), 2( icon), 3(Cover), 4(video), 5(Audio), 6(Infographic), 7(avatar)
    let body = {
      objectType: "topic",
      displayType: 7,
    };
    switch (prop) {
      case "topicThumbnail":
        body.displayType = 1;
        break;
      case "topicIcon":
        body.displayType = 2;
        break;
      default:
        body.displayType = 7;
        break;
    }

    try {
      const resp = await fileService.uploadImage(file, body);
      if (Array.isArray(resp.linkUrl)) {
        setFieldValue(prop, resp.linkUrl[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onValidate = Yup.object().shape({
    topicName: Yup.string().required(ERROR_MESSAGE.TOPIC_NAME_REQUIRED),
    preFix: Yup.string().required(ERROR_MESSAGE.PREFIX_REQUIRED),
    categoryId: Yup.string().required(ERROR_MESSAGE.CATEGORY_OPTION_REQUIRED),
    targetId: Yup.string().required(ERROR_MESSAGE.TARGET_OPTION_REQUIRED),
    topicAvatar: Yup.string().required(ERROR_MESSAGE.COVER_IMAGE_REQUIRED),
  });

  const onChooseTarget = async (e, { handleChange }) => {
    handleChange(e);
    await dispatch(
      catFetchAll({
        targetId: e.target.value,
      })
    );
  };

  const onSubmit = async (values) => {
    let dataPost = {
      topicId: values.topicId,
      categoryId: values.categoryId,
      topicName: values.topicName,
      topicContent: values.topicContent,
      order: values.order,
      topicAvatar: values.topicAvatar,
      topicThumbnail: values.topicThumbnail,
      topicIcon: values.topicIcon,
      targetId: values.targetId,
      preFix: values.preFix,
    };

    if (values.priority && parseFloat(values.priority) != NaN) {
      dataPost.priority = parseFloat(values.priority);
    }

    if (activeItem.topicId > 0) {
      await dispatch(onUpdate(dataPost));
    } else {
      await dispatch(onCreate(dataPost));
    }
  };

  return (
    <Modal as={Modal.Dialog} centered show={isOpenModal} onHide={closeModal}>
      <Formik
        initialValues={activeItem}
        onSubmit={onSubmit}
        validationSchema={onValidate}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <Modal.Header>
              <Modal.Title className="h6">Chủ đề</Modal.Title>
              <Button variant="close" aria-label="Close" onClick={closeModal} />
            </Modal.Header>
            <Modal.Body>
              <Form.Group className="mb-3">
                <Form.Label>Tên chủ đề</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nhập tên chủ đề"
                  name="topicName"
                  isInvalid={props.errors.topicName && props.touched.topicName}
                  onChange={props.handleChange}
                  value={props.values.topicName}
                />
                <Form.Control.Feedback type="invalid">
                  {props.errors.topicName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Prefix</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nhập prefix chủ đề"
                  name="preFix"
                  isInvalid={props.errors.preFix && props.touched.preFix}
                  onChange={props.handleChange}
                  value={props.values.preFix}
                />
                <Form.Control.Feedback type="invalid">
                  {props.errors.preFix}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Mục tiêu</Form.Label>
                <Form.Select
                  name="targetId"
                  isInvalid={props.errors.targetId && props.touched.targetId}
                  onChange={(e) => onChooseTarget(e, props)}
                  value={props?.values?.targetId}
                >
                  <option value="">-- Chọn mục tiêu --</option>
                  {listTargets.map((target) => (
                    <option
                      key={`target-${target?.targetId}`}
                      value={target?.targetId}
                      label={target?.targetName}
                    />
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {props.errors.targetId}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Chuyên mục</Form.Label>
                <Form.Select
                  name="categoryId"
                  isInvalid={
                    props.errors.categoryId && props.touched.categoryId
                  }
                  onChange={props.handleChange}
                  value={props?.values?.categoryId}
                >
                  <option value="">-- Chọn chuyên mục --</option>
                  {listCategories.map((category) => (
                    <option
                      key={`category-${category.categoryId}`}
                      value={category.categoryId}
                      label={category.categoryName}
                    />
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {props.errors.categoryId}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Ảnh bìa mục tiêu</Form.Label>
                <Form.Control
                  type="hidden"
                  name="topicAvatar"
                  isInvalid={
                    props.errors.topicAvatar && props.touched.topicAvatar
                  }
                  onChange={props.handleChange}
                  value={props.values.topicAvatar}
                />
                <Field
                  name="file"
                  render={({ field }) => (
                    <label className="mx-3" style={{ cursor: "pointer" }}>
                      <FontAwesomeIcon
                        icon={faCloudUploadAlt}
                        className="me-2"
                      />
                      <input
                        type="file"
                        accept="image/*"
                        {...field}
                        onChange={(e) =>
                          handleFileUpload(
                            e,
                            props.setFieldValue,
                            "topicAvatar"
                          )
                        }
                        className="d-none"
                      />
                    </label>
                  )}
                />
                {/*{props.values.topicAvatar && (*/}
                {/*    <div style={{ backgroundImage: `url('${props?.values?.topicAvatar}')`, backgroundSize: 'cover', height: '300px', backgroundPosition: 'center' }}></div>*/}
                {/*)}*/}

                <div>
                  {props.values.topicAvatar && (
                    <img
                      src={props.values.topicAvatar}
                      style={{ height: 300, width: "auto" }}
                    />
                  )}
                </div>
                <Form.Control.Feedback type="invalid">
                  {props.errors.topicAvatar}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Ảnh thumbnail</Form.Label>
                <Form.Control
                  type="hidden"
                  name="topicThumbnail"
                  isInvalid={
                    props.errors.topicThumbnail && props.touched.topicThumbnail
                  }
                  onChange={props.handleChange}
                  value={props.values.topicThumbnail}
                />
                <Field
                  name="file"
                  render={({ field }) => (
                    <label className="mx-3" style={{ cursor: "pointer" }}>
                      <FontAwesomeIcon
                        icon={faCloudUploadAlt}
                        className="me-2"
                      />
                      <input
                        type="file"
                        accept="image/*"
                        {...field}
                        onChange={(e) =>
                          handleFileUpload(
                            e,
                            props.setFieldValue,
                            "topicThumbnail"
                          )
                        }
                        className="d-none"
                      />
                    </label>
                  )}
                />
                {/*{props.values.topicThumbnail && (*/}
                {/*    <div style={{ backgroundImage: `url('${props?.values?.topicThumbnail}')`, backgroundSize: 'cover', height: '300px', backgroundPosition: 'center' }}></div>*/}
                {/*)}*/}

                <div>
                  {props.values.topicThumbnail && (
                    <img
                      src={props.values.topicThumbnail}
                      style={{ height: 300, width: "auto" }}
                    />
                  )}
                </div>
                <Form.Control.Feedback type="invalid">
                  {props.errors.topicThumbnail}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Ảnh Icon</Form.Label>
                <Form.Control
                  type="hidden"
                  name="topicIcon"
                  isInvalid={props.errors.topicIcon && props.touched.topicIcon}
                  onChange={props.handleChange}
                  value={props.values.topicIcon}
                />
                <Field
                  name="file"
                  render={({ field }) => (
                    <label className="mx-3" style={{ cursor: "pointer" }}>
                      <FontAwesomeIcon
                        icon={faCloudUploadAlt}
                        className="me-2"
                      />
                      <input
                        type="file"
                        accept="image/*"
                        {...field}
                        onChange={(e) =>
                          handleFileUpload(e, props.setFieldValue, "topicIcon")
                        }
                        className="d-none"
                      />
                    </label>
                  )}
                />
                {/*{props.values.topicIcon && (*/}
                {/*    <div style={{ backgroundImage: `url('${props?.values?.topicIcon}')`, backgroundSize: 'cover', height: '300px', backgroundPosition: 'center' }}></div>*/}
                {/*)}*/}

                <div>
                  {props.values.topicIcon && (
                    <img
                      src={props.values.topicIcon}
                      style={{ height: 300, width: "auto" }}
                    />
                  )}
                </div>
                <Form.Control.Feedback type="invalid">
                  {props.errors.topicIcon}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Nội dung</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  name="topicContent"
                  isInvalid={
                    props.errors.topicContent && props.touched.topicContent
                  }
                  onChange={props.handleChange}
                  value={props.values.topicContent}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Số thứ tự</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nhập số thứ tự"
                  name="priority"
                  isInvalid={props.errors.priority && props.touched.priority}
                  onChange={props.handleChange}
                  value={props.values.priority}
                />
                <Form.Control.Feedback type="invalid">
                  {props.errors.priority}
                </Form.Control.Feedback>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                className="text-gray ms-auto"
                type="submit"
              >
                {activeItem.topicId > 0 ? `Cập nhật` : `Tạo chủ đề`}
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default TopicForm;
