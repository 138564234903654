import React from "react";
import { Modal, Button, FormControl } from "@themesberg/react-bootstrap";
import { Row, Col, Input } from "antd";
import _ from "lodash";
import FileService from "../../services/file.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import Dropzone from "react-dropzone";
import Select from "react-select";

import {
  BABY_CARES_PERIOD,
  BABY_CARES_POST_FORMAT,
  BABY_CARES_POST_TYPE,
  PORTRAIT_ORIENTATION_LANDSCAPE,
} from "../../contains/common";
import { toast } from "react-toastify";
import BabyCaresService from "../../services/baby-cares.service";
import ProfessionalsService from "../../services/professionals.service";
import Editor from "../../components/Editor";
import ReactHlsPlayer from "react-hls-player";

export default class ModalPost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bodyData: {},
      todoLists: [],
    };
    this.timeOut = "";
    this.fileService = new FileService();
  }

  componentDidMount() {
    this.getListProfessions();
    this.getTopics();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(prevProps.dataDetail) !==
      JSON.stringify(this.props.dataDetail)
    ) {
      const { dataDetail = {} } = this.props;

      let curFormat = BABY_CARES_POST_FORMAT.find(
        (x) => x.id === dataDetail?.postFormat
      );
      let curType = BABY_CARES_POST_TYPE.find(
        (x) => x.code === dataDetail?.type
      );
      let curPeriod = BABY_CARES_PERIOD.find(
        (x) => x.id === dataDetail?.periodDevelopment
      );

      let newObj = {
        ...dataDetail,
        postName: dataDetail?.name,
        postFormat: curFormat
          ? { value: curFormat?.id, label: curFormat?.name }
          : "",
        babyCareTopic: {
          value: dataDetail?.babyCareRoadmapTopic?.id,
          label: dataDetail?.babyCareRoadmapTopic?.name,
        },
        postType: curType ? { value: curType?.code, label: curType?.name } : "",
        periodDevelopment: curPeriod
          ? { value: curPeriod?.id, label: curPeriod?.name }
          : "",
        professional: {
          value: dataDetail?.professional?.id,
          label: dataDetail?.professional?.name,
        },
        attachments: [],
      };

      let todoArr = [];
      if (
        Array.isArray(dataDetail?.attachments) &&
        dataDetail?.attachments.length > 0
      ) {
        if (curFormat.id === 2) {
          // video
          newObj.attachments = dataDetail?.attachments;
        } else if (curFormat.id === 3) {
          // playlist
          todoArr = dataDetail?.attachments;
        }
      }

      this.setState({
        bodyData: newObj,
        content: dataDetail?.content,
        todoLists: todoArr,
        originalData: _.cloneDeep(newObj),
      });
    }
  }

  onSubmit = async () => {
    const {
      bodyData = {},
      originalData = {},
      content = "",
      todoLists = [],
    } = this.state;
    const { getData } = this.props;

    if (!bodyData?.postName || !bodyData?.postName.trim()) {
      toast.error("Vui lòng nhập tên bài viết");
      return;
    }

    if (!bodyData?.postFormat) {
      toast.error("Vui lòng chọn định dạng bài viết");
      return;
    }
    if (!bodyData?.periodDevelopment) {
      toast.error("Vui lòng chọn giai đoạn");
      return;
    }
    if (!bodyData?.babyCareTopic) {
      toast.error("Vui lòng chọn chủ đề");
      return;
    }
    if (!bodyData?.postType) {
      toast.error("Vui lòng chọn loại bài viết");
      return;
    }
    if (!bodyData?.image) {
      toast.error("Vui lòng nhập ảnh");
      return;
    }
    if (!bodyData?.ordinalNumber) {
      toast.error("Vui lòng nhập số thứ tự");
      return;
    }

    if (bodyData?.postFormat?.value === 1) {
      // 1 bài viết
      if (!bodyData?.readingTime) {
        toast.error("Vui lòng thêm thời gian");
        return;
      }
    } else {
      if (!bodyData?.watchingTime) {
        toast.error("Vui lòng nhập thời lượng");
        return;
      }
    }

    // if (!bodyData?.content || !bodyData?.content.trim()) {
    //     toast.error("Vui lòng nhập nội dung bài viết")
    //     return
    // }

    let attachments = Array.isArray(bodyData.attachments)
      ? bodyData.attachments.map((x, y) => {
          return {
            videoId: x.videoId,
            videoIdStr: x.videoIdStr,
            contentType: x.contentType,
          };
        })
      : [];

    if (bodyData?.postFormat?.value === 3) {
      if (todoLists.length > 0) {
        let checkDuplicateName = false;
        let checkWatchingTime = false;
        attachments = todoLists.map((x) => {
          if (
            todoLists.filter(
              (y) => y?.videoName?.trim() === x?.videoName?.trim()
            ).length > 1
          ) {
            checkDuplicateName = true;
          }
          if (todoLists.find((y) => !y?.watchingTime)) {
            checkWatchingTime = true;
          }

          return {
            videoId: x.videoId,
            videoIdStr: x.videoIdStr,
            contentType: x.contentType,
            videoName: x.videoName.trim(),
            watchingTime: parseFloat(x.watchingTime),
            position: x.position,
          };
        });

        if (checkDuplicateName) {
          toast.error("Tên video bị trùng");
          return;
        }
        if (checkWatchingTime) {
          toast.error("Cần nhập thời lượng của video");
          return;
        }
      }
    }

    if (bodyData?.postFormat?.value !== 1) {
      // video và playlist
      let arr = attachments.filter((x) => x.videoId);
      if (arr.length === 0) {
        toast.error("Cần tải lên video.");
        return;
      }
    }

    let body = {
      postName: bodyData?.postName.trim(),
      summary: bodyData?.summary ? bodyData?.summary.trim() : "",
      periodDevelopment: bodyData?.periodDevelopment?.value,
      babyCareTopicId: bodyData?.babyCareTopic?.value,
      content: content.trim(),
      ordinalNumber: parseFloat(bodyData?.ordinalNumber),
      readingTime: parseFloat(bodyData?.readingTime),
      postType: bodyData?.postType.value,
      postFormat: bodyData?.postFormat?.value,
      professionalId: bodyData?.professional?.value,
      image: bodyData?.image,
      attachments: attachments,
      watchingTime: parseFloat(bodyData?.watchingTime),
    };

    try {
      if (bodyData?.id || bodyData?.id === 0) {
        await BabyCaresService.updatePost(bodyData?.id, body);
        toast.success("Sửa thành công");
      } else {
        await BabyCaresService.createPost(body);
        toast.success("Tạo thành công");
      }
      this.onToggle();
      getData();
    } catch (error) {
      let msg = "";
      switch (error?.message) {
        case "baby_care_post_duplicate":
          msg = "Bài viết đã tồn tại";
          break;
        case "ordinal_number_duplicate":
          msg = "Số thứ tự đã tồn tại";
          break;
        case "baby_care_post_not_found":
          msg = "Bài viết không tồn tại";
          break;
        case "popup_not_found":
          msg = "Popup không tồn tại";
          break;
        case "popup_duplicate":
          msg = "Popup đã tồn tại";
          break;
        default:
          break;
      }

      toast.error(
        msg || "Có lỗi xảy ra vui lòng liên hệ kỹ thuật để được hỗ trợ."
      );
    }
  };

  getTopics = async (periodDevelopment) => {
    let query = "";
    if (periodDevelopment) {
      query = `?periodDevelopment=${periodDevelopment?.value}`;
    }
    const resp = await BabyCaresService.listTopics(query);
    this.setState({
      listTopics: resp.data,
    });
  };

  handleFileUploadByPostsFormat = async (files) => {
    // console.log('vào handleFileUploadByPostsFormat')
    const file = files[0];
    let fileSize = file.size / 1024 / 1024; // in MiB
    if (fileSize > 500) {
      return alert("Dung lượng file lớn hơn 500 MiB, vui lòng chọn file khác.");
    }

    let { bodyData = {} } = this.state;

    let body = {
      objectType: "babyCares",
      displayType: 1,
    };
    // objectType: "posts"
    // objectId: ID bài viết
    // displayType: 1(thumbnail), 2( icon), 3(Cover), 4(video), 5(Audio), 6(Infographic), 7(avatar)

    try {
      const resp = await this.fileService.uploadImage(file, body);
      if (resp?.linkUrl?.length > 0) {
        bodyData.image = resp.linkUrl[0];
      }
    } catch (error) {
      console.log(error);
    }

    this.setState({ bodyData: bodyData });
  };

  handleFileUpload = async (files) => {
    // console.log('vào handleFileUploadByPostsFormat')
    const file = files[0];
    let fileSize = file.size / 1024 / 1024; // in MiB
    if (fileSize > 500) {
      return alert("Dung lượng file lớn hơn 500 MiB, vui lòng chọn file khác.");
    }

    let { bodyData = {} } = this.state;

    let body = {
      objectType: "babyCares",
      displayType: 4,
      orientation: PORTRAIT_ORIENTATION_LANDSCAPE,
    };
    // objectType: "posts"
    // objectId: ID bài viết
    // displayType: 1(thumbnail), 2( icon), 3(Cover), 4(video), 5(Audio), 6(Infographic), 7(avatar)
    try {
      const resp = await this.fileService.uploadImage(file, body);
      // BABY_CARES_POST_FORMAT = [
      //     {
      //         id: 1,
      //         code: '1',
      //         name: 'Bài viết'
      //     },
      //     {
      //         id: 2,
      //         code: '2',
      //         name: 'Video'
      //     },
      //     {
      //         id: 3,
      //         code: '3',
      //         name: 'Playlist'
      //     },
      // ]
      let data = [];
      if (resp?.linkUrl?.length > 0) {
        setTimeout(() => {
          data.push({
            videoId: resp?.attachId,
            videoIdStr: resp?.attachIdStr,
            videoName: resp.fileName,
            ...resp,
          });
          bodyData.attachments = data;
          this.setState({
            bodyData,
          });
        }, 10000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleFileUploadSegment = async (files, id) => {
    // console.log('vào handleFileUploadByPostsFormat')
    const file = files[0];
    let fileSize = file.size / 1024 / 1024; // in MiB
    if (fileSize > 500) {
      return alert("Dung lượng file lớn hơn 500 MiB, vui lòng chọn file khác.");
    }

    let { todoLists = [] } = this.state;
    const curr = todoLists.find(
      (x) => (x.videoId && x.videoId === id) || x.id === id
    );

    let body = {
      objectType: "babyCares",
      displayType: 4,
      orientation: PORTRAIT_ORIENTATION_LANDSCAPE,
    };
    // objectType: "posts"
    // objectId: ID bài viết
    // displayType: 1(thumbnail), 2( icon), 3(Cover), 4(video), 5(Audio), 6(Infographic), 7(avatar)
    try {
      const resp = await this.fileService.uploadImage(file, body);
      let data = {};

      if (resp?.linkUrl?.length > 0) {
        setTimeout(() => {
          data = {
            ...curr,
            ...resp,
            videoId: resp?.attachId,
            videoIdStr: resp?.attachIdStr,
            videoName: curr?.videoName || resp.fileName,
          };
          const findIndex = todoLists.findIndex(
            (x) => (x.videoId && x.videoId === id) || x.id === id
          );
          todoLists[findIndex] = data;

          this.setState({
            todoLists,
          });
        }, 10000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  onToggle = () => {
    const { toggle } = this.props;
    this.setState(
      {
        bodyData: {},
        todoLists: [],
        content: "",
      },
      () => {
        toggle();
      }
    );
  };

  getListProfessions = async (searchString) => {
    let queryString = `?page=0&size=${100}&sort=createdDate:desc${
      searchString ? `&name=${encodeURIComponent(searchString)}` : ""
    }`;

    const resp = await ProfessionalsService.listProfessions(queryString);
    this.setState({ professionals: resp.data || [] });
  };

  onInputSelectChange = (value) => {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
    }
    this.timeOut = setTimeout(() => {
      this.getListProfessions(value);
    }, 500);
  };

  renderByType = () => {
    const { bodyData = {}, content = "" } = this.state;
    switch (bodyData?.postFormat?.value) {
      case 2:
        let attachLink = Array.isArray(bodyData?.attachments)
          ? bodyData?.attachments[0]?.linkUrl[0]
          : "";
        let contentType = Array.isArray(bodyData?.attachments)
          ? bodyData?.attachments[0]?.contentType
          : "";

        return (
          <div>
            <div className="modal-row d-flex align-items-start">
              <div className="modal-label">Mô tả:</div>

              <Input.TextArea
                value={bodyData.summary}
                onChange={(e) =>
                  this.setState({
                    bodyData: { ...bodyData, summary: e.target.value },
                  })
                }
                placeholder="Nhập mô tả"
                rows={3}
              />
            </div>

            <div className="modal-row d-flex align-items-center">
              <div className="modal-label">Thời lượng (phút):</div>

              <FormControl
                value={bodyData.watchingTime}
                onChange={(e) =>
                  this.setState({
                    bodyData: { ...bodyData, watchingTime: e.target.value },
                  })
                }
                placeholder="Nhập thời lượng"
              />
            </div>

            <div className="modal-row d-flex align-items-center">
              <div className="modal-label">Attach Video:</div>
              <Dropzone
                accept={"video/*"}
                multiple={false}
                onDrop={(acceptedFiles) => this.handleFileUpload(acceptedFiles)}
              >
                {({ getRootProps, getInputProps }) => (
                  <section key={"dropzone"}>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <FontAwesomeIcon
                        icon={Icon.faCloudUploadAlt}
                        className="me-2"
                      />
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>

            <div className="modal-row d-flex align-items-center">
              <div className="modal-label"></div>
              {!!attachLink && contentType.indexOf("video/") >= 0 && (
                <ReactHlsPlayer
                  src={attachLink}
                  autoPlay={false}
                  controls={true}
                  width="500"
                  height="auto"
                />
              )}
            </div>
          </div>
        );
      case 3:
        return this.renderToDoList();
      default:
        return (
          <div>
            <div className="modal-row d-flex align-items-start">
              <div className="modal-label">Mô tả:</div>

              <Input.TextArea
                value={bodyData.summary}
                onChange={(e) =>
                  this.setState({
                    bodyData: { ...bodyData, summary: e.target.value },
                  })
                }
                placeholder="Nhập mô tả"
                rows={3}
              />
            </div>
            <div className="modal-row d-flex align-items-start">
              <div className="modal-label">Nội dung bài viết:</div>
              <div className={"editor-container"}>
                <Editor
                  key={"editor1"}
                  ref={(ref) => (this.editor1 = ref)}
                  style={{ height: 250 }}
                  value={content || ""}
                  onChange={(e) => {
                    this.setState({ content: e });
                  }}
                />
              </div>
            </div>
          </div>
        );
    }
  };

  renderToDoList = () => {
    const { todoLists = [], bodyData = {} } = this.state;

    return (
      <div>
        <div className="modal-row d-flex align-items-start">
          <div className="modal-label">Mô tả:</div>

          <Input.TextArea
            value={bodyData.summary}
            onChange={(e) =>
              this.setState({
                bodyData: { ...bodyData, summary: e.target.value },
              })
            }
            placeholder="Nhập mô tả"
            rows={3}
          />
        </div>

        <div className="modal-row d-flex align-items-center">
          <div className="modal-label">Thời lượng (phút):</div>

          <FormControl
            value={bodyData.watchingTime}
            onChange={(e) =>
              this.setState({
                bodyData: { ...bodyData, watchingTime: e.target.value },
              })
            }
            placeholder="Nhập thời lượng"
          />
        </div>

        <div className="modal-row d-flex align-items-center">
          <div className="modal-label">Video segment</div>

          <div
            className="cursor-pointer"
            onClick={() => {
              todoLists.push({
                id:
                  todoLists.length > 0
                    ? (todoLists[todoLists.length - 1].videoId ||
                        todoLists[todoLists.length - 1].id) + 1
                    : 1,
                videoName: "",
                videoId: "",
                videoIdStr: "",
                contentType: "",
                position:
                  todoLists.length > 0
                    ? (todoLists[todoLists.length - 1].videoId ||
                        todoLists[todoLists.length - 1].id) + 1
                    : 1,
              });
              this.setState({ todoLists });
            }}
          >
            <FontAwesomeIcon icon={Icon.faPlusCircle} className="me-2" />
          </div>
        </div>
        <div className="modal-row d-flex align-items-center">
          <div className="modal-label"></div>
          <div className="video-segments-container">
            {todoLists.map((item, index) => {
              let attachLink = Array.isArray(item?.linkUrl)
                ? item?.linkUrl[0]
                : "";
              let contentType = item?.contentType || "";

              return (
                <div className="video-segments-item" key={index}>
                  <div className="modal-row d-flex align-items-center">
                    <div className="modal-label-small">
                      {index + 1}. Segment name:
                    </div>

                    <FormControl
                      value={item.videoName}
                      onChange={(e) => {
                        let curr = todoLists[index];
                        curr.videoName = e.target.value;
                        todoLists[index] = curr;
                        this.setState({ todoLists });
                      }}
                      placeholder="Nhập tên video"
                    />
                  </div>
                  <div className="modal-row d-flex align-items-center">
                    <div className="modal-label-small">Thời lượng (s):</div>

                    <FormControl
                      value={item.watchingTime}
                      onChange={(e) => {
                        let curr = todoLists[index];
                        curr.watchingTime = e.target.value;
                        todoLists[index] = curr;
                        this.setState({ todoLists });
                      }}
                      placeholder="Nhập thời lượng"
                    />
                  </div>
                  <div className="modal-row d-flex align-items-center">
                    <div className="modal-label-small">Attach file:</div>
                    <div className="d-flex align-items-center justify-content-between width-100-percent">
                      <Dropzone
                        accept={"video/*"}
                        multiple={false}
                        onDrop={(acceptedFiles) =>
                          this.handleFileUploadSegment(
                            acceptedFiles,
                            item.videoId || item.id
                          )
                        }
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section key={"dropzone"}>
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <FontAwesomeIcon
                                icon={Icon.faCloudUploadAlt}
                                className="me-2"
                              />
                            </div>
                          </section>
                        )}
                      </Dropzone>

                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          let arr = todoLists.filter(
                            (x) =>
                              (x.videoId && x.videoId !== item.videoId) ||
                              x.id !== item.id
                          );
                          this.setState({ todoLists: arr });
                        }}
                      >
                        <FontAwesomeIcon icon={Icon.faTrash} className="me-2" />
                      </div>
                    </div>
                  </div>

                  <div className="modal-row d-flex align-items-center">
                    <div className="modal-label-small"></div>
                    {!!attachLink && contentType.indexOf("video/") >= 0 && (
                      <ReactHlsPlayer
                        src={attachLink}
                        autoPlay={false}
                        controls={true}
                        width="500"
                        height="auto"
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { modalStatus } = this.props;
    const { listTopics = [], professionals = [], bodyData = {} } = this.state;
    // console.log('bodyData', bodyData)
    // let curFormat = BABY_CARES_POST_FORMAT.find(x => x.id === bodyData?.postFormat)
    // let curPeriod = BABY_CARES_PERIOD.find(x => x.id === bodyData?.periodDevelopment)
    // let curTopic = listTopics.find(x => x.id === bodyData?.babyCareTopicId)
    // let curType = BABY_CARES_POST_TYPE.find(x => x.code === bodyData?.postType)
    // let curProfessional = professionals.find(x => x.id === bodyData?.professionalId)

    return (
      <Modal
        dialogClassName="modal-90w"
        as={Modal.Dialog}
        show={modalStatus}
        onHide={this.onToggle}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {bodyData?.id || bodyData?.id === 0
              ? "Chỉnh sửa bài viết"
              : "Thêm mới bài viết"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row gutter={20}>
            <Col span={12}>
              <div className="modal-row d-flex align-items-center">
                <div className="modal-label">Tên bài viết:</div>

                <FormControl
                  value={bodyData.postName}
                  onChange={(e) =>
                    this.setState({
                      bodyData: { ...bodyData, postName: e.target.value },
                    })
                  }
                  placeholder="Nhập tên bài viết"
                />
              </div>
            </Col>
            <Col span={12}>
              <div className="modal-row d-flex align-items-center">
                <div className="modal-label">Định dạng bài viết:</div>

                <Select
                  className="custom-react-select"
                  placeholder="Chọn định dạng bài viết"
                  options={BABY_CARES_POST_FORMAT.map((x) => {
                    return { value: x.id, label: x.name };
                  })}
                  value={bodyData?.postFormat}
                  isMulti={false}
                  onChange={(e) => {
                    this.setState({ bodyData: { ...bodyData, postFormat: e } });
                  }}
                  isClearable={true}
                  backspaceRemovesValue={true}
                />
              </div>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <div className="modal-row d-flex align-items-center">
                <div className="modal-label">Giai đoạn:</div>

                <Select
                  className="custom-react-select"
                  placeholder="Chọn giai đoạn"
                  options={BABY_CARES_PERIOD.map((x) => {
                    return { value: x.id, label: x.name };
                  })}
                  value={bodyData?.periodDevelopment}
                  isMulti={false}
                  onChange={(e) => {
                    this.setState(
                      { bodyData: { ...bodyData, periodDevelopment: e } },
                      () => {
                        this.getTopics(e);
                      }
                    );
                  }}
                  isClearable={true}
                  backspaceRemovesValue={true}
                />
              </div>
            </Col>

            <Col span={12}>
              <div className="modal-row d-flex align-items-center">
                <div className="modal-label">Chủ đề:</div>

                <Select
                  isDisabled={!bodyData?.periodDevelopment}
                  className="custom-react-select"
                  placeholder="Chọn chủ đề"
                  options={listTopics.map((x) => {
                    return { value: x.id, label: x.name };
                  })}
                  value={bodyData?.babyCareTopic}
                  isMulti={false}
                  onChange={(e) => {
                    this.setState({
                      bodyData: { ...bodyData, babyCareTopic: e },
                    });
                  }}
                  isClearable={true}
                  backspaceRemovesValue={true}
                />
              </div>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col span={16}>{this.renderByType()}</Col>

            <Col span={8}>
              <div className="modal-row">
                <div className="d-flex align-items-center">
                  <div className="modal-label-small">Ảnh bìa bài viết:</div>
                  <Dropzone
                    accept={"image/*"}
                    multiple={false}
                    onDrop={(acceptedFiles) =>
                      this.handleFileUploadByPostsFormat(acceptedFiles)
                    }
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section key={"dropzone"}>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <FontAwesomeIcon
                            icon={Icon.faCloudUploadAlt}
                            className="me-2"
                          />
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
                <div className="d-flex align-items-center">
                  <div className="modal-label-small" />
                  <div>
                    {bodyData.image && (
                      <img
                        src={bodyData.image}
                        style={{ maxHeight: 300, maxWidth: "100%" }}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="modal-row d-flex align-items-center">
                <div className="modal-label-small">Số thứ tự:</div>

                <FormControl
                  value={bodyData.ordinalNumber}
                  onChange={(e) =>
                    this.setState({
                      bodyData: { ...bodyData, ordinalNumber: e.target.value },
                    })
                  }
                  placeholder="Số thứ tự"
                />
              </div>

              {bodyData?.postFormat?.value === 1 && (
                <div className="modal-row d-flex align-items-center">
                  <div className="modal-label-small">Thời gian đọc (m):</div>

                  <FormControl
                    value={bodyData.readingTime}
                    onChange={(e) =>
                      this.setState({
                        bodyData: { ...bodyData, readingTime: e.target.value },
                      })
                    }
                    placeholder="Thời gian đọc"
                  />
                </div>
              )}

              <div className="modal-row d-flex align-items-center">
                <div className="modal-label-small">Loại bài viết:</div>
                <Select
                  className="custom-react-select"
                  placeholder="Chọn loại bài viết"
                  options={BABY_CARES_POST_TYPE.map((x) => {
                    return { value: x.code, label: x.name };
                  })}
                  value={bodyData?.postType}
                  isMulti={false}
                  onChange={(e) => {
                    this.setState({ bodyData: { ...bodyData, postType: e } });
                  }}
                  isClearable={true}
                  backspaceRemovesValue={true}
                />
              </div>

              <div className="modal-row d-flex align-items-center">
                <div className="modal-label-small">Chuyên gia:</div>

                {professionals && (
                  <Select
                    className="custom-react-select"
                    placeholder="Chọn chuyên gia"
                    onInputChange={this.onInputSelectChange}
                    options={professionals.map((x) => {
                      return { value: x.id, label: x.name };
                    })}
                    value={bodyData?.professional}
                    isMulti={false}
                    onChange={(e) => {
                      this.setState({
                        bodyData: { ...bodyData, professional: e },
                      });
                    }}
                    isClearable={true}
                    backspaceRemovesValue={true}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.onToggle}>
            Đóng
          </Button>
          <Button variant="primary" onClick={() => this.onSubmit()}>
            {bodyData?.id || bodyData?.id === 0 ? "Lưu" : "Tạo"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
