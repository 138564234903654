import APIService from "./customAPI.services";

class BannersService extends APIService {
  constructor() {
    super();
  }

  async listBanners(query) {
    return await this.request("GET", `banners${query || ""}`, {});
  }

  async listPopups(query) {
    return await this.request("GET", `popups${query || ""}`, {});
  }

  async getBanner(id) {
    return await this.request("GET", `banners/${id}`, {});
  }

  async getPopup(id) {
    return await this.request("GET", `popups/${id}`, {});
  }

  async createBanner(body) {
    return await this.request("POST", `banners`, body);
  }

  async createPopup(body) {
    return await this.request("POST", `popups`, body);
  }

  async updateBanner(id, body) {
    return await this.request("PATCH", `banners/${id}`, body);
  }

  async updatePopup(id, body) {
    return await this.request("PATCH", `popups/${id}`, body);
  }

  async categoryPage() {
    return await this.request("GET", `categories/pages`, {});
  }
}
const bannersService = new BannersService();
export default bannersService;
