import { Typography } from "antd";

type Props = {
  title: string;
};

const TitleColumn = (props: Props) => {
  const { title } = props;
  return (
    <Typography.Text ellipsis={true} style={styles.title as any}>
      {title}
    </Typography.Text>
  );
};

export { TitleColumn };

const styles = {
  title: {
    maxWidth: 150,
    flexWrap: "wrap",
    wordWrap: "break-word",
    fontWeight: "bold",
  },
};
