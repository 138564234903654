import React, { useEffect, useRef, useState } from "react";
import { Routes } from "../../routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import { Button, Card, Modal, notification, Table, Pagination } from "antd";
import { Link } from "react-router-dom";
import { Filter } from "./Filter";
import notificationService from "../../services/notification.service";
import lodash from "lodash";
import { notificationObjectService } from "../../services/notification-object.service";
import moment from "moment";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import ActionsBox from "./ActionsBox";

export const Notification = () => {
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationObjects, setNotificationObjects] = useState([]);

  const filters = useRef({});

  const getNotifications = (filter = {}) => {
    setLoading(true);
    notificationService
      .filter({ sort: "createdDate:desc", ...filter })
      .then((response) => {
        setNotifications(response.data);
        setTotal(lodash.get(response.headers, "x-total-count"));
      })
      .catch(() => setNotifications([]))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getNotifications();
    notificationObjectService
      .filter({ limit: 1000 })
      .then((response) => {
        setNotificationObjects(response.data);
      })
      .catch(() => setNotificationObjects([]));
  }, []);

  const columns = [
    {
      title: "Tiêu đề",
      key: "title",
      render: (row: any) => {
        return <div>{row.title}</div>;
      },
    },
    {
      title: "Hiển thị",
      key: "displayType",
      render: (row: any) => {
        if (Array.isArray(row.displayType.split(","))) {
          return lodash
            .map(row.displayType.split(","), (value) => {
              switch (value) {
                case "onscreen":
                  return "Ngoài màn hình";
                case "inapp":
                  return "In app";
                default:
                  return "";
              }
            })
            .join(", ");
        }
        return "--";
      },
    },
    {
      title: "Lặp lại",
      key: "interval",
      render: (row: any) => {
        return (
          <div>
            {row.interval} {row.repetitionInterval}
          </div>
        );
      },
    },
    {
      title: "Đối tượng nhận",
      key: "customerGroupDefinitionId",
      render: (row: any) => {
        const notificationObject = notificationObjects.find(
          (item: any) => item.id === row.customerGroupDefinitionId
        );
        return <div>{lodash.get(notificationObject, "name")}</div>;
      },
    },
    {
      title: "Ngày tạo",
      key: "createdDate",
      render: (row: any) =>
        row.createdDate
          ? moment(row.createdDate).format("YYYY-MM-DD HH:mm:ss")
          : "--",
    },
    {
      title: "Thao tác",
      key: "actions",
      render: (row: any) => {
        return (
          <ActionsBox
            item={row}
            handleRemoveNotification={handleRemoveNotification}
          />
        );
      },
    },
  ];

  const handleRemoveNotification = (item: any) => {
    Modal.confirm({
      title: "Bạn có chắc chắn muốn xoá thông báo này?",
      okButtonProps: {
        loading: loading,
        disabled: loading,
      },
      cancelButtonProps: {
        loading: loading,
        disabled: loading,
      },
      onOk: () => {
        setLoading(true);
        notificationService
          .delete(item.id)
          .then(() => {
            notification.success({
              message: "Xoá thông báo thành công",
            });
            getNotifications();
          })
          .catch(() => {
            notification.error({
              message: "Xoá thông báo không thành công",
            });
          })
          .finally(() => setLoading(false));
      },
    });
  };

  const handleSearch = (filter: any) => {
    filters.current = filter;
    getNotifications(filter);
  };

  const onShowSizeChange = (page: number, pageSize: number) => {
    console.log("onShowSizeChange", filters.current);
    getNotifications({ ...filters.current, page: page - 1, size: pageSize });
  };

  return (
    <div>
      {/* <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item to={Routes.DashboardOverview.path}>
              <FontAwesomeIcon icon={Icon.faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item to={Routes.Notification.path}>
              <FontAwesomeIcon icon={Icon.faBell} /> Danh sách thông báo
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div> */}

      <Filter onSearch={handleSearch} />

      <Card
        title={"Danh sách thông báo"}
        extra={
          <Button type={"primary"}>
            <Link to={Routes.NotificationCreate.path}>Thêm thông báo</Link>
          </Button>
        }
      >
        <Table
          columns={columns}
          dataSource={notifications}
          rowKey={"id"}
          pagination={false}
        />
        <Pagination
          style={{ marginTop: 12 }}
          showSizeChanger
          onChange={onShowSizeChange}
          onShowSizeChange={onShowSizeChange}
          defaultCurrent={1}
          total={total}
          showTotal={(total) => `Tổng số ${total}`}
        />
      </Card>
    </div>
  );
};
