import APIService from "./api.services";

class PackageService extends APIService {
  constructor() {
    super();
    this.modulePath = "package";
  }
}

export default PackageService;
