import React from "react";
import { defaultCustomerAvatar } from "../../contains/common";
import moment from "moment";
import { Button } from "antd";
import { SketchOutlined } from "@ant-design/icons";

type Props = {
  isActive: boolean;
  item: any;
  onSelectRoom: any;
  onReceiveContact: any;
  showBtn: boolean;
};

export const RoomItem = (props: Props) => {
  const { isActive, item, onSelectRoom, onReceiveContact, showBtn } = props;

  const lastMessage =
    item?.latestMessage?.content?.length > 20
      ? item?.latestMessage?.content.substring(0, 20) + "..."
      : item?.latestMessage?.content;

  const startDate =
    moment(moment().format("YYYY-MM-DD 00:00:01")).format("X") * 1000;
  const addedTimestamp = item?.latestMessage?.addedTimestamp;

  const { fromCustomer, staff } = item?.latestMessage;
  const { id, fullName } = item?.customer;
  return (
    <div onClick={onSelectRoom} className={"room-container"}>
      <div className={isActive ? "room-active" : "room-normal"}>
        <div className={"room-avatar room-flex"}>
          <img
            src={item.customer.avatar || defaultCustomerAvatar}
            alt={"img-chat-message"}
            className={"room-avatar"}
          />
          {item.customerIsVip && (
            <SketchOutlined
              style={{
                fontSize: '150%',
                marginLeft: -20
              }}
            />  
          )}
        </div>
        <div className={"room-info"}>
          <p className={"txt-small"}>
            {id} {fullName}
          </p>
          <span className={"txt-time"}>
            {moment(addedTimestamp).format(
              startDate < addedTimestamp ? "HH:mm" : "DD/MM/YYYY"
            )}
          </span>
          <div className={"chat-user-item__message"}>
            {fromCustomer ? fullName : staff}: {lastMessage}
          </div>
          {showBtn && (
            <Button
              onClick={onReceiveContact}
              className={"button-receive-contact"}
            >
              Nhận contact
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
