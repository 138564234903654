import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Modal } from "@themesberg/react-bootstrap";

import { Formik } from "formik";
import * as Yup from "yup";

import { ERROR_MESSAGE } from "../../contains/errors";
import { onCreate, onUpdate, hideModal } from "../../store/promotion";

const PromotionForm = () => {
  const dispatch = useDispatch();
  const isOpenModal = useSelector(({ promotions }) => promotions.isOpenModal);
  const activeItem = useSelector(({ promotions }) => promotions.item);

  const closeModal = () => {
    dispatch(hideModal());
  };

  const onValidate = Yup.object().shape({
    promtionName: Yup.string().required(ERROR_MESSAGE.NEWS_NAME_REQUIRED),
  });

  const onSubmit = async (values) => {
    delete values.target;
    delete values.createdBy;
    delete values.modifiedBy;
    delete values.modifiedDate;
    delete values.createdDate;
    delete values.status;
    delete values.statusStr;
    delete values.isChecked;
    delete values.order;

    if (activeItem.promtionId > 0) {
      await dispatch(onUpdate(values));
    } else {
      await dispatch(onCreate(values));
    }
  };

  return (
    <Modal
      size="xl"
      as={Modal.Dialog}
      centered
      show={isOpenModal}
      onHide={closeModal}
    >
      <Formik
        initialValues={activeItem}
        onSubmit={onSubmit}
        validationSchema={onValidate}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <Modal.Header>
              <Modal.Title className="h6">Khuyến mại</Modal.Title>
              <Button variant="close" aria-label="Close" onClick={closeModal} />
            </Modal.Header>
            <Modal.Body>
              <Form.Group className="mb-3">
                <Form.Label>Tên khuyến mại</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nhập tên khuyến mại"
                  name="promtionName"
                  isInvalid={
                    props.errors.promtionName && props.touched.promtionName
                  }
                  onChange={props.handleChange}
                  value={props.values.promtionName}
                />
                <Form.Control.Feedback type="invalid">
                  {props.errors.promtionName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Nội dung</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="20"
                  name="promtionContent"
                  isInvalid={
                    props.errors.promtionContent &&
                    props.touched.promtionContent
                  }
                  onChange={props.handleChange}
                  value={props.values.promtionContent}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                className="text-gray ms-auto"
                type="submit"
              >
                {activeItem.promtionId > 0 ? `Cập nhật` : `Tạo khuyến mại`}
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default PromotionForm;
