import APIService from "./customAPI.services";

class WeaningFoodsService extends APIService {
  constructor() {
    super();
  }

  async listFoods(query) {
    return await this.request("GET", `weaning-foods${query || ""}`, {});
  }

  async listDishes(query) {
    return await this.request("GET", `weaning-dishes${query || ""}`, {});
  }

  async getFood(id) {
    return await this.request("GET", `weaning-foods/${id}`, {});
  }

  async getDishes(id) {
    return await this.request("GET", `weaning-dishes/${id}`, {});
  }

  async deleteFoods(ids) {
    return await this.request("DELETE", `weaning-foods?foodIds=${ids}`, {});
  }

  async deleteDishes(ids) {
    return await this.request("DELETE", `weaning-dishes?dishIds=${ids}`, {});
  }

  async createFood(body) {
    return await this.request("POST", `weaning-foods`, body);
  }

  async createDishes(body) {
    return await this.request("POST", `weaning-dishes`, body);
  }

  async updateFood(id, body) {
    return await this.request("PUT", `weaning-foods/${id}`, body);
  }

  async updateDishes(id, body) {
    return await this.request("PUT", `weaning-dishes/${id}`, body);
  }

  async getFoodGroups() {
    return await this.request("GET", `weaning-food-groups`, {});
  }

  async getWeaningMethods() {
    return await this.request("GET", `categories/weaning-methods`, {});
  }

  async getWeaningStages() {
    return await this.request("GET", `categories/weaning-stages`, {});
  }

  async getWeaningProcessing() {
    return await this.request("GET", `categories/weaning-processing`, {});
  }
}
const weaningFoodsService = new WeaningFoodsService();
export default weaningFoodsService;
