import React from "react";
import { Modal, Button, FormControl } from "@themesberg/react-bootstrap";
import { Row, Col, Input } from "antd";
import _ from "lodash";
import FileService from "../../services/file.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import Dropzone from "react-dropzone";
import Select from "react-select";

import {
  BABY_CARES_PERIOD,
  PODCAST_TYPE,
  PORTRAIT_ORIENTATION_LANDSCAPE,
} from "../../contains/common";
import { toast } from "react-toastify";
import Editor from "../../components/Editor";
import ReactHlsPlayer from "react-hls-player";
import apiServices from "../../services/podcast.service";
import professionalServices from "../../services/professionals.service";

export default class ModalPost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bodyData: {},
      todoLists: [],
    };
    this.timeOut = "";
    this.fileService = new FileService();
  }

  componentDidMount() {
    this.getAuthors();
    this.getAllTopics();

    const { dataDetail = {} } = this.props;

    if (dataDetail?.id) {
      let newObj = {
        ...dataDetail,
        topic: {
          value: dataDetail?.topic?.id,
          label: dataDetail?.topic?.topicName,
        },
        author: {
          value: dataDetail?.author?.id,
          label: `${dataDetail?.author?.fullName} (${dataDetail?.author?.nickName})`,
        },
        podcastType: dataDetail?.podcastType
          ? { value: dataDetail?.podcastType, label: dataDetail?.podcastType }
          : "",
        image: dataDetail?.image,
        audioId: dataDetail?.audioId,
      };

      this.setState({
        bodyData: newObj,
        originalData: _.cloneDeep(newObj),
      });
    }
  }

  onSubmit = async () => {
    const { bodyData = {} } = this.state;
    const { getData } = this.props;
    if (!bodyData?.name || !bodyData?.name.trim()) {
      toast.error("Vui lòng nhập tên bài viết");
      return;
    }

    if (!bodyData?.image) {
      toast.error("Vui lòng nhập ảnh");
      return;
    }
    if (!bodyData?.audioId) {
      toast.error("Vui lòng nhập file âm thanh");
      return;
    }

    if (!bodyData?.podcastType) {
      toast.error("Vui lòng chọn loại podcast");
      return;
    }
    if (!bodyData?.topic) {
      toast.error("Vui lòng chọn chủ đề");
      return;
    }
    if (!bodyData?.author) {
      toast.error("Vui lòng chọn tác giả");
      return;
    }
    if (!bodyData?.duration) {
      toast.error("Vui lòng nhập thời lượng");
      return;
    }
    if (!bodyData?.description) {
      toast.error("Vui lòng nhập mô tả");
      return;
    }

    let body = {
      name: bodyData?.name.trim(),
      description: bodyData?.description ? bodyData?.description.trim() : "",
      duration: parseFloat(bodyData?.duration),
      podcastType: bodyData?.podcastType.value,
      topicId: bodyData?.topic.value,
      authorId: bodyData?.author.value,
      image: bodyData?.image,
      audioId: bodyData?.audioId,
    };

    if (bodyData?.attachmentAudioId) {
      body.attachmentAudioId = bodyData?.attachmentAudioId;
    }
    if (bodyData?.attachmentImageId) {
      body.attachmentImageId = bodyData?.attachmentImageId;
    }

    try {
      if (bodyData?.id || bodyData?.id === 0) {
        await apiServices.updatePodcast(bodyData?.id, body);
        toast.success("Sửa thành công");
      } else {
        await apiServices.createPodcast(body);
        toast.success("Tạo thành công");
      }
      this.onToggle();
      getData();
    } catch (error) {
      let msg = "";
      switch (error?.message) {
        case "baby_care_post_duplicate":
          msg = "Bài viết đã tồn tại";
          break;
        case "ordinal_number_duplicate":
          msg = "Số thứ tự đã tồn tại";
          break;
        case "baby_care_post_not_found":
          msg = "Bài viết không tồn tại";
          break;
        case "popup_not_found":
          msg = "Popup không tồn tại";
          break;
        case "popup_duplicate":
          msg = "Popup đã tồn tại";
          break;
        default:
          break;
      }

      toast.error(
        msg || "Có lỗi xảy ra vui lòng liên hệ kỹ thuật để được hỗ trợ."
      );
    }
  };

  getAllTopics = async () => {
    let queryString = `?page=${0}&size=${100}&sort=createdDate:desc`;
    const resp = await apiServices.listTopics(queryString);
    this.setState({
      listTopics: resp.data,
      isLoading: false,
    });
  };

  getAuthors = async () => {
    let queryString = `?page=${0}&size=${100}&sort=createdDate:desc`;
    const resp = await apiServices.getAuthor(queryString);
    this.setState({
      listAuthors: resp.data?.body,
      isLoading: false,
    });
  };

  handleFileUpload = async (files, type) => {
    const file = files[0];
    let fileSize = file.size / 1024 / 1024; // in MiB
    if (fileSize > 500) {
      return alert("Dung lượng file lớn hơn 500 MiB, vui lòng chọn file khác.");
    }

    let { bodyData = {} } = this.state;

    let body = {
      objectType: "podcast",
      displayType: type || 1,
    };
    // objectType: "posts"
    // objectId: ID bài viết
    // displayType: 1(thumbnail), 2( icon), 3(Cover), 4(video), 5(Audio), 6(Infographic), 7(avatar)

    try {
      const resp = await this.fileService.uploadImage(file, body);
      if (type === 5) {
        // bodyData.audioObj = resp
        if (resp?.linkUrl?.length > 0) {
          bodyData.audioId = resp.attachIdStr;
          bodyData.attachmentAudioId = resp.attachId;
          bodyData.audioAttachments = [resp];
        }
      } else {
        // bodyData.imageObj = resp
        if (resp?.linkUrl?.length > 0) {
          bodyData.image = resp.attachIdStr;
          bodyData.attachmentImageId = resp.attachId;
          bodyData.imageUrl = resp.linkUrl[0];
        }
      }
    } catch (error) {
      console.log(error);
    }

    this.setState({ bodyData: bodyData });
  };

  onToggle = () => {
    const { toggle } = this.props;
    this.setState(
      {
        bodyData: {},
        todoLists: [],
        content: "",
      },
      () => {
        toggle();
      }
    );
  };

  render() {
    const { modalStatus } = this.props;
    const { listTopics = [], listAuthors = [], bodyData = {} } = this.state;
    const audio = Array.isArray(bodyData?.audioAttachments)
      ? bodyData?.audioAttachments[0]
      : {};
    const audioSrc = Array.isArray(audio?.linkUrl) ? audio?.linkUrl[0] : "";
    const audioName = audio?.fileName;
    return (
      <Modal
        dialogClassName="modal-90w"
        as={Modal.Dialog}
        show={modalStatus}
        onHide={this.onToggle}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {bodyData?.id || bodyData?.id === 0
              ? "Chỉnh sửa podcast"
              : "Thêm mới podcast"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-row d-flex align-items-center">
            <div className="modal-label">Tên Podcast:</div>

            <FormControl
              value={bodyData.name}
              onChange={(e) =>
                this.setState({
                  bodyData: { ...bodyData, name: e.target.value },
                })
              }
              placeholder="Nhập tên podcast"
            />
          </div>

          <div className="modal-row">
            <div className="d-flex align-items-center">
              <div className="modal-label">Ảnh podcast:</div>
              <Dropzone
                accept={"image/*"}
                multiple={false}
                onDrop={(acceptedFiles) => this.handleFileUpload(acceptedFiles)}
              >
                {({ getRootProps, getInputProps }) => (
                  <section key={"dropzone"}>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <FontAwesomeIcon
                        icon={Icon.faCloudUploadAlt}
                        className="me-2"
                      />
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            <div className="d-flex align-items-center">
              <div className="modal-label" />
              <div>
                {bodyData?.imageUrl && (
                  <img
                    src={bodyData?.imageUrl}
                    style={{ maxHeight: 300, maxWidth: "100%" }}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="modal-row">
            <div className="d-flex align-items-center">
              <div className="modal-label">Tải lên file âm thanh:</div>
              <Dropzone
                accept={"audio/*"}
                multiple={false}
                onDrop={(acceptedFiles) =>
                  this.handleFileUpload(acceptedFiles, 5)
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <section key={"dropzone"}>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <FontAwesomeIcon
                        icon={Icon.faCloudUploadAlt}
                        className="me-2"
                      />
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            <div className="d-flex align-items-center">
              <div className="modal-label" />
              <div style={{ paddingTop: 10 }}>
                {audioSrc && (
                  <audio controls>
                    <source src={audioSrc} />
                    Your browser does not support the audio element.
                  </audio>
                )}
                {audioName && <div>{audioName}</div>}
              </div>
            </div>
          </div>

          <Row gutter={20}>
            <Col span={12}>
              <div className="modal-row d-flex align-items-center">
                <div className="modal-label">Thời lượng (giây):</div>

                <FormControl
                  value={bodyData.duration}
                  onChange={(e) =>
                    this.setState({
                      bodyData: { ...bodyData, duration: e.target.value },
                    })
                  }
                  placeholder="Thời lượng"
                />
              </div>
            </Col>

            <Col span={12}>
              <div className="modal-row d-flex align-items-center">
                <div className="modal-label">Loại podcast:</div>

                <Select
                  className="custom-react-select"
                  placeholder="Chọn loại podcast"
                  options={PODCAST_TYPE.map((x) => {
                    return { value: x.code, label: x.name };
                  })}
                  value={bodyData?.podcastType}
                  isMulti={false}
                  onChange={(e) => {
                    this.setState({
                      bodyData: { ...bodyData, podcastType: e },
                    });
                  }}
                  isClearable={true}
                  backspaceRemovesValue={true}
                />
              </div>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <div className="modal-row d-flex align-items-center">
                <div className="modal-label">Chủ đề:</div>

                <Select
                  className="custom-react-select"
                  placeholder="Chọn chủ đề"
                  options={listTopics.map((x) => {
                    return { value: x.id, label: x.topicName };
                  })}
                  value={bodyData?.topic}
                  isMulti={false}
                  onChange={(e) => {
                    this.setState({ bodyData: { ...bodyData, topic: e } });
                  }}
                  isClearable={true}
                  backspaceRemovesValue={true}
                />
              </div>
            </Col>

            <Col span={12}>
              <div className="modal-row d-flex align-items-center">
                <div className="modal-label">Tác giả:</div>

                <Select
                  className="custom-react-select"
                  placeholder="Chọn tác giả"
                  options={listAuthors.map((x) => {
                    return {
                      value: x.id,
                      label: `${x.fullName} (${x.nickName})`,
                    };
                  })}
                  value={bodyData?.author}
                  isMulti={false}
                  onChange={(e) => {
                    this.setState({ bodyData: { ...bodyData, author: e } });
                  }}
                  isClearable={true}
                  backspaceRemovesValue={true}
                />
              </div>
            </Col>
          </Row>

          <div className="modal-row d-flex align-items-start">
            <div className="modal-label">Mô tả:</div>

            <Input.TextArea
              value={bodyData.description}
              onChange={(e) =>
                this.setState({
                  bodyData: { ...bodyData, description: e.target.value },
                })
              }
              placeholder="Nhập mô tả"
              rows={3}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.onToggle}>
            Đóng
          </Button>
          <Button variant="primary" onClick={() => this.onSubmit()}>
            {bodyData?.id || bodyData?.id === 0 ? "Lưu" : "Tạo"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
