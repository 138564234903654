import React from "react";
import BannersService from "../../services/banners.service";
import {
  Breadcrumb,
  Button,
  InputGroup,
  FormControl,
  Spinner,
  Table,
  Tabs,
  Form,
  Pagination,
} from "@themesberg/react-bootstrap";
import { Switch, Avatar } from "antd";

import { Routes } from "../../routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import { Helper } from "../../common/helper";
import ModalBanner from "./ModalBanner";
import ModalPopup from "./ModalPopup";
import { toast } from "react-toastify";
import moment from "moment";

const { Tab } = Tabs;
const pageSize = 20;
export default class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      categoriesPage: [],
      tabActive: "banners",
      page: 1,
      pageSize: pageSize,

      modalShow: false,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true }, () => {
      this.getData();
    });
  }

  getData = async () => {
    const { tabActive } = this.state;
    if (tabActive === "banners") {
      this.getListBanners();
    } else {
      this.getListPopups();
    }
  };

  getDetailData = async (id) => {
    const { tabActive } = this.state;
    this.setState({ isLoadingDetail: true }, () => {
      if (tabActive === "banners") {
        this.getDetailBanner(id);
      } else {
        this.getDetailPopup(id);
      }
    });
  };

  getListBanners = async () => {
    const { page, pageSize } = this.state;
    let queryString = this.buildQueryString();
    if (queryString) {
      queryString =
        queryString +
        `&page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdDate:desc`;
    } else {
      queryString = `?page=${
        page ? page - 1 : 0
      }&size=${pageSize}&sort=createdDate:desc`;
    }

    const resp = await BannersService.listBanners(queryString);
    const headers = resp.headers || {};
    this.setState({
      data: resp.data,
      metadata: {
        pageCount: parseFloat(headers["x-page-count"]),
        page: parseFloat(headers["x-page-number"]),
        size: parseFloat(headers["x-page-size"]),
        total: parseFloat(headers["x-total-count"]),
      },
      isLoading: false,
    });
  };

  getListPopups = async () => {
    const { page, pageSize } = this.state;
    let queryString = this.buildQueryString();
    if (queryString) {
      queryString =
        queryString +
        `&page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdDate:desc`;
    } else {
      queryString = `?page=${
        page ? page - 1 : 0
      }&size=${pageSize}&sort=createdDate:desc`;
    }
    const resp = await BannersService.listPopups(queryString);
    const headers = resp.headers || {};
    this.setState({
      data: resp.data,
      metadata: {
        pageCount: parseFloat(headers["x-page-count"]),
        page: parseFloat(headers["x-page-number"]),
        size: parseFloat(headers["x-page-size"]),
        total: parseFloat(headers["x-total-count"]),
      },
      isLoading: false,
    });
  };

  getDetailBanner = async (id) => {
    const resp = await BannersService.getBanner(id);
    this.setState({
      dataDetail: resp.data,
      isLoadingDetail: false,
      modalShow: true,
    });
  };

  getDetailPopup = async (id) => {
    const resp = await BannersService.getPopup(id);
    this.setState({
      dataDetail: resp.data,
      isLoadingDetail: false,
      modalShow: true,
    });
  };

  onPageChanged = (page) => {
    this.setState({ page: page }, () => {
      this.getData();
    });
  };

  /**
   * xử lý với dữ liệu search trong state
   */
  onSubmitSearch = () => {
    this.setState({ page: 1 }, () => {
      this.getData();
    });
  };

  /**
   * xây dựng query string theo các giá trị trong state
   * @returns {string}
   */
  buildQueryString = () => {
    let { listChecked, startValue, endValue, querySearch } = this.state;
    let queryString = "";
    for (let p in listChecked) {
      let value = "";
      if (Array.isArray(listChecked[p]) && listChecked[p].length > 0) {
        listChecked[p].map((x) => {
          value = value + x + ",";
        });

        value = value.slice(0, -1);
      }
      if (value)
        queryString = Helper.updateQueryStringParameter(queryString, p, value);
    }

    if (querySearch) {
      queryString = Helper.updateQueryStringParameter(
        queryString,
        "name",
        querySearch.trim()
      );
    }

    return queryString;
  };

  onItemChecked = (item) => {
    const { listItemChecked = [] } = this.state;
    let newList = listItemChecked;
    if (!!listItemChecked.find((x) => x.foodId === item.foodId)) {
      newList = listItemChecked.filter((x) => x.foodId !== item.foodId);
    } else {
      newList.push(item);
    }

    this.setState({ listItemChecked: newList });
  };

  onCheckedAll = () => {
    const { data = [], listItemChecked = [] } = this.state;
    if (listItemChecked.length === data.length) {
      this.setState({ listItemChecked: [] });
    } else {
      this.setState({ listItemChecked: data });
    }
  };

  toggleModal = () => {
    // console.log('vào toggleModal')

    if (this.state.modalShow) {
      this.setState({
        modalShow: false,
        dataDetail: {},
      });
    } else {
      this.setState({
        modalShow: true,
      });
    }
  };

  renderRow = (item, index) => {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>
          <Avatar shape="square" size={64} src={item?.image} />
        </td>
        <td>
          <span className="fw-normal">{item?.name || "---"}</span>
        </td>
        <td>
          <span className="fw-normal">{item?.display || "---"}</span>
        </td>
        <td>
          <span className="fw-normal">{item?.categoryPage?.name || "---"}</span>
        </td>
        <td>
          <span className={`fw-normal`}>
            {moment(item?.timestampFrom).format("DD/MM/YYYY") || "---"}
          </span>
        </td>
        <td>
          <span className={`fw-normal`}>
            {moment(item?.timestampTo).format("DD/MM/YYYY") || "---"}
          </span>
        </td>
        <td>
          <Switch
            disabled={true}
            size="small"
            checked={item?.status === "ACTIVE"}
          />
        </td>
        <td>
          <a
            target="_blank"
            href={item?.url}
            className="fw-normal link-blue ellipsis-150"
          >
            {item?.url || "---"}
          </a>
        </td>
        <td>
          <div ref={(ref) => (this.actionContainer = ref)} className="dp-flex">
            <div
              onClick={() => this.getDetailData(item.id)}
              className={"table-action-btn"}
            >
              <FontAwesomeIcon icon={Icon.faEdit} className="me-2" />
            </div>
          </div>
        </td>
      </tr>
    );
  };

  renderContentBanner = () => {
    const { data = [], page, metadata = {} } = this.state;
    if (this.state.isLoading) {
      return (
        <div className="d-flex justify-content-center pt-8">
          <Spinner animation="border" />
        </div>
      );
    }

    return (
      <div>
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th key={1} className="border-bottom">
                STT
              </th>
              <th key={2} className="border-bottom">
                Hình ảnh
              </th>
              <th key={3} className="border-bottom">
                Tên chương trình
              </th>
              <th key={4} className="border-bottom">
                Phạm vi hiển thị
              </th>
              <th key={4} className="border-bottom">
                Vị trí hiển thị
              </th>
              <th key={4} className="border-bottom">
                Thời gian bắt đầu
              </th>
              <th key={4} className="border-bottom">
                Thời gian kết thúc
              </th>
              <th key={4} className="border-bottom">
                Hoạt động
              </th>
              <th key={4} className="border-bottom">
                Đường dẫn
              </th>
              <th key={6} className="border-bottom" />
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => this.renderRow(item, index))}
          </tbody>
        </Table>
        <Pagination className="mb-2 mb-lg-0">
          <Pagination.Prev
            disabled={metadata?.page === 0}
            onClick={(e) => this.onPageChanged(page - 1)}
          >
            Previous
          </Pagination.Prev>
          {[...Array(metadata?.pageCount).keys()].map((p) => (
            <Pagination.Item
              key={`page-${p}`}
              onClick={(e) => this.onPageChanged(p + 1)}
              active={metadata.page === p}
            >
              {" "}
              {p + 1}{" "}
            </Pagination.Item>
          ))}
          <Pagination.Next
            disabled={metadata?.page + 1 === metadata?.pageCount}
            onClick={(e) => this.onPageChanged(page + 1)}
          >
            Next
          </Pagination.Next>
        </Pagination>
      </div>
    );
  };

  render() {
    const {
      tabActive,
      data,
      querySearch,
      modalShow,
      dataDetail = {},
    } = this.state;
    if (!Array.isArray(data)) {
      return <div>Có lỗi xảy ra vui lòng liên kệ kỹ thuật</div>;
    }

    return (
      <div>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-md-0">
            <Breadcrumb
              className="d-none d-md-inline-block"
              listProps={{
                className: "breadcrumb-dark breadcrumb-transparent",
              }}
            >
              <Breadcrumb.Item to={Routes.DashboardOverview.path}>
                <FontAwesomeIcon icon={Icon.faHome} />
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                {tabActive === "banners" ? "Banners" : "Popups"}
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>

        <Tabs
          activeKey={tabActive}
          onSelect={(k) =>
            this.setState(
              {
                tabActive: k,
                listItemChecked: [],
                data: [],
                isLoading: true,
                querySearch: "",
              },
              () => this.getData()
            )
          }
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="banners" title="Banners">
            <div className="dp-flex justify-content-space-between align-items-center">
              <div
                onClick={(e) => {
                  this.setState({ dataDetail: {} }, () => {
                    this.toggleModal();
                  });
                }}
                className="custom-btn"
              >
                <FontAwesomeIcon icon={Icon.faPlus} className="me-2" /> Thêm
              </div>

              <div>
                <InputGroup className="mb-3">
                  <FormControl
                    value={querySearch}
                    onChange={(e) =>
                      this.setState({ querySearch: e.target.value })
                    }
                    onKeyDown={(e) => {
                      if (e?.keyCode === 13) {
                        this.onSubmitSearch();
                      }
                    }}
                    placeholder="Nhập chương trình"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                  />
                  <Button
                    onClick={() => {
                      this.onSubmitSearch();
                    }}
                    variant="outline-secondary"
                    id="button-addon2"
                  >
                    <FontAwesomeIcon icon={Icon.faSearch} className="me-2" />
                  </Button>
                </InputGroup>
              </div>
            </div>

            {this.renderContentBanner()}
          </Tab>

          <Tab eventKey="popups" title="Popups">
            <div className="dp-flex justify-content-space-between align-items-center">
              <div
                onClick={(e) => {
                  this.setState({ dataDetail: {} }, () => {
                    this.toggleModal();
                  });
                }}
                className="custom-btn"
              >
                <FontAwesomeIcon icon={Icon.faPlus} className="me-2" /> Thêm
              </div>

              <div>
                <InputGroup className="mb-3">
                  <FormControl
                    value={querySearch}
                    onChange={(e) =>
                      this.setState({ querySearch: e.target.value })
                    }
                    onKeyDown={(e) => {
                      if (e?.keyCode === 13) {
                        this.onSubmitSearch();
                      }
                    }}
                    placeholder="Nhập chương trình"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                  />
                  <Button
                    onClick={() => {
                      this.onSubmitSearch();
                    }}
                    variant="outline-secondary"
                    id="button-addon2"
                  >
                    <FontAwesomeIcon icon={Icon.faSearch} className="me-2" />
                  </Button>
                </InputGroup>
              </div>
            </div>

            {this.renderContentBanner()}
          </Tab>
        </Tabs>

        {tabActive === "banners" ? (
          <ModalBanner
            dataDetail={dataDetail}
            toggle={this.toggleModal}
            getData={this.getData}
            modalStatus={modalShow}
          />
        ) : (
          <ModalPopup
            dataDetail={dataDetail}
            toggle={this.toggleModal}
            getData={this.getData}
            modalStatus={modalShow}
          />
        )}
      </div>
    );
  }
}
