import isArray from "lodash/isArray";

export type ObjectType = { [key: string]: any | any[] };

export function getQueryString(
  requestUrl: string,
  params?: ObjectType
): string {
  if (typeof params === "object" && params) {
    const ret: string[] = [];
    for (const d of Object.keys(params)) {
      if (isArray(params[d])) {
        const arrayParams: string[] = [];
        for (const p of params[d]) {
          arrayParams.push(`${d}[]=` + encodeURIComponent(p));
        }
        ret.push(arrayParams.join("&"));
      } else {
        ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(params[d]));
      }
    }

    if (ret.length > 0) {
      const retString = ret.join("&");
      if (requestUrl.includes("?")) {
        requestUrl = requestUrl + "&" + retString;
      } else {
        requestUrl = requestUrl + "?" + retString;
      }
    }
  }
  return requestUrl;
}

export function updateQueryStringParameter(uri: any, key: string, value: any) {
  if (uri === undefined || uri === null) return "";
  let re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  let separator = uri.indexOf("?") !== -1 ? "&" : "?";
  if (value === null) {
    return uri.replace(re, "$1" + "$2");
  }
  if (uri.match(re)) {
    return uri.replace(re, "$1" + key + "=" + encodeURIComponent(value) + "$2");
  } else {
    return uri + separator + key + "=" + encodeURIComponent(value);
  }
}

export function deleteParamsNotUsing(params: any) {
  for (const key in params) {
    if (
      params[key] === null ||
      params[key] === undefined ||
      params[key] === ""
    ) {
      delete params[key];
    }
  }
}
