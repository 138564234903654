import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, Spin, Upload } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icon from '@fortawesome/free-solid-svg-icons';
import { API_URL } from '../../contains/common';
import ChatsService from '../../services/chats.service';
import _ from 'lodash';
import { MessageItem } from './MessageItem';
import StoreService from '../../services/store.services';
import { UserInfo } from '../../components/chat/UserInfo';
import { Client, over } from 'stompjs';
import SockJS from 'sockjs-client';
import Search from 'antd/lib/input/Search';
import { CloseCircleOutlined } from '@ant-design/icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import ChatRooms from './ChatRooms';
import { isNullOrEmpty } from 'src/utils/Lang';

const chatsService = new ChatsService();

var stompClient: Client | null = null;

const TAB_ROOMS = {
  pendingRooms: 'pendingRooms',
  processRooms: 'processRooms',
};

const DEFAULT_ROOM_SIZE = 25;

export const Chat = () => {
  const chatMsgListRef = useRef<HTMLDivElement | null>(null);

  const [loading, setLoading] = useState(false);
  const [rooms, setRooms] = useState<RoomInfo[]>([]);

  const processRoomsCur = useRef<RoomInfo[]>([]);
  const pendingRoomsCur = useRef<RoomInfo[]>([]);

  const processNoMoreData = useRef<boolean>(false);
  const pendingNoMoreData = useRef<boolean>(false);

  const isLoadMore = useRef<boolean>(false);

  const processRoomSize = useRef<number>(DEFAULT_ROOM_SIZE);
  const pendingRoomSize = useRef<number>(DEFAULT_ROOM_SIZE);
  const messageSize = useRef<number>(DEFAULT_ROOM_SIZE);

  const isLoadMoreMess = useRef<boolean>(false);
  const [messages, setMessages] = useState<MessageItemType[]>([]);
  const currentMessages = useRef<MessageItemType[]>([]);

  const [currentRoom, setCurrentRoom] = useState<RoomInfo | null>(null);
  const currentRoomId = useRef<string | null>(null);
  const [currentCustomer, setCurrentCustomer] = useState<Customer | null>(null);

  const [currentTabRoom, setCurrentTabRoom] = useState(TAB_ROOMS.pendingRooms);
  const [currentMsg, setCurrentMsg] = useState('');

  const [files, setFiles] = useState<any>(null);
  const fileUpload = useRef<any>(null);

  const store = new StoreService();

  const onListenerNewProcessRoom = (data: any) => {
    const newRoom = JSON.parse(data.body);
    const findIndex = processRoomsCur.current.findIndex(
      (room) => room.latestMessage.roomId === newRoom.latestMessage.roomId
    );

    console.log(
      'onListenerNewProcessRoom processRoomsCur.current',
      processRoomsCur.current
    );

    if (findIndex > -1) {
      processRoomsCur.current[findIndex] = newRoom;
    } else {
      processRoomsCur.current = [newRoom, ...processRoomsCur.current];
    }
  };

  const onListenerNewPendingRoom = (data: any) => {
    const newRoom = JSON.parse(data.body);
    console.log('onListenerNewPendingRoom', newRoom);
    const findIndex = pendingRoomsCur.current.findIndex(
      (room) => room.id === newRoom.id
    );
    if (findIndex > -1) {
      pendingRoomsCur.current[findIndex] = newRoom;
    } else {
      pendingRoomsCur.current = [newRoom, ...pendingRoomsCur.current];
    }
  };

  const getRoomsData = async () => {
    const result = await Promise.all([
      chatsService.getPendingRooms(DEFAULT_ROOM_SIZE),
      chatsService.getProcessedRooms(DEFAULT_ROOM_SIZE),
    ]);

    pendingRoomsCur.current = result[0].content;
    processRoomsCur.current = result[1].content;
    setRooms(result[0].content);
    if (result[0].content.length > 0) {
      onSelectRoom(result[0].content[0]);
    }
    setLoading(false);
  };

  const onConnected = async () => {
    stompClient?.subscribe(
      '/users/queue/new.processed.room',
      onListenerNewProcessRoom,
      { 'auto-delete': 'true' }
    );
    stompClient?.subscribe(
      '/topic/new.pending.room',
      onListenerNewPendingRoom,
      { 'auto-delete': 'true' }
    );
  };

  const onError = (err: any) => {
    console.log('Connected Error', err);
    setLoading(false);
    setTimeout(() => {
      connect();
    }, 1000);
  };

  const onDisconnected = () => {
    console.log('onDisconnected');
  };

  const connect = () => {
    let Sock = new SockJS(
      `${API_URL}websocket?access_token=${store.accessToken}`
    );
    stompClient = over(Sock);
    stompClient?.connect({}, onConnected, onError);
  };

  useEffect(() => {
    connect();
    chatMsgListRef.current?.addEventListener('scroll', () => {
      if (
        (chatMsgListRef.current?.scrollTop ?? 2) <= 1 &&
        !isLoadMoreMess.current
      ) {
        isLoadMoreMess.current = true;
        if (currentRoomId.current) {
          getMessagesInRoom(currentRoomId.current, messageSize.current);
        }
      }
    });
    getRoomsData();
    return () => {
      stompClient?.disconnect(onDisconnected);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onGetListMessage = (mess: MessageItemType[]) => {
    currentMessages.current = mess;
    setMessages(
      currentMessages.current.sort(function (x, y) {
        return x.addedTimestamp - y.addedTimestamp;
      })
    );
  };

  const onListenerNewMessage = (data: any) => {
    console.log('onListenerNewMessage', JSON.parse(data.body));
    const newMessage = JSON.parse(data.body);
    if (newMessage.roomId === currentRoomId.current) {
      currentMessages.current = [
        ...currentMessages.current,
        JSON.parse(data.body),
      ];
      setMessages(currentMessages.current);
    }
  };

  useEffect(() => {
    if (!isLoadMoreMess.current) {
      chatMsgListRef?.current?.scroll({
        top: chatMsgListRef?.current?.scrollHeight,
        left: 0,
      });
    }
  }, [messages]);

  const getMessagesInRoom = async (roomId: string, size: number) => {
    const result = await chatsService.getMessagesInRoom(roomId, size);
    onGetListMessage(result.content ?? []);
    setLoading(false);
    messageSize.current += DEFAULT_ROOM_SIZE;
    isLoadMoreMess.current = false;
  };

  const joinRoom = (roomId: string) => {
    getMessagesInRoom(roomId, messageSize.current);
    stompClient?.subscribe(
      `/users/queue/${roomId}.message`,
      onListenerNewMessage,
      { 'auto-delete': 'true' }
    );
  };

  const onSelectRoom = (room: RoomInfo) => {
    if (room?.id) {
      messageSize.current = DEFAULT_ROOM_SIZE;
      setLoading(true);
      setCurrentRoom(room);
      currentRoomId.current = room.id;
      setCurrentCustomer({
        ...room?.customer,
      });
      joinRoom(room.id);
    } else {
      setCurrentRoom(null);
      setCurrentCustomer(null);
    }
  };

  const onReceiveContact = async (room: RoomInfo) => {
    setLoading(true);
    await chatsService.receiveContact(room.id);
    getRoomsData();
  };

  const onChatRoomLoadMore = async () => {
    if (!isLoadMore.current) {
      isLoadMore.current = true;
      setLoading(true);
      if (
        currentTabRoom === TAB_ROOMS.pendingRooms &&
        !pendingNoMoreData.current
      ) {
        const newSize = pendingRoomSize.current + DEFAULT_ROOM_SIZE;
        const result = await chatsService.getPendingRooms(newSize);
        pendingRoomSize.current = newSize;
        pendingRoomsCur.current = result.content;
        setRooms(result.content);
        pendingNoMoreData.current = newSize > result.totalElements;
        isLoadMore.current = false;
      }
      if (
        currentTabRoom === TAB_ROOMS.processRooms &&
        !processNoMoreData.current
      ) {
        const newSize = processRoomSize.current + DEFAULT_ROOM_SIZE;
        const result = await chatsService.getProcessedRooms(newSize);
        processRoomSize.current = newSize;
        processRoomsCur.current = result.content;
        setRooms(result.content);
        processNoMoreData.current = newSize > result.totalElements;
        isLoadMore.current = false;
      }
      setLoading(false);
    }
  };

  const handleChangeTabRoom = (type: string) => {
    setCurrentTabRoom(type);
    if (type === TAB_ROOMS.pendingRooms) {
      setRooms(pendingRoomsCur.current);
      onSelectRoom(pendingRoomsCur.current[0]);
    }
    if (type === TAB_ROOMS.processRooms) {
      setRooms(processRoomsCur.current);
      onSelectRoom(processRoomsCur.current[0]);
    }
  };

  const handleSubmitMessage = (msg: string) => {
    if (
      (isNullOrEmpty(msg) && isNullOrEmpty(fileUpload.current)) ||
      currentTabRoom === TAB_ROOMS.pendingRooms
    ) {
      return;
    }

    setCurrentMsg('');
    var chatMessage = {
      content: msg,
      attachments: fileUpload.current,
    };
    stompClient?.send(
      `/babiuni/chat/rooms/${currentRoomId.current}/send.message`,
      {},
      JSON.stringify(chatMessage)
    );
    onClickDeleteImage();
  };

  const handleChangeFile = (fileList: any) => {
    console.log('handleChangeFile', fileList);
    setFiles(fileList.file);
  };

  const handleUploadFile = async (file: any) => {
    console.log('handleUploadFile', file);
    setLoading(true);
    const response = await chatsService.uploadFile(file);
    fileUpload.current = response ?? null;
    setLoading(false);
    return false;
  };

  const onClickDeleteImage = () => {
    fileUpload.current = null;
    setFiles(null);
  };

  const inMyRoom = (customerId?: number) => {
    return !rooms.find((item: any) => item.userId === customerId);
  };

  const onSearch = async (txt: string) => {
    if (txt === '') {
      if (currentTabRoom === TAB_ROOMS.pendingRooms) {
        chatsService.getPendingRooms(DEFAULT_ROOM_SIZE);
      }
      if (currentTabRoom === TAB_ROOMS.processRooms) {
        chatsService.getProcessedRooms(DEFAULT_ROOM_SIZE);
      }
    } else {
      const result = await chatsService.searchRoomFromUserId(txt);
      setRooms(result.content);
    }
  };

  const onKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      handleSubmitMessage(e.target.value);
      e.preventDefault();
    }
  };

  return (
    <div className="full-page-container">
      <Search
        placeholder={'Nhập user ID, tin nhắn'}
        className={'chat-filter-input'}
        allowClear
        enterButton="Search"
        size="large"
        onSearch={onSearch}
      />
      <div className="chat-container">
        <div className="chat-sidebar">
          <div className={'chat-buttons-filter'}>
            <Button
              onClick={() => handleChangeTabRoom(TAB_ROOMS.pendingRooms)}
              type={
                currentTabRoom === TAB_ROOMS.pendingRooms
                  ? 'primary'
                  : 'default'
              }
              className={'chat-buttons-filter__btn mgr-5'}
            >
              Chưa xử lý ({pendingRoomsCur.current.length})
            </Button>
            <Button
              onClick={() => handleChangeTabRoom(TAB_ROOMS.processRooms)}
              type={
                currentTabRoom === TAB_ROOMS.processRooms
                  ? 'primary'
                  : 'default'
              }
              className={'chat-buttons-filter__btn'}
            >
              DS của tôi ({processRoomsCur.current.length})
            </Button>
          </div>
          <ChatRooms
            isLoadMore={isLoadMore.current}
            rooms={rooms}
            currentRoom={currentRoom}
            showBtnReceive={currentTabRoom === TAB_ROOMS.pendingRooms}
            onSelectRoom={onSelectRoom}
            onReceiveContact={onReceiveContact}
            onLoadMore={onChatRoomLoadMore}
          />
        </div>

        <div className="chat-message">
          <div className="chat-message-header">
            <div className="chat-message-header__userinfo">
              {currentTabRoom === TAB_ROOMS.pendingRooms &&
              pendingRoomsCur.current.length === 0 ? (
                <div>Không có tin nhắn gửi về hệ thống</div>
              ) : (
                <span className="chat-message-header__userinfo__account-type">
                  Đến userId: {_.get(currentCustomer, 'id', '---')}
                </span>
              )}
            </div>
          </div>
          <Spin spinning={loading}>
            <div className="chat-message-list" ref={chatMsgListRef}>
              {/* {isLoadMoreMess.current && <p>Loading...</p>} */}
              {messages.map((item) => (
                <MessageItem
                  key={item?.id + 'message'}
                  item={item}
                  itMe={!item.fromCustomer}
                  currentCustomer={currentCustomer}
                />
              ))}
            </div>
          </Spin>

          {currentRoom && inMyRoom(currentCustomer?.id) && (
            <div className="chat-message-input-box">
              <div className="chat-message-helper">
                {files?.name && (
                  <div className="_image-upload">
                    {files?.name}
                    <CloseCircleOutlined
                      style={{ height: 20, width: 20 }}
                      onClick={onClickDeleteImage}
                    />
                  </div>
                )}
                <Upload
                  showUploadList={false}
                  onChange={handleChangeFile}
                  beforeUpload={handleUploadFile}
                  multiple={true}
                >
                  <FontAwesomeIcon
                    className={'pointer'}
                    icon={Icon.faPaperclip as IconDefinition}
                  />
                </Upload>
              </div>
              <div className={'chat-input '}>
                <Input.TextArea
                  autoFocus={true}
                  value={currentMsg}
                  onChange={(e) => setCurrentMsg(e.target.value)}
                  // onPressEnter={(e: any) => handleSubmitMessage(e.target.value)}
                  className={'chat-message-input'}
                  placeholder={'Enter để gửi'}
                  onKeyDown={onKeyDown}
                />
                <Button
                  type={'primary'}
                  onClick={() => handleSubmitMessage(currentMsg)}
                >
                  Gửi
                </Button>
              </div>
            </div>
          )}
        </div>

        <UserInfo customer={currentCustomer} />
      </div>
    </div>
  );
};
