import APIService from "./api.services";

class NewsService extends APIService {
  constructor() {
    super();
    this.modulePath = "news";
  }
}

export default NewsService;
