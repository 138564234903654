import React from "react";
import { Modal, Button, FormControl } from "@themesberg/react-bootstrap";
import { Row, Col, Input } from "antd";
import _ from "lodash";
import FileService from "../../services/file.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import Dropzone from "react-dropzone";
import Select from "react-select";

import {
  NURSERY_EDUCATIONS_DEVELOPMENT_TYPE,
  NURSERY_EDUCATIONS_MONTHS,
  NURSERY_EDUCATIONS_POST_FORMAT,
  NURSERY_EDUCATIONS_POST_TYPE,
  PORTRAIT_ORIENTATION_LANDSCAPE,
} from "../../contains/common";
import { toast } from "react-toastify";
import NurseryEducations from "../../services/nursery-educations.service";
import ProfessionalsService from "../../services/professionals.service";
import Editor from "../../components/Editor";
import ReactHlsPlayer from "react-hls-player";

export default class ModalPost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bodyData: {},
      todoLists: [],
    };
    this.timeOut = "";
    this.fileService = new FileService();
  }

  componentDidMount() {
    this.getListProfessions();
    this.getListTeachingAids();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(prevProps.dataDetail) !==
      JSON.stringify(this.props.dataDetail)
    ) {
      const { dataDetail = {} } = this.props;
      console.log("dataDetail", this.props.dataDetail);
      let monthsArr = [];
      let monthObjArr = [];
      if (dataDetail?.months) {
        monthsArr = dataDetail?.months.split(",");
        monthsArr.map((item, index) => {
          let curMonth = NURSERY_EDUCATIONS_MONTHS.find((x) => x.code === item);
          if (curMonth) {
            monthObjArr.push({
              value: curMonth?.code,
              label: curMonth?.name,
            });
          }
        });
      }

      let teachingAids = [];
      if (Array.isArray(dataDetail?.teachingAids)) {
        teachingAids = dataDetail?.teachingAids.map((x) => ({
          value: x.id,
          label: x.teachingName,
        }));
      }

      let newObj = {
        ...dataDetail,
        postFormat: dataDetail?.postFormat && {
          value: dataDetail?.postFormat,
          label: dataDetail?.postFormat,
        },
        postType: dataDetail?.postType && {
          value: dataDetail?.postType,
          label: dataDetail?.postType,
        },
        topic: dataDetail?.topicName && {
          value: dataDetail?.topicName,
          label: dataDetail?.topicName,
        },
        professional: dataDetail?.professional
          ? {
              value: dataDetail?.professional?.id,
              label: dataDetail?.professional?.name,
            }
          : null,
        teachingAids: teachingAids,
        months: monthObjArr,
      };

      this.setState({
        bodyData: newObj,
        content: dataDetail?.content,
        originalData: _.cloneDeep(newObj),
      });
    }
  }

  onSubmit = async () => {
    const { bodyData = {}, content = "" } = this.state;
    const { getData } = this.props;

    if (!bodyData?.postName || !bodyData?.postName.trim()) {
      toast.error("Vui lòng nhập tên bài viết");
      return;
    }

    if (!bodyData?.postFormat) {
      toast.error("Vui lòng chọn định dạng bài viết");
      return;
    }
    if (!bodyData?.topic) {
      toast.error("Vui lòng chọn chủ đề");
      return;
    }

    if (!bodyData?.postType) {
      toast.error("Vui lòng chọn loại bài viết");
      return;
    }

    if (!content || !content.trim()) {
      toast.error("Vui lòng nhập nội dung");
      return;
    }

    if (bodyData?.postFormat?.value !== "Video") {
      if (!bodyData?.image) {
        toast.error("Vui lòng nhập ảnh");
        return;
      }

      if (!bodyData?.readingTime) {
        toast.error("Vui lòng thêm thời gian đọc");
        return;
      }

      if (!Array.isArray(bodyData?.months) || bodyData?.months.length === 0) {
        toast.error("Vui lòng thêm tháng");
        return;
      }

      if (!bodyData?.ordinalNumber) {
        toast.error("Vui lòng nhập số thứ tự");
        return;
      }
    } else {
      if (!bodyData?.attachment) {
        toast.error("Vui lòng tải lên video");
        return;
      }

      // if (!Array.isArray(bodyData?.teachingAids) || bodyData?.teachingAids.length === 0) {
      //     toast.error("Vui lòng thêm giáo cụ")
      //     return
      // }
    }

    let monthStr = "";
    if (Array.isArray(bodyData?.months)) {
      monthStr = ",";
      bodyData?.months.map((item, index) => {
        monthStr = monthStr + `${item?.value},`;
      });
    }

    let teachingAidIds = [];
    if (Array.isArray(bodyData?.teachingAids)) {
      bodyData?.teachingAids.map((item, index) => {
        teachingAidIds.push(item.value);
      });
    }

    let body = {
      postName: bodyData?.postName.trim(),
      summary: bodyData?.summary ? bodyData?.summary.trim() : "",
      topicName: bodyData?.topic?.value,
      content: content.trim(),
      ordinalNumber: parseFloat(bodyData?.ordinalNumber),
      readingTime: parseFloat(bodyData?.readingTime),
      postType: bodyData?.postType.value,
      postFormat: bodyData?.postFormat?.value,
      professionalId: bodyData?.professional?.value,
      months: monthStr,
      note1: bodyData?.note1 ? bodyData?.note1.trim() : "",
      note2: bodyData?.note2 ? bodyData?.note2.trim() : "",
      image: bodyData?.image,
      attachment: bodyData?.attachment,
      teachingAidIds: teachingAidIds,
    };

    try {
      if (bodyData?.id || bodyData?.id === 0) {
        await NurseryEducations.updatePost(bodyData?.id, body);
        toast.success("Sửa thành công");
      } else {
        await NurseryEducations.createPost(body);
        toast.success("Tạo thành công");
      }
      this.onToggle();
      getData();
    } catch (error) {
      let msg = "";
      switch (error?.message) {
        case "nursery_education_duplicate":
          msg = "Bài viết đã tồn tại";
          break;
        case "nursery_education_not_found":
          msg = "Bài viết không tồn tại";
          break;
        default:
          break;
      }

      toast.error(
        msg || "Có lỗi xảy ra vui lòng liên hệ kỹ thuật để được hỗ trợ."
      );
    }
  };

  handleFileUploadByPostsFormat = async (files) => {
    const file = files[0];
    let fileSize = file.size / 1024 / 1024; // in MiB
    if (fileSize > 500) {
      return alert("Dung lượng file lớn hơn 500 MiB, vui lòng chọn file khác.");
    }

    let { bodyData = {} } = this.state;

    let body = {
      objectType: "nurseryEducations",
      displayType: 1,
    };
    // objectType: "posts"
    // objectId: ID bài viết
    // displayType: 1(thumbnail), 2( icon), 3(Cover), 4(video), 5(Audio), 6(Infographic), 7(avatar)

    try {
      const resp = await this.fileService.uploadImage(file, body);
      if (resp?.linkUrl?.length > 0) {
        bodyData.image = resp.linkUrl[0];
      }
    } catch (error) {
      console.log(error);
    }

    this.setState({ bodyData: bodyData });
  };

  handleFileUpload = async (files) => {
    const file = files[0];
    let fileSize = file.size / 1024 / 1024; // in MiB
    if (fileSize > 500) {
      return alert("Dung lượng file lớn hơn 500 MiB, vui lòng chọn file khác.");
    }

    let { bodyData = {} } = this.state;

    let body = {
      objectType: "nurseryEducations",
      displayType: 4,
      orientation: PORTRAIT_ORIENTATION_LANDSCAPE,
    };
    // objectType: "posts"
    // objectId: ID bài viết
    // displayType: 1(thumbnail), 2( icon), 3(Cover), 4(video), 5(Audio), 6(Infographic), 7(avatar)
    try {
      const resp = await this.fileService.uploadImage(file, body);
      if (resp?.linkUrl?.length > 0) {
        setTimeout(() => {
          bodyData.attachment = {
            videoId: resp?.attachId,
            videoIdStr: resp?.attachIdStr,
            videoName: resp.fileName,
            ...resp,
          };
          this.setState({
            bodyData,
          });
        }, 10000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  onToggle = () => {
    const { toggle } = this.props;
    this.setState(
      {
        bodyData: {},
        todoLists: [],
        content: "",
      },
      () => {
        toggle();
      }
    );
  };

  getListProfessions = async (searchString) => {
    let queryString = `?page=0&size=${100}&sort=createdDate:desc${
      searchString ? `&name=${encodeURIComponent(searchString)}` : ""
    }`;

    const resp = await ProfessionalsService.listProfessions(queryString);
    this.setState({ professionals: resp.data || [] });
  };

  getListTeachingAids = async (searchString) => {
    let queryString = `?page=0&size=${100}&sort=createdDate:desc${
      searchString ? `&teachingName=${encodeURIComponent(searchString)}` : ""
    }`;

    const resp = await NurseryEducations.listTeachingAids(queryString);
    this.setState({ teachingAids: resp.data || [] });
  };

  onInputSelectChange = (value, prop) => {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
    }
    this.timeOut = setTimeout(() => {
      if (prop === "teachingAids") {
        this.getListTeachingAids(value);
      } else {
        this.getListProfessions(value);
      }
    }, 500);
  };

  renderByType = () => {
    const { bodyData = {} } = this.state;
    switch (bodyData?.postFormat?.value) {
      case "Video":
        let attachLink = Array.isArray(bodyData?.attachment?.linkUrl)
          ? bodyData?.attachment?.linkUrl[0]
          : "";
        let contentType = bodyData?.attachment?.contentType;
        //watchingTime
        return (
          <div>
            <div className="modal-row d-flex align-items-center">
              <div className="modal-label-small">Attach Video:</div>
              <Dropzone
                accept={"video/*"}
                multiple={false}
                onDrop={(acceptedFiles) => this.handleFileUpload(acceptedFiles)}
              >
                {({ getRootProps, getInputProps }) => (
                  <section key={"dropzone"}>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <FontAwesomeIcon
                        icon={Icon.faCloudUploadAlt}
                        className="me-2"
                      />
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>

            {!!attachLink && contentType.indexOf("video/") >= 0 && (
              <ReactHlsPlayer
                src={attachLink}
                autoPlay={false}
                controls={true}
                width="500"
                height="auto"
              />
            )}

            <div className="modal-row d-flex align-items-center">
              <div className="modal-label-small">Chú thích 1:</div>

              <FormControl
                value={bodyData.note1}
                onChange={(e) =>
                  this.setState({
                    bodyData: { ...bodyData, note1: e.target.value },
                  })
                }
                placeholder="Chú thích 1"
              />
            </div>

            <div className="modal-row d-flex align-items-center">
              <div className="modal-label-small">Chú thích 2:</div>

              <FormControl
                value={bodyData.note2}
                onChange={(e) =>
                  this.setState({
                    bodyData: { ...bodyData, note2: e.target.value },
                  })
                }
                placeholder="Chú thích 2"
              />
            </div>
          </div>
        );
      default:
        return (
          <div>
            <div className="modal-row d-flex align-items-center">
              <div className="modal-label-small">Số thứ tự:</div>

              <FormControl
                value={bodyData.ordinalNumber}
                onChange={(e) =>
                  this.setState({
                    bodyData: { ...bodyData, ordinalNumber: e.target.value },
                  })
                }
                placeholder="Số thứ tự"
              />
            </div>

            <div className="modal-row d-flex align-items-center">
              <div className="modal-label-small">Thời gian đọc (m):</div>

              <FormControl
                value={bodyData.readingTime}
                onChange={(e) =>
                  this.setState({
                    bodyData: { ...bodyData, readingTime: e.target.value },
                  })
                }
                placeholder="Thời gian đọc"
              />
            </div>
          </div>
        );
    }
  };

  render() {
    const { modalStatus } = this.props;
    const {
      content,
      professionals = [],
      teachingAids = [],
      bodyData = {},
    } = this.state;

    return (
      <Modal
        dialogClassName="modal-90w"
        as={Modal.Dialog}
        show={modalStatus}
        onHide={this.onToggle}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {bodyData?.id || bodyData?.id === 0
              ? "Chỉnh sửa bài viết"
              : "Thêm mới bài viết"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row gutter={20}>
            <Col span={12}>
              <div className="modal-row d-flex align-items-center">
                <div className="modal-label">Định dạng bài viết:</div>

                <Select
                  className="custom-react-select"
                  placeholder="Chọn định dạng bài viết"
                  options={NURSERY_EDUCATIONS_POST_FORMAT.map((x) => {
                    return { value: x.name, label: x.name };
                  })}
                  value={bodyData?.postFormat}
                  isMulti={false}
                  onChange={(e) => {
                    this.setState({
                      bodyData: {
                        ...bodyData,
                        ordinalNumber: null,
                        readingTime: null,
                        note1: "",
                        note2: "",
                        postFormat: e,
                        months: [],
                        teachingAids: [],
                      },
                    });
                  }}
                  isClearable={true}
                  backspaceRemovesValue={true}
                />
              </div>
            </Col>

            <Col span={12}>
              <div className="modal-row d-flex align-items-center">
                <div className="modal-label">Chủ đề:</div>

                <Select
                  className="custom-react-select"
                  placeholder="Chọn chủ đề"
                  options={NURSERY_EDUCATIONS_DEVELOPMENT_TYPE.map((x) => {
                    return { value: x.name, label: x.name };
                  })}
                  value={bodyData?.topic}
                  isMulti={false}
                  onChange={(e) => {
                    this.setState({ bodyData: { ...bodyData, topic: e } });
                  }}
                  isClearable={true}
                  backspaceRemovesValue={true}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className="modal-row d-flex align-items-center">
                <div className="modal-label">Tên bài viết:</div>

                <FormControl
                  value={bodyData.postName}
                  onChange={(e) =>
                    this.setState({
                      bodyData: { ...bodyData, postName: e.target.value },
                    })
                  }
                  placeholder="Nhập tên bài viết"
                />
              </div>
            </Col>
          </Row>
          <Row gutter={20}>
            {bodyData?.postFormat?.value === "Video" ? (
              <Col span={24}>
                <div className="modal-row d-flex align-items-center">
                  <div className="modal-label">Giáo cụ:</div>

                  {Array.isArray(teachingAids) && (
                    <Select
                      className="custom-react-select"
                      placeholder="Chọn giáo cụ"
                      onInputChange={(e) =>
                        this.onInputSelectChange(e, "teachingAids")
                      }
                      options={teachingAids.map((x) => {
                        return { value: x.id, label: x.teachingName };
                      })}
                      value={bodyData?.teachingAids}
                      isMulti={true}
                      onChange={(e) => {
                        this.setState({
                          bodyData: { ...bodyData, teachingAids: e },
                        });
                      }}
                      isClearable={true}
                      backspaceRemovesValue={true}
                    />
                  )}
                </div>
              </Col>
            ) : (
              <Col span={24}>
                <div className="modal-row d-flex align-items-center">
                  <div className="modal-label">Tháng:</div>

                  <Select
                    className="custom-react-select"
                    placeholder="Chọn tháng"
                    options={NURSERY_EDUCATIONS_MONTHS.map((x) => {
                      return { value: x.code, label: x.name };
                    })}
                    value={bodyData?.months}
                    isMulti={true}
                    onChange={(e) => {
                      let arr = e;
                      if (e.find((x) => x.value === "ALL")) {
                        arr = NURSERY_EDUCATIONS_MONTHS.filter(
                          (x) => x.code !== "ALL"
                        ).map((x) => {
                          return { value: x.code, label: x.name };
                        });
                      }
                      this.setState({ bodyData: { ...bodyData, months: arr } });
                    }}
                    isClearable={true}
                    backspaceRemovesValue={true}
                  />
                </div>
              </Col>
            )}
          </Row>

          <Row gutter={20}>
            <Col span={16}>
              <div className="modal-row d-flex align-items-start">
                <div className="modal-label">Mô tả:</div>

                <Input.TextArea
                  value={bodyData.summary}
                  onChange={(e) =>
                    this.setState({
                      bodyData: { ...bodyData, summary: e.target.value },
                    })
                  }
                  placeholder="Nhập mô tả"
                  rows={3}
                />
              </div>
              <div className="modal-row d-flex align-items-start">
                <div className="modal-label">Nội dung bài viết:</div>
                <div className={"editor-container"}>
                  <Editor
                    key={"editor1"}
                    ref={(ref) => (this.editor1 = ref)}
                    style={{ height: 250 }}
                    value={content || ""}
                    onChange={(e) => {
                      this.setState({ content: e });
                    }}
                  />
                </div>
              </div>
            </Col>

            <Col span={8}>
              <div className="modal-row">
                <div className="d-flex align-items-center">
                  <div className="modal-label-small">Ảnh bìa bài viết:</div>
                  <Dropzone
                    accept={"image/*"}
                    multiple={false}
                    onDrop={(acceptedFiles) =>
                      this.handleFileUploadByPostsFormat(acceptedFiles)
                    }
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section key={"dropzone"}>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <FontAwesomeIcon
                            icon={Icon.faCloudUploadAlt}
                            className="me-2"
                          />
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
                <div className="d-flex align-items-center">
                  <div className="modal-label-small" />
                  <div>
                    {bodyData.image && (
                      <img
                        src={bodyData.image}
                        style={{ maxHeight: 300, maxWidth: "100%" }}
                      />
                    )}
                  </div>
                </div>
              </div>

              {this.renderByType()}

              <div className="modal-row d-flex align-items-center">
                <div className="modal-label-small">Loại bài viết:</div>
                <Select
                  className="custom-react-select"
                  placeholder="Chọn loại bài viết"
                  options={NURSERY_EDUCATIONS_POST_TYPE.map((x) => {
                    return { value: x.name, label: x.name };
                  })}
                  value={bodyData?.postType}
                  isMulti={false}
                  onChange={(e) => {
                    this.setState({ bodyData: { ...bodyData, postType: e } });
                  }}
                  isClearable={true}
                  backspaceRemovesValue={true}
                />
              </div>

              <div className="modal-row d-flex align-items-center">
                <div className="modal-label-small">Chuyên gia:</div>

                {professionals && (
                  <Select
                    className="custom-react-select"
                    placeholder="Chọn chuyên gia"
                    onInputChange={this.onInputSelectChange}
                    options={professionals.map((x) => {
                      return { value: x.id, label: x.name };
                    })}
                    value={bodyData?.professional}
                    isMulti={false}
                    onChange={(e) => {
                      this.setState({
                        bodyData: { ...bodyData, professional: e },
                      });
                    }}
                    isClearable={true}
                    backspaceRemovesValue={true}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.onToggle}>
            Đóng
          </Button>
          <Button variant="primary" onClick={() => this.onSubmit()}>
            {bodyData?.id || bodyData?.id === 0 ? "Lưu" : "Tạo"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
