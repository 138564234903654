import {
  Input,
  Modal,
  Form,
  FormInstance,
  notification,
  Select,
  Button,
} from "antd";
import React, { useEffect } from "react";
import { isNullOrEmpty } from "src/utils/Lang";
import Editor from "src/components/Editor";
import notificationPostService from "src/services/notification-post";
import { NOTIFICATION_TYPES } from "src/utils/constant";

type Props = {
  notificationInfo?: any;
  onHide: () => void;
  onSubmitSuccess: () => void;
};

const ModalCreatePostNoti = (props: Props) => {
  const { onHide, onSubmitSuccess, notificationInfo } = props;

  const [form] = Form.useForm();
  const formRef =
    React.useRef<FormInstance<CreatePostNotificationParams> | null>(null);
  const isCreatePost = isNullOrEmpty(notificationInfo?.id);

  const onSelectType = (value: number) => {};

  const handleSubmit = async (values: CreatePostNotificationParams) => {
    let response;
    let message;
    if (isCreatePost) {
      response = await notificationPostService.create(values);
      message = "Tạo";
    } else {
      response = await notificationPostService.edit(
        notificationInfo?.id,
        values
      );
      message = "Cập nhật";
    }

    if (response?.status === 200) {
      notification.success({
        message: `${message} bài viết thành công`,
      });
      onSubmitSuccess();
    } else {
      notification.error({
        message: `${message} bài viết không thành công`,
      });
    }
  };

  useEffect(() => {
    formRef?.current?.setFieldsValue({
      postName: notificationInfo?.postName,
      postType: notificationInfo?.postType,
      content: notificationInfo?.content ?? "",
    });
  }, [notificationInfo]);

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 18 },
  };

  const { Option } = Select;

  return (
    <Modal
      open={true}
      title={isCreatePost ? "Cập nhật bài viết" : "Thêm mới bài viết"}
      onCancel={onHide}
      footer={[]}
      width={"70%"}
    >
      <Form
        form={form}
        name="form-create"
        onFinish={handleSubmit}
        ref={formRef}
        {...formItemLayout}
      >
        <Form.Item
          name="postName"
          label="Tên bài viết"
          rules={[{ required: true }]}
        >
          <Input placeholder="Nhập tên bài viết" />
        </Form.Item>

        <Form.Item
          name="postType"
          label="Loại thông báo"
          rules={[{ required: true }]}
        >
          <Select
            allowClear
            placeholder="Loại thông báo"
            optionFilterProp="children"
            style={{ width: 150 }}
            onChange={onSelectType}
          >
            {NOTIFICATION_TYPES.map((item) => (
              <Option value={item.id} key={"modal-noti-type-" + item.id}>
                {item.title}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="content"
          label="Nội dung"
          initialValue=""
          rules={[{ required: true }]}
        >
          <Editor key={"editor1"} className={"ql-editor"} />
        </Form.Item>

        <Form.Item>
          <div className="form-group">
            <Button type="primary" htmlType="submit">
              {isCreatePost ? "Thêm mới" : "Cập nhật"}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalCreatePostNoti;
